import React, { useEffect, useRef } from "react";
import axios from "axios";
import Typography from '@mui/material/Typography';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import DeleteIcon from '@mui/icons-material/Delete';
import {toast } from "react-toastify";
import {geraIterAleatoria} from "../Function/Base";

import {
  FormContainer,
  InputArea,
  Label,
  Input2,
  Select1,
  ButtonArea,
  Button
} from "../Styles/Styles";


const FromProductsServices = ({
  handleCloseL,
  openL,
  handleOpenL,
  regist,
  onEdit,
  setOnEdit,
  getProducts,
  setOpen
}) => {
  const ref = useRef();
  const handleClose = () => setOpen(false);
  const IdCompany = localStorage.getItem("id_company");
  const IdUser = localStorage.getItem("id");
  const [typeProducts, setTypeProducts] = React.useState({ value: "flex" });
  const [statusMovProducts, setStatusMovProducts] = React.useState(false);

  const onTypeProductsChange = (event) =>{
   if( event.target.value === "P"){
    setTypeProducts({ value: "flex" });
   } else {
    setTypeProducts({ value: "none" });
   }  
  };

  const limparCampos = () => {
    const product = ref.current;

    product.cod.value = "";
    product.description.value = "";
    product.value_vlr.value = "";
    product.location.value = "";
    product.brand.value = "";
    product.minimum_stock.value = "";
    product.current_inventory.value = "";
    product.supplier.value = "";
    product.type.value = "P";
    product.reference_code.value = "";
    product.bar_code.value = "";
    product.model.value = "";
    product.application.value = "";
    product.st.value = "1";

  }

  useEffect(() => {
    const product = ref.current;
    
    if (regist) {
      limparCampos();
    } else {

      if( onEdit.type === "P"){
        setTypeProducts({ value: "flex" });
       } else {
        setTypeProducts({ value: "none" });
      };  

      if (onEdit) {
        let exitsQtd = (onEdit.exits === null? 0 : onEdit.exits.toString());
        let appetizerQtd = (onEdit.appetizer === null? 0 : onEdit.appetizer.toString());
        
        let currentInventory = ((+ parseInt(appetizerQtd)) - (+ parseInt(exitsQtd)));

        if(exitsQtd > 0){
          setStatusMovProducts(true);
        }

        product.cod.value = onEdit.id;
        product.description.value = onEdit.description;
        product.value_vlr.value = onEdit.value_vlr;
        product.location.value = onEdit.location;
        product.brand.value = onEdit.brand;
        product.minimum_stock.value = onEdit.minimum_stock;
        product.current_inventory.value = currentInventory;
        product.appetizer.value = appetizerQtd;
        product.exits.value = exitsQtd;
        product.supplier.value = onEdit.supplier;
        product.type.value = onEdit.type;
        product.reference_code.value = onEdit.reference_code;
        product.bar_code.value = onEdit.bar_code;
        product.model.value = onEdit.model;
        product.application.value = onEdit.application;
        product.st.value = onEdit.st;

      };
    };
    // eslint-disable-next-line
  }, []);

  const handleDelete = async (e) => {
    e.preventDefault();
    const product = ref.current;
    handleOpenL();

    if (statusMovProducts === true) {
      handleCloseL();
      return toast.error("Produto/Serviço possui movimentações em aberto, excluir movimentações primeiro!");
    }

    if (!window.confirm("Tem certeza que deseja excluir?")) {
      handleCloseL();
      return;
    } else {
      await axios
        .delete("products-services/products-services-list/" + IdCompany + "/" + product.cod.value)
        .then(() => {
          toast.success("Cadastro excluido com sucesso!");
          getProducts();
          setOnEdit(null);
          handleClose();
        })
        .catch(() => toast.error("Erro, não foi possível excluir!"));
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    handleOpenL();

    const product = ref.current;

    if (
      !product.description.value
    ) {
      handleCloseL();
      return toast.warn("Preencha o campos Descrição !");
    };
    if (
      !product.value_vlr.value
    ) {
      handleCloseL();
      return toast.warn("Preencha o campos Fornecedor !");
    };
    if (
      !product.bar_code.value
    ) {
      product.bar_code.value = 968+geraIterAleatoria(10);
    };

    if (onEdit) {
      await axios
        .put("products-services/products-services-list/", {
          id: product.cod.value,
          description: product.description.value.trim(),
          value_vlr: product.value_vlr.value.trim(),
          location: product.location.value.trim(),
          brand: product.brand.value.trim(),
          minimum_stock: product.minimum_stock.value.trim(),
          current_inventory: product.current_inventory.value.trim(),
          supplier: product.supplier.value,
          type: product.type.value,
          reference_code: product.reference_code.value.trim(),
          bar_code: product.bar_code.value.trim(),
          model: product.model.value.trim(),
          application: product.application.value.trim(),
          st: product.st.value,
          id_company: IdCompany,
          user: IdUser,
        })
        .then(() => {

          limparCampos();

          getProducts();
          setOnEdit(null);
          handleClose();

          toast.success("Cadastrado atualizado com sucesso!");
        })
        .catch(() => {
          toast.error("Erro na atualizado!");
          handleCloseL();
        });
    } else {
      await axios
        .post("products-services/products-services-list/", {
          description: product.description.value.trim(),
          value_vlr: product.value_vlr.value.trim(),
          location: product.location.value.trim(),
          brand: product.brand.value.trim(),
          minimum_stock: product.minimum_stock.value.trim(),
          current_inventory: product.current_inventory.value.trim(),
          supplier: product.supplier.value,
          type: product.type.value,
          reference_code: product.reference_code.value.trim(),
          bar_code: product.bar_code.value.trim(),
          model: product.model.value.trim(),
          application: product.application.value.trim(),
          st: product.st.value,
          id_company: IdCompany,
          user: IdUser,

        })
        .then(() => {
          toast.success("Cadastrado com sucesso!");
          limparCampos();
          getProducts();
          setOnEdit(null);
          handleClose();
        })
        .catch(() => {
          toast.error("Erro no Cadastro!");
          handleCloseL();
        });

    }


  };

  return (
    <>
      <Typography style={{ color: "#fff" }} id="modal-modal-title" variant="h6" component="h2">
        Ficha do produto / serviço
      </Typography>
      <FormContainer ref={ref} onSubmit={handleSubmit}>
        <InputArea>
          <Label>Código</Label>
          <Input2 style={{ color: "#fff" }} disabled={true} name="cod" />
        </InputArea>
        <InputArea>
          <Label>Situação</Label>
          <Select1 name="st">
            <option value={1}>Ativo</option>
            <option value={0}>Inativo</option>
          </Select1>
        </InputArea>
        <InputArea >
          <Label>Tipo</Label>
          <Select1 name="type" onChange={onTypeProductsChange}>
            <option value="P">Produto</option>
            <option value="S">Serviço</option>
          </Select1>
        </InputArea>
        <InputArea style={{ display:typeProducts.value }} >
          <Label>Cód. Barras</Label>
          <Input2 name="bar_code" style={{ color: statusMovProducts === true ? "#fff" : "#000" }} disabled={statusMovProducts}/>
        </InputArea>
        <InputArea style={{ display:typeProducts.value }}>
          <Label>Cód. referencia</Label>
          <Input2 name="reference_code" />
        </InputArea>
        <InputArea>
          <Label>Descrição*</Label>
          <Input2 style={{ width: "350px" }} name="description" />
        </InputArea>
        <InputArea>
          <Label>Valor R$*</Label>
          <Input2 name="value_vlr" type="number" pattern="[0-9]+([,\.][0-9]+)?" min="0" step="any" placeholder="Digite só número" />
        </InputArea>
        <InputArea style={{ display:typeProducts.value }}>
          <Label>Localização</Label>
          <Input2 name="location" />
        </InputArea>
        <InputArea style={{ display:typeProducts.value }}>
          <Label>Marca</Label>
          <Input2 style={{ width: "350px" }} name="brand" />
        </InputArea>
        <InputArea style={{ display:typeProducts.value }}>
          <Label >Modelo</Label>
          <Input2 name="model" />
        </InputArea>
        <InputArea style={{ display:typeProducts.value }}>
          <Label >Aplicação</Label>
          <Input2 style={{ width: "350px" }} name="application" />
        </InputArea>
        <InputArea style={{ display:typeProducts.value }}>
          <Label >Estoque Minimo</Label>
          <Input2 name="minimum_stock" type="number" pattern="[0-9]+([,\.][0-9]+)?" min="0" step="any" placeholder="Digite só número" />
        </InputArea>
        <InputArea style={{ display:typeProducts.value }}>
          <Label >Entradas</Label>
          <Input2 style={{ color: "#fff" }} disabled={true} type="number" name="appetizer" pattern="[0-9]+([,\.][0-9]+)?" min="0" step="any" placeholder="Digite só número" />
        </InputArea>
        <InputArea style={{ display:typeProducts.value }}>
          <Label >Saidas</Label>
          <Input2 style={{ color: "#fff" }} disabled={true} type="number" name="exits" pattern="[0-9]+([,\.][0-9]+)?" min="0" step="any" placeholder="Digite só número" />
        </InputArea>
        <InputArea style={{ display:typeProducts.value }}>
          <Label >Estoque Atual</Label>
          <Input2 style={{ color: "#fff" }} disabled={true} type="number" name="current_inventory" pattern="[0-9]+([,\.][0-9]+)?" min="0" step="any" placeholder="Digite só número" />
        </InputArea>
        <InputArea style={{ display:typeProducts.value }}>
          <Label >Fornecedor</Label>
          <Input2 disabled={true} style={{color: "#fff" , width: "350px" }} name="supplier"/>
        </InputArea>

        <div style={{
          display: "flex",
          padding: "10px 0",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          width: "100%",
        }} >
          <ButtonArea >
            <Button type="submit">SALVAR</Button>
            {regist === true ?
              null
              :
              <Button
                style={{ backgroundColor: "red" }}
                onClick={handleDelete}>EXCLUIR <DeleteIcon />
              </Button>
            }

            <Button onClick={handleClose}>SAIR</Button>
          </ButtonArea>
        </div>

      </FormContainer>

      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 5 }}
        open={openL}
      >
         <Label>Carregando...</Label>
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};

export default FromProductsServices;