import React, { useEffect, useRef } from "react";
import axios from "axios";
import {toast } from "react-toastify";
import Typography from '@mui/material/Typography';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import DeleteIcon from '@mui/icons-material/Delete';
import Select from 'react-select';
import { 
  FormContainer, 
  InputArea, 
  Label, 
  Input, 
  Select1, 
  Input2, 
  Input3, 
  ButtonArea, 
  Button 
} from "../Styles/Styles";

const FromReleasesFuel = ({
  vehicles,
  bombs,
  employees,
  handleCloseL,
  openL,
  handleOpenL,
  regist,
  onEdit,
  setOnEdit,
  getReleases,
  setOpen,
  fuel,
  getEmployees,
  getVehicles,
  getFuel,
  getBombs
}) => {

  const ref = useRef();
  const handleClose = () => setOpen(false);
  const [calfuel, setCalfuel] = React.useState();
  const [descriptionBombs, setDescriptionBombs] = React.useState();
  const [idSubCompany, setIdSubCompany] = React.useState();
  const IdCompany = localStorage.getItem("id_company");
  const IdUser = localStorage.getItem("id");
  const vehicle = useRef(null);
  const dateRef = useRef();
  const vehicleName = useRef();
  function adicionaZero(numero) {
    if (numero <= 9)
      return "0" + numero;
    else
    return numero;
}

  {/*
  let dataAtual = new Date();
  let dataAtualFormatada = (dataAtual.getFullYear() + "-" + (adicionaZero(dataAtual.getMonth() + 1).toString()) + "-" + adicionaZero(dataAtual.getDate().toString()));
  */}

  const onNumContBombChange = (event) =>{
    const fuel = ref.current;
    setDescriptionBombs();
   const res = bombs.filter((a) => (a.id === event.target.value));
   // eslint-disable-next-line
       res.map((a) => {
         fuel.initial_pump_control_number.value = a.num_cont_bomb;
         setDescriptionBombs(a.description);
         setIdSubCompany(a.id_subcompany);
       });
  }

  const updateBombs = () =>{
    const fuel = ref.current;

    if(descriptionBombs === "" || descriptionBombs === undefined || descriptionBombs === null){

      const res = bombs.filter((a) => (a.id === fuel.bomb.value));
      // eslint-disable-next-line
          res.map((a) => {
            setDescriptionBombs(a.description);
            setIdSubCompany(a.id_subcompany);
          });
    }
   }

  const onContFuelChange = () =>{
    const fuel = ref.current;
    const quantity = fuel.fuel_quantity.value;
    const initial = fuel.initial_pump_control_number.value;
    setCalfuel(parseFloat((+quantity) + (+initial)));

    fuel.value_average.value = (parseFloat((+fuel.value_traveled.value) / (+fuel.fuel_quantity.value)).toFixed(2));
  };

  const onDateChange = (item) =>{
    const fuel = ref.current;
    if(regist){
      fuel.vehicle.value = item.value;
    }
    var time = new Date(fuel.date.value);
    var termDay = 0;
    
    termDay = 30;
    let newD = new Date(time.setDate((+time.getDate()) - (+termDay)));
    let newDate = (newD.getFullYear() + "-" + (adicionaZero(newD.getMonth() + 1).toString()) + "-" + adicionaZero((newD.getDate()).toString()));
    dateRef.current = newDate;
    getRelease();
  }

  const getRelease = async () => {
    const fuel = ref.current;
    handleOpenL();
    fuel.km_vehicle.value = "";
    const res = vehicles.filter((a) => (a.id === fuel.vehicle.value));
    // eslint-disable-next-line
        res.map((a) => {
          fuel.km_vehicle.value = a.km_current == null? 0 : a.km_current;
          vehicleName.current = a.surname + " - " + a.plate;
        });
      handleCloseL();

  }

  const getValueTraveledChange = () =>{
    const fuel = ref.current;
    const currentKm = fuel.current_km.value;
    const kmVehicle = fuel.km_vehicle.value;
    fuel.value_traveled.value = (parseFloat((+currentKm) - (+kmVehicle)));
    
    fuel.value_average.value = (parseFloat((+fuel.value_traveled.value) / (+fuel.fuel_quantity.value)).toFixed(2));
  };


  const ClearFields = () => {
    const fuel = ref.current;

    fuel.cod.value = "";
    fuel.vehicle.value = "";
    fuel.date.value = "";
    fuel.km_vehicle.value = "";
    fuel.current_km.value = "";
    fuel.fuel.value = "";
    fuel.fuel_quantity.value = 0;
    fuel.bomb.value = "";
    fuel.initial_pump_control_number.value = "";
    fuel.final_pump_control_number.value = "";
    fuel.driver.value = "";
    fuel.obs.value = "";
    fuel.value_traveled.value = 0;
    fuel.value_average.value = 0; 

  };

  useEffect(() => {
    const fuel = ref.current;
    if (regist) {
      ClearFields();
      getEmployees();
      getVehicles();
      getFuel();
      getBombs();

    } else {
      if (onEdit) {
        fuel.cod.value = onEdit.id;
        fuel.vehicle.value = onEdit.vehicle;
        fuel.date.value = onEdit.date;
        fuel.km_vehicle.value = onEdit.km_vehicle;
        fuel.current_km.value = onEdit.current_km;
        fuel.fuel.value = onEdit.fuel;
        fuel.fuel_quantity.value = onEdit.fuel_quantity;
        fuel.bomb.value = onEdit.bomb;
        fuel.initial_pump_control_number.value = parseFloat(onEdit.initial_pump_control_number).toFixed(2);
        fuel.final_pump_control_number.value = parseFloat(onEdit.final_pump_control_number).toFixed(2);
        fuel.driver.value = onEdit.driver;
        fuel.obs.value = onEdit.obs;
        getValueTraveledChange();
      };
    };
    updateBombs();
    //getRelease();
    return;
    // eslint-disable-next-line
  }, []);

  const handleUpdateVehicle = async () => {
    const fuel = ref.current;
    const res = vehicles.filter((a) => (a.id === fuel.vehicle.value ));
    // eslint-disable-next-line
    res.map((a) => {
      vehicle.current = a;
    });
    const v = vehicle.current;

    let valeuinitial= parseFloat(v.km_current);
    let valeufinal= parseFloat(fuel.current_km.value);
    let result = valeufinal - valeuinitial;
    if( result <= 0 ){
      return;
    }else{

        await axios
            .put("vehicle/vehicle-list/", {
              id: v.id,
              plate: v.plate,
              surname: v.surname, 
              equipment: v.equipment,
              owner: v.owner, 
              contact: v.contact, 
              brand: v.brand, 
              model: v.model,
              year: v.year,
              fuel: v.fuel,
              color: v.color,
              category: v.category,
              chassis: v.chassis,
              reindeer: v.reindeer,
              fuel_consumption: v.fuel_consumption,
              document_expiration: v.document_expiration,
              st: v.st,
              axis_schema :v.axis_schema,
              km_current: fuel.current_km.value,
              id_company: IdCompany,
              id_subcompany: v.id_subcompany,
              user :IdUser,
            })
            .then(() => {
              toast.success("Cadastro do veículo atualizado com sucesso!");

              getVehicles();
            })
            .catch(() => {
              toast.error("Erro na atualizado!");
            });
      }
  };

  const updateContBoms = async () => {
    const fuel = ref.current;
    updateBombs()

    if (onEdit) {

      await axios
        .put("bombs/bombs-list/", {
          id: fuel.bomb.value.trim(),
          description: descriptionBombs.trim(),
          num_cont_bomb: fuel.final_pump_control_number.value.trim(),
          status: "1",
        })
        .then(() => {
          
          ClearFields();
          getReleases();
          setOnEdit(null);
          handleClose();

        })
        .catch(() => {
          handleClose();
        });
    } else {
      await axios
        .put("bombs/bombs-list/", {
          id: fuel.bomb.value.trim(),
          description: descriptionBombs.trim(),
          num_cont_bomb: fuel.final_pump_control_number.value.trim(),
          status: "1",
        })
        .then(() => {
          
          ClearFields();

          getReleases();
          setOnEdit(null);
          handleClose();
        })
        .catch(() => {
          handleClose();
        });

    }


  };

  const handleDelete = async (e) => {
    e.preventDefault();
    const fuel = ref.current;
    handleOpenL();

    if (!window.confirm("Tem certeza que deseja excluir?")) {
      handleCloseL();
      return;
    } else {
      await axios
        .delete("releases/releases-fuel-list/"+ fuel.cod.value)
        .then(() => {
          toast.success("Lançamento excluido com sucesso!");
          getReleases();
          setOnEdit(null);
          handleClose();
        })
        .catch(() => {
          handleCloseL();
          toast.error("Erro, não foi possível excluir!");
        });
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    handleOpenL();
    const fuel = ref.current;
    if(parseFloat(fuel.value_traveled.value) <= 0){
      handleCloseL();
      return toast.warn("Valor do Km Percorrido não pode ser menor ou igual a zero!");
    }

    if( !window.confirm("Tem certeza que deseja salvar? Confira se os dados estão corretos!") ){
      handleCloseL();
      return;
    } else {
 
    const valeuinitial= fuel.initial_pump_control_number.value;
    const valeufinal= fuel.final_pump_control_number.value;
    const result = valeufinal - valeuinitial;
    
    if ( result < 0 ){
      handleCloseL();
      return toast.warn("Número de controle bomba final não pode ser menor que o inicial!");
    } else {
    if (
      !fuel.km_vehicle.value ||
      !fuel.current_km.value ||
      !fuel.fuel.value ||
      !fuel.fuel_quantity.value ||
      !fuel.bomb.value ||
      !fuel.initial_pump_control_number.value ||
      !fuel.final_pump_control_number.value 
    ) {
      handleCloseL();
      return toast.warn("Preencha todos com ( * )!");
    }

    if(!fuel.date.value
    ){
      handleCloseL();
      return toast.warn("Falta adicionar data de lançamento!");
    }

    if (onEdit) {
      await axios
        .put( "releases/releases-fuel-list/", {
          id: fuel.cod.value,
          vehicle: fuel.vehicle.value.trim(),
          date: fuel.date.value,
          km_vehicle: fuel.km_vehicle.value.trim(),
          current_km: fuel.current_km.value.trim(),
          fuel: fuel.fuel.value.trim(),
          fuel_quantity: fuel.fuel_quantity.value.trim(),
          bomb: fuel.bomb.value.trim(),
          initial_pump_control_number:  parseFloat(fuel.initial_pump_control_number.value.trim()).toFixed(2),
          final_pump_control_number: parseFloat(fuel.final_pump_control_number.value.trim()).toFixed(2),
          driver: fuel.driver.value.trim(),
          obs: fuel.obs.value.trim(),
          id_subcompany: idSubCompany,
          user: IdUser,
        })
        .then(() => {
          updateContBoms();
          handleUpdateVehicle();
          toast.success("Lançamento atualizado com sucesso!");
        })
        .catch(() => {
          handleCloseL();
          toast.error("Erro na atualizado!");
        });
    } else {
      await axios
        .post("releases/releases-fuel-list/", {
          vehicle: fuel.vehicle.value.trim(),
          date: fuel.date.value,
          km_vehicle: fuel.km_vehicle.value.trim(),
          current_km: fuel.current_km.value.trim(),
          fuel: fuel.fuel.value.trim(),
          fuel_quantity: fuel.fuel_quantity.value.trim(),
          bomb: fuel.bomb.value.trim(),
          initial_pump_control_number:  parseFloat(fuel.initial_pump_control_number.value.trim()).toFixed(2),
          final_pump_control_number: parseFloat(fuel.final_pump_control_number.value.trim()).toFixed(2),
          driver: fuel.driver.value.trim(),
          obs: fuel.obs.value.trim(),
          id_company: IdCompany,
          id_subcompany: idSubCompany,
          user: IdUser,
        })
        .then(() => {
          updateContBoms();
          handleUpdateVehicle();
          toast.success("Lançamento cadastrado com sucesso!");
        })
        .catch(() => {
          handleCloseL();
          toast.error("Erro no Lançamento!");
        });

    }
  }
    }

  };

  return (
    <>
      <Typography style={{ color: "#fff" }} id="modal-modal-title" variant="h6" component="h2">
        Ordem de Abastecimento
      </Typography>
      <FormContainer ref={ref} onSubmit={handleSubmit}>
        <InputArea>
          <Label>Código</Label>
          <Input style={{ color: "#fff" }} disabled={true} name="cod" />
        </InputArea>
        <InputArea>
          <Label>Data de lançamento*</Label>
          <Input2 type="date" name="date" />
        </InputArea>
        
        <InputArea style={{width: "175px", color: "#000", fontSize:"12px" }}>
          <Label style={{color: "#fff" }}>Veículo*</Label>
          {regist?
         <Select 
          name={"vehicle"}
          onChange = {(item) => onDateChange(item)}       
          options = {vehicles === undefined ? [] : vehicles.map((item) => (
            {value : item.id, 
              label : item.surname + " - " + item.plate}
          ))} 
          theme = {theme => ({
            ...theme,
            colors: {
             ...theme.colors,
             primary: "#2c73d2",
             secondary: "#bbb",
            },
          })}
          /> : <Select1 disabled={regist === true ? false : true} name="vehicle">
          <option value="">Selecione</option>
          {vehicles === undefined ? [] : vehicles.map((item, i) => (
            <option key={i} value={item.id}>{item.surname} - {item.plate}</option>
          ))}
        </Select1>}
        </InputArea>

        <InputArea>
          <Label>Km Veículo*</Label>
          <Input onChange={getValueTraveledChange} name="km_vehicle" />
        </InputArea>
        <InputArea>
          <Label>Km Atual*</Label>
          <Input3 onChange={getValueTraveledChange} name="current_km" type="number" pattern="/([0-9]{3}).([0-9]{3}),([0-9]{2})$/g, '.$1.$2,$3'" min="0" step="any" placeholder="Digite só número" />
        </InputArea>
        <InputArea>
          <Label>Km Percorrido </Label>
          <Input style={{ color: "#fff", width: "90px" }} disabled={true} name="value_traveled" />
        </InputArea>
        <InputArea>
          <Label>Combustivel*</Label>
          <Select1 name="fuel">
            <option value="">Selecione a categoria</option>
                  {fuel === undefined ? [] : fuel.map((item, i) => (
                    <option key={i} value={item.fuel}>{item.fuel}</option>
                  ))}
          </Select1>
        </InputArea>
        <InputArea>
          <Label>Bomba*</Label>
          <Select1 onChange={onNumContBombChange}  style={{ width: "350px" }} name="bomb">
            <option value="">Selecione</option>
              {bombs === undefined ? [] : bombs.map((item, i) => (
                 <option key={i} value={item.id} >
                   {item.id} - {item.description}
                 </option>
            ))}
          </Select1>
        </InputArea>
        <InputArea>
          <Label>N. Controle Bomba Inicial*</Label>
          <Input3 name="initial_pump_control_number" type="number" pattern="/([0-9]{3}).([0-9]{3}),([0-9]{2})$/g, '.$1.$2,$3'" min="0" step="any" placeholder="Digite só número" />
        </InputArea>
        <InputArea>
          <Label>Quantidade Comb.*</Label>
          <Input3 onChange={onContFuelChange} name="fuel_quantity" type="number" pattern="/([0-9]{3}).([0-9]{3}),([0-9]{2})$/g, '.$1.$2,$3'" min="0" step="any" placeholder="Digite só número" />
        </InputArea>
        <InputArea>
          <Label>Media por litro </Label>
          <Input style={{ color: "#fff", width: "95px" }} disabled={true} name="value_average" />
        </InputArea>
        <InputArea>
          <Label>N. Controle Bomba Final*</Label>
          <Input3 name="final_pump_control_number" value={calfuel} type="number" pattern="/([0-9]{3}).([0-9]{3}),([0-9]{2})$/g, '.$1.$2,$3'" min="0" step="any" placeholder="Digite só número" />
        </InputArea>
        <InputArea>
          <Label>Abastecedor/Motorista</Label>
          <Select1 style={{ width: "350px" }} name="driver">
            <option value="">Selecione</option>
            {employees === undefined ? [] : employees.map((item, i) => (
              <option key={i} value={item.id}>{item.nome}</option>
            ))}
          </Select1>
        </InputArea>
        <InputArea>
          <Label>Observação</Label>
          <Input2 style={{ width: "350px" }} name="obs" />
        </InputArea>

        <div style={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
        }} >
          <ButtonArea >
            <Button type="submit">SALVAR</Button>
            {regist === true ?
              null
              :
              <Button
                style={{ backgroundColor: "red" }}
                onClick={handleDelete}>EXCLUIR <DeleteIcon/>
              </Button>
            }

            <Button onClick={handleClose}>SAIR</Button>
          </ButtonArea>
        </div>

      </FormContainer>

      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 5 }}
        open={openL}
      >
         <Label>Carregando...</Label>
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};

export default FromReleasesFuel;