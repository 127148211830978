
import React from "react";
import { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import { signout } from "../../Services/UserService";
import ContentPage from "../../components/Content/ContentPage";
import ModalOrderOfService from "../../components/OrderOfService/ModalOrderOfService";
import GridOrderOfService from "../../components/OrderOfService/GridOrderOfService";
import { toast } from "react-toastify";
import axios from "axios";

import { geraStringAleatoria } from "../../components/Function/Base"

import {
  FormContainer,
  Loc,
  InputArea,
  Button,
  Input,
  Select1
} from "../Styles/Styles"
import { Label } from "../../components/Styles/Styles";

const OrderOfServiceList = () => {
  const navigate = useNavigate();
  const [orderService, setOrderService] = useState([]);
  const [onEdit, setOnEdit] = useState(null);
  const [open, setOpen] = React.useState(false);
  const [loc, setLoc] = React.useState();
  const [regist, setRegist] = React.useState(false);
  const [employees, setEmployees] = useState([]);
  const [vehicles, setVehicles] = useState([]);
  const IdCompany = localStorage.getItem("id_company");
  const IdUser = localStorage.getItem("id");

  const [openL, setOpenL] = React.useState(false);

  const handleCloseL = () => {
    setOpenL(false);
  };
  const handleOpenL = () => {
    setOpenL(true);
  };



  function adicionaZero(numero) {
    if (numero <= 9)
      return "0" + numero;
    else
      return numero;
  }

  let dataAtual = new Date();
  let dataAtualFormatada = (dataAtual.getFullYear() + "-" + (adicionaZero(dataAtual.getMonth() + 1).toString()) + "-" + adicionaZero(dataAtual.getDate().toString()));

  const [locDate, setLocDate] = React.useState(dataAtualFormatada);
  const [locDateF, setLocDateF] = React.useState(dataAtualFormatada);

  const getOrderService = async () => {
    handleOpenL();
    setOrderService([]);

    if (locDate > locDateF) {
      toast.error("A data inicial não pode ser maior que a data final!");
      handleCloseL();
      return;
    };

    try {
      const res = await axios.get("order-service/order-service-list/" +
        IdCompany + "/" + locDate + "/" + locDateF + "/?loc=" + (loc === undefined ? '%' : loc));
      if (res.data[0].status === "false") {
        toast.error(res.data[0].message);
        signout();
        navigate("/login");
      } else {
        setOrderService(res.data.sort((a, b) => (a.id > b.id ? 1 : -1)));
      }
      handleCloseL();
    } catch (error) {
      toast.error(error);
      handleCloseL();
    }
  };

  const getEmployees = async () => {
    handleOpenL();
    setEmployees([]);
    try {
      const res = await axios.get("employees/employees-list/" + IdCompany + "/?loc=%");
      if (res.data[0].status === "false") {
        toast.error(res.data[0].message);
        signout();
        navigate("/login");
      } else {
        setEmployees(res.data.sort((a, b) => (a.nome > b.nome ? 1 : -1)));
      }
      handleCloseL();
    } catch (error) {
      toast.error(error);
      handleCloseL();
    }
  };

  const getVehicles = async () => {
    handleOpenL();
    setVehicles([]);
    try {
      const res = await axios.get("vehicle/vehicle-list/" + IdCompany + "/?loc=%");
      if (res.data[0].status === "false") {
        toast.error(res.data[0].message);
        signout();
        navigate("/login");
      } else {
        setVehicles(res.data.sort((a, b) => (a.plate > b.plate ? 1 : -1)));
      }
      handleCloseL();
    } catch (error) {
      toast.error(error);
      handleCloseL();
    }
  };


  const handleRegist = async () => {
    const idOs = geraStringAleatoria(10);

    handleOpenL();

    await axios
      .post("order-service/order-service-list/", {
        open_date: dataAtualFormatada,
        surname_vehicle: idOs,
        situation: "AC",
        id_company: IdCompany,
        user: IdUser,
      })
      .then(async () => {
        // toast.success("Nova ordem de serviço!");
        const res = await axios.get("order-service/order-service-list/" +
          IdCompany + "/" + locDate + "/" + locDateF + "/?loc=" + idOs);

        setOnEdit(res.data[0]);
        setRegist(true);
        setOpen(true);

        getOrderService();
        handleCloseL();
      })
      .catch(() => {
        toast.error("Erro no Cadastro!");
        handleCloseL();
      });

  };

  useEffect(() => {
    getOrderService();
    getEmployees();
    getVehicles();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <ContentPage caminho={[{ nome: "Lançamentos > Ordem de Serviço - Relação", link: "/Order-Of-Service/list" }]}>

        <ModalOrderOfService
          regist={regist}
          orderService={orderService}
          setOrderService={setOrderService}
          setLoc={setLoc}
          loc={loc}
          onEdit={onEdit}
          setOnEdit={setOnEdit}
          getOrderService={getOrderService}
          setOpen={setOpen}
          open={open}
          openL={openL}
          handleOpenL={handleOpenL}
          handleCloseL={handleCloseL}
          locDate={locDate}
          employees={employees}
          vehicles={vehicles}
        />

        <FormContainer>
          <Loc
            style={{
              width: "250px",
              height: "30px",
              border: "1px solid #343f46",
              textTransform: "uppercase",
              fontSize: "12px"
            }}
            type='text'
            placeholder="Digite apelido do veículo"
            value={loc}
            onChange={(e) => [setLoc(e.target.value)]}
          />
          <InputArea>
            <Select1 style={{
              width: "300px",
              height: "30px",
              border: "1px solid #343f46",
              textTransform: "uppercase",
              fontSize: "12px"
            }} name="fuel">
              <option value="">Todos ou Selecione</option>
              <option value="AC">AC - Aguardando Confirmação</option>
              <option value="ES">ES - Executando Serviço</option>
              <option value="F">F - Fechada</option>
              <option value="C">C - Cancelada</option>
            </Select1>
          </InputArea>
          <InputArea>
            <Input type="date"
              value={locDate}
              onChange={(e) => [setLocDate(e.target.value)]}
            />
          </InputArea>

          <InputArea>
            <Label style={{ color: "#242a38" }}>à</Label>
          </InputArea>

          <InputArea>
            <Input type="date"
              value={locDateF}
              onChange={(e) => [setLocDateF(e.target.value)]}
            />
          </InputArea>

          <InputArea >
            <Button
              onClick={getOrderService}>Localizar
            </Button>
          </InputArea>
          <InputArea  >
            <Button
              onClick={handleRegist}>Novo
            </Button>
          </InputArea>
        </FormContainer>

        <GridOrderOfService openL={openL} regist={regist} setRegist={setRegist} orderService={orderService} setOrderService={setOrderService} setOnEdit={setOnEdit} setOpen={setOpen} />
      </ContentPage>
    </>
  );
};

export default OrderOfServiceList;