import React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import FromTireRegistration from "./FromTireRegistration";

import { Container, styledModal } from "../../Styles/Styles";


const ModalTireRegistration = ({ 
  handleCloseL, 
  handleOpenL, 
  openL, 
  regist, 
  onEdit, 
  setOnEdit, 
  getTireRegistration, 
  setOpen, 
  open, 
  supplier, 
  getsetIdentification,
  identification,
  category,
  tireRegistration 
}) => {
  

  return (
    <>
      <Modal
        open={open}
        aria-labelledby="modal-modal-title"
      >
        <Box sx={styledModal}>
          <Container>
            <FromTireRegistration 
              handleCloseL={handleCloseL} 
              handleOpenL={handleOpenL}  
              openL={openL} 
              regist={regist} 
              onEdit={onEdit} 
              setOnEdit={setOnEdit} 
              getTireRegistration={getTireRegistration} 
              setOpen={setOpen}
              supplier={supplier}
              getsetIdentification={getsetIdentification}
              identification={identification}
              category={category}
              tireRegistration={tireRegistration}
            />
          </Container>
        </Box>
        
        
      </Modal>
    </>
  );
};

export default ModalTireRegistration;