import React, { useState, useRef } from "react";
import { validatorEmail, validatorPassword } from "../../components/Utils/validations";
import { Container, From, Img, ImgLogo } from "./Styles";
import Input from "../../components/Input/index";
import Button from "../../components/Button/index";
import { Link, useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

import Banner from './banner.jpg';
import Logo from "./logo192.png"
import { Label } from "../Styles/Styles.js";
import UserServices from "../../Services/UserService";

const UserService = new UserServices();

const Login = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState();
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [open, setOpen] = useState("password");
    const [showPassword, setShowPassword] = useState("fa-solid fa-eye-slash");
    const response = useRef(false);

    function adicionaZero(numero) {
        if (numero <= 9)
          return "0" + numero;
        else
          return numero;
      }
    
      let dataAtual = new Date();
      let dataAtualFormatada = (dataAtual.getFullYear() + "-" + (adicionaZero(dataAtual.getMonth() + 1).toString()) + "-" + adicionaZero(dataAtual.getDate().toString()));

    var diaSemana = ["Domingo", "Segunda", "Terça", "Quarta", "Quinta", "Sexta", "Sábado"]

    function obterDiaSemana(value){
        let sp = value.split("-")
        let result = new Date(sp[0], sp[1] - 1, sp[2])
        let day = result.getDay()
        return diaSemana[day]
    }

    const handleMenu = () => {
        (open === "text") ? setOpen("password") : setOpen("text");
        (showPassword === "fa-solid fa-eye") ? setShowPassword("fa-solid fa-eye-slash") : setShowPassword("fa-solid fa-eye");
    };

    const handleSubmit = async () => {

        if (!validatorInput()) {
            toast.warn("Preencha os campos corretamente!");
            return;
        } else {

            try {
                setLoading(true);

                response.current = await UserService.login(email, password);

                if (response.current) {

                    setLoading(false);

                    navigate("/Home");

                } else {
                    toast.error("Email ou senha invalidos!");
                    setLoading(false);
                }
            }
            catch (error) {
                toast.error(error);
                setLoading(false);
            }
        }
    }

    const validatorInput = () => {
        return validatorEmail(email) && validatorPassword(password);
    }

    return (
        <>
            <Container >
                <Img src={
                    obterDiaSemana(dataAtualFormatada) === "Segunda"? "https://guabirubazeitung.com.br/wp-content/uploads/2021/09/WhatsApp-Image-2021-09-10-at-11.12.26.jpeg":
                    obterDiaSemana(dataAtualFormatada) === "Terça"? "https://omunicipio.com.br/wp-content/uploads/2023/06/conheca-as-propostas-do-plano-diretor-para-o-zoneamento-e-uso-de-solo-de-brusque-plano-diretor.jpg":
                    obterDiaSemana(dataAtualFormatada) === "Quarta"? "https://file-wc.b-cdn.net/w_1920/h_1080/arq_121314:noticias:981788:00d6c99eb11a31551b72b690bb32941a.jpeg":
                    obterDiaSemana(dataAtualFormatada) === "Quinta"? "https://omunicipio.com.br/wp-content/uploads/2021/08/brusque-2030-conheca-as-acoes-estudadas-para-infraestrutura-e-seguranca-para-os-proximos-anos-brusque.jpg":
                    obterDiaSemana(dataAtualFormatada) === "Sexta"? "https://olhardovale.com.br/wp-content/uploads/2023/08/brusque-olhar-do-vale-centro.jpg":
                    obterDiaSemana(dataAtualFormatada) === "Sábado"? "https://www.tvbrusque.com.br/images/noticias/13691/6e1aa2adb7d6b738211d4ca1858fbd87.jpg":
                    obterDiaSemana(dataAtualFormatada) === "Domingo"? "https://olhardovale.com.br/wp-content/uploads/2022/09/brusque.jpeg":
                    Banner
                    } />
                <From>
                    <ImgLogo src={Logo} />
                    <h1>Faça o seu Login</h1>
                    <Input
                        placeholder="Digite seu E-mail!"
                        value={email}
                        onChange={(e) => [setEmail(e.target.value)]}
                        type="email"

                    />
                    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%" }}
                    >
                        <Input
                            placeholder="Digite sua Senha!"
                            value={password}
                            onChange={(e) => [setPassword(e.target.value)]}
                            type={open}
                        />
                        <div style={{ display: "flex", marginLeft: "320px", position: "absolute", color: "#2c73d2", fontSize: "18px", cursor: "pointer" }}>
                            <i class={showPassword} onClick={handleMenu}></i>
                        </div>
                    </div>
                    <Button
                        Text="Entrar"
                        onClick={handleSubmit}
                    />
                    <div>
                        <p><Link to={'https://api.whatsapp.com/send?phone=5511918765404&text=Ol%C3%A1,%20'} style={{ color: "#fff" }} >Não possui conta?</Link></p>

                    </div>
                    <h6>Versão: 0.0.205</h6>
                    <h6>&copy; 2024</h6>
                </From>
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 5 }}
                    open={loading}
                >
                    <Label>Carregando...</Label>
                    <CircularProgress color="inherit" />
                </Backdrop>
            </Container>

            <ToastContainer
                position="top-right"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
        </>
    );
};

export default Login;