import * as React from 'react';
import { GridActionsCellItem } from '@mui/x-data-grid';
import EditIcon from '@mui/icons-material/Edit';

import { DataGrid, GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';
import { useDemoData } from '@mui/x-data-grid-generator';
import { FaFileSignature } from "react-icons/fa"
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

import { 
  TableMobile,
  Table, 
  Tbody, 
  Tr, 
  Td, 
  Label 
} from "../Styles/Styles";

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarExport />
    </GridToolbarContainer>
  );
}

const GridVehicles = ({openL, setRegist, vehicles, setOnEdit, setOpen }) => {

  const alterVehicles = React.useCallback(
    (id) => () => {
      const res = vehicles.filter((a) => (a.id === id));
       // eslint-disable-next-line
       res.map((a) => {
        handleEdit(a);
       });
    },
    [vehicles],
  );

  const data = {
    columns: [
      {
        field: 'actions',
        type: 'actions',
        headerName: 'Ações',
        width: 80,
        getActions: (params) => [
          <GridActionsCellItem
            icon={<EditIcon style={{
              color: "#2c73d2"}} />}
            label="Delete"
            onClick={alterVehicles(params.id)}
          />,
        ],
      },
      { field: 'id', headerName: 'Cód.', width: 100 },
      { field: 'plate', headerName: 'Placa', width: 100 },
      { field: 'surname', headerName: 'Apelido', width: 200 },
      { field: 'category', headerName: 'Categoria', width: 150 },
      { field: 'fuel', headerName: 'Combustivel', width: 100 },
      { field: 'model', headerName: 'Modelo', width: 300 },
      { field: 'year', headerName: 'Ano', width: 100 },
      { field: 'document_expiration', headerName: 'Vencimento CRV', width: 150 },
      { field: 'st', headerName: 'Situação', width: 150 },
    ],
    rows: vehicles === undefined? [] : vehicles.map((item, i) => ({
      id: item.id,
      plate: item.plate,
      surname: item.surname,
      fuel: item.fuel,
      model: item.model,
      year: item.year,
      document_expiration: item.document_expiration,
      category: (
        item.category === "1"? "Urbano" : 
        item.category === "2"? "Rodoviário" : 
        item.category === "3"? "Escolar" : "Não Identificado"
      ),
      st: (item.st === "0" ? "Inativo" : "Ativo"),
    }))
  }
  ;

  const { loading } = useDemoData({
    dataSet: 'Commodity',
    rowLength: 10,
    maxColumns: 6,
  });

  const handleOpen = () => setOpen(true);

  const handleEdit = (item) => {
    setRegist(false);
    setOnEdit(item);
    handleOpen();
  };



  return (
    <>
    <TableMobile onlyWeb>
      <Tbody >
        {vehicles === undefined ? [] : vehicles.map((item, i) => (
          <Tr key={i}>
            <Td >
              <h1
                style={{fontSize: "12px", color:"#022025" }} >
                Código.: {item.id} <br />
                Placa.: {item.plate} / Apelido.: {item.surname} <br />
                Chassi.: {item.chassis} / Modelo.: {item.model}  / Ano.: {item.year} <br />
                {item.st === "0" ?
                  <Label 
                    style={{ fontSize: "12px", color: "red" }}> 
                    Status.: {item.st === "0" ? "Inativo" : "Ativo"} 
                  </Label>
                  :
                  <Label style={{ fontSize: "12px" }}> 
                    Status.: {item.st === "0" ? "Inativo" : "Ativo"} 
                  </Label>}
              </h1>


            </Td>
            <Td >
                <FaFileSignature onClick={() => handleEdit(item)}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "start",
                    cursor: "pointer",
                    width: "20px",
                    height: "70px",
                    textAlign: "start",
                    color: "#2c73d2",
                  }}
                />
            </Td>
          </Tr>
        ))}
      </Tbody>
    </TableMobile>

    <Table onlyWeb>
      <Tbody > 
      <DataGrid
        {...data}
        loading={loading}
        rowHeight = {30}
        slots={{
          toolbar: CustomToolbar,
        }}
      />
      </Tbody>
    </Table>
    
    <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 5}}
        open={openL}
    >
         <Label>Carregando...</Label>
        <CircularProgress color="inherit" />
    </Backdrop>
    </>
  );
};

export default GridVehicles;