import * as React from 'react';
import { GridActionsCellItem } from '@mui/x-data-grid';
import EditIcon from '@mui/icons-material/Edit';

import { DataGrid, GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';
import { useDemoData } from '@mui/x-data-grid-generator';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

import { 
  Table, 
  Tbody, 
  Label
} from "../Styles/Styles";

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarExport />
    </GridToolbarContainer>
  );
}


const GridMaintenanceControl = ({openL, setRegist, maintenance, setOnEdit, setOpen }) => {

  const alterUsers = React.useCallback(
    (id) => () => {
      const res = maintenance.filter((a) => (a.id === id));
       // eslint-disable-next-line
       res.map((a) => {
        handleEdit(a);
       });
    },
    [maintenance],
  );

  const data = {
    columns: [
      { field: 'st', headerName: 'Status', width: 100 },
      { field: 'maintenance_date', headerName: 'Data Manutenção', width: 150 },
      { field: 'km_maintenance', headerName: 'KM Manut.', width: 100 },
      { field: 'plate', headerName: 'Placa', width: 100 },
      { field: 'surname', headerName: 'Prefixo', width: 100 },
      { field: 'maintenance_description', headerName: 'Descrição da Manutenção', width: 350 },
      { field: 'term', headerName: 'Prazo', width: 100 },
      { field: 'unit', headerName: 'Unidade', width: 100 },
      { field: 'km_next_maintenance', headerName: 'KM Próxima Manut.', width: 150 },
      { field: 'next_maintenance_date', headerName: 'Data Próxima Manut.', width: 150 },
      { field: 'cost', headerName: 'Custo R$', width: 100 },
      {
        field: 'actions',
        type: 'actions',
        headerName: 'Ações',
        width: 80,
        getActions: (params) => [
          <GridActionsCellItem
            icon={<EditIcon style={{
              color: "#2c73d2"}} />}
            label="Delete"
            onClick={alterUsers(params.id)}
          />,
        ],
      },
    ],
    rows: maintenance === undefined? [] : maintenance.map((item) => ({
      id: item.id,
      st: item.st === "1"? "Pendente" : "Realizada",
      maintenance_date: item.maintenance_date.split("-").reverse().join("/"),
      plate: item.plate,
      km_maintenance: item.km_maintenance,
      surname: item.surname,
      maintenance_description: item.maintenance_description,
      term: item.term,
      unit: item.unit === "1"? "KM" : "Dias",
      km_next_maintenance: item.unit === "1"? item.km_next_maintenance : "",
      next_maintenance_date: item.unit === "2"? item.next_maintenance_date.split("-").reverse().join("/") : "",
      cost: item.cost,
    }))
  }
  ;

  const { loading } = useDemoData({
    dataSet: 'Commodity',
    rowLength: 10,
    maxColumns: 6,
  });

  const handleOpen = () => setOpen(true);

  const handleEdit = (item) => {
    setRegist(false);
    setOnEdit(item);
    handleOpen();
  };



  return (
    <>
    <Table onlyWeb>
      <Tbody > 
      <DataGrid
        {...data}
        loading={loading}
        rowHeight = {30}
        slots={{
          toolbar: CustomToolbar,
        }}
      />
      </Tbody>
    </Table>
    
    <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 5}}
        open={openL}
    >
         <Label>Carregando...</Label>
        <CircularProgress color="inherit" />
    </Backdrop>
    </>
  );
};

export default GridMaintenanceControl;