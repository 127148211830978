import React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import FromTireMovement from "./FromTireMovement";

import { Container, styledModal } from "../../Styles/Styles";


const ModalTireMovement = ({ 
  handleCloseL, 
  handleOpenL, 
  openL, 
  regist, 
  onEdit, 
  setOnEdit, 
  getTireMovement, 
  setOpen, 
  open, 
  tireRegistration,
  vehicles,
  axisSchema
}) => {
  

  return (
    <>
      <Modal
        open={open}
        aria-labelledby="modal-modal-title"
      >
        <Box sx={styledModal}>
          <Container>
            <FromTireMovement 
              handleCloseL={handleCloseL} 
              handleOpenL={handleOpenL}  
              openL={openL} 
              regist={regist} 
              onEdit={onEdit} 
              setOnEdit={setOnEdit} 
              getTireMovement={getTireMovement} 
              setOpen={setOpen}
              tireRegistration={tireRegistration}
              vehicles={vehicles}
              axisSchema={axisSchema}
            />
          </Container>
        </Box>
        
        
      </Modal>
    </>
  );
};

export default ModalTireMovement;