import * as React from 'react';
import { GridActionsCellItem } from '@mui/x-data-grid';
import EditIcon from '@mui/icons-material/Edit';

import { DataGrid, GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';
import { useDemoData } from '@mui/x-data-grid-generator';
import { FaFileSignature } from "react-icons/fa"
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { 
  Table, 
  TableMobile, 
  Tbody, 
  Tr, 
  Td, 
  Label 
} from "../Styles/Styles";


function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarExport />
    </GridToolbarContainer>
  );
}

const GridBombs = ({openL, setRegist, bombs, setOnEdit, setOpen }) => {

  const handleOpen = () => setOpen(true);

  const handleEdit = (item) => {
    setRegist(false);
    setOnEdit(item);
    handleOpen();
  };

  const alterBombs = React.useCallback(
    (id) => () => {
      const res = bombs.filter((a) => (a.id === id));
       // eslint-disable-next-line
       res.map((a) => {
        handleEdit(a);
       });
    },
    [bombs],
  );

  const data = {
    columns: [
      { field: 'id', headerName: 'Cód.', width: 100 },
      { field: 'description', headerName: 'Descrição', width: 350 },
      { field: 'num_cont_bomb', headerName: 'N. Controle', width: 150 },
      { field: 'status', headerName: 'Situação', width: 100 },
      {
        field: 'actions',
        type: 'actions',
        headerName: 'Ações',
        width: 100,
        getActions: (params) => [
          <GridActionsCellItem
            icon={<EditIcon style={{
              color: "#2c73d2"}} />}
            label="Delete"
            onClick={alterBombs(params.id)}
          />,
        ],
      },
    ],
    rows: bombs === undefined? [] : bombs.map((item, i) => ({
      id: item.id,
      description: item.description,
      num_cont_bomb: item.num_cont_bomb,
      status: (item.status === "0" ? "Inativo" : "Ativo"),
    }))
  }
  ;

  const { loading } = useDemoData({
    dataSet: 'Commodity',
    rowLength: 10,
    maxColumns: 6,
  });


  return (
    <>
    <TableMobile onlyWeb>
      <Tbody >
        {bombs === undefined ? [] : bombs.map((item, i) => (
          <Tr key={i}>
            <Td >
              <h1
                style={{fontSize: "12px", color:"#022025" }} >
                Código.: {item.id} <br />
                Descrição.: {item.description} <br />
                N. Controle.: {item.num_cont_bomb} <br />
                {item.status === "0" ?
                  <Label 
                    style={{ fontSize: "12px", color: "red" }}> 
                    Status.: {item.status === "0" ? "Inativo" : "Ativo"} 
                  </Label>
                  :
                  <Label style={{ fontSize: "12px" }}> 
                    Status.: {item.status === "0" ? "Inativo" : "Ativo"} 
                  </Label>}
              </h1>
            </Td>
            <Td >
                <FaFileSignature onClick={() => handleEdit(item)}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "start",
                    cursor: "pointer",
                    width: "20px",
                    height: "70px",
                    textAlign: "start",
                    color: "#2c73d2",
                  }}
                />
            </Td>
          </Tr>
        ))}
      </Tbody>
    </TableMobile>

    <Table onlyWeb>
      <Tbody > 
      <DataGrid
        {...data}
        loading={loading}
        rowHeight = {30}
        slots={{
          toolbar: CustomToolbar,
        }}
      />
      </Tbody>
    </Table>
    
    <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 5}}
        open={openL}
    >
         <Label>Carregando...</Label>
        <CircularProgress color="inherit" />
    </Backdrop>
    </>
  );
};

export default GridBombs;