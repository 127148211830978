
import React from "react";
import ContentPage from "../../components/Content/ContentPage";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { GoogleMap, useJsApiLoader, MarkerF } from '@react-google-maps/api';
import axios from "axios";
import {
  FormContainer,
  Label,
  InputArea,
  Select1
} from "../../components/Styles/Styles";
import { BaseUrl, GoogleMapsApiKey, companyTrackers } from "../../components/Function/Base";

import car from "./car.svg";
import Swal from 'sweetalert2';

const containerStyle = {
  width: '100%',
  height: 'calc(90vh - 90px)'
};

const centerV = {
  lat: -27.107966, 
  lng: -48.9094
};

const centerB = {
  lat: -27.002202, 
  lng: -51.170136
};

const MapsTelemetry = () => {
  const [map, setMap] = React.useState(null)
  const locationTrackers = React.useRef([]);
  const [loading, setLoading] = React.useState({ value: "none" });
  const zoom = React.useRef(0);
  const vehicles = React.useRef(0);
  const location = React.useRef([]);
  const dataLc = React.useRef([]);
  const company = React.useRef();

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: GoogleMapsApiKey,
  });

  const opcoes = {
    timeZone: 'America/Sao_Paulo',
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
  };

  const LastStatusOfSeveralTrackers = async () => {
    setLoading({ value: "flex" });
    try {
      const { data } = await axios.get(BaseUrl + "list?hash=" + companyTrackers[company.current].Hash);

      vehicles.current = data.list.map(vehicle => {
        return {
          id: vehicle.id,
          name: vehicle.label,
          id_tracker: vehicle.source.id,
        }
      });
      
    } catch (error) {
      setLoading(false);
    }

    try {
      const { data } = await axios.get(BaseUrl + "get_states?hash=" + companyTrackers[company.current].Hash + "&trackers="+ companyTrackers[company.current].Trackers);
      
      // Atualiza a lista de localizações dos veículos
      locationTrackers.current = Object.keys(data.states).map(key => {
        let trackData = data.states[key];
        
        location.current = {
            lat: trackData.gps.location.lat,
            lng: trackData.gps.location.lng,
            last_update: trackData.last_update,
            id_tracker: trackData.source_id,
          }

        const res = vehicles.current.filter((a) => (a.id_tracker === location.current.id_tracker ));
        // eslint-disable-next-line
        res.map((a) => {
          dataLc.current = {
            lat: location.current.lat,
            lng: location.current.lng,
            last_update: location.current.last_update.toLocaleString('pt-BR', opcoes),
            id_tracker: location.current.id_tracker,
            name: a.name,
          }
        });
        return dataLc.current;
      });
      setLoading({ value: "none" });
    } catch (error) {
      setLoading(false);
      toast.error({ value: "none" });
    }
    updateLocation();
  };


  const onLoad = React.useCallback(function callback(map) {
    // This is just an example of getting and using the map instance!!! don't just blindly copy!
    const bounds = new window.google.maps.LatLngBounds(company.current === "342368"? centerV : centerB);
    map.fitBounds(bounds);

    setMap(map)
  }, []);

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null)
  }, []);

  const updateLocation = () => {
    zoom.current = 13;
    // Executa a função de verificação de manutenções a cada 30 segundos
    setInterval(() => {
      LastStatusOfSeveralTrackers();
  }, 60000); 
  };

  const onConfirmLocation = (event) => {
    // Atualiza a lista de localizações dos veículos
    if(event){
      company.current = event.target.value;
    } else {
      company.current = "342368";
    }
    LastStatusOfSeveralTrackers();
  }
  
  React.useEffect(() => {
    onConfirmLocation();
  }, []);

  return isLoaded ? (
    <>
      <ContentPage caminho={[{ nome: "Telemetria", link: "/Maps/Telemetry" }]}>
        <FormContainer>
        <InputArea >
          <Select1 
            onChange={onConfirmLocation}> 
            <option value={"342368"}>Nosso Brusque</option>
            <option value={"350813"}>Santa Teresinha</option>
          </Select1>
        </InputArea>
        <div style={{display:loading.value}}>
        <Label style={{color:"#bbb"}} >Carregando...</Label>
        </div>
        <GoogleMap
          mapContainerStyle={containerStyle}
          center={company.current === "342368"? centerV : centerB}
          zoom={zoom.current}
          onLoad={onLoad}
          onUnmount={onUnmount}
          options={{
            streetViewControl: false,
            fullscreenControl: false,
            mapTypeControl: false,
            
          }}
        >
          {locationTrackers.current.map((point, i) => (  
          <MarkerF 
          id={i} 
          position={point} 
          icon={{
            url: car,
            scaledSize: new window.google.maps.Size(25, 25),
              origin: new window.google.maps.Point(0, 0),
              anchor: new window.google.maps.Point(15, 30),
          }}
          animation={window.google.maps.Animation.DROP}
          options={{
            cursor: "pointer",
            position: point,
            map: map,
            title: `${point.name}`,
          }}
          onClick={() => {
            // Ação para abrir uma modal com mais detalhes do veículo
            Swal.fire({
              title: `${point.name}`,
              text: `Ultima atualização: ${new Date(point.last_update).toLocaleString('pt-BR', opcoes)}`,
              icon: "success"
            })
          }}
          onMouseOver={() => {
            toast.info(
            <Label style={{color:"#2c73d2"}} >
            {point.name} Ultima atualização: {new Date(point.last_update).toLocaleString('pt-BR', opcoes)}
            </Label>
          );
          }}
          ></MarkerF>
          ))
          }
          <></>
        </GoogleMap>
        </FormContainer>
        <ToastContainer autoClose={3000} />
        {/*<Button onClick={LastStatusOfSeveralTrackers}>Atualizar Status</Button>*/}
        
      </ContentPage>
    </>
  ) : <Label>Carregando...</Label>
};

export default MapsTelemetry;