
import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from 'react-router-dom';
import { signout } from "../../Services/UserService";
import ContentPage from "../../components/Content/ContentPage";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import {
  FormContainer,
  InputArea,
  Label,
  Select1,
  ButtonArea,
  Button
} from "../../components/Styles/Styles";
import Select from 'react-select';
import ReleasesMovTiresDetailedPdf from "../../components/ReportsMovTires/MovTiresDetailed";

const ReportsMovTires = () => {
  const navigate = useNavigate();
  const ref = useRef();
  const [vehicles, setVehicles] = useState([]);
  const IdCompany = localStorage.getItem("id_company");
  const [tireRegistration, setTireRegistration] = useState([]);
  const [tire, setTire] = useState(true);
  const [vehicle, setVehicle] = useState(false);
  const releasesRef = React.useRef();
  const category = useRef("");
  const surnameVehicle = useRef("");
  const tireFire = useRef("");

  const [openL, setOpenL] = React.useState(false);

  function adicionaZero(numero) {
    if (numero <= 9)
      return "0" + numero;
    else
      return numero;
  }


  let dataAtual = new Date();

  let dataAtualFormatada = (dataAtual.getFullYear() + "-" + (adicionaZero(dataAtual.getMonth() + 1).toString()) + "-" + adicionaZero(dataAtual.getDate().toString()));

  const handleCloseL = () => {
    setOpenL(false);
  };
  const handleOpenL = () => {
    setOpenL(true);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    await getReleases();
    const typeReport = ref.current;

    category.current = (
      typeReport.tire_status.value === "" ? "Todos" :
        typeReport.tire_status.value === "0" ? "Estoque" :
          typeReport.tire_status.value === "1" ? "Transito" :
            typeReport.tire_status.value === "2" ? "Manutenção" :
              typeReport.tire_status.value === "3" ? "Sucata" :
                typeReport.tire_status.value === "4" ? "Finalização do Transito" :
                  typeReport.tire_status.value === "5" ? "Veículo" :
                    typeReport.tire_status.value === "6" ? "Número Fogo Pneu" :
                      ""
    )


    if (releasesRef.current.length === 0) {
      toast.error(" Nenhum lançamento encontrado! ");
      handleCloseL();
      return;
    } else {
      handleCloseL();
      ReleasesMovTiresDetailedPdf(releasesRef.current, dataAtualFormatada);

    }

  };

  const getReleases = async () => {
    const typeReport = ref.current;
    handleOpenL();
    releasesRef.current = [];


    await axios.post("tires/tire-mov-list/", {
      id_company: IdCompany,
      tire_status: (
        typeReport.tire_status.value === "" ? "%" :
          typeReport.tire_status.value === "0" ? 0 :
            typeReport.tire_status.value === "1" ? 1 :
              typeReport.tire_status.value === "2" ? 2 :
                typeReport.tire_status.value === "3" ? 3 :
                  typeReport.tire_status.value === "4" ? 4 : "%"
      ),
      fire_number: (
        tireFire.current === "" ? "%" : tireFire.current
      ),
      surname: (
        surnameVehicle.current === "" ? "%" : surnameVehicle.current
      ),
    }).then((res) => {
      releasesRef.current = res.data;
      if (releasesRef.current.length > 0) {
        console.log("tire-mov-list => ", releasesRef.current);
        handleCloseL();
      } else {
        handleCloseL();
      };
    })
      .catch((error) => {
        toast.error(error);
        handleCloseL();
      });


  }

  const getTireRegistration = async () => {
    setTireRegistration([]);
    try {
      const res = await axios.get("tires/tire-registration-list/" + IdCompany + "/?loc=%");
      setTireRegistration(res.data.sort((a, b) => (a.surname < b.surname ? 1 : -1)));
    } catch (error) {
      toast.error(error);
      handleCloseL();
    }
  };

  const getVehicles = async () => {
    setVehicles([]);
    try {
      const res = await axios.get("vehicle/vehicle-list/" + IdCompany + "/?loc=%");
      if (res.data[0].status === "false") {
        toast.error(res.data[0].message);
        signout();
        navigate("/login");
      } else {
        setVehicles(res.data.sort((a, b) => (a.plate > b.plate ? 1 : -1)));
      }
    } catch (error) {
      toast.error(error);
    }
  };

  const loadingIdVehicle = (item) => {
    if(item){
    surnameVehicle.current = item.label === "" ? "%" : item.label;
    }
  };

  const onStatusTireChange = () => {
    const typeReport = ref.current;
    if (typeReport.tire_status.value === "5") {
      setVehicle(true);
      setTire(false);
    }
    if (typeReport.tire_status.value === "6") {
      setVehicle(false);
      setTire(true);
    }
  };

  const onTireChange = (item) => {
    if(item){
      tireFire.current = item.label === "" ? "%" : item.label;
    }
  };

  useEffect(() => {
    getVehicles();
    getTireRegistration();
    // eslint-disable-next-line

  }, []);


  return (
    <>
      <ContentPage caminho={[{ nome: "Relatórios / Mov. Penus", link: "/Reports/MovTires" }]}>

        <Typography style={{ color: "#022025" }} id="modal-modal-title" variant="h6" component="h2">
          Movimentação de Pneus
        </Typography>
        <FormContainer style={{ maxWidth: "350px" }} ref={ref} onSubmit={handleSubmit}>

          <InputArea >
            <Label>Status Pneu</Label>
            <Select1 onChange={onStatusTireChange} name="tire_status" style={{ width: "300px" }}>
              <option value="">Todos</option>
              <option value={1}>Transito</option>
              <option value={2}>Manutenção</option>
              <option value={3}>Sucata</option>
              <option value={4}>Finalização do Transito</option>
              <option value={5}>Veículo</option>
              <option value={6}>Número Fogo Pneu</option>
            </Select1>
          </InputArea>

          <InputArea style={{ display: (vehicle === false ? "none" : "flex"), width: "300px", color: "#000", fontSize: "12px" }}>
            <Label style={{ color: "#fff" }}>Veículo</Label>
            <Select
              className="basic-single"
              classNamePrefix="select"
              isClearable={true}
              defaultValue={tireRegistration[0]}
              isSearchable={true}
              onChange={(item) => loadingIdVehicle(item)}
              options={vehicles === undefined ? [] : vehicles.map((item) => (
                {
                  value: item.id,
                  label: item.surname
                }
              ))}
              theme={theme => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  primary: "#2c73d2",
                  secondary: "#bbb",
                },
              })}
            />
          </InputArea>

          <InputArea style={{ display: (tire === false ? "none" : "flex"), width: "300px", color: "#000", fontSize: "12px" }}>
            <Label style={{ color: "#fff" }}>Número Fogo Pneu*</Label>
            <Select
              className="basic-single"
              classNamePrefix="select"
              isClearable={true}
              defaultValue={tireRegistration[0]}
              isSearchable={true}
              onChange={(item) => onTireChange(item)}
              options={tireRegistration === undefined ? [] : tireRegistration.map((item) => (
                {
                  value: item.id,
                  label: item.fire_number
                }
              ))}
              theme={theme => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  primary: "#2c73d2",
                  secondary: "#bbb",
                },
              })}
            />
          </InputArea>

          <ButtonArea style={{marginTop:"100%"}} >
            <Button type="submit">Visualizar</Button>
          </ButtonArea>
        </FormContainer>

        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 5 }}
          open={openL}
        >
          <CircularProgress color="inherit" />
          <Label>Carregando...</Label>
        </Backdrop>
        <ToastContainer autoClose={3000} />
      </ContentPage>
    </>
  );
};

export default ReportsMovTires;