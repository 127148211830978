
import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from 'react-router-dom';
import { signout } from "../../Services/UserService";
import ContentPage from "../../components/Content/ContentPage";
import ModalVehicles from "../../components/Vehicles/ModalVehicles";
import GridVehicles from "../../components/Vehicles/GridVehicles";
import { toast } from "react-toastify";
import axios from "axios";
import {
  FormContainer,
  Loc,
  InputArea,
  Button
} from "../Styles/Styles";


const VehiclesList = () => {
  const navigate = useNavigate();
  const [vehicles, setVehicles] = useState([]);
  const [onEdit, setOnEdit] = useState(null);
  const [open, setOpen] = React.useState(false);
  const [loc, setLoc] = React.useState();
  const [regist, setRegist] = React.useState(false);
  const [company, setCompany] = useState([]);
  const fuel = useRef([]);
  const [axisSchema, setAxisSchema] = useState([]);
  const IdCompany = localStorage.getItem("id_company");

  const [openL, setOpenL] = React.useState(false);

  const handleCloseL = () => {
    setOpenL(false);
  };
  const handleOpenL = () => {
    setOpenL(true);
  };


  const handleOpen = () => {
    setOpen(true);
    setRegist(true);
  };

  const getVehicles = async () => {
    handleOpenL();
    setVehicles([]);
    setOnEdit(null);
    try {
      const res = await axios.get("vehicle/vehicle-list/" + IdCompany + "/?loc=" + (loc === undefined ? '%' : loc));
      if (res.data[0].status === "false") {
        toast.error(res.data[0].message);
        signout();
        navigate("/login");
      } else {
        setVehicles(res.data.sort((a, b) => (a.plate > b.plate ? 1 : -1)));
      }
      handleCloseL();
    } catch (error) {
      toast.error(error);
    }
  };

  const getFuel = async () => {
    handleOpenL();
    try {
      const res = await axios.get("fuel/fuel-list/" + IdCompany + "/?loc=%");
      fuel.current = res.data.sort((a, b) => (a.fuel > b.fuel ? 1 : -1));
      handleCloseL();
    } catch (error) {
      toast.error(error);
    }
  };

  const getCompany = async () => {
    setCompany([]);
    try {
      const res = await axios.get("company/company-list/" + IdCompany + "/?loc=%");
      if (res.data[0].status === "false") {
        toast.error(res.data[0].message);
        signout();
        navigate("/login");
      } else {
        setCompany(res.data.sort((a, b) => (a.name > b.name ? 1 : -1)));
      }

    } catch (error) {
      toast.error(error);
    }
  };

  const getAxisSchema = async () => {
    handleOpenL();
    setAxisSchema([]);
    setOnEdit(null);
    try {
      const res = await axios.get("tires/axis-schema-list/" + IdCompany + "/?loc=%");
      if (res.data[0].status === "false") {
        toast.error(res.data[0].message);
        signout();
        navigate("/login");
      } else {
        setAxisSchema(res.data.sort((a, b) => (a.id > b.id ? 1 : -1)));
      }
      handleCloseL();
    } catch (error) {
      toast.error(error);
      handleCloseL();
    }
  };

  useEffect(() => {
    getVehicles();
    getFuel();
    getCompany();
    getAxisSchema();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <ContentPage caminho={[{ nome: "Cadastros > Veículos", link: "/Vehicles/list" }]}>

        <ModalVehicles
          regist={regist}
          vehicles={vehicles}
          setVehicles={setVehicles}
          setLoc={setLoc}
          loc={loc}
          onEdit={onEdit}
          setOnEdit={setOnEdit}
          getVehicles={getVehicles}
          setOpen={setOpen}
          open={open}
          openL={openL}
          handleOpenL={handleOpenL}
          handleCloseL={handleCloseL}
          company={company}
          fuel={fuel.current}
          axisSchema={axisSchema}
        />

        <FormContainer>
          <Loc
            style={{
              width: "300px",
              height: "30px",
              border: "1px solid #343f46",
              textTransform: "uppercase",
              fontSize: "12px"
            }}
            type='text'
            placeholder="Digite apelido do veículo"
            value={loc}
            onChange={(e) => [setLoc(e.target.value)]}
          />

          <InputArea >
            <Button
              onClick={getVehicles}>Localizar
            </Button>
          </InputArea>
          <InputArea  >
            <Button
              onClick={handleOpen}>Novo
            </Button>
          </InputArea>
        </FormContainer>

        <GridVehicles
          openL={openL}
          regist={regist}
          setRegist={setRegist}
          vehicles={vehicles}
          setOnEdit={setOnEdit}
          setOpen={setOpen} />
      </ContentPage>
    </>
  );
};

export default VehiclesList;