import React, { useEffect, useRef } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import Typography from '@mui/material/Typography';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import DeleteIcon from '@mui/icons-material/Delete';
import Select from 'react-select';
import {
  FormContainer,
  InputArea,
  Label,
  Input,
  Select1,
  Input2,
  ButtonArea,
  Button
} from "../Styles/Styles";

const FromLaunchSalesTicket = ({
  vehicles,
  employees,
  handleCloseL,
  openL,
  handleOpenL,
  regist,
  onEdit,
  setOnEdit,
  getReleases,
  setOpen,
  getEmployees,
  getVehicles,
  lines
}) => {

  const ref = useRef();
  const handleClose = () => setOpen(false);
  const IdCompany = localStorage.getItem("id_company");
  const IdUser = localStorage.getItem("id");
  const [vtValue, setVT] = React.useState(0);
  const [citizenValue, setCitizen] = React.useState(0);
  const [integrationValue, setIntegration] = React.useState(0);
  const [schoolValue, setSchool] = React.useState(0);
  const [payersValue, setPayers] = React.useState(0);

  function adicionaZero(numero) {
    if (numero <= 9)
      return "0" + numero;
    else
      return numero;
  }

  let dataAtual = new Date();
  let dataAtualFormatada = (dataAtual.getFullYear() + "-" + (adicionaZero(dataAtual.getMonth() + 1).toString()) + "-" + adicionaZero(dataAtual.getDate().toString()));

  const onContChange = () => {
    const rest = ref.current;

    const vt = rest.vt.value;
    const citizen = rest.citizen.value;
    const integration = rest.integration.value;
    const school = rest.school.value;
    const payers = rest.payers.value;

    rest.total_vt.value = parseFloat((+vtValue === undefined ? 0 : vtValue) * (+vt)).toFixed(2);
    rest.total_citizen.value = parseFloat((+citizenValue === undefined ? 0 : citizenValue) * (+citizen)).toFixed(2);
    rest.total_integration.value = parseFloat((+integrationValue === undefined ? 0 : integrationValue) * (+integration)).toFixed(2);
    rest.total_school.value = parseFloat((+schoolValue === undefined ? 0 : schoolValue) * (+school)).toFixed(2);
    rest.total_payers.value = parseFloat((+payersValue === undefined ? 0 : payersValue) * (+payers)).toFixed(2);

    rest.total_daily.value = parseFloat((+vt) + (+citizen) + (+integration) + (+school) + (+payers));
    const totalDaily = rest.total_daily.value;
    rest.amount.value = parseFloat((+rest.total_vt.value) + (+rest.total_citizen.value) + (+rest.total_integration.value) + (+rest.total_school.value) + (+rest.total_payers.value)).toFixed(2);
    rest.difference.value = parseFloat(+totalDaily) - parseFloat(+rest.total_users.value);
  };

  const onLineChange = (e) => {
    const res = lines.filter((a) => (a.id === e.target.value));
    // eslint-disable-next-line
    res.map((a) => {
      setVT(a.vt)
      setCitizen(a.citizen)
      setIntegration(a.integration)
      setSchool(a.school)
      setPayers(a.payers)
    });
    onContChange();
  };

  const onTrurnstileChange = () => {
    const rest = ref.current;
    const initial = rest.trurnstile_initial.value;
    const final = rest.trurnstile_final.value;
    rest.total_users.value = (parseFloat((+final) - (+initial)))
  };


  const ClearFields = () => {
    const rest = ref.current;

    rest.cod.value = "";
    rest.id_vehicle.value = "";
    rest.date.value = dataAtualFormatada;
    rest.id_driver.value = "";
    rest.amount.value = "";
    rest.citizen.value = "";
    rest.difference.value = "";
    rest.integration.value = "";
    rest.payers.value = "";
    rest.school.value = "";
    rest.total_daily.value = "";
    rest.total_users.value = "";
    rest.trurnstile_final.value = "";
    rest.trurnstile_initial.value = "";
    rest.vt.value = "";

    rest.total_vt.value = "";
    rest.total_citizen.value = "";
    rest.total_integration.value = "";
    rest.total_school.value = "";
    rest.total_payers.value = "";
    rest.obs.value = "";
    rest.id_line.value = "";
  };

  useEffect(() => {
    const rest = ref.current;
    if (regist) {
      ClearFields();
      getEmployees();
      getVehicles();
    } else {
      if (onEdit) {

        console.log(onEdit);
        rest.cod.value = onEdit.id;
        rest.id_vehicle.value = onEdit.id_vehicle;
        rest.date.value = onEdit.date;
        rest.id_driver.value = onEdit.id_driver;
        rest.amount.value = onEdit.amount;
        rest.citizen.value = onEdit.citizen;
        rest.difference.value = onEdit.difference;
        rest.integration.value = onEdit.integration;
        rest.payers.value = onEdit.payers;
        rest.school.value = onEdit.school;
        rest.total_daily.value = onEdit.total_daily;
        rest.total_users.value = onEdit.total_users;
        rest.trurnstile_final.value = onEdit.trurnstile_final;
        rest.trurnstile_initial.value = onEdit.trurnstile_initial;
        rest.vt.value = onEdit.vt;

        rest.total_vt.value = onEdit.vt_total;
        rest.total_citizen.value = onEdit.citizen_total;
        rest.total_integration.value = onEdit.integration_total;
        rest.total_school.value = onEdit.school_total;
        rest.total_payers.value = onEdit.payers_total;
        rest.obs.value = onEdit.obs;
        rest.id_line.value = onEdit.id_line;

        setVT(onEdit.vt_value)
        setCitizen(onEdit.citizen_value)
        setIntegration(onEdit.integration_value)
        setSchool(onEdit.school_value)
        setPayers(onEdit.payers_value)
      };
    };

    return;
    // eslint-disable-next-line
  }, []);

  const handleDelete = async (e) => {
    e.preventDefault();
    const rest = ref.current;
    handleOpenL();

    if (!window.confirm("Tem certeza que deseja excluir?")) {
      handleCloseL();
      return;
    } else {
      await axios
        .delete("launch-sales-ticket/launch-sales-ticket-list/" + rest.cod.value)
        .then(() => {
          toast.success("Lançamento excluido com sucesso!");
          getReleases();
          setOnEdit(null);
          handleClose();
        })
        .catch(() => {
          handleCloseL();
          toast.error("Erro, não foi possível excluir!");
        });
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    handleOpenL();
    const rest = ref.current;
    if (!window.confirm("Tem certeza que deseja salvar? Confira se os dados estão corretos!")) {
      handleCloseL();
      return;
    } else {

      const valeuinitial = rest.trurnstile_initial.value;
      const valeufinal = rest.trurnstile_final.value;
      const result = valeufinal - valeuinitial;

      if (result < 0) {
        handleCloseL();
        return toast.warn("Número da Catraca final não pode ser menor que o inicial!");
      } else {
        if (!rest.date.value
        ) {
          handleCloseL();
          return toast.warn("Falta adicionar data de lançamento!");
        }

        if (
          !rest.id_vehicle.value ||
          !rest.id_driver.value ||
          !rest.citizen.value ||
          !rest.integration.value ||
          !rest.payers.value ||
          !rest.school.value ||
          !rest.trurnstile_final.value ||
          !rest.trurnstile_initial.value ||
          !rest.vt.value ||
          !rest.id_line.value
        ) {
          handleCloseL();
          return toast.warn("Preencha todos com ( * )!");
        }

        if (rest.amount.value === "0.00"
        ) {
          onContChange();
        }
        if (rest.difference.value === "0") {
          toast.warn("Aguarde!");
        } else {
          if (rest.difference.value < "1") {
            if (!rest.obs.value) {
              handleCloseL();
              return toast.warn("A Diferença na quantidade de Usuários, precisa justificar no campos Observação!");
            }
          }

          if (rest.difference.value > "1") {
            if (!rest.obs.value) {
              handleCloseL();
              return toast.warn("A Diferença na quantidade de Usuários, precisa justificar no campos Observação!");
            }
          }
        }

        if (onEdit) {
          await axios
            .put("launch-sales-ticket/launch-sales-ticket-list/", {
              id: rest.cod.value,
              id_vehicle: rest.id_vehicle.value,
              date: rest.date.value,
              id_driver: rest.id_driver.value,
              amount: rest.amount.value,
              citizen: rest.citizen.value.trim(),
              difference: rest.difference.value,
              citizen_total: rest.total_citizen.value,
              citizen_value: citizenValue,
              id_line: rest.id_line.value,
              integration: rest.integration.value.trim(),
              integration_total: rest.total_integration.value,
              integration_value: integrationValue,
              obs: rest.obs.value.trim(),
              payers: rest.payers.value.trim(),
              payers_total: rest.total_payers.value,
              payers_value: payersValue,
              school: rest.school.value.trim(),
              school_total: rest.total_school.value,
              school_value: schoolValue,
              total_daily: rest.total_daily.value,
              total_users: rest.total_users.value,
              trurnstile_final: rest.trurnstile_final.value.trim(),
              trurnstile_initial: rest.trurnstile_initial.value.trim(),
              vt: rest.vt.value.trim(),
              vt_total: rest.total_vt.value,
              vt_value: vtValue,
              user: IdUser,
            })
            .then(() => {
              handleCloseL();
              getReleases();
              setOnEdit(null);
              handleClose();
              toast.success("Lançamento atualizado com sucesso!");
            })
            .catch(() => {
              handleCloseL();
              toast.error("Erro na atualizado!");
            });
        } else {
          await axios
            .post("launch-sales-ticket/launch-sales-ticket-list/", {
              id_vehicle: rest.id_vehicle.value,
              date: rest.date.value,
              id_driver: rest.id_driver.value,
              amount: rest.amount.value,
              citizen: rest.citizen.value.trim(),
              difference: rest.difference.value,
              citizen_total: rest.total_citizen.value,
              citizen_value: citizenValue,
              id_line: rest.id_line.value,
              integration: rest.integration.value.trim(),
              integration_total: rest.total_integration.value,
              integration_value: integrationValue,
              obs: rest.obs.value.trim(),
              payers: rest.payers.value.trim(),
              payers_total: rest.total_payers.value,
              payers_value: payersValue,
              school: rest.school.value.trim(),
              school_total: rest.total_school.value,
              school_value: schoolValue,
              total_daily: rest.total_daily.value,
              total_users: rest.total_users.value,
              trurnstile_final: rest.trurnstile_final.value.trim(),
              trurnstile_initial: rest.trurnstile_initial.value.trim(),
              vt: rest.vt.value.trim(),
              vt_total: rest.total_vt.value,
              vt_value: vtValue,
              id_company: IdCompany,
              user: IdUser,
            })
            .then(() => {
              handleCloseL();
              getReleases();
              setOnEdit(null);
              handleClose();
              toast.success("Lançamento cadastrado com sucesso!");
            })
            .catch(() => {
              handleCloseL();
              toast.error("Erro no Lançamento!");
            });

        }
      }
    }

  };

  const onDateChange = (item) =>{
    const rest = ref.current;
    if(regist){
      rest.id_vehicle.value = item.value;
    }
  }

  return (
    <>
      <Typography style={{ color: "#fff" }} id="modal-modal-title" variant="h6" component="h2">
        Lançamento Vendas de Passagem
      </Typography>
      <FormContainer ref={ref} onSubmit={handleSubmit}>
        <InputArea>
          <Label>Código</Label>
          <Input style={{ color: "#fff" }} disabled={true} name="cod" />
        </InputArea>
        <InputArea>
          <Label>Data de lançamento*</Label>
          <Input2 type="date" name="date" />
        </InputArea><InputArea style={{width: "175px", color: "#000", fontSize:"12px" }}>
          <Label style={{color: "#fff" }}>Veículo*</Label>
          {regist?
         <Select 
          name={"id_vehicle"}
          onChange = {(item) => onDateChange(item)}       
          options = {vehicles === undefined ? [] : vehicles.map((item) => (
            {value : item.id, 
              label : item.surname + " - " + item.plate}
          ))} 
          theme = {theme => ({
            ...theme,
            colors: {
             ...theme.colors,
             primary: "#2c73d2",
             secondary: "#bbb",
            },
          })}
          /> : <Select1 disabled={regist === true ? false : true} name="id_vehicle">
          <option value="">Selecione</option>
          {vehicles === undefined ? [] : vehicles.map((item, i) => (
            <option key={i} value={item.id}>{item.surname} - {item.plate}</option>
          ))}
        </Select1>}
        </InputArea>
        <InputArea>
          <Label>Motorista</Label>
          <Select1 style={{ width: "350px" }} name="id_driver">
            <option value="">Selecione</option>
            {employees === undefined ? [] : employees.map((item, i) => (
              <option key={i} value={item.id}>{item.nome}</option>
            ))}
          </Select1>
        </InputArea>
        <InputArea>
          <Label>Linha</Label>
          <Select1 onChange={onLineChange} style={{ width: "350px" }} name="id_line">
            <option value="">Selecione</option>
            {lines === undefined ? [] : lines.map((item, i) => (
              <option key={i} value={item.id}>{item.name_line}</option>
            ))}
          </Select1>
        </InputArea>
        <InputArea>
          <Label>Catraca Inicial*</Label>
          <Input onChange={onTrurnstileChange} name="trurnstile_initial" type="number" pattern="/([0-9]{3}).([0-9]{3}),([0-9]{2})$/g, '.$1.$2,$3'" min="0" step="any" placeholder="Digite só número" />
        </InputArea>
        <InputArea>
          <Label>Catraca Final*</Label>
          <Input onChange={onTrurnstileChange} name="trurnstile_final" type="number" pattern="/([0-9]{3}).([0-9]{3}),([0-9]{2})$/g, '.$1.$2,$3'" min="0" step="any" placeholder="Digite só número" />
        </InputArea>
        <InputArea>
          <Label>Total Usuários*</Label>
          <Input style={{ color: "#fff" }} disabled={true} name="total_users" type="number" pattern="/([0-9]{3}).([0-9]{3}),([0-9]{2})$/g, '.$1.$2,$3'" min="0" step="any" placeholder="Digite só número" />
        </InputArea>
        <InputArea>
          <Label>VT* R$ {vtValue} </Label>
          <Input onChange={onContChange} name="vt" type="number" pattern="/([0-9]{3}).([0-9]{3}),([0-9]{2})$/g, '.$1.$2,$3'" min="0" step="any" placeholder="Digite QTde só número" />
        </InputArea>
        <InputArea>
          <Label>Cidadão* R$ {citizenValue} </Label>
          <Input onChange={onContChange} name="citizen" type="number" pattern="/([0-9]{3}).([0-9]{3}),([0-9]{2})$/g, '.$1.$2,$3'" min="0" step="any" placeholder="Digite QTde só número" />
        </InputArea>
        <InputArea>
          <Label>Integração* R$ {integrationValue} </Label>
          <Input onChange={onContChange} name="integration" type="number" pattern="/([0-9]{3}).([0-9]{3}),([0-9]{2})$/g, '.$1.$2,$3'" min="0" step="any" placeholder="Digite QTde só número" />
        </InputArea>
        <InputArea>
          <Label>Escola* R$ {schoolValue} </Label>
          <Input onChange={onContChange} name="school" type="number" pattern="/([0-9]{3}).([0-9]{3}),([0-9]{2})$/g, '.$1.$2,$3'" min="0" step="any" placeholder="Digite QTde só número" />
        </InputArea>
        <InputArea>
          <Label>Pagantes* R$ {payersValue} </Label>
          <Input onChange={onContChange} name="payers" type="number" pattern="/([0-9]{3}).([0-9]{3}),([0-9]{2})$/g, '.$1.$2,$3'" min="0" step="any" placeholder="Digite QTde só número" />
        </InputArea>
        <InputArea>
          <Label>Total VT R$</Label>
          <Input style={{ color: "#fff" }} disabled={true} name="total_vt" />
        </InputArea>
        <InputArea>
          <Label>Total Cidadão R$</Label>
          <Input style={{ color: "#fff" }} disabled={true} name="total_citizen" />
        </InputArea>
        <InputArea>
          <Label>Total Integração R$</Label>
          <Input style={{ color: "#fff" }} disabled={true} name="total_integration" />
        </InputArea>
        <InputArea>
          <Label>Total Escola R$</Label>
          <Input style={{ color: "#fff" }} disabled={true} name="total_school" />
        </InputArea>
        <InputArea>
          <Label>Total Pagantes R$</Label>
          <Input style={{ color: "#fff" }} disabled={true} name="total_payers" />
        </InputArea>
        <InputArea>
          <Label>Total Diário</Label>
          <Input style={{ color: "#fff" }} disabled={true} name="total_daily" />
        </InputArea>
        <InputArea>
          <Label>Valor Total Linha R$</Label>
          <Input style={{ color: "#fff" }} disabled={true} name="amount" />
        </InputArea>
        <InputArea>
          <Label>Diferença</Label>
          <Input style={{ color: "#fff" }} disabled={true} name="difference" />
        </InputArea>
        <InputArea>
          <Label>Observação</Label>
          <Input2 style={{ width: "350px" }} name="obs" />
        </InputArea>

        <div style={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
        }} >
          <ButtonArea >
            <Button type="submit">SALVAR</Button>
            {regist === true ?
              null
              :
              <Button
                style={{ backgroundColor: "red" }}
                onClick={handleDelete}>EXCLUIR <DeleteIcon />
              </Button>
            }

            <Button onClick={handleClose}>SAIR</Button>
          </ButtonArea>
        </div>

      </FormContainer>

      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 5 }}
        open={openL}
      >
        <Label>Carregando...</Label>
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};

export default FromLaunchSalesTicket;