import * as React from 'react';
import { GridActionsCellItem } from '@mui/x-data-grid';
import EditIcon from '@mui/icons-material/Edit';

import { DataGrid, GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';
import { useDemoData } from '@mui/x-data-grid-generator';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';

import { 
  Table, 
  Tbody, 
  Label 
} from "../Styles/Styles";

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarExport />
    </GridToolbarContainer>
  );
}

const GridBillsToPay = ({openL, setRegist, billsToPay, setOnEdit, setOpen }) => {

  const alterBillsToPay = React.useCallback(
    (id) => () => {
      const res = billsToPay.filter((a) => (a.id === id));
       // eslint-disable-next-line
       res.map((a) => {
        handleEdit(a);
       });
    },
    [billsToPay],
  );

  const data = {
    columns: [
      { field: 'situation', headerName: 'Situação', width: 80 },
      { field: 'due_date', headerName: 'Data vencimento', width: 130 },
      { field: 'supplier_name', headerName: 'Fornecedor', width: 350 },
      { field: 'historic', headerName: 'Histórico', width: 350 },
      { field: 'invoice', headerName: 'Nota Fiscal', width: 150 },
      { field: 'document', headerName: 'Documento', width: 150 },
      { field: 'species', headerName: 'Espécie', width: 150 },
      { field: 'amount', headerName: 'Valor R$', width: 150 },
      {
        field: 'actions',
        type: 'actions',
        headerName: 'Ações',
        width: 80,
        getActions: (params) => [
          <GridActionsCellItem
            icon={<EditIcon style={{
              color: "#2c73d2"}} />}
            label="Editar"
            onClick={alterBillsToPay(params.id)}
          />,
          <GridActionsCellItem
            icon={<CurrencyExchangeIcon style={{
              color: "#2c73d2"}} />}
            label="Pagar"
            onClick={alterBillsToPay(params.id)}
          />,
        ],
      },
    ],
    rows: billsToPay === undefined? [] : billsToPay.map((item) => ({
      id: item.id,
      due_date: item.due_date.split("-").reverse().join("/"),
      supplier_name: item.supplier_name,
      amount: item.amount,
      document: item.document,
      invoice: item.invoice,
      historic: item.historic,
      species: (
        item.species === "1"? "Dinheiro" : 
        item.species === "2"? "Cheque" : 
        item.species === "3"? "Cartão de Crédito" : 
        item.species === "4"? "Cartão de Débito" : 
        item.species === "5"? "Crédito Loja" : 
        item.species === "10"? "Vale Alimentação" : 
        item.species === "11"? "Vale Refeição" : 
        item.species === "12"? "Vale Presente" : 
        item.species === "13"? "Vale Combustível" : 
        item.species === "14"? "Duplicata Mercantil" : 
        item.species === "15"? "Boleto Bancário" :
        item.species === "17"? "PIX" : 
        item.species === "90"? "Sem Pagamento" : 
        item.species === "99"? "Outros" : ""
      ),
      situation: (item.situation === "1" ? "A pagar" : "Pago"),
    }))
  }
  ;

  const { loading } = useDemoData({
    dataSet: 'Commodity',
    rowLength: 10,
    maxColumns: 6,
  });

  const handleOpen = () => setOpen(true);

  const handleEdit = (item) => {
    setRegist(false);
    setOnEdit(item);
    handleOpen();
  };



  return (
    <>
    <Table onlyWeb>
      <Tbody > 
      <DataGrid
        {...data}
        loading={loading}
        rowHeight = {30}
        slots={{
          toolbar: CustomToolbar,
        }}
      />
      </Tbody>
    </Table>
    
    <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 5}}
        open={openL}
    >
         <Label>Carregando...</Label>
        <CircularProgress color="inherit" />
    </Backdrop>
    </>
  );
};

export default GridBillsToPay;