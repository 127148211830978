
import React from "react";
import { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import { signout } from "../../Services/UserService";
import ContentPage from "../../components/Content/ContentPage";
import ModalProductsServices from "../../components/ProductsServices/ModalProductsServices";
import GridProductsServices from "../../components/ProductsServices/GridProductsServices";
import { toast } from "react-toastify";
import axios from "axios";
import {
  FormContainer,
  Loc,
  InputArea,
  Button
} from "../Styles/Styles";
import { Label, Select1 } from "../../components/Styles/Styles";


const ProductsServicesList = () => {
  const navigate = useNavigate();
  const [products, setProducts] = useState([]);
  const [onEdit, setOnEdit] = useState(null);
  const [open, setOpen] = React.useState(false);
  const [loc, setLoc] = React.useState();
  const [regist, setRegist] = React.useState(false);
  const IdCompany = localStorage.getItem("id_company");
  const [typeReg, setTypeReg] = React.useState("P");
  const [statusReg, setStatusReg] = React.useState(1);

  const [openL, setOpenL] = React.useState(false);

  const handleCloseL = () => {
    setOpenL(false);
  };
  const handleOpenL = () => {
    setOpenL(true);
  };


  const handleOpen = () => {
    setOpen(true);
    setRegist(true);
  };

  const getProducts = async () => {
    handleOpenL();
    setProducts([]);
    setOnEdit(null);
    try {
      const res = await axios.get("products-services/products-services-list/" + IdCompany + "/" + typeReg + "/" + statusReg + "/?loc=" + (loc === undefined ? '%' : loc));
      if (res.data[0].status === "false") {
        toast.error(res.data[0].message);
        signout();
        navigate("/login");
      } else {
        setProducts(res.data.sort((a, b) => (a.description > b.description ? 1 : -1)));
      }
      handleCloseL();
    } catch (error) {
      toast.error(error);
      handleCloseL();
    }
  };

  useEffect(() => {
    getProducts();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <ContentPage caminho={[{ nome: "Estoque > Produtos / Serviços", link: "/Products-Services/list" }]}>

        <ModalProductsServices
          regist={regist}
          products={products}
          setLoc={setLoc}
          loc={loc}
          onEdit={onEdit}
          setOnEdit={setOnEdit}
          getProducts={getProducts}
          setOpen={setOpen}
          open={open}
          openL={openL}
          handleOpenL={handleOpenL}
          handleCloseL={handleCloseL}
        />

        <FormContainer>

          <Label>Situação</Label>
          <Select1
            value={statusReg}
            onChange={(e) => [setStatusReg(e.target.value)]}
            style={{
              width: "150px",
              height: "30px",
              border: "1px solid #343f46",
              textTransform: "uppercase",
              fontSize: "12px"
            }}>
            <option value={1}>Ativo</option>
            <option value={0}>Inativo</option>
          </Select1>
          <Label>Tipo</Label>
          <Select1
            value={typeReg}
            onChange={(e) => [setTypeReg(e.target.value)]}
            style={{
              width: "150px",
              height: "30px",
              border: "1px solid #343f46",
              textTransform: "uppercase",
              fontSize: "12px"
            }}>
            <option value="P">Produto</option>
            <option value="S">Serviço</option>
          </Select1>
          <Loc
            style={{
              width: "300px",
              height: "30px",
              border: "1px solid #343f46",
              textTransform: "uppercase",
              fontSize: "12px"
            }}
            type='text'
            placeholder="Digite Descrição do produto ou serviço"
            value={loc}
            onChange={(e) => [setLoc(e.target.value)]}
          />

          <InputArea >
            <Button
              onClick={getProducts}>Localizar
            </Button>
          </InputArea>
          <InputArea  >
            <Button
              onClick={handleOpen}>Cadastrar
            </Button>
          </InputArea>
        </FormContainer>

        <GridProductsServices
          openL={openL}
          regist={regist}
          setRegist={setRegist}
          products={products}
          setOnEdit={setOnEdit}
          setOpen={setOpen}
        />
      </ContentPage>
    </>
  );
};

export default ProductsServicesList;