import axios from "axios";

// Configurações globais para a API

const url = "https://acthauros.com.br/api/controle-frota-api/registrations/";

export default class UserServices {
    constructor() {
        this.axios = axios.create({
            baseURL: url
        })
    }
    async login(email, password) {
        // TODO: Atualizar a chamada para a API de login
        const response = await this.axios.post("auth/auth/", {
            email: email,
            password: password
        })
            .then((res) => {
                if (res.data.length === 0) {
                    return false;
                } else {

                    localStorage.setItem("id", res.data[0].id)
                    localStorage.setItem("name", res.data[0].name)
                    localStorage.setItem("id_company", res.data[0].id_company)
                    localStorage.setItem("company", res.data[0].company)
                    localStorage.setItem("t", res.data[0].t)
                    localStorage.setItem("token", res.data[1].token)

                    return true;
                }

            })
            .catch((error) => {
                throw new Error("Erro ao efetuar login: " + error);
            });
        return response;


    }

    userAuthenticated() {

        axios.defaults.headers.common['Authorization'] = localStorage.getItem("token");
        axios.defaults.baseURL = url;

        return localStorage.getItem("token") !== null;
    }

}
export const signout = () => {
    localStorage.removeItem("id");
    localStorage.removeItem("name");
    localStorage.removeItem("id_company");
    localStorage.removeItem("company");
    localStorage.removeItem("token");
    localStorage.removeItem("t");
};