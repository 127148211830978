import React, { useEffect, useRef } from "react";
import axios from "axios";
import {toast } from "react-toastify";
import Typography from '@mui/material/Typography';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import DeleteIcon from '@mui/icons-material/Delete';
import { 
  FormContainer, 
  InputArea, 
  Label, 
  Input, 
  Select1, 
  Input3, 
  ButtonArea, 
  Button 
} from "../Styles/Styles"


const FromBombs = ({ 
  handleCloseL, 
  openL, 
  handleOpenL, 
  regist, 
  onEdit, 
  setOnEdit, 
  getBombs, 
  setOpen, 
  company 
}) => {
  const ref = useRef();
  const handleClose = () => setOpen(false);
  const IdCompany = localStorage.getItem("id_company");
  const IdUser = localStorage.getItem("id");

  useEffect(() => {
    const bomb = ref.current;
    if (regist) {
      bomb.cod.value = "";
      bomb.description.value = "";
      bomb.num_cont_bomb.value = "";
      bomb.id_subcompany.value = "";
      bomb.status.value = "1";
    } else {
      if (onEdit) {

        bomb.cod.value = onEdit.id;
        bomb.description.value = onEdit.description;
        bomb.num_cont_bomb.value = onEdit.num_cont_bomb;
        bomb.id_subcompany.value = onEdit.id_subcompany;
        bomb.status.value = onEdit.status;

      };
    };
    // eslint-disable-next-line
  }, []);

  const handleDelete = async (e) => {
    e.preventDefault();
    const bomb = ref.current;
    handleOpenL();

    if (!window.confirm("Tem certeza que deseja excluir?")) {
      handleCloseL();
      return;
    } else {
      await axios
        .delete("bombs/bombs-list/" + bomb.cod.value)
        .then(() => {
          toast.success("Cadastro excluido com sucesso!");
          getBombs();
          setOnEdit(null);
          handleClose();
        })
        .catch(() => {
          handleCloseL();
          toast.error("Erro, não foi possível excluir!");
        });
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    handleOpenL();

    const bomb = ref.current;

    if (
      !bomb.description.value ||
      !bomb.num_cont_bomb.value
    ) {
      handleCloseL();
      return toast.warn("Preencha todos os campos!");
    }

    if (onEdit) {
      await axios
        .put("bombs/bombs-list/", {
          id: bomb.cod.value.trim(),
          description: bomb.description.value.trim(),
          num_cont_bomb: bomb.num_cont_bomb.value.trim(),
          status: bomb.status.value.trim(),
          id_subcompany: bomb.id_subcompany.value,
          user: IdUser,
        })
        .then(() => {
          toast.success("Cadastro atualizado com sucesso!");

          bomb.cod.value = "";
          bomb.description.value = "";
          bomb.num_cont_bomb.value = "";
          bomb.id_subcompany.value = "";
          bomb.status.value = "1";

          getBombs();
          setOnEdit(null);
          handleClose();
        })
        .catch(() => {
          handleCloseL();
          toast.error("Erro na atualizado!");
        });
    } else {
      await axios
        .post("bombs/bombs-list/", {
          description: bomb.description.value.trim(),
          num_cont_bomb: bomb.num_cont_bomb.value.trim(),
          status: bomb.status.value.trim(),
          id_subcompany: bomb.id_subcompany.value,
          id_company: IdCompany,
          user: IdUser,
        })
        .then(() => {
          toast.success("Cadastrado com sucesso!");

          bomb.cod.value = "";
          bomb.description.value = "";
          bomb.num_cont_bomb.value = "";
          bomb.id_subcompany.value = "";
          bomb.status.value = "1";

          getBombs();
          setOnEdit(null);
          handleClose();
        })
        .catch(() => {
          handleCloseL();
          toast.error("Erro no Cadastro!");
        });

    }


  };

  return (
    <>
      <Typography style={{ color: "#fff" }} id="modal-modal-title" variant="h6" component="h2">
        Ficha da Bomba
      </Typography>
      <FormContainer ref={ref} onSubmit={handleSubmit}>
        <InputArea>
          <Label>Código</Label>
          <Input style={{ color: "#fff" }} disabled={true} name="cod" />
        </InputArea>
        <InputArea>
          <Label>Situação</Label>
          <Select1 name="status">
            <option value={1}>Ativo</option>
            <option value={0}>Inativo</option>
          </Select1>
        </InputArea>
        <InputArea>
          <Label>Número de Controle*</Label>
          <Input type="number" pattern="[0-9]+([,\.][0-9]+)?" min="0" step="any" placeholder="Digite seu número" name="num_cont_bomb" />
        </InputArea>
        <InputArea>
          <Label>Descrição*</Label>
          <Input3 style={{ width: "350px" }} name="description" />
        </InputArea>
        <InputArea>
          <Label>Empresa*</Label>
          <Select1 style={{ width: "350px" }} name="id_subcompany">
            <option value="">Selecione</option>
            {company === undefined ? [] : company.map((item, i) => (
              <option key={i} value={item.id}>{item.name}</option>
            ))}
          </Select1>
        </InputArea>
        <div style={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
        }} >
          <ButtonArea >
            <Button type="submit">SALVAR</Button>
            {regist === true ?
              null
              :
              <Button
                style={{ backgroundColor: "red" }}
                onClick={handleDelete}>EXCLUIR<DeleteIcon/>
              </Button>
            }

            <Button onClick={handleClose}>SAIR</Button>
          </ButtonArea>
        </div>

      </FormContainer>

      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 5 }}
        open={openL}
      >
         <Label>Carregando...</Label>
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};

export default FromBombs;