import React, { useEffect, useRef } from "react";
import { toast } from "react-toastify";
import axios from "axios";
import Typography from '@mui/material/Typography';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';

import { GridActionsCellItem } from '@mui/x-data-grid';
import DeleteIcon from '@mui/icons-material/Delete';

import { DataGrid, GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';
import { useDemoData } from '@mui/x-data-grid-generator';

import ModalAddProtuct from "./ModalAddProtuct";
import ModalAddService from "./ModalAddService";

import {
  FormContainer,
  InputArea,
  Label,
  Input,
  Select1,
  Input2,
  Button,
  Table,
  Tbody,
  Div
} from "../Styles/Styles";


function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const FromOrderOfService = ({
  vehicles,
  employees,
  onEdit, 
  setOnEdit,
  setOpen,
  getOrderService,
  regist
}) => {

  const ref = useRef();
  const handleClose = () => setOpen(false);
  const [products, setProducts] = React.useState([]);
  const [services, setServices] = React.useState([]);
  const [defectsDetected, setDefectsDetected] = React.useState("");
  const [openL, setOpenL] = React.useState(false);
  const IdCompany = localStorage.getItem("id_company");
  const IdUser = localStorage.getItem("id");
  const [vehicle, setVehicle] = React.useState();
  const totalOs = useRef(0);
  const totalProducts = useRef(0);
  const totalServices = useRef(0);
  const kmCurrent = useRef(0);
  const dateCurrent = useRef(null);
  const osVehicle = useRef(null);
  
  const noteCodeRef = React.useRef();

  const [value, setValue] = React.useState(0);

 
  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarExport />
      </GridToolbarContainer>
    );
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleVehicle = (id) =>{
    setVehicle("");
   const res = vehicles.filter((a) => (a.id === id));
   // eslint-disable-next-line
       res.map((a) => {
        setVehicle(a.surname == null? '' : a.surname);
       });
  }

  const onVehicleChange = (event) =>{
    setVehicle(0);
   const res = vehicles.filter((a) => (a.id === event.target.value));
   // eslint-disable-next-line
       res.map((a) => {
        setVehicle(a.surname == null? 0 : a.surname);
       });
  }

  const getProducts = async () => {
    setOpenL(true);
    setProducts([]);

    try {
      const res = await axios.get("order-service/order-service-Products-list/" + onEdit.id);
      setProducts(res.data.sort((a, b) => (a.id > b.id ? 1 : -1)));

      setOpenL(false);

    } catch (error) {
      toast.error(error);
      setOpenL(false);
    }
  };

  const getServices = async () => {
    setOpenL(true);
    setServices([]);

    try {
      const res = await axios.get("order-service/order-service-Services-list/" + onEdit.id);
      setServices(res.data.sort((a, b) => (a.id > b.id ? 1 : -1)));

      setOpenL(false);

    } catch (error) {
      toast.error(error);
      setOpenL(false);
    }
  };

  const handleExit = async (e) => {
    e.preventDefault();
    const os = ref.current;

    if (
      !os.open_date.value ||
      !os.id_vehicle.value ||
      !os.attendant.value ||
      !os.requester.value ||
      !os.km_current.value
    ) {
      setOpenL(false);
      return toast.warn("Precisa preenche todos os campos acima!");
    } else {
      setOpenL(false);
      getOrderService();
      setOnEdit(null);
      handleClose();
    }
  }

  const handleDeleteOs = async (e) => {
    e.preventDefault();

    if (!window.confirm("*Excluir O.S* Tem certeza que deseja continuar? Todos os itens serão excluído dessa O.S!") ) {
      setOpenL(false)
      return;
    } else {
      setOpenL(true);
      await axios
        .delete("order-service/order-service-list/"+ onEdit.id)
        .then(() => {
          toast.success("Lançamento excluido com sucesso!");
          setOpenL(false);
          getOrderService();
          setOnEdit(null);
          handleClose();
        })
        .catch(() => {
          toast.error("Erro, não foi possível excluir!");
        });
    }
    
  }

  
  const { loading } = useDemoData({
    dataSet: 'Commodity',
    rowLength: 10,
    maxColumns: 6,
  });

  const getOS = async () => {
    const os = ref.current;

    handleVehicle(os.id_vehicle.value);
    noteCodeRef.current = onEdit.id;
    
    const res = await axios.get("order-service/order-service-loc/"+IdCompany +"/"+ onEdit.id);
   
       // eslint-disable-next-line
       await res.data.map((a) => {
        os.cod.value = a.id;
        os.open_date.value = a.open_date;
        os.id_vehicle.value = a.id_vehicle;
        os.situation.value = a.situation;
        os.attendant.value = a.attendant;
        os.requester.value = a.requester;
        setDefectsDetected(a.defects_detected);
        totalOs.current = ((+a.total_service) + (+a.total_product)).toFixed(2);
        totalProducts.current = a.total_product;
        totalServices.current = a.total_service;
        os.km_current.value = a.km_current;
        dateCurrent.current = a.open_date;
        kmCurrent.current = a.km_current;
        osVehicle.current = a.id_vehicle;
       });
   
  }

  const record = async () => {
    setOpenL(true);
    const os = ref.current;
    let id = os.id_vehicle.value;

    if (vehicle === undefined) {
       handleVehicle(id)
    }

    if (
      !os.open_date.value ||
      !os.id_vehicle.value ||
      !os.attendant.value ||
      !os.requester.value ||
      !os.km_current.value
    ) {
      setOpenL(false);
      return toast.warn("Precisa preenche todos os campos acima para adicionar produtos/serviços!");
    } else {
      await axios
          .put( "order-service/order-service-list/", {
            id: os.cod.value,
            open_date : os.open_date.value,
            id_vehicle: os.id_vehicle.value,
            situation: os.situation.value.trim(),
            attendant: os.attendant.value.trim(),
            requester: os.requester.value.trim(),
            surname_vehicle: vehicle,
            defects_detected: defectsDetected,
            km_current: os.km_current.value,
            id_company: IdCompany,
            user: IdUser,
          })
          .then(() => {
            setOpenL(false);
            toast.success("Lançamento atualizado com sucesso!");   
            getOrderService();
            handleClose();
          })
          .catch(() => {
            setOpenL(false);
            toast.error("Erro na atualizado!");
          });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setOpenL(true);
    const os = ref.current;
    
    if (
      !os.open_date.value ||
      !os.id_vehicle.value ||
      !os.attendant.value ||
      !os.requester.value ||
      !os.km_current.value
    ) {
      setOpenL(false);
      return toast.warn("Precisa preenche todos os campos acima para adicionar produtos/serviços!");
    } else {
      record();    
    }
  };

  const deleteService = React.useCallback(
    (id) => async () => {
      await axios
        .delete("order-service/order-service-Services-list/"+ id)
        .then(() => {
          getOrderService();
          getServices();
          getOS();
          setOpenL(false);
          toast.success("Serviço excluído com sucesso!");
        })
        .catch(() => {
          toast.error("Erro, não foi possível excluir!");
        });
    },
  );

  const deleteProduct = React.useCallback(
    (id) => async () => {
      let idCod = noteCodeRef.current;
      const res = products.filter((a) => (a.id === id));
      // eslint-disable-next-line
          res.map((a) => {
            idCod = a.Identification_code;
          });
      await axios
        .delete("order-service/order-service-Products-list/"+ idCod)
        .then(() => {
          getOrderService();
          getProducts();
          getOS();
          setOpenL(false);
          toast.success("Serviço excluído com sucesso!");
        })
        .catch(() => {
          toast.error("Erro, não foi possível excluir!");
        });
    },
  );

  const dataProducts = {
    columns: [
      { field: 'id', headerName: 'CÓD.', width: 100 },
      { field: 'product', headerName: 'PRODUTO', width: 350 },
      { field: 'price', headerName: 'UNITARIO', width: 100 },
      { field: 'amount', headerName: 'QTDE', width: 100 },
      { field: 'total', headerName: 'TOTAL', width: 100 },
      {
        field: 'actions',
        type: 'actions',
        headerName: 'Ações',
        width: 80,
        getActions: (params) => [
          <GridActionsCellItem
            icon={<DeleteIcon style={{
              color: "#2c73d2"}} />}
            label="Delete"
            onClick={deleteProduct(params.id)}
          />,
        ],
      },
    ],
    rows: products === undefined ? [] : products.map((item, i) => ({
      id: item.id,
      product: item.product,
      price: item.price,
      amount: item.amount,
      total: item.total,
    }))
  }
    ;

  const dataServices = {
    columns: [
      { field: 'id', headerName: 'CÓD.', width: 100 },
      { field: 'services', headerName: 'SERVIÇO', width: 300 },
      { field: 'complement', headerName: 'COMPLEMENTO', width: 300 },
      { field: 'amount', headerName: 'QTDE', width: 100 },
      { field: 'price', headerName: 'VALOR R$', width: 100 },
      { field: 'discount', headerName: 'DESCONTO', width: 100 },
      { field: 'total', headerName: 'TOTAL R$', width: 100 },
      { field: 'technician', headerName: 'TÉCNICO', width: 300 },
      {
        field: 'actions',
        type: 'actions',
        headerName: 'Ações',
        width: 80,
        getActions: (params) => [
          <GridActionsCellItem
            icon={<DeleteIcon style={{
              color: "#2c73d2"}} />}
            label="Delete"
            onClick={deleteService(params.id)}
          />,
        ],
      },
    ],
    rows: services === undefined ? [] : services.map((item, i) => ({
      id: item.id,
      services: item.services,
      complement: item.complement,
      amount: item.amount,
      price: item.price,
      discount: item.discount,
      total: item.total,
      technician: item.technician,
    }))
  };

  useEffect(() => {
    getOS();
    getProducts();
    getServices();
    return;
   // eslint-disable-next-line
  }, []);

  return (
    <>
      <Typography style={{ color: "#fff" }} id="modal-modal-title" variant="h6" component="h2">
        Ordem de Serviço
      </Typography>
      <Box sx={{ width: '95%', height: "calc(95vh - 1px)", backgroundColor: "#fff", borderRadius: "5px", border: "5px solid #343f46" }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <FormContainer ref={ref} onSubmit={handleSubmit}>
            <InputArea>
              <Label>Código</Label>
              <Input disabled={true} name="cod" style={{ color: "#fff" }} />
            </InputArea>
            <InputArea>
              <Label>Data*</Label>
              <Input2 type="date" name="open_date" />
            </InputArea>
            <InputArea>
              <Label>Veículo*</Label>
              <Select1 onChange={onVehicleChange} name="id_vehicle">
                <option value="">Selecione</option>
                {vehicles === undefined ? [] : vehicles.map((item, i) => (
                  <option key={i} value={item.id}>{item.surname} - {item.plate}</option>
                ))}
              </Select1>
            </InputArea>
            <InputArea>
              <Label>Situação</Label>
              <Select1 style={{
                width: "300px",
                height: "30px",
                border: "1px solid #343f46",
                textTransform: "uppercase",
                fontSize: "12px"
              }} name="situation">
                <option value="AC">AC - Aguardando Confirmação</option>
                <option value="ES">ES - Executando Serviço</option>
                <option value="F">F - Fechada</option>
                <option value="C">C - Cancelada</option>
              </Select1>
            </InputArea>
            <InputArea>
              <Label>Atendente*</Label>
              <Select1 name="attendant">
                <option value="">Selecione</option>
                {employees === undefined ? [] : employees.map((item, i) => (
                  <option key={i} value={item.id}>{item.nome}</option>
                ))}
              </Select1>
            </InputArea>
            <InputArea>
              <Label>Solicitante*</Label>
              <Input2 style={{ width: "300px" }} name="requester" />
            </InputArea>
            <InputArea>
              <Label>Valor Total</Label>
              <Input disabled={true} value={Intl.NumberFormat('pt-br', { style: 'currency', currency: 'BRL' }).format(totalOs.current)} style={{ color: "#fff" }} />
            </InputArea>
            <InputArea>
              <Label>KM Atual*</Label>
              <Input type="number" pattern="[0-9]+([,\.][0-9]+)?" min="0" step="any" placeholder="Digite só número" name="km_current" />
            </InputArea>

            <div style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }} >
              <Button type="submit">SALVAR</Button>
              <Button onClick={handleDeleteOs}>Excluir <DeleteIcon/> </Button>
              {regist === true? null : 
                <Button onClick={handleExit}>SAIR</Button>
              }
            </div>
          </FormContainer>
          <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" textColor="primary" >
            <Tab label="Serviços" {...a11yProps(0)} />
            <Tab label="Produtos" {...a11yProps(1)} />
            <Tab label="Laudo" {...a11yProps(2)} />
          </Tabs>
        </Box>

        <CustomTabPanel value={value} index={0}>
          <Div>
            <div style={{
              display: "flex",
              justifyContent: "end",
              width: "100%",
            }} >
              <ModalAddService 
              idOs={noteCodeRef.current} 
              getServices={getServices} 
              totalServices={totalServices.current} 
              getOrderService={getOrderService} 
              getOS={getOS} 
              />
            </div>
            <Table onlyWeb >
              <Tbody style={{ width: '100%', height: "calc(100vh - 400px)", overlay: "hidden", backgroundColor: "#fff", borderRadius: "5px", border: "5px solid #343f46" }}>
                <DataGrid
                  {...dataServices}
                  loading={loading}
                  slots={{
                    toolbar: CustomToolbar,
                  }}
                />
              </Tbody>
            </Table>
          </Div>
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>

          <Div>
            <div style={{
              display: "flex",
              justifyContent: "end",
              width: "100%",
            }} >
              <ModalAddProtuct 
              idOs={noteCodeRef.current} 
              getProducts={getProducts} 
              totalProducts={totalProducts.current} 
              getOrderService={getOrderService} 
              getOS={getOS}       
              dateCurrent={dateCurrent.current}
              kmCurrent={kmCurrent.current}
              osVehicle={osVehicle.current}
              />
            </div>
            <Table onlyWeb >
              <Tbody style={{ width: '100%', height: "calc(100vh - 400px)", overlay: "hidden", backgroundColor: "#fff", borderRadius: "5px", border: "5px solid #343f46" }}>
                <DataGrid
                  {...dataProducts}
                  loading={loading}
                  slots={{
                    toolbar: CustomToolbar,
                  }}
                />
              </Tbody>
            </Table>

          </Div>

        </CustomTabPanel>
        <CustomTabPanel value={value} index={2}>
          <FormContainer style={{ backgroundColor: "#fff" }}>

            <InputArea >
              <Label style={{ color: "#343f46" }} >Defeitos detectados</Label>
              <textarea style={{
                width: "280px",
                height: "calc(100vh - 650px)",
                border: "1px solid #343f46",
                textTransform: "uppercase",
                fontSize: "12px"
              }}
                value={defectsDetected} 
                onChange={(e) => setDefectsDetected(e.target.value)}
                />
            </InputArea>
          </FormContainer>
        </CustomTabPanel>
      </Box>

      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 5 }}
        open={openL}
      >
         <Label>Carregando...</Label>
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};

export default FromOrderOfService;