import React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import {Button, Container, styledModal } from "../Styles/Styles";
import FromAddService from "./FromAddService";

const ModalAddService = ({ idOs, getServices, totalServices, getOrderService, getOS }) => {
  const [open, setOpen] = React.useState(false);
  const handleClose = () => setOpen(false);

  const handleOpen = () => { 
    return setOpen(true); 
  };
  

  return (
    <>
    <div style={{
      display: "flex",
      justifyContent: "space-between",
      width: "100%",
    }} >
    <h5 style={{ marginRight: "10px"}} 
    >Total {Intl.NumberFormat('pt-br', { style: 'currency', currency: 'BRL' }).format(totalServices)}</h5>
    <Button onClick={handleOpen}>Adicionar Serviços</Button>
    </div>
      <Modal
        open={open}
        aria-labelledby="modal-modal-title"
        
      >
        <Box sx={styledModal}>
          <Container>
            <FromAddService handleClose={handleClose} idOs={idOs} getServices={getServices} getOrderService={getOrderService} getOS={getOS} />
          </Container>
        </Box>
        
        
      </Modal>
      
    </>
  );
};

export default ModalAddService;