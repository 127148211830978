
import React from "react";
import { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import { signout } from "../../Services/UserService";
import ContentPage from "../../components/Content/ContentPage";
import ModalFuel from "../../components/Fuel/ModalFuel";
import GridFuel from "../../components/Fuel/GridFuel";
import { toast } from "react-toastify";
import axios from "axios";

import {
  FormContainer,
  Loc,
  InputArea,
  Button
} from "../Styles/Styles"

const FuelList = () => {
  const navigate = useNavigate();
  const [fuel, setFuel] = useState([]);
  const [onEdit, setOnEdit] = useState(null);
  const [open, setOpen] = React.useState(false);
  const [loc, setLoc] = React.useState();
  const [regist, setRegist] = React.useState(false);
  const IdCompany = localStorage.getItem("id_company");

  const [openL, setOpenL] = React.useState(false);

  const handleCloseL = () => {
    setOpenL(false);
  };
  const handleOpenL = () => {
    setOpenL(true);
  };


  const handleOpen = () => {
    setOpen(true);
    setRegist(true);
  };

  const getFuel = async () => {
    handleOpenL();
    setFuel([]);
    setOnEdit(null);
    try {
      const res = await axios.get("fuel/fuel-list/" + IdCompany + "/?loc=" + (loc === undefined ? '%' : loc));
      if (res.data[0].status === "false") {
        toast.error(res.data[0].message);
        signout();
        navigate("/login");
      } else {
        setFuel(res.data.sort((a, b) => (a.name > b.name ? 1 : -1)));
      }
      handleCloseL();
    } catch (error) {
      toast.error(error);
      handleCloseL();
    }
  };

  useEffect(() => {
    getFuel();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <ContentPage caminho={[{ nome: "Cadastros > Combustível", link: "/fuel/list" }]}>

        <ModalFuel
          regist={regist}
          fuel={fuel}
          setFuel={setFuel}
          setLoc={setLoc}
          loc={loc}
          onEdit={onEdit}
          setOnEdit={setOnEdit}
          getFuel={getFuel}
          setOpen={setOpen}
          open={open}
          openL={openL}
          handleOpenL={handleOpenL}
          handleCloseL={handleCloseL}
        />

        <FormContainer>
          <Loc
            style={{
              width: "350px",
              height: "30px",
              border: "1px solid #343f46",
              textTransform: "uppercase",
              fontSize: "12px"
            }}
            type='text'
            placeholder="Digite nome"
            value={loc}
            onChange={(e) => [setLoc(e.target.value)]}
          />

          <InputArea >
            <Button
              onClick={getFuel}>Localizar
            </Button>
          </InputArea>
          <InputArea  >
            <Button
              onClick={handleOpen}>Novo
            </Button>
          </InputArea>
        </FormContainer>

        <GridFuel openL={openL} regist={regist} setRegist={setRegist} fuel={fuel} setFuel={setFuel} setOnEdit={setOnEdit} setOpen={setOpen} />
      </ContentPage>
    </>
  );
};

export default FuelList;