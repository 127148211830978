
import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from 'react-router-dom';
import { signout } from "../../Services/UserService";
import ContentPage from "../../components/Content/ContentPage";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import {
  FormContainer,
  InputArea,
  Label,
  Select1,
  ButtonArea,
  Button,
  InputDate
} from "../../components/Styles/Styles";
import ReleasesSimplePdf from "../../components/ReportLaunchSalesTicket/LaunchSalesTicketSimple";
import isValidObject from "../../shared/isValidObject";
import ReleasesDetailedPdf from "../../components/ReportLaunchSalesTicket/LaunchSalesTicketDetailed";
import Select from 'react-select';

const ReportsLaunchSalesTicket = () => {
  const navigate = useNavigate();
  const ref = useRef();
  const [vehicles, setVehicles] = useState([]);
  const [employees, setEmployees] = useState([]);
  const NameLinhe = useRef("");
  const IdCompany = localStorage.getItem("id_company");
  const [lines, setLines] = useState([]);

  const releasesRef = React.useRef();
  const releasesSumRef = React.useRef();
  const typeReportRef = React.useRef();

  const [openL, setOpenL] = React.useState(false);

  function adicionaZero(numero) {
    if (numero <= 9)
      return "0" + numero;
    else
      return numero;
  }


  let dataAtual = new Date();

  let dataAtualFormatada = (dataAtual.getFullYear() + "-" + (adicionaZero(dataAtual.getMonth() + 1).toString()) + "-" + adicionaZero(dataAtual.getDate().toString()));

  const [locDateI, setLocDate] = React.useState(dataAtualFormatada);
  const [locDateF, setLocDateF] = React.useState(dataAtualFormatada);

  const handleCloseL = () => {
    setOpenL(false);
  };
  const handleOpenL = () => {
    setOpenL(true);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    await getReleases();
    await getReleasesSum();
    onLinesChange();
    if (locDateI > locDateF) {
      toast.error("Data inicial não pode ser maior que a final!");
      handleCloseL();
      return;
    }
    if (releasesRef.current.length === 0 && !isValidObject(releasesSumRef.current)) {
      toast.error(" Nenhum lançamento encontrado! ");
      handleCloseL();
      return;
    } else {
      handleCloseL();
      if (typeReportRef.current === "1") {
        ReleasesSimplePdf(locDateI, locDateF, releasesRef.current, dataAtualFormatada, releasesSumRef.current, NameLinhe.current);
      }
      if (typeReportRef.current === "2") {
        ReleasesDetailedPdf(locDateI, locDateF, releasesRef.current, dataAtualFormatada, releasesSumRef.current, NameLinhe.current);
      }
    }

  };

  const onLinesChange = () => {
    const typeReport = ref.current;
    NameLinhe.current = "";
    const res = lines.filter((a) => (a.id === (typeReport.line.value === "" ? "0" : typeReport.line.value)));
    // eslint-disable-next-line
    res.map((a) => {
      NameLinhe.current = a.name_line;
    });
  };

  const getReleases = async () => {
    const typeReport = ref.current;
    handleOpenL();
    releasesRef.current = [];
    typeReportRef.current = typeReport.type_report.value;
    var dateFilter = "";
    var filterDate = (typeReport.type_filter_date.value === undefined ? "1" : typeReport.type_filter_date.value);

    if (filterDate === "1") {
      dateFilter = "r.date";
    } else {
      dateFilter = "r.created_at";
    }

    if (typeReport.type_report.value === "1") {

      await axios.post("launch-sales-ticket/report-launch-sales-ticket-simple/", {
        id_company: IdCompany,
        id_vehicle: (typeReport.vehicle.value === "" ? "%" : typeReport.vehicle.value),
        id_driver: (typeReport.id_driver.value === "" ? "%" : typeReport.id_driver.value),
        id_line: (typeReport.line.value === "" ? "%" : typeReport.line.value),
        idate: (filterDate === "1" ? locDateI : (locDateI + " " + "00:00:00")),
        fdate: (filterDate === "1" ? locDateF : (locDateF + " " + "23:00:00")),
        dateFilter: dateFilter,
      })
        .then((res) => {
          releasesRef.current = res.data;
          if (releasesRef.current.length > 0) {
            handleCloseL();
          } else {
            handleCloseL();
          };
        })
        .catch((error) => {
          toast.error(error);
          handleCloseL();
        });
    }
    if (typeReport.type_report.value === "2") {

      await axios.post("launch-sales-ticket/report-launch-sales-ticket-detailed/", {
        id_company: IdCompany,
        id_vehicle: (typeReport.vehicle.value === "" ? "%" : typeReport.vehicle.value),
        id_driver: (typeReport.id_driver.value === "" ? "%" : typeReport.id_driver.value),
        id_line: (typeReport.line.value === "" ? "%" : typeReport.line.value),
        idate: (filterDate === "1" ? locDateI : (locDateI + " " + "00:00:00")),
        fdate: (filterDate === "1" ? locDateF : (locDateF + " " + "23:00:00")),
        dateFilter: dateFilter,
      })
        .then((res) => {
          releasesRef.current = res.data;
          if (releasesRef.current.length > 0) {
            handleCloseL();
          } else {
            handleCloseL();
          };
        })
        .catch((error) => {
          toast.error(error);
          handleCloseL();
        });
    }

  }

  const getReleasesSum = async () => {
    const typeReport = ref.current;
    handleOpenL();
    releasesSumRef.current = [];
    var dateFilter = "";
    var filterDate = (typeReport.type_filter_date.value === undefined ? "1" : typeReport.type_filter_date.value);

    if (filterDate === "1") {
      dateFilter = "r.date";
    } else {
      dateFilter = "r.created_at";
    }

    await axios.post("launch-sales-ticket/report-launch-sales-ticket-simple-sum/", {
      id_company: IdCompany,
      id_vehicle: (typeReport.vehicle.value === "" ? "%" : typeReport.vehicle.value),
      id_driver: (typeReport.id_driver.value === "" ? "%" : typeReport.id_driver.value),
      id_line: (typeReport.line.value === "" ? "%" : typeReport.line.value),
      idate: (filterDate === "1" ? locDateI : (locDateI + " " + "00:00:00")),
      fdate: (filterDate === "1" ? locDateF : (locDateF + " " + "23:00:00")),
      dateFilter: dateFilter,
    })
      .then((res) => {
        if (!res || !res.data[0]) {
          throw new Error(" Nenhum lançamento encontrado! ");
        }
        releasesSumRef.current = res.data[0];
        if (releasesSumRef.current.length > 0) {
          handleCloseL();
        } else {
          handleCloseL();
        };
      })
      .catch((error) => {
        toast.error(error);
        handleCloseL();
      });
  }
  const getVehicles = async () => {
    setVehicles([]);
    try {
      const res = await axios.get("vehicle/vehicle-list/" + IdCompany + "/?loc=%");
      if (res.data[0].status === "false") {
        toast.error(res.data[0].message);
        signout();
        navigate("/login");
      } else {
       setVehicles(res.data.sort((a, b) => (a.plate > b.plate ? 1 : -1)));
      }
    } catch (error) {
      toast.error(error);
    }
  };

  const getLines = async () => {
    setLines([]);
    try {
      const res = await axios.get("lines/lines-list/" + IdCompany + "/?loc=%");
      if (res.data[0].status === "false") {
        toast.error(res.data[0].message);
        signout();
        navigate("/login");
      } else {
        setLines(res.data.sort((a, b) => (a.name > b.name ? 1 : -1)));
      }
    } catch (error) {
      toast.error(error);
    }
  };

  const getEmployees = async () => {
    setEmployees([]);
    try {
      const res = await axios.get("employees/employees-list/" + IdCompany + "/?loc=%");
      if (res.data[0].status === "false") {
        toast.error(res.data[0].message);
        signout();
        navigate("/login");
      } else {
        setEmployees(res.data.sort((a, b) => (a.name > b.name ? 1 : -1)));
      }
      handleCloseL();
    } catch (error) {
      toast.error(error);
      handleCloseL();
    }

  };

  const loadingIdVehicle = (item) => {    
    const typeReport = ref.current;
    if(item){
      typeReport.vehicle.value = item.value;
    }
  };

  const loadingIdEmployees = (item) => {    
    const typeReport = ref.current;
    if(item){
      typeReport.id_driver.value = item.value;
    }
  };

  useEffect(() => {
    getVehicles();
    getLines();
    getEmployees();
    // eslint-disable-next-line

  }, []);


  return (
    <>
      <ContentPage caminho={[{ nome: "Relatórios / Vendas de passagem", link: "/Reports/Launch-Sales-Ticket" }]}>

        <Typography style={{ color: "#022025" }} id="modal-modal-title" variant="h6" component="h2">
          Fechamento por período
        </Typography>
        <FormContainer style={{ maxWidth: "350px"}} ref={ref} onSubmit={handleSubmit}>
          <InputArea>
            <Label>Relatórios</Label>
            <Select1 name="type_report" style={{ width: "300px" }} >
              <option value={1}>Simples</option>
              <option value={2}>Detalhado</option>
            </Select1>
          </InputArea>
          <InputArea style={{width: "300px", color: "#000", fontSize:"12px" }}>
            <Label style={{color: "#fff" }}>Motorista</Label>
            <Select 
            className="basic-single"
            classNamePrefix="select"
            isClearable={true}
            defaultValue={employees[0]}
            isSearchable={true}
            name="id_driver"
            onChange = {(item) => loadingIdEmployees(item)}       
            options = {employees === undefined ? [] : employees.map((item) => (
              {value : item.id, 
                label : item.id + " - " + item.nome}
            ))} 
            theme = {theme => ({
              ...theme,
              colors: {
              ...theme.colors,
                primary: "#2c73d2",
                secondary: "#bbb",
              },
            })}
            /> 
          </InputArea>

          <InputArea style={{width: "300px", color: "#000", fontSize:"12px" }}>
          <Label style={{color: "#fff" }}>Veículo</Label>
         <Select 
          className="basic-single"
          classNamePrefix="select"
          isClearable={true}
          defaultValue={vehicles[0]}
          isSearchable={true}
          name="vehicle"
          onChange = {(item) => loadingIdVehicle(item)}       
          options = {vehicles === undefined ? [] : vehicles.map((item) => (
            {value : item.id, 
              label : item.surname + " - " + item.plate}
          ))} 
          theme = {theme => ({
            ...theme,
            colors: {
            ...theme.colors,
              primary: "#2c73d2",
              secondary: "#bbb",
            },
          })}
          /> 
        </InputArea>

          <InputArea>
            <Label>Linha</Label>
            <Select1 style={{ width: "300px" }} name="line">
              <option value="">Todos</option>
              {lines === undefined ? [] : lines.map((item, i) => (
                <option key={i} value={item.id}>{item.name_line}</option>
              ))}
            </Select1>
          </InputArea>
          <InputArea>
            <Label>Filtar por</Label>
            <Select1 name="type_filter_date" style={{ width: "300px" }} >
              <option value={1}>Data Fechamento</option>
              <option value={2}>Data de Emissão</option>
            </Select1>
          </InputArea>
          <InputArea>
            <Label>Data inicial</Label>
            <InputDate
              type="date"
              value={locDateI}
              onChange={(e) => [setLocDate(e.target.value)]}
              style={{ width: "147px" }}
            />
          </InputArea>
          <InputArea>
            <Label>Data final</Label>
            <InputDate
              type="date"
              value={locDateF}
              onChange={(e) => [setLocDateF(e.target.value)]}
              style={{ width: "147px" }}
            />
          </InputArea>

          <ButtonArea style={{marginTop:"45%"}}>
            <Button type="submit">Visualizar</Button>
          </ButtonArea>
        </FormContainer>

        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 5 }}
          open={openL}
        >
          <Label>Carregando...</Label>
          <CircularProgress color="inherit" />
        </Backdrop>
        <ToastContainer autoClose={3000} />
      </ContentPage>
    </>
  );
};

export default ReportsLaunchSalesTicket;