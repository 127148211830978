export function geraStringAleatoria(tamanho) {
  var stringAleatoria = '';
  var caracteres = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  for (var i = 0; i < tamanho; i++) {
      stringAleatoria += caracteres.charAt(Math.floor(Math.random() * caracteres.length));
  }
  return stringAleatoria;
}

export function geraIterAleatoria(tamanho) {
  var stringAleatoria = '';
  var caracteres = '0123456789';
  for (var i = 0; i < tamanho; i++) {
      stringAleatoria += caracteres.charAt(Math.floor(Math.random() * caracteres.length));
  }
  return stringAleatoria;
}
//produção = AIzaSyDtaCWcPMY83HvnFpoeQVjQn5L6Ox48-Fk
//dev = AIzaSyCkgBBSnu0F73HDMfBeskNknAurc3ec-ag
export const GoogleMapsApiKey = "AIzaSyCkgBBSnu0F73HDMfBeskNknAurc3ec-ag";
export const BaseUrl = "https://api.eu.navixy.com/dwh/v1/tracker/";
export const companyTrackers = {
    "342368" : {
    "Hash": "6e26a693488ccfd6062ba8c64fa2844e",
    "Trackers": "[3048209,3048212,3048224,3048226,3048229,3048230,3048231,3048232,3048233,3066627,3066629,3066631,3066633,3066634,3066635,3066636,3066637,3066639,3066640,3066641,3066643,3066644,3066645,3066646,3066647,3066648,3066650,3066652,3066654,3066656,3066659,3067443,3068350,3068353,3170358,3066670,3153361]"
    },
    "350813" : {
    "Hash": "10dcd51063467d88f5ffbb56050fad3f",
    "Trackers": "[3107318,3107320,3107322,3107326,3107327,3107328,3107329,3107330,3167829,3167905,3193636,3194717]"
    }
  };