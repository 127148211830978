import React, { useEffect, useRef } from "react";
import axios from "axios";
import {toast } from "react-toastify";
import Typography from '@mui/material/Typography';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import DeleteIcon from '@mui/icons-material/Delete';

import { 
  FormContainer, 
  InputArea, 
  Label, 
  Input, 
  Input2, 
  Input3, 
  ButtonArea, 
  Button,
  Select1,
  InputDate
} from "../Styles/Styles";

const FromMaintenanceControl = ({handleCloseL, openL, handleOpenL, regist, onEdit, setOnEdit, getMaintenance, setOpen, vehicles }) => {
  const ref = useRef();
  const handleClose = () => setOpen(false);
  const IdCompany = localStorage.getItem("id_company");
  const IdUser = localStorage.getItem("id");
  const term = useRef(0);
  const [kmMaint, setKmMaint] = React.useState("");
  const [alterColor, setAlterColor] = React.useState({value:"#fff"});
  const [releaseField, setReleaseField] = React.useState(true);

  function adicionaZero(numero) {
    if (numero <= 9)
      return "0" + numero;
    else
      return numero;
  }

  const onKmVehicleChange = (event) =>{
    const maint = ref.current;
   const res = vehicles.filter((a) => (a.id === event.target.value ));
   // eslint-disable-next-line
       res.map((a) => {
        maint.km_maintenance.value = (a.km_current == null? 0 : a.km_current);
       });
  }

  let dataAtual = new Date();
  let dataAtualFormatada = (dataAtual.getFullYear() + "-" + (adicionaZero(dataAtual.getMonth() + 1).toString()) + "-" + adicionaZero(dataAtual.getDate().toString()));

  const onTermChange = (event) =>{   
    if(event.target.value === "1"){
      term.current = 1;
      setAlterColor({value:"#000"});
      setReleaseField(false); 
      return;
    } 
    if(event.target.value === "2"){
      term.current = 2;
      setAlterColor({value:"#000"});
      setReleaseField(false); 
      return;
    } else{
      term.current = 0;
      setAlterColor({value:"#fff"});
      setReleaseField(true);
      return;
    }
   };

   const cleanearFields = () => {
    const maint = ref.current;
    maint.cod.value = "";
    maint.id_vehicle.value = "";
    maint.maintenance_date.value = dataAtualFormatada;
    maint.km_maintenance.value = "";
    maint.maintenance_description.value = "";
    maint.term.value = "";
    maint.unit.value = "";
    maint.cost.value = "";
    maint.st.value = 1;
    setKmMaint("KM Atual*");
   };

    useEffect(() => {
    const maint = ref.current;

    if(regist) {
      cleanearFields();
    } else {
    if (onEdit) {
      maint.cod.value = onEdit.id;
      maint.id_vehicle.value = onEdit.id_vehicle;
      maint.maintenance_date.value = onEdit.maintenance_date;
      maint.km_maintenance.value = onEdit.km_maintenance;
      maint.maintenance_description.value = onEdit.maintenance_description;
      maint.term.value = onEdit.term;
      maint.unit.value = onEdit.unit;
      maint.cost.value = onEdit.cost;
      maint.st.value = onEdit.st;
      setKmMaint("KM Manut.*");
      

      if(onEdit.unit === "1"){
        term.current = 1;
        setAlterColor({value:"#000"});
        setReleaseField(false); 
        return;
      } 
      if(onEdit.unit === "2"){
        term.current = 2;
        setAlterColor({value:"#000"});
        setReleaseField(false); 
        return;
      } else{
        term.current = 0;
        setAlterColor({value:"#fff"});
        setReleaseField(true);
        return;
      }
    };
  };
  // eslint-disable-next-line
  }, []);

  const handleDelete = async (e) => {
    e.preventDefault();
    const maint = ref.current;
    handleOpenL();

    if (!window.confirm("Tem certeza que deseja excluir?")) {
      handleCloseL();
      return;
    } else {
    await axios
     .delete("maintenance-control/maintenance-control-list/"+ maint.cod.value)
     .then(() => {
        toast.success("Cadastro excluido com sucesso!");
        getMaintenance();
        setOnEdit(null);
        handleClose();
     })
     .catch(() => {
      handleCloseL();
      toast.error("Erro, não foi possível excluir!");});
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    handleOpenL();
  
    const maint = ref.current;
      if (
        !maint.km_maintenance.value ||
        !maint.id_vehicle.value  ||
        !maint.km_maintenance.value ||
        !maint.maintenance_description.value ||
        !maint.term.value ||
        !maint.unit.value ||
        !maint.cost.value
      ) {
        handleCloseL();
        return toast.warn("Preencha todos os campos com ( * )!");
      }

      var time = new Date();
      var nextMaintenanceDate = ""
      var km = (maint.km_maintenance.value === undefined ? 0 : maint.km_maintenance.value);
      var termKm = 0;
      var termDay = 0;
      if(maint.unit.value === "1"){
       termKm = (maint.term.value === undefined ? 0 : maint.term.value);
      } else{
       termDay = (maint.term.value === undefined? 0 : maint.term.value);
       let newD = new Date(time.setDate((+time.getDate()) +(+termDay)));
       let newDate = (newD.getFullYear() + "-" + (adicionaZero(newD.getMonth() + 1).toString()) + "-" + adicionaZero((newD.getDate()).toString()));
       nextMaintenanceDate = newDate;
      }

    if (onEdit) {
      await axios
        .put("maintenance-control/maintenance-control-list/", {
          id: maint.cod.value.trim(),
          st: maint.st.value,
          id_vehicle: maint.id_vehicle.value,
          maintenance_date: maint.maintenance_date.value,
          km_maintenance: maint.km_maintenance.value,
          maintenance_description: maint.maintenance_description.value,
          term: maint.term.value,
          unit: maint.unit.value,
          km_next_maintenance: (maint.unit.value === "1"? (parseFloat((+km) + (+termKm))) : ""),
          next_maintenance_date: nextMaintenanceDate,
          cost: maint.cost.value.trim(),
          user :IdUser,
        })
        .then(() => {
          toast.success("Cadastro atualizado com sucesso!");

          
          getMaintenance();
          setOnEdit(null);
          cleanearFields();
          handleClose();
        })
        .catch(() => {
          handleCloseL();
          toast.error("Erro na atualizado!");
        });
    } else {
      await axios
        .post("maintenance-control/maintenance-control-list/", {
          st: maint.st.value,
          id_vehicle: maint.id_vehicle.value,
          maintenance_date: maint.maintenance_date.value,
          km_maintenance: maint.km_maintenance.value,
          maintenance_description: maint.maintenance_description.value,
          term: maint.term.value,
          unit: maint.unit.value,
          km_next_maintenance: (maint.unit.value === "1"? (parseFloat((+km) + (+termKm))) : ""),
          next_maintenance_date: nextMaintenanceDate,
          cost: maint.cost.value.trim(),
          id_company: IdCompany,
          user :IdUser,
        })
        .then(() => {
          toast.success("Cadastrado com sucesso!");
          getMaintenance();
          setOnEdit(null);
          cleanearFields();
          handleClose();
        })
        .catch(() => {
          handleCloseL();
          toast.error("Erro no Cadastro!");
        });
        
    }


  };

  return (
    <>
          <Typography style={{ color: "#fff" }} id="modal-modal-title" variant="h6" component="h2">
            Ficha do controle de manutenção 
          </Typography>
          <FormContainer ref={ref} onSubmit={handleSubmit}>
            <InputArea>
              <Label>Código</Label>
              <Input style={{ color: "#fff" }} disabled={true} name="cod"  />
            </InputArea>
            <InputArea>
              <Label>Situação</Label>
              <Select1 name="st">
                <option value={1}>Pendente</option>
                <option value={2}>Realizada</option>
              </Select1>
            </InputArea>
            <InputArea>
              <Label>Data Manutenção</Label>
              <InputDate type="date" name="maintenance_date"  />
            </InputArea>
            <InputArea>
              <Label>Veículo*</Label>
              <Select1 onChange={onKmVehicleChange} name="id_vehicle">
                <option value="">Selecione</option>
                {vehicles === undefined ? [] : vehicles.map((item, i) => (
                  <option key={i} value={item.id}>{item.surname} - {item.plate}</option>
                ))}
              </Select1>
            </InputArea>
            <InputArea>
              <Label>{kmMaint}</Label>
              <Input name="km_maintenance" type="number" pattern="[0-9]+([,\.][0-9]+)?" min="0" step="any" placeholder="Digite seu número"/>
            </InputArea>
            <InputArea>
              <Label>Descrição da Manutenção*</Label>
              <Input2 style={{ width: "715px" }} name="maintenance_description" />
            </InputArea>

            <InputArea>
              <Label>Custo R$*</Label>
              <Input3 type="number" pattern="/([0-9]{3}).([0-9]{3}),([0-9]{2})$/g, '.$1.$2,$3'" min="0" step="any" placeholder="Digite só número" name="cost" />
            </InputArea>
            
            <InputArea>
              <Label>Controle de manutenção*</Label>
              <Select1 onChange={onTermChange} name="unit" >
                <option value="">Selecione</option>
                <option value={1}>KM</option>
                <option value={2}>Dias</option>
              </Select1>
            </InputArea>
            
            <InputArea>
            { term.current === 1?  <Label>Prazo KM*</Label> : <Label>Prazo Dias*</Label>}
              <Input style={{ color:{alterColor} }} disabled={releaseField} type="number" pattern="[0-9]+([,\.][0-9]+)?" min="0" step="any" placeholder="Digite seu número" name="term" />
            </InputArea>
            <div style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }} >
              <ButtonArea >
                <Button type="submit">SALVAR</Button>
              </ButtonArea>
              <ButtonArea >
              { regist === true? 
                 null
                 : 
                 <Button 
                    style={{backgroundColor:"red"}} 
                    onClick={handleDelete}>EXCLUIR <DeleteIcon/> 
                 </Button>
                 }
              </ButtonArea>
              <ButtonArea >
                <Button onClick={handleClose}>SAIR</Button>
              </ButtonArea>
            </div>

          </FormContainer>
          
      <Backdrop
              sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 5}}
              open={openL}
          >
         <Label>Carregando...</Label>
              <CircularProgress color="inherit" />
          </Backdrop>
    </>
  );
};

export default FromMaintenanceControl;