import * as React from 'react';
import { GridActionsCellItem } from '@mui/x-data-grid';
import EditIcon from '@mui/icons-material/Edit';

import { DataGrid, GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';
import { useDemoData } from '@mui/x-data-grid-generator';

import { FaFileSignature } from "react-icons/fa"
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { 
  TableMobile,
  Table, 
  Tbody, 
  Tr, 
  Td, 
  Label
} from "../Styles/Styles";

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarExport />
    </GridToolbarContainer>
  );
}


const GridOrderOfService = ({openL, setRegist, orderService, setOnEdit, setOpen }) => {


  const alterOrderService = React.useCallback(
    (id) => () => {
      const res = orderService.filter((a) => (a.id === id));
       // eslint-disable-next-line
       res.map((a) => {
        handleEdit(a);
       });
    },
    [orderService],
  );

  const data = {
    columns: [
      { field: 'situation', headerName: 'Situação', width: 100 },
      { field: 'id', headerName: 'Cód.', width: 100 },
      { field: 'open_date', headerName: 'Data Abertura', width: 150 },
      { field: 'completion_date', headerName: 'Data Conclusão', width: 150 },
      { field: 'requester', headerName: 'Solicitante', width: 300 },
      { field: 'surname_vehicle', headerName: 'Apelido', width: 100 },
      { field: 'price', headerName: 'Valor R$', width: 100 },
      {
        field: 'actions',
        type: 'actions',
        headerName: 'Ações',
        width: 80,
        getActions: (params) => [
          <GridActionsCellItem
            icon={<EditIcon style={{
              color: "#2c73d2"}} />}
            label="Delete"
            onClick={alterOrderService(params.id)}
          />,
        ],
      },
    ],
    rows: orderService === undefined? [] : orderService.map((item, i) => ({
      situation: item.situation,
      id: item.id,
      open_date: item.open_date.split("-").reverse().join("/"),
      completion_date: (!item.completion_date === null ? item.completion_date.split("-").reverse().join("/") : ""),
      requester: item.requester,
      surname_vehicle: item.surname_vehicle,
      price: ((+item.total_service) + (+item.total_product)).toFixed(2),
    }))
  }
  ;

  const { loading } = useDemoData({
    dataSet: 'Commodity',
    rowLength: 10,
    maxColumns: 6,
  });


  const handleOpen = () => setOpen(true);

  const handleEdit = (item) => {
    setRegist(false);
    setOnEdit(item);
    handleOpen();
  };

  return (
    <>
    <TableMobile onlyWeb>
      <Tbody >
        {orderService === undefined ? [] : orderService.map((item, i) => (
          <Tr key={i}>
            <Td >
              <h1
                style={{fontSize: "12px", color:"#022025" }} >
                    Situação.: {item.situation} <br/> 
                    Data Abertura.: {item.open_date.split("-").reverse().join("/")}<br/> 
                    Data Conclusão.: {(!item.completion_date === null ? item.completion_date.split("-").reverse().join("/") : "")}<br/>
                    Veículo.: {item.surname_vehicle} = Valor R$.: {item.price}<br/>
                    Solicitante.: {item.requester} <br/>   
              </h1>
            </Td>
            <Td >
                <FaFileSignature onClick={() => handleEdit(item)}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "start",
                    cursor: "pointer",
                    width: "20px",
                    height: "70px",
                    textAlign: "start",
                    color: "#2c73d2",
                  }}
                />
            </Td>
          </Tr>
        ))}
      </Tbody>
    </TableMobile>

    <Table onlyWeb>
      <Tbody > 
      <DataGrid
        {...data}
        loading={loading}
        rowHeight = {30}
        slots={{
          toolbar: CustomToolbar,
        }}
      />
      </Tbody>
    </Table>
    
    <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 5}}
        open={openL}
    >
         <Label>Carregando...</Label>
        <CircularProgress color="inherit" />
    </Backdrop>
    </>
  );
};

export default GridOrderOfService;