import * as React from 'react';
import { GridActionsCellItem } from '@mui/x-data-grid';
import EditIcon from '@mui/icons-material/Edit';

import { DataGrid, GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';
import { useDemoData } from '@mui/x-data-grid-generator';
import { FaFileSignature } from "react-icons/fa"
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

import { 
  TableMobile,
  Table, 
  Tbody, 
  Tr, 
  Td, 
  Label 
} from "../Styles/Styles";

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarExport />
    </GridToolbarContainer>
  );
}

const GridProductsServices = ({openL, setRegist, products, setOnEdit, setOpen }) => {

  const alterProducts = React.useCallback(
    (id) => () => {
      const res = products.filter((a) => (a.id === id));
       // eslint-disable-next-line
       res.map((a) => {
        handleEdit(a);
       });
    },
    [products],
    
  );
 
  const dataProducts = {
    columns: [
      { field: 'description', headerName: 'Descrição', width: 600 },
      { field: 'current_inventory', headerName: 'Estoque', width: 100 },
      { field: 'bar_code', headerName: 'Cód.Barra', width: 150 },
      { field: 'location', headerName: 'Localização', width: 150 },
      { field: 'reference_code', headerName: 'Cód. Referencia', width: 150 },
      { field: 'model', headerName: 'Modelo', width: 150 },
      { field: 'type', headerName: 'Tipo', width: 100 },
      { field: 'value_vlr', headerName: 'Valor R$', width: 150 },
      { field: 'st', headerName: 'Situação', width: 150 },
      {
        field: 'actions',
        type: 'actions',
        headerName: 'Ações',
        width: 80,
        getActions: (params) => [
          <GridActionsCellItem
            icon={<EditIcon style={{
              color: "#2c73d2"}} />}
            label="Delete"
            onClick={alterProducts(params.id)}
          />,
        ],
      },
    ],
    rows: products === undefined? [] : products.map((item, i) => ({

      id: item.id,
      description: item.description,
      current_inventory: ((+ parseInt(item.appetizer === null ? 0 : item.appetizer)) - (+ parseInt(item.exits === null ? 0 : item.exits))).toString(),
      location: item.location,
      bar_code: item.bar_code,
      reference_code: item.reference_code,
      model: item.model,
      type: (item.type === "P"? "Produto" : "Serviço"),
      value_vlr: item.value_vlr,
      st: (item.st === "0" ? "Inativo" : "Ativo"),
    }))
  };

  const { loading } = useDemoData({
    dataSet: 'Commodity',
    rowLength: 10,
    maxColumns: 6,
  });

  const handleOpen = () => setOpen(true);

  const handleEdit = (item) => {
    setRegist(false);
    setOnEdit(item);
    handleOpen();
  };

  return (
    <>
    <TableMobile onlyWeb>
      <Tbody >
        {products === undefined ? [] : products.map((item, i) => (
          <Tr key={i}>
            <Td >
              <h1
                style={{fontSize: "12px", color:"#022025" }} >
                Código.: {item.id} <br />
                Placa.: {item.plate} / Apelido.: {item.surname} <br />
                Chassi.: {item.chassis} / Modelo.: {item.model}  / Ano.: {item.year} <br />
                {item.st === "0" ?
                  <Label 
                    style={{ fontSize: "12px", color: "red" }}> 
                    Status.: {item.st === "0" ? "Inativo" : "Ativo"} 
                  </Label>
                  :
                  <Label style={{ fontSize: "12px" }}> 
                    Status.: {item.st === "0" ? "Inativo" : "Ativo"} 
                  </Label>}
              </h1>


            </Td>
            <Td >
                <FaFileSignature onClick={() => handleEdit(item)}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "start",
                    cursor: "pointer",
                    width: "20px",
                    height: "70px",
                    textAlign: "start",
                    color: "#2c73d2",
                  }}
                />
            </Td>
          </Tr>
        ))}
      </Tbody>
    </TableMobile>

    <Table onlyWeb>
      <Tbody > 
          <DataGrid
            {...dataProducts}
            loading={loading}
            rowHeight = {30}
            slots={{
              toolbar: CustomToolbar,
            }}
          />
      </Tbody>
    </Table>
    
    <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 5}}
        open={openL}
    >
         <Label>Carregando...</Label>
        <CircularProgress color="inherit" />
    </Backdrop>
    </>
  );
};

export default GridProductsServices;