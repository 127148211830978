import * as React from 'react';
import { GridActionsCellItem } from '@mui/x-data-grid';
import EditIcon from '@mui/icons-material/Edit';

import { DataGrid, GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';
import { useDemoData } from '@mui/x-data-grid-generator';

import { FaFileSignature } from "react-icons/fa"
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { 
  TableMobile,
  Table, 
  Tbody, 
  Tr, 
  Td, 
  Label
} from "../Styles/Styles";

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarExport />
    </GridToolbarContainer>
  );
}


const GridLaunchSalesTicket = ({openL, setRegist, releases, setOnEdit, setOpen }) => {


  const alterReleases = React.useCallback(
    (id) => () => {
      const res = releases.filter((a) => (a.id === id));
       // eslint-disable-next-line
       res.map((a) => {
        handleEdit(a);
       });
    },
    [releases],
  );

  const data = {
    columns: [
      {
        field: 'actions',
        type: 'actions',
        headerName: 'Ações',
        width: 80,
        getActions: (params) => [
          <GridActionsCellItem
            icon={<EditIcon style={{
              color: "#2c73d2"}} />}
            label="Delete"
            onClick={alterReleases(params.id)}
          />,
        ],
      },
      { field: 'id', headerName: 'Cód.', width: 100 },
      { field: 'date', headerName: 'Data', width: 100 },
      { field: 'plate', headerName: 'Placa', width: 100 },
      { field: 'surname', headerName: 'Apelido', width: 150 },
      { field: 'nome', headerName: 'Motorista', width: 150 },
      { field: 'trurnstile_initial', headerName: 'Catraca Inicial', width: 150 },
      { field: 'trurnstile_final', headerName: 'Catraca Final', width: 150 },
      { field: 'total_users', headerName: 'Total Usuários', width: 150 },
      { field: 'vt', headerName: 'VT', width: 100 },
      { field: 'citizen', headerName: 'Cidadão', width: 100 },
      { field: 'integration', headerName: 'Integração', width: 100 },
      { field: 'school', headerName: 'Escola', width: 100 },
      { field: 'payers', headerName: 'Pagantes', width: 100 },
      { field: 'total_daily', headerName: 'Total Diário', width: 100 },
      { field: 'amount', headerName: 'V. Total R$', width: 100 },
      { field: 'difference', headerName: 'Diferença', width: 100 },
    ],
    rows: releases === undefined? [] : releases.map((item, i) => ({
      id: item.id,
      date: item.date.split("-").reverse().join("/"),
      plate: item.plate,
      surname: item.surname,
      nome: item.nome,
      trurnstile_initial: item.trurnstile_initial,
      trurnstile_final: item.trurnstile_final,
      vt: item.vt,
      citizen: item.citizen,
      integration: item.integration,
      school: item.school,
      payers: item.payers,
      total_daily: item.total_daily,
      amount: item.amount,
      difference: item.difference,
      total_users: item.total_users,
    }))
  }
  ;

  const { loading } = useDemoData({
    dataSet: 'Commodity',
    rowLength: 10,
    maxColumns: 6,
  });


  const handleOpen = () => setOpen(true);

  const handleEdit = (item) => {
    setRegist(false);
    setOnEdit(item);
    handleOpen();
  };

  return (
    <>
    <TableMobile onlyWeb>
      <Tbody >
        {releases === undefined ? [] : releases.map((item, i) => (
          <Tr key={i}>
            <Td >
              <h1
                style={{fontSize: "12px", color:"#022025" }} >
                    Data.: {item.date.split("-").reverse().join("/")} <br/>
                    Veículo.: {item.surname} = Motorista.: {item.nome}<br/>
                    Total Diário.: {item.total_daily} <br/> 
                    V. Total R$.: {item.amount}   
              </h1>
            </Td>
            <Td >
                <FaFileSignature onClick={() => handleEdit(item)}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "start",
                    cursor: "pointer",
                    width: "20px",
                    height: "70px",
                    textAlign: "start",
                    color: "#2c73d2",
                  }}
                />
            </Td>
          </Tr>
        ))}
      </Tbody>
    </TableMobile>

    <Table onlyWeb>
      <Tbody > 
      <DataGrid
        {...data}
        rowHeight = {30}
        loading={loading}
        slots={{
          toolbar: CustomToolbar,
        }}
      />
      </Tbody>
    </Table>
    
    <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 5}}
        open={openL}
    >
         <Label>Carregando...</Label>
        <CircularProgress color="inherit" />
    </Backdrop>
    </>
  );
};

export default GridLaunchSalesTicket;