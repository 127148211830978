import * as React from 'react';
import { GridActionsCellItem } from '@mui/x-data-grid';
import EditIcon from '@mui/icons-material/Edit';

import { DataGrid, GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';
import { useDemoData } from '@mui/x-data-grid-generator';
import { FaFileSignature } from "react-icons/fa"
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

import { 
  TableMobile,
  Table, 
  Tbody, 
  Tr, 
  Td, 
  Label 
} from "../Styles/Styles";

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarExport />
    </GridToolbarContainer>
  );
}


const GridUsers = ({openL, setRegist, users, setOnEdit, setOpen }) => {

  const alterUsers = React.useCallback(
    (id) => () => {
      const res = users.filter((a) => (a.id === id));
       // eslint-disable-next-line
       res.map((a) => {
        handleEdit(a);
       });
    },
    [users],
  );

  const data = {
    columns: [
      { field: 'id', headerName: 'Cód.', width: 100 },
      { field: 'name', headerName: 'Nome', width: 150 },
      { field: 'email', headerName: 'E-mail', width: 350 },
      { field: 'phone', headerName: 'Telefone', width: 150 },
      { field: 'st', headerName: 'Situação', width: 150 },
      {
        field: 'actions',
        type: 'actions',
        headerName: 'Ações',
        width: 80,
        getActions: (params) => [
          <GridActionsCellItem
            icon={<EditIcon style={{
              color: "#2c73d2"}} />}
            label="Delete"
            onClick={alterUsers(params.id)}
          />,
        ],
      },
    ],
    rows: users === undefined? [] : users.map((item, i) => ({
      id: item.id,
      name: item.name,
      email: item.email,
      phone: item.phone,
      st: (item.st === "0" ? "Inativo" : "Ativo"),
    }))
  }
  ;

  const { loading } = useDemoData({
    dataSet: 'Commodity',
    rowLength: 10,
    maxColumns: 6,
  });

  const handleOpen = () => setOpen(true);

  const handleEdit = (item) => {
    setRegist(false);
    setOnEdit(item);
    handleOpen();
  };



  return (
    <>
    <TableMobile onlyWeb>
      <Tbody >
        {users === undefined ? [] : users.map((item, i) => (
          <Tr key={i}>
            <Td >
              <h1
                style={{fontSize: "12px", color:"#022025" }} >
                    Código.: {item.id} <br />
                    Nome.: {item.name} <br />
                    Telefone.: {item.phone} <br />
                    E-mail.: {item.email} <br />
                    {item.st === "0" ?
                      <Label 
                        style={{ fontSize: "12px", color: "red" }}> 
                        Status.: {item.st === "0" ? "Inativo" : "Ativo"} 
                      </Label>
                      :
                      <Label style={{ fontSize: "12px" }}> 
                        Status.: {item.st === "0" ? "Inativo" : "Ativo"} 
                      </Label>
                    }
              </h1>


            </Td>
            <Td >
                <FaFileSignature onClick={() => handleEdit(item)}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "start",
                    cursor: "pointer",
                    width: "20px",
                    height: "70px",
                    textAlign: "start",
                    color: "#2c73d2",
                  }}
                />
            </Td>
          </Tr>
        ))}
      </Tbody>
    </TableMobile>
    
    <Table onlyWeb>
      <Tbody > 
      <DataGrid
        {...data}
        loading={loading}
        rowHeight = {30}
        slots={{
          toolbar: CustomToolbar,
        }}
      />
      </Tbody>
    </Table>
    
    <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 5}}
        open={openL}
    >
         <Label>Carregando...</Label>
        <CircularProgress color="inherit" />
    </Backdrop>
    </>
  );
};

export default GridUsers;