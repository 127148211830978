import React, { useEffect, useRef } from "react";
import axios from "axios";
import {toast } from "react-toastify";
import Typography from '@mui/material/Typography';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import DeleteIcon from '@mui/icons-material/Delete';

import { 
  FormContainer, 
  InputArea, 
  Label, 
  Input, 
  Input2, 
  Input3, 
  ButtonArea, 
  Button
} from "../Styles/Styles";

const FromFuel = ({handleCloseL, openL, handleOpenL, regist, onEdit, setOnEdit, getFuel, setOpen }) => {
  const ref = useRef();
  const handleClose = () => setOpen(false);
  const IdCompany = localStorage.getItem("id_company");
  const IdUser = localStorage.getItem("id");

  useEffect(() => {
    const fuel = ref.current;
    if(regist) {
        fuel.cod.value = "";
        fuel.fuel.value = "";
        fuel.liter_value.value = "";
    } else {
    if (onEdit) {
      fuel.cod.value = onEdit.id;
      fuel.fuel.value = onEdit.fuel;
      fuel.liter_value.value = onEdit.liter_value;
    };
  };
  // eslint-disable-next-line
  }, []);

  const handleDelete = async (e) => {
    e.preventDefault();
    const fuel = ref.current;
    handleOpenL();

    if (!window.confirm("Tem certeza que deseja excluir?")) {
      handleCloseL();
      return;
    } else {
    await axios
     .delete("fuel/fuel-list/"+ fuel.cod.value)
     .then(() => {
        toast.success("Cadastro excluido com sucesso!");
        getFuel();
        setOnEdit(null);
        handleClose();
     })
     .catch(() => {
      handleCloseL();
      toast.error("Erro, não foi possível excluir!");});
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    handleOpenL();
  
    const fuel = ref.current;
      if (
        !fuel.fuel.value ||
        !fuel.liter_value.value 
      ) {
        handleCloseL();
        return toast.warn("Preencha todos os campos com ( * )!");
      }

    if (onEdit) {
      await axios
        .put("fuel/fuel-list/", {
          id: fuel.cod.value.trim(),
          name: fuel.name.value.trim(),
          phone: fuel.phone.value.trim(),
          user :IdUser,
        })
        .then(() => {
          toast.success("Cadastro atualizado com sucesso!");

          fuel.cod.value = "";
          fuel.fuel.value = "";
          fuel.liter_value.value = "";
          
          getFuel();
          setOnEdit(null);
          handleClose();
        })
        .catch(() => {
          handleCloseL();
          toast.error("Erro na atualizado!");
        });
    } else {
      await axios
        .post("fuel/fuel-list/", {
          fuel: fuel.fuel.value.trim(),
          liter_value: fuel.liter_value.value,
          id_company: IdCompany,
          user :IdUser,
        })
        .then(() => {
          toast.success("Cadastrado com sucesso!");
          
          fuel.cod.value = "";
          fuel.fuel.value = "";
          fuel.liter_value.value = "";

          getFuel();
          setOnEdit(null);
          handleClose();
        })
        .catch(() => {
          handleCloseL();
          toast.error("Erro no Cadastro!");
        });
        
    }


  };

  return (
    <>
          <Typography style={{ color: "#fff" }} id="modal-modal-title" variant="h6" component="h2">
            Ficha do combustível
          </Typography>
          <FormContainer  ref={ref} onSubmit={handleSubmit}>
            <InputArea>
              <Label>Código</Label>
              <Input style={{ color: "#fff" }} disabled={true} name="cod"  />
            </InputArea>
            <InputArea>
              <Label>Nome*</Label>
              <Input2 style={{ width: "350px" }} name="fuel" />
            </InputArea>
            <InputArea>
              <Label>Valor LT R$</Label>
              <Input3 type="number" pattern="/([0-9]{3}).([0-9]{3}),([0-9]{2})$/g, '.$1.$2,$3'" min="0" step="any" placeholder="Digite só número" name="liter_value" />
            </InputArea>
            <div style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }} >
              <ButtonArea >
                <Button type="submit">SALVAR</Button>
              </ButtonArea>
              <ButtonArea >
              { regist === true? 
                 null
                 : 
                 <Button 
                    style={{backgroundColor:"red"}} 
                    onClick={handleDelete}>EXCLUIR <DeleteIcon/> 
                 </Button>
                 }
              </ButtonArea>
              <ButtonArea >
                <Button onClick={handleClose}>SAIR</Button>
              </ButtonArea>
            </div>

          </FormContainer>
          
      <Backdrop
              sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 5}}
              open={openL}
          >
         <Label>Carregando...</Label>
              <CircularProgress color="inherit" />
          </Backdrop>
    </>
  );
};

export default FromFuel;