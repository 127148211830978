import * as React from 'react';
import { GridActionsCellItem } from '@mui/x-data-grid';
import FileDownloadDoneIcon from '@mui/icons-material/FileDownloadDone';

import { DataGrid, GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';
import { useDemoData } from '@mui/x-data-grid-generator';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

import { 
  Table, 
  Tbody, 
  Label
} from "../Styles/Styles";

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarExport />
    </GridToolbarContainer>
  );
}


const GridLoc = ({openL, products, handleClose, dataEdit, xmlProd, notRef }) => {
  const codRef = React.useRef();
  const nameRef = React.useRef();

  const alterItem = () => {
    if (xmlProd.prod === undefined ) {
        const res = notRef.filter((a) => (a.cProd === dataEdit.cProd));
        const rest = xmlProd.filter((a) => (a.prod.cProd === dataEdit.cProd));
        // eslint-disable-next-line
        res.map((a) => {
          a.cEAN = codRef.current;
          a.xProd = nameRef.current;
        });
        rest.map((a) => {
            a.prod.cEAN = codRef.current;
            a.prod.xProd = nameRef.current;
        });
    } else{
        notRef[0].cEAN = codRef.current;
        xmlProd.prod.cEAN  = codRef.current;
        notRef[0].xProd = nameRef.current;
        xmlProd.prod.xProd  = nameRef.current;
    }
  }

  const alterProducts = React.useCallback(
    (id) => () => {
      const res = products.filter((a) => (a.id === id));
       // eslint-disable-next-line
       res.map((a) => {
        codRef.current = a.bar_code;
        nameRef.current = a.description;
        alterItem()
        handleClose();
       });
    },
    [products],
  );

  const data = {
    columns: [
      {
        field: 'actions',
        type: 'actions',
        headerName: 'Ações',
        width: 80,
        getActions: (params) => [
          <GridActionsCellItem
            icon={<FileDownloadDoneIcon style={{
              color: "#2c73d2"}} />}
            label="Edit"
            onClick={alterProducts(params.id)}
          />,
        ],
      },
      { field: 'description', headerName: 'Descrição', width: 600 },
      { field: 'current_inventory', headerName: 'Estoque', width: 150 },
      { field: 'location', headerName: 'Localização', width: 150 },
      { field: 'reference_code', headerName: 'Cód. Referencia', width: 150 },
      { field: 'model', headerName: 'Modelo', width: 150 },
      { field: 'type', headerName: 'Tipo', width: 100 },
      { field: 'value_vlr', headerName: 'Valor R$', width: 150 },
      { field: 'st', headerName: 'Situação', width: 150 },
    ],
    rows: products === undefined? [] : products.map((item, i) => ({
      id: item.id,
      description: item.description,
      current_inventory: ((+ parseInt(item.appetizer === null ? 0 : item.appetizer)) - (+ parseInt(item.exits === null ? 0 : item.exits))).toString(),
      location: item.location,
      reference_code: item.reference_code,
      model: item.model,
      type: (item.type === "P"? "Produto" : "Serviço"),
      value_vlr: item.value_vlr,
      st: (item.st === "0" ? "Inativo" : "Ativo"),
    }))
  }
  ;

  const { loading } = useDemoData({
    dataSet: 'Commodity',
    rowLength: 10,
    maxColumns: 6,
  });

  return (
    <>
    <Table onlyWeb>
      <Tbody > 
      <DataGrid
        {...data}
        loading={loading}
        rowHeight = {30}
        slots={{
          toolbar: CustomToolbar,
        }}
      />
      </Tbody>
    </Table>
    
    <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 5}}
        open={openL}
    >
         <Label>Carregando...</Label>
        <CircularProgress color="inherit" />
    </Backdrop>
    </>
  );
};

export default GridLoc;