import React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import FromLaunchSalesTicket from "./FromLaunchSalesTicket";
import { Container, styledModal } from "../Styles/Styles";

const ModalLaunchSalesTicket = ({
  vehicles, 
  employees, 
  locDate, 
  handleCloseL, 
  handleOpenL, 
  openL, 
  regist, 
  onEdit, 
  setOnEdit, 
  getReleases, 
  setOpen, 
  open,
  getEmployees,
  getVehicles,
  lines
 }) => {
  

  return (
    <>
      <Modal
        open={open}
        aria-labelledby="modal-modal-title"
      >
        <Box sx={styledModal}>
          <Container>
            <FromLaunchSalesTicket 
              handleCloseL={handleCloseL} 
              handleOpenL={handleOpenL}  
              openL={openL} 
              regist={regist} 
              onEdit={onEdit} 
              setOnEdit={setOnEdit} 
              getReleases={getReleases} 
              setOpen={setOpen}
              locDate={locDate}
              employees={employees}
              vehicles={vehicles} 
              getEmployees={getEmployees}
              getVehicles={getVehicles}
              lines={lines}
            />
          </Container>
        </Box>
        
        
      </Modal>
      
    </>
  );
};

export default ModalLaunchSalesTicket;