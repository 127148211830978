import styled from "styled-components";

export const Div = styled.div`
  margin: 0;
  padding: 0;
  width: 100%;
  height: calc(90vh - 250px);
  overflow: auto;
`

export const Table = styled.table`
  display: row;
  width: 100%;
  margin-top: 5px;
  background-color: 0px 0px 5px #022025;
  padding: 10px;
  box-shadow: 0px 0px 5px #022025;
  border-radius: 5px;
  font-size: 12px;
  backdrop-filter: blur(5.5px);
  @media (max-width: 500px) {
    ${(props) => props.onlyWeb && "display: none"}
  }
`;

export const Loc = styled.input`
  padding: 0 10px;
  border-radius: 5px;
  height: 40px;
  width: 200px;
  font-size: 12px;
  
`;

export const TableMobile = styled.table`
  display: row;
  width: 100%;
  margin-top: 5px;
  background-color: 0px 0px 5px #022025;
  padding: 10px;
  box-shadow: 0px 0px 5px #022025;
  border-radius: 5px;
  font-size: 12px;
  backdrop-filter: blur(5.5px);
  @media (min-width: 500px) {
    ${(props) => props.onlyWeb && "display: none"}
  }
`;

export const Thead = styled.thead`

`;

export const Tbody = styled.tbody`
  position: fixed;
  box-sizing: 'border-box';
  width: 100%;
  height: calc(100vh - 170px);
  outline: auto;
  overflow-y: scroll;
  z-index: 5;
  background-color: #fff;
`;


export const Tr = styled.tr`
  display: flex;
  margin: 5px;
  justify-content: space-between;
  border-radius: 5px;
  border: 1px solid #022025;

`;

export const Th = styled.th`
  text-align: start;
  padding: 5px;
  @media (max-width: 500px) {
    ${(props) => props.onlyWeb && "display: none"}
  }
`;

export const Label = styled.label`
font-size: 14px;
`

export const Td = styled.td`
  display: block;
  align-items: center;
  text-align: start;
  padding: 5px;
  text-align: ${(props) => (props.alignCenter ? "center" : "start")};
  width: ${(props) => (props.width ? props.width : "auto")};

  @media (max-width: 500px) {
    ${(props) => props.onlyWeb && "display: none"}
  }
`;

export const FormContainer = styled.form`
display: flex;
margin: 5px;
padding: 10px;
gap: 5px;
flex-wrap: wrap;
border-radius: 5px;
border: 1px solid #fff;
color: #fff;
align-items: center;
background: #343f46;

overflow: auto;
`;

export const InputArea = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ButtonArea = styled.div`
display: flex;
align-items: center;
margin-bottom: 0;
gap: 5px;
padding: 0px;
justify-content: space-between;
`

export const Input = styled.input`
  width: 175px;
  padding: 0 10px;
  border: 1px solid #bbb;
  border-radius: 5px;
  height: 30px;
  font-size: 12px;
`;

export const Input3 = styled.input`
  width: 175px;
  padding: 0 10px;
  border: 1px solid #bbb;
  border-radius: 5px;
  height: 30px;
  font-size: 12px;
`;

/* campo nome */
export const Input2 = styled.input`
  width: 175px;
  padding: 0 10px;
  border: 1px solid #bbb;
  border-radius: 5px;
  height: 30px;
  text-transform: uppercase;

  font-size: 12px;
`;

/*campo tipo */
export const Select1 = styled.select`
  width: 175px;
  padding: 0 10px;
  border: 1px solid #bbb;
  border-radius: 5px;
  height: 30px;

  font-size: 12px;
`;

export const Button = styled.button`
  padding: 5px;
  cursor: pointer;
  border-radius: 5px;
  border: none;
  background-color: #2c73d2;
  color: white;
  height: 35px;
  width: 120px;
  font-size: 12px; 
  &:hover {
    cursor: pointer;
    background-color: rgba(31, 38, 135, 0.37);
    transition: 0.3s ease-in-out;
  };
  
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(5.5px);
  border: 1px solid rgba(255, 255, 255, 0.18);
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column ;
  align-items: center;
  justify-content: center ;
  height: 100%;
  max-width: 1024px;
`


export const styledModal = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '100%',
  height: '100%',
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  borderRadius: '10px',
  outline: 'none',
  

  boxSizing: 'border-box',
  overflowY: 'auto',

  
  boxShadow: '0 8px 32px 0 rgba(31, 38, 135, 0.37)',
  backdropFilter: 'blur(5.5px)',
  border: '1px solid rgba(255, 255, 255, 0.18)',
}


/* campo nome */
export const InputEmail = styled.input`
  width: 350px;  
  padding: 0 10px;
  border: 1px solid #bbb;
  border-radius: 5px;
  height: 30px;
  text-transform: lowercase ;

  font-size: 12px;
`;

/* campo nome */
export const InputDate = styled.input`
  width: 175px;  
  padding: 0 10px;
  border: 1px solid #bbb;
  border-radius: 5px;
  height: 30px;

  font-size: 12px;
`;