import pdfMake from 'pdfmake/build/pdfmake';
import pdfFontes from 'pdfmake/build/vfs_fonts';
//import { Logo } from './Logo4bus';



const ReleasesSimplePdf = (locDateI, locDateF, releases, dataAtualFormatada, releasesSumRef, subCompany, category) => {
    pdfMake.vfs = pdfFontes.pdfMake.vfs;
    const qtde = releases.length;
    const { fuel_qtd: fuelQtd, km_wheeled: KmWheeled }= releasesSumRef
    const dados = releases.map((item) => {
        return [
            { text: item.surname, fontSize: 7, margin: [0, 2, 0, 2] },
            { text: item.plate, fontSize: 7, margin: [0, 2, 0, 2] },
            { text: item.model, fontSize: 7, margin: [0, 2, 0, 2] },
            { text: item.km_wheeled, fontSize: 7, margin: [0, 2, 0, 2] },
            { text: item.fuel_qtd, fontSize: 7, margin: [0, 2, 0, 2] },
            { text: parseFloat(item.m_fuel).toFixed(2), fontSize: 7, margin: [0, 2, 0, 2] },
        ]
    })
    const details = [
        {
            text: 'Relatório de abastecimentos simples',
            alignment: 'center',
            fontSize: 12,
            margin: [0, 5, 0, 5] // left, top, right, bottom
        },
        {
            text:"Empresa.: " + (subCompany === "" ? "Todos" : subCompany) + " / Categoria.: " + category,
            style: "textBody",
            fontSize: 10,
            alignment: "left",
            margin: [5, 0, 5, 0] // left, top, right, bottom
        },
        {
            text:
                "Data.: " + dataAtualFormatada.split("-").reverse().join("/"),
            style: "textBody",
            fontSize: 8,
            alignment: "right",
            margin: [5, 0, 5, 0] // left, top, right, bottom
        },
        {
            text:
                "Registros.: " + qtde,
            style: "textBody",
            fontSize: 8,
            alignment: "right",
            margin: [5, 0, 5, 0] // left, top, right, bottom
        },
        {
            style: 'tableExample',
            table: {
                alignment: "justify",
                margin: [5, 5, 0, 1], // left, top, right, bottom
                headerRows: 1,
                widths: ['*', 200, '*'],
                body: [
                    [{ text: 'Período', style: 'tableHeader', fontSize: 8, color: '#071251', bold: true },
                    { text: 'Total Km Rodados', style: 'tableHeader', fontSize: 8, color: '#071251', bold: true },
                    { text: 'Total Combustivel', style: 'tableHeader', fontSize: 8, color: '#071251', bold: true }
                    ],
                    [
                        { text: locDateI.split("-").reverse().join("/") + " à " + locDateF.split("-").reverse().join("/"), style: 'tableHeader', fontSize: 8 },
                        { text: parseFloat(KmWheeled).toFixed(2), style: 'tableHeader', fontSize: 8, alignment: "right", bold: true },
                        {
                            style: 'tableExample',
                            table: {
                                fontSize: 8,
                                widths: ['*', '*'],
                                body: [
                                    [{ text: 'LT.:', style: 'tableHeader', fontSize: 8, alignment: "left", bold: true },
                                    { text: parseFloat(fuelQtd).toFixed(2), style: 'tableHeader', fontSize: 8, alignment: "right", bold: true }
                                    ],
                                ]
                            },
                            layout: 'noBorders'
                        }
                    ]
                ]
            }
        },
        {
            text: "Movimentações",
            style: "textBody",
            fontSize: 8,
            alignment: "justify",
            margin: [5, 5, 0, 1], // left, top, right, bottom
            bold: true
        },
        {
            style: 'tableExample',
            alignment: 'left',
            table: {
                headerRows: 1,
                widths: [60, 60, '*', 60, 60, 60],
                body: [
                    [
                        { text: 'Veículo', style: 'tableHeader', fontSize: 8, bold: true },
                        { text: 'Placa', style: 'tableHeader', fontSize: 8, bold: true },
                        { text: 'Modelo', style: 'tableHeader', fontSize: 8, bold: true },
                        { text: 'Km Rodados', style: 'tableHeader', fontSize: 8, bold: true },
                        { text: 'Qtde. Comb.', style: 'tableHeader', fontSize: 8, bold: true },
                        { text: 'M. Comb.', style: 'tableHeader', fontSize: 8, bold: true }
                    ],
                    ...dados
                ]
            },
            layout: {
                hLineWidth: function (i, node) {
                    return (i === 0 || i === node.table.body.length) ? 2 : 1;
                },
                vLineWidth: function (i, node) {
                    return (i === 0 || i === node.table.widths.length) ? 2 : 1;
                },
                hLineColor: function (i, node) {
                    return (i === 0 || i === node.table.body.length) ? 'black' : 'gray';
                },
                vLineColor: function (i, node) {
                    return (i === 0 || i === node.table.widths.length) ? 'black' : 'gray';
                },
            }
        },


    ];

    const baseboard = (currentPage, pageCount) => {
        return [
            {
                text: currentPage + ' / ' + pageCount,
                alignment: 'right',
                fontSize: 9,
                margin: [0, 10, 20, 0] // left, top, right, bottom
            }
        ]
    }

    const docDefinitios = {
        pageSize: 'A4',

        //header: [reportTitle],
        content: [details],
        footer: baseboard
    }

    pdfMake.createPdf(docDefinitios).print();
}

export default ReleasesSimplePdf;