import React, { useEffect, useRef } from "react";
import axios from "axios";
import {toast } from "react-toastify";
import Typography from '@mui/material/Typography';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import DeleteIcon from '@mui/icons-material/Delete';

import { 
  FormContainer, 
  InputArea, 
  Label, 
  Input, 
  Input2, 
  ButtonArea, 
  Button,
  Select1
} from "../../Styles/Styles";

const FromTireRegistration = ({
  handleCloseL, 
  openL, 
  handleOpenL, 
  regist, 
  onEdit, 
  setOnEdit, 
  getTireRegistration, 
  setOpen, 
  supplier,
  tireRegistration
}) => {
  const ref = useRef();
  const handleClose = () => setOpen(false);
  const IdCompany = localStorage.getItem("id_company");
  const IdUser = localStorage.getItem("id");
  const nameSupplier = useRef();

   
  const cleanearFields = () => {
    const tire = ref.current;
    tire.cod.value = "";
    tire.identification.value = "";
    tire.purchase_value.value = "";
    tire.supplier.value = "";
    tire.purchase_date.value = "";
    tire.invoice.value = "";
    tire.mark.value = "";
    tire.projected_useful_life.value = "";
    tire.fire_number.value = "";
    tire.serial_number.value = "";
    tire.dot.value = "";
    tire.recaps.value = "0";
    tire.tire_status.value = "0";
  }
  /*
  const onCategoryChange = async () =>{
    const tire = ref.current;
    await getsetIdentification();
    category.current = tire.category.value;
  };
  */


  const onIdentificationFireNumberChange = () =>{
    const tire = ref.current;
    const res = tireRegistration.filter((a) => (a.fire_number === tire.fire_number.value));
    if(res.length > 0){
      toast.error("Erro, Número Fogo já cadastrado em outro Pneu! "+ tire.fire_number.value);
      return; 
    }   
  }  

  const onSupplierChange = () =>{
    const tire = ref.current;
   const res = supplier.filter((a) => (a.name === onEdit.supplier));
   // eslint-disable-next-line
       res.map((a) => {
        tire.supplier.value = a.id;
       });
  }

  const getSupplierChange = () =>{
    const tire = ref.current;
   const res = supplier.filter((a) => (a.id === tire.supplier.value));
   // eslint-disable-next-line
       res.map((a) => {
        nameSupplier.current = a.name;
       });
  }

  useEffect(() => {
    const tire = ref.current;
    if(regist) {
      cleanearFields();
    } else {
    if (onEdit) {
      onSupplierChange();
      tire.cod.value = onEdit.id;
      tire.purchase_value.value = onEdit.purchase_value;
      tire.purchase_date.value = onEdit.purchase_date;
      tire.invoice.value = onEdit.invoice;
      tire.mark.value = onEdit.mark;
      tire.projected_useful_life.value = onEdit.projected_useful_life;
      tire.fire_number.value = onEdit.fire_number;
      tire.serial_number.value = onEdit.serial_number;
      tire.dot.value = onEdit.dot;
      tire.recaps.value = onEdit.recaps;
      tire.tire_status.value = onEdit.tire_status;
      tire.identification.value = onEdit.identification;
    };
    
  };
  // eslint-disable-next-line
  }, []);

  const handleDelete = async (e) => {
    e.preventDefault();
    const tire = ref.current;
    handleOpenL();

    if (!window.confirm("Tem certeza que deseja excluir?")) {
      handleCloseL();
      return;
    } else {
    await axios
     .delete("tires/tire-registration-list/"+ tire.cod.value)
     .then(() => {
        toast.success("Cadastro excluido com sucesso!");
        getTireRegistration();
        setOnEdit(null);
        handleClose();
     })
     .catch(() => {
      handleCloseL();
      toast.error("Erro, não foi possível excluir!");});
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    handleOpenL();
    getSupplierChange();
    const tire = ref.current;
    if(regist){
      const res = tireRegistration.filter((a) => (a.fire_number === tire.fire_number.value));
      if(res.length > 0){
        handleCloseL();
        return toast.error("Erro, Número Fogo já cadastrado em outro Pneu! "+ tire.fire_number.value); 
      };
    }
    if (
      !tire.identification.value ||
      !tire.purchase_value.value ||
      !tire.supplier.value ||
      !tire.purchase_date.value ||
      !tire.invoice.value ||
      !tire.mark.value ||
      !tire.projected_useful_life.value ||
      !tire.fire_number.value 
    ) {
      handleCloseL();
      return toast.warn("Preencha todos os campos com ( * )!");
    };

    if (onEdit) {
      await axios
        .put("tires/tire-registration-list/", {
          id: tire.cod.value,   
          identification: tire.identification.value,
          purchase_value: tire.purchase_value.value.trim(),
          supplier: nameSupplier.current,
          purchase_date: tire.purchase_date.value,
          invoice: tire.invoice.value.trim(),
          mark: tire.mark.value,
          projected_useful_life: tire.projected_useful_life.value.trim(),
          fire_number: tire.fire_number.value.trim(),
          serial_number: tire.serial_number.value.trim(),
          dot: tire.dot.value.trim(),
          recaps: tire.recaps.value,
          tire_status: tire.tire_status.value,
          user :IdUser,
        })
        .then(() => {
          toast.success("Cadastro atualizado com sucesso!");

          getTireRegistration();
          cleanearFields();
          setOnEdit(null);
          handleClose();
        })
        .catch(() => {
          handleCloseL();
          toast.error("Erro na atualizado!");
        });
    } else {
      await axios
        .post("tires/tire-registration-list/", {
          identification: tire.identification.value,
          purchase_value: tire.purchase_value.value.trim(),
          supplier: nameSupplier.current,
          purchase_date: tire.purchase_date.value,
          invoice: tire.invoice.value.trim(),
          mark: tire.mark.value,
          projected_useful_life: tire.projected_useful_life.value.trim(),
          fire_number: tire.fire_number.value.trim(),
          serial_number: tire.serial_number.value.trim(),
          dot: tire.dot.value.trim(),
          recaps: tire.recaps.value,
          tire_status: tire.tire_status.value,
          id_company: IdCompany,
          user :IdUser,
        })
        .then(() => {
          toast.success("Cadastrado com sucesso!");
          
          cleanearFields();
          getTireRegistration();
          setOnEdit(null);
          handleClose();
        })
        .catch(() => {
          handleCloseL();
          toast.error("Erro no Cadastro!");
        });
        
    }


  };

  return (
    <>
          <Typography style={{ color: "#fff" }} id="modal-modal-title" variant="h6" component="h2">
            Ficha de Cadastro Pneu
          </Typography>
          <FormContainer  ref={ref} onSubmit={handleSubmit}>
            <InputArea>
              <Label>Código</Label>
              <Input style={{ color: "#fff" }} disabled={true} name="cod"  />
            </InputArea>
            <InputArea>
              <Label>Identificação*</Label>
              <Input2 style={{ width: "755px" }} name="identification"/>
            </InputArea>
              <InputArea>
                <Label>Fornecedor*</Label>
                <Select1 style={{ width: "400px" }} name="supplier">
                  <option value="">Selecione</option>
                  {supplier === undefined ? [] : supplier.map((item, i) => (
                    <option key={i} value={item.id}>{item.name}</option>
                  ))}
                </Select1>
              </InputArea>
            <InputArea>
              <Label>Data Compra*</Label>
              <Input2 type="date" name="purchase_date" />
            </InputArea>
            <InputArea>
              <Label>Nota Fiscal*</Label>
              <Input2 name="invoice" type="number" pattern="/([0-9]{3}).([0-9]{3}),([0-9]{2})$/g, '.$1.$2,$3'" min="0" step="any" placeholder="Digite só número"/>
            </InputArea>
            <InputArea>
              <Label>Valor*</Label>
              <Input2 name="purchase_value" type="number" pattern="/([0-9]{3}).([0-9]{3}),([0-9]{2})$/g, '.$1.$2,$3'" min="0" step="any" placeholder="Digite só número"/>
            </InputArea>
            <InputArea>
              <Label>Marca*</Label>
              <Select1 name="mark">
                <option value="">Selecione</option>
                <option value="ANTEO">ANTEO</option>
                <option value="APOLLO">APOLLO</option>
                <option value="BARUM">BARUM</option>
                <option value="BFGOODRICH">BFGOODRICH</option>
                <option value="BRIDGESTONE">BRIDGESTONE</option>
                <option value="CONTINENTAL">CONTINENTAL</option>
                <option value="DURABLE">DURABLE</option>
                <option value="DYNAMO">DYNAMO</option>
                <option value="EVERGREEN">EVERGREEN</option>
                <option value="FATE">FATE</option>
                <option value="FERENTINO">FERENTINO</option>
                <option value="FIRESTONE">FIRESTONE</option>
                <option value="FORMULA">FORMULA</option>
                <option value="GENERAL-TIRE">GENERAL-TIRE</option>
                <option value="GOODYEAR">GOODYEAR</option>
                <option value="Hankook">Hankook</option>
                <option value="HORIZON">HORIZON</option>
                <option value="Invovic">Invovic</option>
                <option value="KELLY">KELLY</option>
                <option value="KPATOS">KPATOS</option>
                <option value="KUMHO">KUMHO</option>
                <option value="MICHELIN">MICHELIN</option>
                <option value="MILEVER">MILEVER</option>
                <option value="NEXEN">NEXEN</option>
                <option value="ONYX">ONYX</option>
                <option value="PIRELLI">PIRELLI</option>
                <option value="ROADX">ROADX</option>
                <option value="Sunny">Sunny</option>
                <option value="Sunrise">Sunrise</option>
                <option value="TRAZANO">TRAZANO</option>
                <option value="WESTLAKE">WESTLAKE</option>
                <option value="XBRI">XBRI</option>
              </Select1>
            </InputArea>
            <InputArea>
              <Label>Vida Util Projetada ( KM )*</Label>
              <Input2 name="projected_useful_life" type="number" pattern="/([0-9]{3}).([0-9]{3}),([0-9]{2})$/g, '.$1.$2,$3'" min="0" step="any" placeholder="Digite só número"/>
            </InputArea>
            <InputArea>
              <Label>Número Fogo*</Label>
              <Input2 style={{ width: "120px" }} name="fire_number" type="number" pattern="/([0-9]{3}).([0-9]{3}),([0-9]{2})$/g, '.$1.$2,$3'" min="0" step="any" placeholder="Digite só número"/>
            </InputArea>
            <InputArea>
              <Label>Número Série</Label>
              <Input2 name="serial_number" type="number" pattern="/([0-9]{3}).([0-9]{3}),([0-9]{2})$/g, '.$1.$2,$3'" min="0" step="any" placeholder="Digite só número"/>
            </InputArea>
            <InputArea>
              <Label>DOT</Label>
              <Input2 name="dot" type="number" pattern="/([0-9]{3}).([0-9]{3}),([0-9]{2})$/g, '.$1.$2,$3'" min="0" step="any" placeholder="Digite só número"/>
            </InputArea>
            <InputArea>
              <Label>Recapes</Label>
              <Select1 style={{ width: "100px" }} name="recaps">
                <option value="">Selecione</option>
                <option value={0}>0</option>
                <option value={1}>1</option>
                <option value={2}>2</option>
                <option value={3}>3</option>
                <option value={4}>4</option>
                <option value={5}>5</option>
                <option value={6}>6</option>
                <option value={7}>7</option>
              </Select1>
            </InputArea>
            <InputArea>
              <Label>Status Pneu</Label>
              <Select1 style={{ color: "#000" }} disabled={true} name="tire_status">
                <option value={0}>Estoque</option>
                <option value={1}>Transito</option>
                <option value={2}>Manutenção</option>
                <option value={3}>Sucata</option>
                <option value={4}>Finalização do Transito</option>
              </Select1>
            </InputArea>
            

            <div style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }} >
              <ButtonArea >
                <Button type="submit">SALVAR</Button>
              </ButtonArea>
              <ButtonArea >
              { regist === true? 
                 null
                 : 
                 <Button 
                    style={{backgroundColor:"red"}} 
                    onClick={handleDelete}>EXCLUIR <DeleteIcon/> 
                 </Button>
                 }
              </ButtonArea>
              <ButtonArea >
                <Button onClick={handleClose}>SAIR</Button>
              </ButtonArea>
            </div>

          </FormContainer>
          
      <Backdrop
              sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 5}}
              open={openL}
          >
         <Label>Carregando...</Label>
              <CircularProgress color="inherit" />
          </Backdrop>
    </>
  );
};

export default FromTireRegistration;