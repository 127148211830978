import React, { useEffect, useRef } from "react";
import axios from "axios";
import {toast } from "react-toastify";
import Typography from '@mui/material/Typography';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import DeleteIcon from '@mui/icons-material/Delete';
import { 
  FormContainer, 
  InputArea, 
  Label, 
  Input, 
  Select1, 
  Input2, 
  ButtonArea, 
  Button, 
  InputEmail,
  InputDate 
} from "../Styles/Styles";


const FromEmployees = ({handleCloseL, openL, handleOpenL, regist, onEdit, setOnEdit, getEmployees, setOpen }) => {
  const ref = useRef();
  const handleClose = () => setOpen(false);
  const IdCompany = localStorage.getItem("id_company");
  const IdUser = localStorage.getItem("id");

  const limparCampos = () => {
    const employee = ref.current;

    employee.cod.value = "";
    employee.nome.value = "";
    employee.telefone.value = "";
    employee.cpf.value = "";
    employee.rg.value = "";
    employee.n_cnh.value = "";
    employee.categoria_cnh.value = "";
    employee.vencimento_cnh.value = "";
    employee.venc_exame_toxicologico.value = "";
    employee.matricula.value = "";
    employee.data_nacimento.value = "";
    employee.tipo_sanguineo.value = "";
    employee.cep.value = "";
    employee.endereco.value = "";
    employee.numero.value = "";
    employee.cidade.value = "";
    employee.bairro.value = "";
    employee.uf.value = "";
    employee.complemento.value = "";
    employee.email.value = "";
    employee.nome_mae.value = "";
    employee.nome_pai.value = "";
    employee.status.value = "1";
    employee.function.value = "";

  }

  useEffect(() => {
    const employee = ref.current;
      if(regist) {
       limparCampos();
    } else {
    if (onEdit) {
      
      employee.cod.value = onEdit.id;
      employee.nome.value = onEdit.nome;
      employee.telefone.value = onEdit.telefone; 
      employee.cpf.value = onEdit.cpf;
      employee.rg.value = onEdit.rg; 
      employee.n_cnh.value = onEdit.n_cnh; 
      employee.categoria_cnh.value = onEdit.categoria_cnh; 
      employee.vencimento_cnh.value = onEdit.vencimento_cnh;
      employee.venc_exame_toxicologico.value = onEdit.venc_exame_toxicologico;
      employee.matricula.value = onEdit.matricula;
      employee.data_nacimento.value = onEdit.data_nacimento;
      employee.tipo_sanguineo.value = onEdit.tipo_sanguineo;
      employee.cep.value = onEdit.cep;
      employee.endereco.value = onEdit.endereco;
      employee.numero.value = onEdit.numero;
      employee.cidade.value = onEdit.cidade;
      employee.bairro.value = onEdit.bairro;
      employee.uf.value = onEdit.uf;
      employee.complemento.value = onEdit.complemento;
      employee.email.value = onEdit.email;
      employee.nome_mae.value = onEdit.nome_mae;
      employee.nome_pai.value = onEdit.nome_pai;
      employee.status.value = onEdit.status;
      employee.function.value = onEdit.function;
      
    };
  };
  // eslint-disable-next-line
  }, []);

  const handleDelete = async (e) => {
    e.preventDefault();
    const employee = ref.current;
    handleOpenL();

    if (!window.confirm("Tem certeza que deseja excluir?")) {
      handleCloseL();
      return;
    } else {
    await axios
     .delete("employees/employees-list/"+employee.cod.value)
     .then(() => {
        toast.success("Cadastro excluido com sucesso!");
        getEmployees();
        setOnEdit(null);
        handleClose();
     })
     .catch(() => {
      handleCloseL();
      toast.error("Erro, não foi possível excluir!");});
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    handleOpenL();
  
    const employee = ref.current;
   
    if (
      !employee.nome.value 
    ) {
      handleCloseL();
      return toast.warn("Preencha o campos Nome !");
    };
    if (
      !employee.telefone.value 
    ) {
      handleCloseL();
      return toast.warn("Preencha o campos Telefone !");
    };
    if (
      !employee.cpf.value 
    ) {
      handleCloseL();
      return toast.warn("Preencha o campos CPF !");
    };
    if (
      !employee.n_cnh.value 
    ) {
      handleCloseL();
      return toast.warn("Preencha o campos Número CNH !");
    }
    if (
      !employee.function.value 
    ) {
      handleCloseL();
      return toast.warn("Preencha o campos Função !");
    }

    if (onEdit) {
      await axios
        .put("employees/employees-list/", {
          id: employee.cod.value,
          nome: employee.nome.value.trim(),
          telefone: employee.telefone.value.trim(),
          cpf: employee.cpf.value.trim(),
          rg: employee.rg.value.trim(), 
          n_cnh: employee.n_cnh.value.trim(), 
          categoria_cnh: employee.categoria_cnh.value.trim(), 
          vencimento_cnh: employee.vencimento_cnh.value,
          venc_exame_toxicologico: employee.venc_exame_toxicologico.value, 
          matricula: employee.matricula.value.trim(),
          data_nacimento: employee.data_nacimento.value,
          tipo_sanguineo: employee.tipo_sanguineo.value,
          cep: employee.cep.value.trim(),
          endereco: employee.endereco.value.trim(),
          numero: employee.numero.value.trim(), 
          cidade: employee.cidade.value.trim(),
          bairro: employee.bairro.value.trim(),
          uf: employee.uf.value,
          complemento: employee.complemento.value.trim(),
          email: employee.email.value.trim(),
          nome_mae: employee.nome_mae.value.trim(),
          nome_pai: employee.nome_pai.value.trim(),
          status: employee.status.value,
          function: employee.function.value,
          id_company: IdCompany,
          user: IdUser,
        })
        .then(() => {
          toast.success("Cadastro atualizado com sucesso!");

          limparCampos();
          
          getEmployees();
          setOnEdit(null);
          handleClose();
        })
        .catch(() => {
          toast.error("Erro na atualizado!");
          handleCloseL();
        });
    } else {
      await axios
        .post("employees/employees-list/", {
          nome: employee.nome.value.trim(),
          telefone: employee.telefone.value.trim(),
          cpf: employee.cpf.value.trim(),
          rg: employee.rg.value.trim(), 
          n_cnh: employee.n_cnh.value.trim(), 
          categoria_cnh: employee.categoria_cnh.value.trim(), 
          vencimento_cnh: employee.vencimento_cnh.value,
          venc_exame_toxicologico: employee.venc_exame_toxicologico.value,
          matricula: employee.matricula.value.trim(),
          data_nacimento: employee.data_nacimento.value,
          tipo_sanguineo: employee.tipo_sanguineo.value,
          cep: employee.cep.value.trim(),
          endereco: employee.endereco.value.trim(),
          numero: employee.numero.value.trim(), 
          cidade: employee.cidade.value.trim(),
          bairro: employee.bairro.value.trim(),
          uf: employee.uf.value,
          complemento: employee.complemento.value.trim(),
          email: employee.email.value.trim(),
          nome_mae: employee.nome_mae.value.trim(),
          nome_pai: employee.nome_pai.value.trim(),
          status: employee.status.value,
          function: employee.function.value,
          id_company: IdCompany,
          user: IdUser,
        })
        .then(() => {
          toast.success("Cadastrado com sucesso!");
          
          limparCampos();

          getEmployees();
          setOnEdit(null);
          handleClose();
        })
        .catch(() => {
          toast.error("Erro no Cadastro!");
          handleCloseL();
        });
        
    }


  };

  return (
    <>
          <Typography style={{ color: "#fff" }} id="modal-modal-title" variant="h6" component="h2">
            Ficha do Funcionário
          </Typography>
          <FormContainer  ref={ref} onSubmit={handleSubmit}>
            <InputArea>
              <Label>Código</Label>
              <Input style={{color:"#fff"}} disabled={true} name="cod"  />
            </InputArea>
            <InputArea>
              <Label>Situação</Label>
              <Select1 name="status">
                <option value={1}>Ativo</option>
                <option value={0}>Inativo</option>
              </Select1>
            </InputArea>
            <InputArea>
              <Label>Função*</Label>
              <Select1 name="function">
                <option value="">Selecione</option>
                <option value="Motorista">Motorista</option>
                <option value="Tecnico">Técnico</option>
                <option value="Abastecedor">Abastecedor</option>
              </Select1>
            </InputArea>
            <InputArea>
              <Label>Nome*</Label>
              <Input2 style={{ width: "350px" }} placeholder="Digite nome completo" name="nome" />
            </InputArea>
            <InputArea>
              <Label>Telefone*</Label>
              <Input type="number" pattern="/([0-9]{3}).([0-9]{3}),([0-9]{2})$/g, '.$1.$2,$3'" min="0" step="any" placeholder="Digite só número" name="telefone" />
            </InputArea>
            <InputArea>
              <Label>CPF*</Label>
              <Input type="number" pattern="[0-9]+([,\.][0-9]+)?" min="0" step="any" placeholder="Digite só número" name="cpf" />
            </InputArea>
            <InputArea>
              <Label>RG</Label>
              <Input type="number" pattern="[0-9]+([,\.][0-9]+)?" min="0" step="any" placeholder="Digite só número" name="rg" />
            </InputArea>
            <InputArea>
              <Label>Número CNH*</Label>
              <Input type="number" pattern="[0-9]+([,\.][0-9]+)?" min="0" step="any" placeholder="Digite só número" name="n_cnh" />
            </InputArea>
            <InputArea>
              <Label>Categoria CNH</Label>
              <Select1  name="categoria_cnh">
                <option value="">Selecione</option>
                <option value="A">Categoria A</option>
                <option value="B">Categoria B</option>
                <option value="AB">Categoria AB</option>
                <option value="C">Categoria C</option>
                <option value="AC">Categoria AC</option>
                <option value="D">Categoria D</option>
                <option value="AD">Categoria AD</option>
                <option value="E">Categoria E</option>
                <option value="AE">Categoria AE</option>
              </Select1>
            </InputArea>
            <InputArea>
              <Label>Vencimento CNH</Label>
              <InputDate type="date" name="vencimento_cnh" />
            </InputArea>
            <InputArea>
              <Label>Venc. Exame Toxicológico</Label>
              <InputDate type="date" name="venc_exame_toxicologico" />
            </InputArea>
            <InputArea>
              <Label>Matrícula</Label>
              <Input type="number" pattern="[0-9]+([,\.][0-9]+)?" min="0" step="any" placeholder="Digite só número" name="matricula" />
            </InputArea>
            <InputArea>
              <Label>Data de nacimento</Label>
              <InputDate type="date" name="data_nacimento" />
            </InputArea>
            <InputArea>
              <Label>Tipo sanguíneo</Label>
              <Select1  name="tipo_sanguineo">
                <option value="">Selecione</option>
                <option value="A-">A-</option>
                <option value="A+">A+</option>
                <option value="B-">B-</option>
                <option value="B+">B+</option>
                <option value="AB-">AB-</option>
                <option value="AB+">AB+</option>
                <option value="O-">O-</option>
                <option value="O+">O+</option>
              </Select1>
            </InputArea>
            <InputArea>
              <Label>CEP</Label>
              <Input type="number" pattern="[0-9]+([,\.][0-9]+)?" min="0" step="any" placeholder="Digite só número" name="cep" />
            </InputArea>
            <InputArea>
              <Label>Cidade</Label>
              <Input name="cidade" />
            </InputArea>
            <InputArea>
              <Label>Estado</Label>
              <Select1  name="uf">
                <option value="">Selecione UF</option>
                <option value="AC">AC</option>
                <option value="AL">AL</option>
                <option value="AM">AM</option>
                <option value="AP">AP</option>
                <option value="BA">BA</option>
                <option value="CE">CE</option>
                <option value="DF">DF</option>
                <option value="ES">ES</option>
                <option value="GO">GO</option>
                <option value="MA">MA</option>
                <option value="MG">MG</option>
                <option value="MS">MS</option>
                <option value="MT">MT</option>
                <option value="PA">PA</option>
                <option value="PB">PB</option>
                <option value="PE">PE</option>
                <option value="PI">PI</option>
                <option value="PR">PR</option>
                <option value="RJ">RJ</option>
                <option value="RN">RN</option>
                <option value="RO">RO</option>
                <option value="RR">RR</option>
                <option value="RS">RS</option>
                <option value="SC">SC</option>
                <option value="SE">SE</option>
                <option value="SP">SP</option>
                <option value="TO">TO</option>
              </Select1>
            </InputArea>
            <InputArea>
              <Label>Endereço</Label>
              <Input2 style={{ width: "350px" }} name="endereco" />
            </InputArea>
            <InputArea>
              <Label>Número</Label>
              <Input type="number" pattern="[0-9]+([,\.][0-9]+)?" min="0" step="any" placeholder="Digite só número" name="numero" />
            </InputArea>
            <InputArea>
              <Label>Bairro</Label>
              <Input name="bairro" />
            </InputArea>
            <InputArea>
              <Label>Complemento</Label>
              <Input2 style={{ width: "350px" }} name="complemento" />
            </InputArea>
            <InputArea>
              <Label>E-mail</Label>
              <InputEmail name="email" />
            </InputArea>
            <InputArea>
              <Label>Nome da mãe</Label>
              <Input2 style={{ width: "350px" }} name="nome_mae" />
            </InputArea>
            <InputArea>
              <Label>Nome da pai</Label>
              <Input2 style={{ width: "350px" }} name="nome_pai" />
            </InputArea>
            
            <div style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }} >
              <ButtonArea >
                <Button type="submit">SALVAR</Button>
                { regist === true? 
                 null
                 : 
                 <Button 
                    style={{backgroundColor:"red"}} 
                    onClick={handleDelete}>EXCLUIR <DeleteIcon/>
                 </Button>
                 }
                
                <Button onClick={handleClose}>SAIR</Button>
              </ButtonArea>
            </div>

          </FormContainer>
          
      <Backdrop
              sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 5}}
              open={openL}
          >
         <Label>Carregando...</Label>
              <CircularProgress color="inherit" />
          </Backdrop>
    </>
  );
};

export default FromEmployees;