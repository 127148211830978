import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 100vh;
  min-width: 100vw;
  background-color: #383838;
  font-family: 'Inter', sans-serif;
`
export const Img = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 5px;
  object-fit: cover;
`

export const ImgLogo = styled.img`
  display: flex;
  justify-content: center;
  width: 100px;
  height: 100px;
  padding: 5px;
  border-radius: 3px;
`

export const From = styled.form`
  display: flex;
  position: absolute;
  padding: 1rem;
  margin-right: 5px;
  margin-left: 5px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: rgba(6, 47, 79, 0.5);
  border-radius: 5px;
  height: 95vh;
  width: 100vw;
  max-width: 400px;
  max-height: 100%;
  gap: 10px;

  h1 {
    color: white;
    font-size: 20px;
  }

  h6 {
    color: white;
    font-size: 14px;
  }

  p {
    color: #fff;
    font-size: 10px;
    font-weight: lighter;
  }

  a {
    color: white;
    font-size: 14px; 
    text-decoration: none;
    font-weight: lighter;
  }
`