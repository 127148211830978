
import React, { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import { signout } from "../../Services/UserService";
import ContentPage from "../../components/Content/ContentPage";
import ModalMaintenanceControl from "../../components/MaintenanceControl/ModalMaintenanceControl";
import GridMaintenanceControl from "../../components/MaintenanceControl/GridMaintenanceControl";
import { toast } from "react-toastify";
import axios from "axios";

import {
  FormContainer,
  Loc,
  InputArea,
  Button,
  Input,
  Label,
  Select1
} from "../Styles/Styles"

const MaintenanceControlList = () => {
  const navigate = useNavigate();
  const [maintenance, setMaintenance] = useState([]);
  const [onEdit, setOnEdit] = useState(null);
  const [open, setOpen] = React.useState(false);
  const [loc, setLoc] = React.useState();
  const [regist, setRegist] = React.useState(false);
  const IdCompany = localStorage.getItem("id_company");
  const [vehicles, setVehicles] = useState([]);
  const status = React.useRef(1);

  const [openL, setOpenL] = React.useState(false);

  function adicionaZero(numero) {
    if (numero <= 9)
      return "0" + numero;
    else
      return numero;
  }

  const onStatusChange = (event) => {
    status.current = event.target.value;
  }

  let dataAtual = new Date();
  let dataAtualFormatada = (dataAtual.getFullYear() + "-" + (adicionaZero(dataAtual.getMonth() + 1).toString()) + "-" + adicionaZero(dataAtual.getDate().toString()));

  const [locDate, setLocDate] = React.useState(dataAtualFormatada);
  const [locDateF, setLocDateF] = React.useState(dataAtualFormatada);

  const handleCloseL = () => {
    setOpenL(false);
  };
  const handleOpenL = () => {
    setOpenL(true);
  };


  const handleOpen = () => {
    setOpen(true);
    setRegist(true);
  };

  const getMaintenance = async () => {
    handleOpenL();
    setMaintenance([]);
    setOnEdit(null);
    try {
      const res = await axios.get("maintenance-control/maintenance-control-list/" +
        IdCompany + "/" + status.current + "/" + locDate + "/" + locDateF + "/?loc=" + (loc === undefined ? '%' : loc));
      if (res.data[0].status === "false") {
        toast.error(res.data[0].message);
        signout();
        navigate("/login");
      } else {
        setMaintenance(res.data.sort((a, b) => (a.id > b.id ? 1 : -1)));
      }
      handleCloseL();
    } catch (error) {
      toast.error(error);
      handleCloseL();
    }
  };

  const getVehicles = async () => {
    handleOpenL();
    setVehicles([]);
    setOnEdit(null);
    try {
      const res = await axios.get("vehicle/vehicle-list/" + IdCompany + "/?loc=%");
      if (res.data[0].status === "false") {
        toast.error(res.data[0].message);
        signout();
        navigate("/login");
      } else {
        setVehicles(res.data.sort((a, b) => (a.id > b.id ? 1 : -1)));
      }
      handleCloseL();
    } catch (error) {
      toast.error(error);
      handleCloseL();
    }
  };

  useEffect(() => {
    getMaintenance();
    getVehicles();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <ContentPage caminho={[{ nome: "Lançamentos > Controle Manutenção", link: "/maintenance-control/list" }]}>

        <ModalMaintenanceControl
          regist={regist}
          maintenance={maintenance}
          setMaintenance={setMaintenance}
          setLoc={setLoc}
          loc={loc}
          onEdit={onEdit}
          setOnEdit={setOnEdit}
          getMaintenance={getMaintenance}
          setOpen={setOpen}
          open={open}
          openL={openL}
          handleOpenL={handleOpenL}
          handleCloseL={handleCloseL}
          vehicles={vehicles}
        />

        <FormContainer>
          <InputArea>
            <Select1 onChange={onStatusChange} name="st">
              <option value={1}>Pendente</option>
              <option value={2}>Realizada</option>
            </Select1>
          </InputArea>
          <Loc
            style={{
              width: "300px",
              height: "30px",
              border: "1px solid #343f46",
              textTransform: "uppercase",
              fontSize: "12px"
            }}
            type='text'
            placeholder="Digite apelido do veículo"
            value={loc}
            onChange={(e) => [setLoc(e.target.value)]}
          />
          <InputArea>
            <Input type="date"
              value={locDate}
              onChange={(e) => [setLocDate(e.target.value)]}
            />
          </InputArea>

          <InputArea>
            <Label style={{ color: "#242a38" }}>à</Label>
          </InputArea>

          <InputArea>
            <Input type="date"
              value={locDateF}
              onChange={(e) => [setLocDateF(e.target.value)]}
            />
          </InputArea>

          <InputArea >
            <Button
              onClick={getMaintenance}>Localizar
            </Button>
          </InputArea>
          <InputArea  >
            <Button
              onClick={handleOpen}>Novo
            </Button>
          </InputArea>
        </FormContainer>

        <GridMaintenanceControl
          openL={openL}
          regist={regist}
          setRegist={setRegist}
          maintenance={maintenance}
          setMaintenance={setMaintenance}
          setOnEdit={setOnEdit}
          setOpen={setOpen}
        />
      </ContentPage>
    </>
  );
};

export default MaintenanceControlList;