
import React, { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import { signout } from "../../../Services/UserService";
import ContentPage from "../../../components/Content/ContentPage";
import ModalAxisSchema from "../../../components/Tires/AxisSchemaRegistration/ModalAxisSchema";
import GridAxisSchema from "../../../components/Tires/AxisSchemaRegistration/GridAxisSchema";
import { toast } from "react-toastify";
import axios from "axios";

import {
  FormContainer,
  Loc,
  InputArea,
  Button
} from "../../Styles/Styles";

const AxisSchemaRegistrationList = () => {
  const navigate = useNavigate();
  const [axisSchema, setAxisSchema] = useState([]);
  const [onEdit, setOnEdit] = useState(null);
  const [open, setOpen] = React.useState(false);
  const [loc, setLoc] = React.useState();
  const [regist, setRegist] = React.useState(false);
  const IdCompany = localStorage.getItem("id_company");

  const [openL, setOpenL] = React.useState(false);

  const handleCloseL = () => {
    setOpenL(false);
  };
  const handleOpenL = () => {
    setOpenL(true);
  };


  const handleOpen = () => {
    setOpen(true);
    setRegist(true);
  };

  const getAxisSchema = async () => {
    handleOpenL();
    setAxisSchema([]);
    setOnEdit(null);
    try {
      const res = await axios.get("tires/axis-schema-list/" + IdCompany + "/?loc=" + (loc === undefined ? '%' : loc));
      if (res.data[0].status === "false") {
        toast.error(res.data[0].message);
        signout();
        navigate("/login");
      } else {
        setAxisSchema(res.data.sort((a, b) => (a.id > b.id ? 1 : -1)));
      }
      handleCloseL();
    } catch (error) {
      toast.error(error);
      handleCloseL();
    }
  };

  useEffect(() => {
    getAxisSchema();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <ContentPage caminho={[{ nome: "Pneu > Esquema de Eixo ", link: "/axisSchema-registration/list" }]}>

        <ModalAxisSchema
          regist={regist}
          axisSchema={axisSchema}
          setAxisSchema={setAxisSchema}
          setLoc={setLoc}
          loc={loc}
          onEdit={onEdit}
          setOnEdit={setOnEdit}
          getAxisSchema={getAxisSchema}
          setOpen={setOpen}
          open={open}
          openL={openL}
          handleOpenL={handleOpenL}
          handleCloseL={handleCloseL}
        />

        <FormContainer>
          <Loc
            style={{
              width: "350px",
              height: "30px",
              border: "1px solid #343f46",
              textTransform: "uppercase",
              fontSize: "12px"
            }}
            type='text'
            placeholder="Digite nome"
            value={loc}
            onChange={(e) => [setLoc(e.target.value)]}
          />

          <InputArea >
            <Button
              onClick={getAxisSchema}>Localizar
            </Button>
          </InputArea>
          <InputArea  >
            <Button
              onClick={handleOpen}>Novo
            </Button>
          </InputArea>
        </FormContainer>

        <GridAxisSchema openL={openL} regist={regist} setRegist={setRegist} axisSchema={axisSchema} setAxisSchema={setAxisSchema} setOnEdit={setOnEdit} setOpen={setOpen} />
      </ContentPage>
    </>
  );
};

export default AxisSchemaRegistrationList;