import React, { useEffect, useRef } from "react";
import axios from "axios";
import {toast } from "react-toastify";
import Typography from '@mui/material/Typography';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import DeleteIcon from '@mui/icons-material/Delete';

import { 
  FormContainer, 
  InputArea, 
  Label, 
  Input, 
  Select1, 
  InputEmail, 
  InputDate, 
  ButtonArea, 
  Button 
} from "../Styles/Styles";


const FromCompany = ({handleCloseL, openL, handleOpenL, regist, onEdit, setOnEdit, getCompany, setOpen }) => {
  const ref = useRef();
  const handleClose = () => setOpen(false);
  const IdCompany = localStorage.getItem("id_company");
  const IdUser = localStorage.getItem("id");

  const limparCampos = () => {
    const company = ref.current;

    company.cod.value = "";
    company.id_company.value = "";
    company.name.value = ""; 
    company.fantasy.value = "";
    company.cnpj.value = "";
    company.address.value = "";
    company.number.value = ""; 
    company.neighborhood.value = "";
    company.complement.value = "";
    company.city.value = "";
    company.uf.value = "";
    company.cep.value = "";
    company.telephone.value = "";
    company.email.value = "";
    company.st.value = "1";
  }

  useEffect(() => {
    const company = ref.current;
      if(regist) {
       limparCampos();
    } else {
    if (onEdit) {
      
      company.cod.value = onEdit.id;
      company.id_company.value = onEdit.id_company;
      company.name.value = onEdit.name; 
      company.fantasy.value = onEdit.fantasy;
      company.cnpj.value = onEdit.cnpj;
      company.address.value = onEdit.address;
      company.number.value = onEdit.number; 
      company.neighborhood.value = onEdit.neighborhood;
      company.complement.value = onEdit.complement;
      company.city.value = onEdit.city;
      company.uf.value = onEdit.uf;
      company.cep.value = onEdit.cep;
      company.telephone.value = onEdit.telephone;
      company.email.value = onEdit.email;
      company.st.value = onEdit.st;
      
    };
  };
  // eslint-disable-next-line
  }, []);

  const handleDelete = async (e) => {
    e.preventDefault();
    const company = ref.current;
    handleOpenL();

    if(company.cod.value === company.id_company.value) {
      handleCloseL();
      return toast.warn("Não é possível excluir a empresa!");
    }

    if (!window.confirm("Tem certeza que deseja excluir?")) {
      handleCloseL();
      return;
    } else {
    await axios
     .delete("company/company-list/" + company.cod.value)
     .then(() => {
        toast.success("Cadastro excluido com sucesso!");
        getCompany();
        setOnEdit(null);
        handleClose();
     })
     .catch(() => toast.error("Erro, não foi possível excluir!"));
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    handleOpenL();
  
    const company = ref.current;
   
    if (
      !company.name.value 
    ) {
      handleCloseL();
      return toast.warn("Preencha o campos Nome !");
    };
    if (
      !company.fantasy.value 
    ) {
      handleCloseL();
      return toast.warn("Preencha o campos Fantasia !");
    };
    if (
      !company.cnpj.value 
    ) {
      handleCloseL();
      return toast.warn("Preencha o campos CNPJ !");
    };

    if (onEdit) {
      await axios
        .put("company/company-list/", {
          id: company.cod.value,
          id_company: IdCompany,
          name: company.name.value.trim(), 
          fantasy: company.fantasy.value.trim(),
          cnpj: company.cnpj.value.trim(),
          address: company.address.value.trim(),
          number: company.number.value.trim(), 
          neighborhood: company.neighborhood.value.trim(),
          complement: company.complement.value.trim(),
          city: company.city.value.trim(),
          uf: company.uf.value,
          cep: company.cep.value.trim(),
          telephone: company.telephone.value.trim(),
          email: company.email.value.trim(),
          st: company.st.value,
          user: IdUser,
        })
        .then(() => {
          toast.success("Cadastro atualizado com sucesso!");

          limparCampos();
          
          getCompany();
          setOnEdit(null);
          handleClose();
        })
        .catch(() => {
          toast.error("Erro na atualizado!");
          handleCloseL();
        });
    } else {
      await axios
        .post("company/company-list/", { 
          id_company: IdCompany,
          name: company.name.value.trim(), 
          fantasy: company.fantasy.value.trim(),
          cnpj: company.cnpj.value.trim(),
          address: company.address.value.trim(),
          number: company.number.value.trim(), 
          neighborhood: company.neighborhood.value.trim(),
          complement: company.complement.value.trim(),
          city: company.city.value.trim(),
          uf: company.uf.value,
          cep: company.cep.value.trim(),
          telephone: company.telephone.value.trim(),
          email: company.email.value.trim(),
          st: company.st.value,
          user: IdUser,

        })
        .then(() => {
          toast.success("Cadastrado com sucesso!");
          limparCampos();
          getCompany();
          setOnEdit(null);
          handleClose();
        })
        .catch(() => {
          toast.error("Erro no Cadastro!");
          handleCloseL();
        });
        
    }


  };

  return (
    <>
          <Typography style={{ color: "#fff" }} id="modal-modal-title" variant="h6" component="h2">
            Ficha da Empresa
          </Typography>
          <FormContainer  ref={ref} onSubmit={handleSubmit}>
            <InputArea>
              <Label>Código</Label>
              <Input style={{ color: "#fff" }} disabled={true} name="cod"  />
            </InputArea>
            <InputArea>
              <Label>Cód. Empresa</Label>
              <Input style={{color:"#fff"}} disabled="false" name="id_company"  />
            </InputArea>
            <InputArea>
              <Label>Situação</Label>
              <Select1 name="st">
                <option value={1}>Ativo</option>
                <option value={0}>Inativo</option>
              </Select1>
            </InputArea>
            <InputArea>
              <Label>Nome*</Label>
              <Input style={{ width: "350px" }} name="name" />
            </InputArea>
            <InputArea>
              <Label>Fantasia*</Label>
              <Input style={{ width: "350px" }} name="fantasy" />
            </InputArea>
            <InputArea>
              <Label>CNPJ</Label>
              <Input name="cnpj" />
            </InputArea>
            <InputArea>
              <Label>Endereço</Label>
              <Input style={{ width: "350px" }} name="address" />
            </InputArea>
            <InputArea>
              <Label>Número</Label>
              <Input name="number" type="number" pattern="[0-9]+([,\.][0-9]+)?" min="0" step="any" placeholder="Digite só número"/>
            </InputArea>
            <InputArea>
              <Label>Bairro</Label>
              <Input name="neighborhood" />
            </InputArea>
            <InputArea>
              <Label>Complemento</Label>
              <Input style={{ width: "350px" }} name="complement" />
            </InputArea>
            <InputArea>
              <Label>Cidade</Label>
              <Input name="city" />
            </InputArea>
            <InputArea>
              <Label>UF</Label>
              <Select1  name="uf">
                <option value="">Selecione UF</option>
                <option value="AC">AC</option>
                <option value="AL">AL</option>
                <option value="AM">AM</option>
                <option value="AP">AP</option>
                <option value="BA">BA</option>
                <option value="CE">CE</option>
                <option value="DF">DF</option>
                <option value="ES">ES</option>
                <option value="GO">GO</option>
                <option value="MA">MA</option>
                <option value="MG">MG</option>
                <option value="MS">MS</option>
                <option value="MT">MT</option>
                <option value="PA">PA</option>
                <option value="PB">PB</option>
                <option value="PE">PE</option>
                <option value="PI">PI</option>
                <option value="PR">PR</option>
                <option value="RJ">RJ</option>
                <option value="RN">RN</option>
                <option value="RO">RO</option>
                <option value="RR">RR</option>
                <option value="RS">RS</option>
                <option value="SC">SC</option>
                <option value="SE">SE</option>
                <option value="SP">SP</option>
                <option value="TO">TO</option>
              </Select1>
            </InputArea>
            <InputArea>
              <Label>CEP</Label>
              <InputDate name="cep" />
            </InputArea>
            <InputArea>
              <Label>Telefone</Label>
              <Input name="telephone" />
            </InputArea>
            <InputArea>
              <Label>E-mail</Label>
              <InputEmail name="email" />
            </InputArea>
                        
            <div style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }} >
              <ButtonArea >
                <Button type="submit">SALVAR</Button>
                { regist === true? 
                 null
                 : 
                 <Button 
                    style={{backgroundColor:"red"}} 
                    onClick={handleDelete}>EXCLUIR <DeleteIcon/>
                 </Button>
                 }
                
                <Button onClick={handleClose}>SAIR</Button>
              </ButtonArea>
            </div>

          </FormContainer>
          
      <Backdrop
              sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 5}}
              open={openL}
          >
         <Label>Carregando...</Label>
              <CircularProgress color="inherit" />
          </Backdrop>
    </>
  );

};


export default FromCompany;