import * as React from 'react';
import { GridActionsCellItem } from '@mui/x-data-grid';
import EditIcon from '@mui/icons-material/Edit';

import { DataGrid, GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';
import { useDemoData } from '@mui/x-data-grid-generator';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import {geraIterAleatoria} from "../Function/Base";
import ModalEdit from './ModalEdit';

import { 
  Table, 
  Tbody, 
  Label
} from "../Styles/Styles";

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarExport />
    </GridToolbarContainer>
  );
}


const GridXML = ({openL, xmlProd, setOpen, setDadosProd, noteCode, issuer, onEdit }) => {
  const IdCompany = localStorage.getItem("id_company");
  const IdUser = localStorage.getItem("id");
  const [dataEdit, setDataEdit] = React.useState([])
  const [openModal, setOpenModal] = React.useState(false);
  const notRef = React.useRef();

  var data = null; 

  const alterItem = React.useCallback(
    (cProd) => () => {
      if(xmlProd.length === undefined) {
        handleEdit(xmlProd);
      } else {
        const res = xmlProd.filter((a) => (a.prod.cProd === cProd.row.cProd));
        // eslint-disable-next-line
        res.map((a) => {
         handleEdit(a.prod);
        });
      }
    },
    [xmlProd],
  );

  React.useEffect (() => {

    if (xmlProd === undefined) {
      return;
    } else {
      setDadosProd(data.rows);
      notRef.current = data.rows;
    }
  }, [xmlProd, setOpen]);
  
  if(onEdit){

      data = {
        columns: [
          { field: 'id', headerName: 'Item', width: 50 },
          { field: 'cEAN', headerName: 'Cód. Barras', width: 150 },
          { field: 'cProd', headerName: 'Cód. Forn', width: 150 },
          { field: 'xProd', headerName: 'Produto', width: 500 },
          { field: 'NCM', headerName: 'NCM', width: 100 },
          { field: 'CFOP', headerName: 'CFOP', width: 60 },
          { field: 'uTrib', headerName: 'UN', width: 60 },
          { field: 'vUnCom', headerName: 'Vlr.UN', width: 150},
          { field: 'qCom', headerName: 'QTde', width: 100},
          { field: 'vProd', headerName: 'TotalR$', width: 150},
          
        ],
  
        rows: xmlProd.length === undefined? [] : xmlProd.map((item, index) => ({
          id: parseFloat(index) + 1,
          cEAN: item.cEAN,
          cProd: item.cProd,
          xProd: item.xProd,
          uTrib: item.uTrib,
          CFOP: item.CFOP,
          NCM: item.NCM,
          vUnCom: item.vUnCom,
          qCom: item.qCom,
          vProd: item.vProd,
          CEST: item.CEST === undefined? 0 : item.CEST,
          note_code: noteCode,
          supplier: issuer,
          id_company: IdCompany,
          user: IdUser,
        }))
      };

  } else {

  if (xmlProd.length === undefined) {
    data = {
     columns: [
       {
         field: 'actions',
         type: 'actions',
         headerName: 'Ações',
         width: 80,
         getActions: (params) => [
           <GridActionsCellItem
             icon={<EditIcon style={{
               color: "#2c73d2"}} />}
                label="edit"
             onClick={alterItem(params)}
           />,
         ],
       },
       { field: 'id', headerName: 'Item', width: 50 },
       { field: 'cEAN', headerName: 'Cód. Barras', width: 150 },
       { field: 'cProd', headerName: 'Cód. Forn', width: 150 },
       { field: 'xProd', headerName: 'Produto', width: 500 },
       { field: 'NCM', headerName: 'NCM', width: 100 },
       { field: 'CFOP', headerName: 'CFOP', width: 60 },
       { field: 'uTrib', headerName: 'UN', width: 60 },
       { field: 'vUnCom', headerName: 'Vlr.UN', width: 150},
       { field: 'qCom', headerName: 'QTde', width: 100},
       { field: 'vProd', headerName: 'TotalR$', width: 150},
     ],

      rows: xmlProd === undefined? []: [{
      id: 1,
      cEAN: xmlProd.prod.cEAN  === 'SEM GTIN' ? 968+geraIterAleatoria(10) : xmlProd.prod.cEAN,
      cProd: xmlProd.prod.cProd,
      xProd: xmlProd.prod.xProd,
      uTrib: xmlProd.prod.uTrib,
      CFOP: xmlProd.prod.CFOP,
      NCM: xmlProd.prod.NCM,
      vUnCom: xmlProd.prod.vUnCom,
      qCom: xmlProd.prod.qCom,
      vProd: xmlProd.prod.vProd,
      CEST: xmlProd.prod.CEST === undefined? 0 : xmlProd.prod.CEST,
      note_code: noteCode,
      supplier: issuer,
      id_company: IdCompany,
      user: IdUser,
      }] 
    };

  } else {
    data = {
      columns: [
        {
          field: 'actions',
          type: 'actions',
          headerName: 'Ações',
          width: 80,
          getActions: (params) => [
            <GridActionsCellItem
              icon={<EditIcon style={{
                color: "#2c73d2"}} />}
                label="edit"
              onClick={alterItem(params)}
            />,
          ],
        },
        { field: 'id', headerName: 'Item', width: 50 },
        { field: 'cEAN', headerName: 'Cód. Barras', width: 150,},
        { field: 'cProd', headerName: 'Cód. Forn', width: 150 },
        { field: 'xProd', headerName: 'Produto', width: 500 },
        { field: 'NCM', headerName: 'NCM', width: 100 },
        { field: 'CFOP', headerName: 'CFOP', width: 60 },
        { field: 'uTrib', headerName: 'UN', width: 60 },
        { field: 'vUnCom', headerName: 'Vlr.UN', width: 150},
        { field: 'qCom', headerName: 'QTde', width: 100},
        { field: 'vProd', headerName: 'TotalR$', width: 150},
      ],

      rows: xmlProd.length === undefined? [] : xmlProd.map((item, index) => ({
        id: parseFloat(index) + 1,
        cEAN: item.prod.cEAN === 'SEM GTIN' ? 968+geraIterAleatoria(10) : item.prod.cEAN,
        cProd: item.prod.cProd,
        xProd: item.prod.xProd,
        uTrib: item.prod.uTrib,
        CFOP: item.prod.CFOP,
        NCM: item.prod.NCM,
        vUnCom: item.prod.vUnCom,
        qCom: item.prod.qCom,
        vProd: item.prod.vProd,
        CEST: item.prod.CEST === undefined? 0 : item.prod.CEST,
        note_code: noteCode,
        supplier: issuer,
        id_company: IdCompany,
        user: IdUser,
      }))
    };
    
  }

};

  const { loading } = useDemoData({
    dataSet: 'Commodity',
    rowLength: 10,
    maxColumns: 6,
  });

  const handleEdit = (item) => {
    setDataEdit(item);
    setOpenModal(true);
  };



  return (
    <>
    <Table onlyWeb>
      <Tbody style={{height: "calc(100vh - 270px)"}} > 
      <DataGrid
        {...data}
        loading={loading}
        rowHeight = {30}
        slots={{
          toolbar: CustomToolbar,
        }}
      />
      </Tbody>
    </Table>
    <div style={{
      padding: "5px 20px",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    }}>
    <ModalEdit dataEdit={dataEdit} openModal={openModal} setOpenModal={setOpenModal} xmlProd={xmlProd} notRef={notRef.current} />
    </div>
    <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 5}}
        open={openL}
    >
         <Label>Carregando...</Label>
        <CircularProgress color="inherit" />
    </Backdrop>
    </>
  );
};

export default GridXML;