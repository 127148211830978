import React,{ useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import Navbar from "../Nabar/Navbar";
import ItemMenu from "./ItemMenu";
import "./Menu.style.css";
import {signout} from "../../Services/UserService";


const Menu = ({ children }) => {
    const [open, setOpen] = useState( 'aberto');
    const company = localStorage.getItem('company');
    const screenWidth = window.screen.width;
    
    const handleMenu = () => {
        (open === 'aberto') ? setOpen('fechado') : setOpen('aberto');
    };

    useEffect(() => {
        screenWidth < "500"? setOpen('fechado') : setOpen('aberto');
    }, []);

    return (
        <>
            <div className={`menu ${open}`}> 
             <div className="div-logo" >
              <img src="/images/user.png" className="logo" />
             </div>    
             <div className="div-company" >
                  <span className="company" >{company}</span>
             </div> 
                
                <ul className="conteudo-menu">
                    {/* Menu sem Dropdow */}
                    <li className="item-menu">
                        <NavLink className="link-menu" to="/" onClick={screenWidth < "500"? handleMenu: null}>
                        <i class="fa-solid fa-layer-group"></i>
                            <span>Dashboard</span>
                        </NavLink>
                    </li>

                    {/* Menu com Dropdow */}
                    <ItemMenu title="Monitoramento" icon="fa-solid fa-map">
                        <li>
                            <NavLink className="link-menu" to="/Maps/Telemetry" onClick={screenWidth < "500"? handleMenu: null} >
                            <i class="fa fa-location-arrow"></i>
                                <span>Telemetria</span>
                            </NavLink>
                        </li>
                    </ItemMenu>
                    <ItemMenu title="Cadastros" icon="fa-regular fa-folder-open">
                        <li>
                            <NavLink className="link-menu" to="/Bombs/list" onClick={screenWidth < "500"? handleMenu: null} >
                            <i class="fa-solid fa-gas-pump"></i>
                                <span>Bomba Combustível</span>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink className="link-menu" to="/supplier/list" onClick={screenWidth < "500"? handleMenu: null} >
                            <i class="fa-regular fa-building"></i>
                                <span>Fornecedor</span>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink className="link-menu" to="/fuel/list" onClick={screenWidth < "500"? handleMenu: null} >
                            <i class="fa-solid fa-gas-pump"></i>
                                <span>Tipo Combustível</span>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink className="link-menu" to="/Employees/list" onClick={screenWidth < "500"? handleMenu: null} >
                                <i class="fa-solid fa-address-card"></i>
                                <span>Funcionários</span>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink className="link-menu" to="/lines/list" onClick={screenWidth < "500"? handleMenu: null} >
                            <i class="fa-solid fa-route"></i>
                                <span>Linhas</span>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink className="link-menu" to="/Vehicles/list" onClick={screenWidth < "500"? handleMenu: null} >
                            <i class="fa-solid fa-car"></i>
                                <span>Veículos</span>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink className="link-menu" to="/Company/list" onClick={screenWidth < "500"? handleMenu: null} >
                            <i class="fa-regular fa-building"></i>
                                <span>Empresa</span>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink className="link-menu" to="/users/list" onClick={screenWidth < "500"? handleMenu: null} >
                            <i class="fa-solid fa-users"></i>
                                <span>Usuários</span>
                            </NavLink>
                        </li>
                    </ItemMenu>
                    <ItemMenu title="Estoque" icon="fa-solid fa-box">
                        <li>
                            <NavLink className="link-menu" to="/Products-Services/list" onClick={screenWidth < "500"? handleMenu: null} >
                            <i class="fa-solid fa-barcode"></i>
                                <span>Produtos {<i class="fa-solid fa-wrench"></i>} Serviços</span>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink className="link-menu" to="/import-xml-products/list" onClick={screenWidth < "500"? handleMenu: null} >
                            <i class="fa-solid fa-barcode"></i>
                                <span>Nota Fisca de Entrada</span>
                            </NavLink>
                        </li>
                    </ItemMenu>
                    <ItemMenu title="Pneus" icon="fa-solid fa-compact-disc">
                        <li>
                            <NavLink className="link-menu" to="/axisSchema-registration/list" onClick={screenWidth < "500"? handleMenu: null} >
                            <i class="fa-solid fa-maximize"></i>
                                <span>Esquema de Eixo</span>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink className="link-menu" to="/tire-drawing/list" onClick={screenWidth < "500"? handleMenu: null} >
                            <i class="fa-solid fa-maximize"></i>
                                <span>Vida dos Pnues</span>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink className="link-menu" to="/reason-scrapping/list" onClick={screenWidth < "500"? handleMenu: null} >
                            <i class="fa-solid fa-maximize"></i>
                                <span>Motivos de Sucateamentos</span>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink className="link-menu" to="/tire-registration/list" onClick={screenWidth < "500"? handleMenu: null} >
                            <i class="fa-solid fa-compact-disc"></i>
                                <span>Cadastro de Pnes</span>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink className="link-menu" to="/tire-movement/list" onClick={screenWidth < "500"? handleMenu: null} >
                            <i class="fa-solid fa-compact-disc"></i>
                                <span>Movimentação de Pneus</span>
                            </NavLink>
                        </li>
                    </ItemMenu>
                    <ItemMenu title="Financeiro" icon="fa-solid fa-sack-dollar">
                        <li>
                            <NavLink className="link-menu" to="/bills-to-pay/list" onClick={screenWidth < "500"? handleMenu: null} >
                            <i class="fa-solid fa-maximize"></i>
                                <span>Contas a pagar</span>
                            </NavLink>
                        </li>
                    </ItemMenu>
                    <ItemMenu title="Lançamentos" icon="fa-brands fa-rev">
                        <li>
                            <NavLink className="link-menu" to="/ReleasesFuel/list" onClick={screenWidth < "500"? handleMenu: null} >
                            <i class="fa-solid fa-gas-pump"></i>
                                <span>Ordem Abastecimentos</span>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink className="link-menu" to="/launch-sales-ticket/list" onClick={screenWidth < "500"? handleMenu: null} >
                            <i class="fas fa-hand-holding-usd"></i>
                                <span>Ordem Vendas de passagem</span>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink className="link-menu" to="/maintenance-control/list" onClick={screenWidth < "500"? handleMenu: null} >
                            <i class="fa-solid fa-screwdriver-wrench"></i>
                                <span>Controle de Manutenção</span>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink className="link-menu" to="/Order-Of-Service/list" onClick={screenWidth < "500"? handleMenu: null} >
                            <i class="fa-solid fa-screwdriver-wrench"></i>
                                <span>Ordem Serviço - O.S</span>
                            </NavLink>
                        </li>
                    </ItemMenu>
                    <ItemMenu title="Relatórios" icon="fa-solid fa-print">
                        <li>
                            <NavLink className="link-menu" to="/Reports/Releases" onClick={screenWidth < "500"? handleMenu: null} >
                            <i class="fa-solid fa-gas-pump"></i>
                                <span>Abastecimentos</span>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink className="link-menu" to="/Reports/Launch-Sales-Ticket" onClick={screenWidth < "500"? handleMenu: null} >
                            <i class="fas fa-hand-holding-usd"></i>
                                <span>Vendas de passagem</span>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink className="link-menu" to="/Reports/MovTires" onClick={screenWidth < "500"? handleMenu: null} >
                            <i class="fa-solid fa-compact-disc"></i>
                                <span>Movimentação de Pneus</span>
                            </NavLink>
                        </li>
                    </ItemMenu>
                    <li className="item-menu">
                        <NavLink className="link-menu" to="/login" onClick={signout}>
                        <i class="fa-solid fa-power-off"></i>
                            <span>Sair</span>
                        </NavLink>
                    </li>
                </ul>
                
            </div>

            <div className={`site ${open}`}>
                <Navbar
                    tipoMenu={open}
                    handleMenu={handleMenu}
                />

                {children}
            </div>
        </>
    );
};

export default Menu;