import * as React from 'react';
import { useNavigate } from 'react-router-dom';
//import { signout } from "../../Services/UserService";
import axios from "axios";
import { BarChart } from '@mui/x-charts/BarChart';
import ContentPage from "../../components/Content/ContentPage";
import { Div } from './Style';
import { Gauge, gaugeClasses } from '@mui/x-charts/Gauge';
import {
  Typography,
  Grid,
} from '@mui/material';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { toast } from "react-toastify";
import {Label} from "../Styles/Styles.js";

const chartSetting = {
  height: 500,
  width: 1000,
  margin: { bottom: 150 },
  colors: ['#2c73d2']
};



const valueFormatter = (value) => `${value}LT`;

const Home = () => {
  const navigate = useNavigate();
  const [driver, setDriver] = React.useState([]);
  const [vehicle, setVehicles] = React.useState([]);
  const [supplie, setsupplie] = React.useState([]);
  const [dataVehicleFuel, setdataVehicleFuel] = React.useState([]);
  const [vencimentoCnh, setVencimentoCnh] = React.useState([]);
  const [documentExpiration, setDocumentExpiration] = React.useState([]);
  const IdCompany = localStorage.getItem("id_company");

  function adicionaZero(numero) {
    if (numero <= 9)
      return "0" + numero;
    else
      return numero;
  }

  const date = new Date();  // Ano, mês e dia
  const month = date.toLocaleString('default', { month: 'long' });
  const year = date.toLocaleString('default', { year: 'numeric' });


  const DateStart = ((date.getFullYear()) + "-01-01");
  const DateEnd = ((date.getFullYear()) + '-' + (adicionaZero(date.getMonth() + 1)) + '-' + adicionaZero(date.getDate()));

  const [openL, setOpenL] = React.useState(false);

  const handleCloseL = () => {
    setOpenL(false);
  };
  const handleOpenL = () => {
    setOpenL(true);
  };

  const getDados = async () => {
    handleOpenL();
    setDriver([]);
    setVehicles([]);
    setsupplie([]);

    try {
      const res = await axios.get("charts-dashboard/sum-count/" + IdCompany + "/" + DateStart + "/" + DateEnd);
    
      if (res.data[0].status === "false") {
        toast.error(res.data[0].message);
        navigate("/login");
      } else {
        res.data.map((item) => (
          // eslint-disable-next-line 
          setDriver(item.drivers),
          setVehicles(item.vehicles),
          setsupplie(Math.round(item.fuel_quantity).toLocaleString('pt-br', {minimumFractionDigits: 2})),
          setVencimentoCnh(item.vencimento_cnh),
          setDocumentExpiration(item.document_expiration)

        ))
        getDadosFuel();
      }
    } catch (error) {
      toast.error(error);
      handleCloseL();
    }
  };

  const getDadosFuel = async () => {
    handleOpenL();
    setdataVehicleFuel([]);

    try {
      const res = await axios.get("charts-dashboard/sum-plate/" + IdCompany + "/" + DateStart + "/" + DateEnd);
      
      if (res.data[0].status === "false") {
        toast.error(res.data[0].message);
        navigate("/login");
      } else {
        setdataVehicleFuel(res.data.map((item) => (
          {
            label: item.surname,
            value: parseInt(item.fuel_quantity),
          }
        )))
      }
      handleCloseL();
    } catch (error) {
      toast.error(error);
      handleCloseL();
    }
  };

  React.useEffect(() => {
    getDados();
    // eslint-disable-next-line
  }, []);


  const drivers = {
    width: 100,
    height: 100,
    value: driver,
  };
  const vehicles = {
    width: 100,
    height: 100,
    value: vehicle,
  };
  const vencimentoCnhs = {
    width: 100,
    height: 100,
    value: vencimentoCnh,
  };
  const documentExpirations = {
    width: 100,
    height: 100,
    value: documentExpiration,
  };

  return (
    <>
      <ContentPage caminho={[{ nome: "Dashboard", link: "/home" }]}>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Grid container item direction='row-reverse' spacing={2}>
            <Grid item xs={12} sm={12} md={6}>
              <Div style={{ backgroundColor: "transparent", padding: "10px" }}>
                <Typography style={{ color: "#2c73d2", fontSize: "12px" }} >Motoristas.:</Typography>
                <Gauge
                  {...drivers}
                  innerRadius= "50%"
                  outerRadius= "80%"
                  sx={(theme) => ({
                    [`& .${gaugeClasses.valueText}`]: {
                      fontSize: 18,
                    },
                    [`& .${gaugeClasses.valueArc}`]: {
                      fill: '#2c73d2',
                    },
                    [`& .${gaugeClasses.referenceArc}`]: {
                      fill: theme.palette.text.disabled,
                    },
                  })}
                />
                <Typography style={{ color: "#2c73d2", fontSize: "12px" }} >CNH a v.:</Typography>
                <Gauge
                  {...vencimentoCnhs}
                  innerRadius= "50%"
                  outerRadius= "80%"
                  sx={(theme) => ({
                    [`& .${gaugeClasses.valueText}`]: {
                      fontSize: 18,
                    },
                    [`& .${gaugeClasses.valueArc}`]: {
                      fill: '#2c73d2',
                    },
                    [`& .${gaugeClasses.referenceArc}`]: {
                      fill: theme.palette.text.disabled,
                    },
                  })}
                />
              </Div>
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <Div style={{ padding: "10px"}}>
                <Typography style={{ color: "#2c73d2", fontSize: "12px" }} >Veículos.:</Typography>
                <Gauge
                  {...vehicles}
                  innerRadius= "50%"
                  outerRadius= "80%"
                  sx={(theme) => ({
                    [`& .${gaugeClasses.valueText}`]: {
                      fontSize: 18,
                    },
                    [`& .${gaugeClasses.valueArc}`]: {
                      fill: '#2c73d2',
                    },
                    [`& .${gaugeClasses.referenceArc}`]: {
                      fill: theme.palette.text.disabled,
                    },
                  })}
                />
                <Typography style={{ color: "#2c73d2", fontSize: "12px" }} >CRV a v.:</Typography>
                <Gauge
                  {...documentExpirations}
                  innerRadius= "50%"
                  outerRadius= "80%"
                  sx={(theme) => ({
                    [`& .${gaugeClasses.valueText}`]: {
                      fontSize: 18,
                    },
                    [`& .${gaugeClasses.valueArc}`]: {
                      fill: '#2c73d2',
                    },
                    [`& .${gaugeClasses.referenceArc}`]: {
                      fill: theme.palette.text.disabled,
                    },
                  })}
                />
              </Div>
            </Grid>
          </Grid>
        </div>
        <div style={{ marginTop: "5px"}} >
          <Div onlyWeb>
            <BarChart
              dataset={dataVehicleFuel}
              xAxis={
                [{
                  tickLabelStyle: {
                    angle: 50,
                    dominantBaseline: 'hanging',
                    textAnchor: 'start',
                  },
                  scaleType: 'band', dataKey: 'label'
                }]}
              series={[
                { dataKey: 'value', label: 'Abastecimentos LT.: ' + supplie + ' | janeiro a ' + month + '/' + year, valueFormatter },
              ]}
              {...chartSetting}
            />
          </Div>
        </div>
      </ContentPage>

      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 5 }}
        open={openL}
      >
         <Label>Carregando...</Label>
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};

export default Home;