import React, { useRef, useEffect } from "react";
import axios from "axios";
import {toast } from "react-toastify";
import Typography from '@mui/material/Typography';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { 
  FormContainer, 
  InputArea, 
  Label, 
  Input, 
  Select1, 
  Input3, 
  ButtonArea, 
  Button 
} from "../Styles/Styles"


const FromAddService = ({ handleClose, idOs, getServices, getOrderService, getOS }) => {
  const ref = useRef();
  const IdCompany = localStorage.getItem("id_company");
  const IdUser = localStorage.getItem("id");
  const [loading, setLoading] = React.useState(false);
  const [products, setProducts] = React.useState([]);
  const [employees, setEmployees] = React.useState([]);
  const escriptionService = useRef("");
  const technicalName = useRef("");
  const cal = useRef(0);

  const typeReg = "S";
  const statusReg = "1";

  const onServiceChange = (event) =>{   
   const serv = ref.current;
   const res = products.filter((a) => (a.id === event.target.value));
   // eslint-disable-next-line
       res.map((a) => {
        escriptionService.current = a.description;
        serv.price.value = a.value_vlr;
        serv.amount.value = 1;
        serv.discount.value = 0;    
        serv.total.value = a.value_vlr;
       });
  };

  const onTechnicalChange = (event) =>{   
    const res = employees.filter((a) => (a.id === event.target.value));
    // eslint-disable-next-line
        res.map((a) => {
            technicalName.current = a.nome;
        });
   };

   const onCalChange = () =>{
    const serv = ref.current;
    const quantity = serv.amount.value.trim();
    const initial = serv.price.value.trim();
    const descont = serv.discount.value.trim();
    cal.current = (parseFloat((+initial) * (+quantity) - (+descont)))
    serv.total.value = cal.current;
   };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const serv = ref.current;

    if (
      !serv.id_services.value ||
      !serv.id_technician.value
    ) {
      setLoading(false);
      return toast.warn("Preencha todos os campos!");
    }

    
      await axios
        .post("order-service/order-service-Services-list/", {
          id_order_service: idOs,
          id_services: serv.id_services.value,
          services: escriptionService.current,
          complement: serv.complement.value.trim(),
          price: serv.price.value.trim(),
          amount: serv.amount.value.trim(),
          discount: serv.discount.value.trim(),
          total: serv.total.value,
          id_technician: serv.id_technician.value,
          technician: technicalName.current,
          id_company: IdCompany,
          user: IdUser,
        })
        .then(() => {
          toast.success("Cadastrado com sucesso!");

          setLoading(false);
          getServices();
          getOrderService();
          getOS();
          handleClose();
        })
        .catch(() => {
          toast.error("Erro no Cadastro!");
          setLoading(false);
        });

  };

  const getProducts = async () => {
    setLoading(true);
    setProducts([]);
    try {
      const res = await axios.get("products-services/products-services-list/" + IdCompany + "/" + typeReg + "/" + statusReg + "/?loc=%");
      
        setProducts(res.data.sort((a, b) => (a.description > b.description ? 1 : -1)));
      
      setLoading(false);
    } catch (error) {
      toast.error(error);
      setLoading(false);
    }
  };

  const getEmployees = async () => {
    setLoading(true);
    setEmployees([]);
    try {
      const res = await axios.get("employees/employees-list/" + IdCompany + "/?loc=%");
        setEmployees(res.data.sort((a, b) => (a.nome > b.nome ? 1 : -1)));
        setLoading(false);
    } catch (error) {
      toast.error(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    getProducts();
    getEmployees();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <Typography style={{ color: "#fff" }} id="modal-modal-title" variant="h6" component="h2">
        Adicionar Serviço
      </Typography>
      <FormContainer ref={ref} onSubmit={handleSubmit}>
        <InputArea>
          <Label>Código O.S</Label>
          <Input style={{ color: "#fff" }} disabled={true} name="id_order_service" value={idOs} />
        </InputArea>
        <InputArea>
          <Label>Serviço*</Label>
          <Select1 onChange={onServiceChange} style={{ width: "355px" }} name="id_services">
          <option value="">Selecione</option>
          {products === undefined ? [] : products.map((item, i) => (
                  <option key={i} value={item.id}>{item.description}</option>
                ))}
          </Select1>
        </InputArea>
        <InputArea>
          <Label>Complemento</Label>
          <Input3 style={{ width: "355px" }} name="complement" />
        </InputArea>
        <InputArea>
          <Label>Valor Unidade</Label>
          <Input onChange={onCalChange} type="number" pattern="[0-9]+([,\.][0-9]+)?" min="0" step="any" placeholder="Digite seu número" name="price" />
        </InputArea>
        <InputArea>
          <Label>Quantidade</Label>
          <Input onChange={onCalChange} type="number" pattern="[0-9]+([,\.][0-9]+)?" min="0" step="any" placeholder="Digite seu número" name="amount" />
        </InputArea>
        <InputArea>
          <Label>Valor Desconto</Label>
          <Input onChange={onCalChange} type="number" pattern="[0-9]+([,\.][0-9]+)?" min="0" step="any" placeholder="Digite seu número" name="discount" />
        </InputArea>
        <InputArea>
          <Label>Total</Label>
          <Input style={{ color: "#fff" }} disabled={true} type="number" pattern="[0-9]+([,\.][0-9]+)?" min="0" step="any" placeholder="Digite seu número" name="total" />
        </InputArea>
        <InputArea>
          <Label>Técnico*</Label>
          <Select1 onChange={onTechnicalChange} name="id_technician">
            <option value="">Selecione</option>
            {employees === undefined ? [] : employees.map((item, i) => (
                <option key={i} value={item.id}>{item.nome}</option>
            ))}
          </Select1>
        </InputArea>

        <div style={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
        }} >
          <ButtonArea >
            <Button type="submit">SALVAR</Button>
          </ButtonArea>
          <ButtonArea >
            <Button onClick={handleClose}>SAIR</Button>
          </ButtonArea>
        </div>

      </FormContainer>

      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 5 }}
        open={loading}
      >
         <Label>Carregando...</Label>
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};

export default FromAddService;