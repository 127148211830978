import React from'react';
import { styled } from '@mui/material/styles';
import { Box, Typography, Container } from '@mui/material';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import off404 from './Page404.png';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  minHeight: '100%',
  alignItems: 'center',
  paddingTop: theme.spacing(15),
  paddingBottom: theme.spacing(10),
}));

// ----------------------------------------------------------------------

export default function Page404() {
  return (
    <>
      <RootStyle>
        <Container>
          <Box sx={{ margin: 'auto', textAlign: 'center' }}>
            <div>
              <Typography variant="h3" paragraph>
                Ops... Essa página não foi encontrada.
              </Typography>
            </div>
            <img 
            style={{ maxWidth: 480, margin: 'auto', textAlign: 'center' }}
            src={off404} alt="Page not found" />
            
            <Typography variant="h5" component="h2">
                Voltar para a Home
            </Typography>
            <Typography>
                <a href="/">Clique aqui</a> para voltar à página inicial.
            </Typography>
          </Box>
        </Container>
      </RootStyle>
    </>
  );
}