
import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from 'react-router-dom';
import { signout } from "../../Services/UserService";
import ContentPage from "../../components/Content/ContentPage";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import {
  FormContainer,
  InputArea,
  Label,
  Select1,
  ButtonArea,
  Button,
  InputDate
} from "../../components/Styles/Styles";
import ReleasesSimplePdf from "../../components/ReportReleases/ReleasesSimple";
import isValidObject from "../../shared/isValidObject";
import ReleasesDetailedPdf from "../../components/ReportReleases/ReleasesDetailed";
import Select from 'react-select';

const ReportsReleases = () => {
  const navigate = useNavigate();
  const ref = useRef();
  const [vehicles, setVehicles] = useState([]);
  const [company, setCompany] = useState([]);
  const IdCompany = localStorage.getItem("id_company");

  const releasesRef = React.useRef();
  const releasesSumRef = React.useRef();
  const typeReportRef = React.useRef();
  const subCompany = useRef("");
  const category = useRef("");

  const [openL, setOpenL] = React.useState(false);

  function adicionaZero(numero) {
    if (numero <= 9)
      return "0" + numero;
    else
      return numero;
  }


  let dataAtual = new Date();

  let dataAtualFormatada = (dataAtual.getFullYear() + "-" + (adicionaZero(dataAtual.getMonth() + 1).toString()) + "-" + adicionaZero(dataAtual.getDate().toString()));

  const [locDateI, setLocDate] = React.useState(dataAtualFormatada);
  const [locDateF, setLocDateF] = React.useState(dataAtualFormatada);

  const handleCloseL = () => {
    setOpenL(false);
  };
  const handleOpenL = () => {
    setOpenL(true);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    await getReleases();
    await getReleasesSum();
    const typeReport = ref.current;
    
    category.current = (
      typeReport.category.value === "" ? "Todos" :
      typeReport.category.value === "1" ? "Urbano" :
      typeReport.category.value === "2" ? "Rodoviário" :
      typeReport.category.value === "3" ? "Escolar": "")

    if (locDateI > locDateF) {
      toast.error("Data inicial não pode ser maior que a final!");
      handleCloseL();
      return;
    }
    if (releasesRef.current.length === 0 && !isValidObject(releasesSumRef.current)) {
      toast.error(" Nenhum lançamento encontrado! ");
      handleCloseL();
      return;
    } else {
      handleCloseL();
      if (typeReportRef.current === "1") {
        ReleasesSimplePdf(locDateI, locDateF, releasesRef.current, dataAtualFormatada, releasesSumRef.current, subCompany.current, category.current);
      }
      if (typeReportRef.current === "2") {
        ReleasesDetailedPdf(locDateI, locDateF, releasesRef.current, dataAtualFormatada, releasesSumRef.current, subCompany.current, category.current);
      }
    }

  };

  const onLinesChange = () => {
    const typeReport = ref.current;
    subCompany.current = "";
    const res = company.filter((a) => (a.id === (typeReport.id_subcompany.value === "" ? "0" : typeReport.id_subcompany.value)));
    // eslint-disable-next-line
    res.map((a) => {
      subCompany.current = a.name;
    });
  };

  const getReleases = async () => {
    const typeReport = ref.current;
    handleOpenL();
    releasesRef.current = [];
    typeReportRef.current = typeReport.type_report.value;

    if (typeReport.type_report.value === "1") {

      await axios.post("releases/report-releases-simple/", {
        id_company: IdCompany,
        id_vehicle: (typeReport.vehicle.value === "" ? "%" : typeReport.vehicle.value),
        id_subcompany: (typeReport.id_subcompany.value === "" ? "%" : typeReport.id_subcompany.value),
        category: (typeReport.category.value === "" ? "%" : typeReport.category.value),
        idate: locDateI,
        fdate: locDateF,
      })
        .then((res) => {
          releasesRef.current = res.data;
          if (releasesRef.current.length > 0) {
            handleCloseL();
          } else {
            handleCloseL();
          };
        })
        .catch((error) => {
          toast.error(error);
          handleCloseL();
        });
    }
    if (typeReport.type_report.value === "2") {

      await axios.post("releases/report-releases-detailed/", {
        id_company: IdCompany,
        id_vehicle: (typeReport.vehicle.value === "" ? "%" : typeReport.vehicle.value),
        id_subcompany: (typeReport.id_subcompany.value === "" ? "%" : typeReport.id_subcompany.value),
        category: (typeReport.category.value === "" ? "%" : typeReport.category.value),
        idate: locDateI,
        fdate: locDateF,
      })
        .then((res) => {
          releasesRef.current = res.data;
          if (releasesRef.current.length > 0) {
            handleCloseL();
          } else {
            handleCloseL();
          };
        })
        .catch((error) => {
          toast.error(error);
          handleCloseL();
        });
    }

  }

  const getReleasesSum = async () => {
    const typeReport = ref.current;
    handleOpenL();
    releasesSumRef.current = [];

    await axios.post("releases/report-releases-simple-sum/", {
      id_company: IdCompany,
      id_vehicle: (typeReport.vehicle.value === "" ? "%" : typeReport.vehicle.value),
      id_subcompany: (typeReport.id_subcompany.value === "" ? "%" : typeReport.id_subcompany.value),
      category: (typeReport.category.value === "" ? "%" : typeReport.category.value),
      idate: locDateI,
      fdate: locDateF,
    })
      .then((res) => {
        if (!res || !res.data[0]) {
          throw new Error(" Nenhum lançamento encontrado! ");
        }
        releasesSumRef.current = res.data[0];
        if (releasesSumRef.current.length > 0) {
          handleCloseL();
        } else {
          handleCloseL();
        };
      })
      .catch((error) => {
        toast.error(error);
        handleCloseL();
      });
  }
  const getVehicles = async () => {
    setVehicles([]);
    try {
      const res = await axios.get("vehicle/vehicle-list/" + IdCompany + "/?loc=%");
      if (res.data[0].status === "false") {
        toast.error(res.data[0].message);
        signout();
        navigate("/login");
      } else {
        setVehicles(res.data.sort((a, b) => (a.plate > b.plate ? 1 : -1)));
      }
    } catch (error) {
      toast.error(error);
    }
  };

  const getCompany = async () => {
    setCompany([]);
    try {
      const res = await axios.get("company/company-list/" + IdCompany + "/?loc=%");
      if (res.data[0].status === "false") {
        toast.error(res.data[0].message);
        signout();
        navigate("/login");
      } else {
        setCompany(res.data.sort((a, b) => (a.name > b.name ? 1 : -1)));
      }
      handleCloseL();
    } catch (error) {
      toast.error(error);
      handleCloseL();
    }

  };

  const loadingIdVehicle = (item) => {    
    const typeReport = ref.current;
    if(item){
      typeReport.vehicle.value = item.value;
    }
  };

  useEffect(() => {
    getVehicles();
    getCompany();
    // eslint-disable-next-line

  }, []);


  return (
    <>
      <ContentPage caminho={[{ nome: "Relatórios / Abastecimentos", link: "/Reports/Releases" }]}>

        <Typography style={{ color: "#022025" }} id="modal-modal-title" variant="h6" component="h2">
          Lançamentos por período
        </Typography>
        <FormContainer style={{ maxWidth: "350px" }} ref={ref} onSubmit={handleSubmit}>
          <InputArea>
            <Label>Relatórios</Label>
            <Select1 name="type_report" style={{ width: "300px" }} >
              <option value={1}>Simples</option>
              <option value={2}>Detalhado</option>
            </Select1>
          </InputArea>
          <InputArea>
            <Label>Empresa</Label>
            <Select1 onChange={onLinesChange} style={{ width: "300px" }} name="id_subcompany">
              <option value="">Todos</option>
              {company === undefined ? [] : company.map((item, i) => (
                <option key={i} value={item.id} >
                  {item.id} - {item.name}
                </option>
              ))}
            </Select1>
          </InputArea>
          <InputArea>
            <Label>Categoria</Label>
            <Select1 name="category" style={{ width: "300px" }} >
              <option value="">Todos</option>
              <option value={1}>Urbano</option>
              <option value={2}>Rodoviário</option>
              <option value={3}>Escolar</option>
            </Select1>
          </InputArea>

          <InputArea style={{width: "300px", color: "#000", fontSize:"12px" }}>
          <Label style={{color: "#fff" }}>Veículo</Label>
          <Select 
          className="basic-single"
          classNamePrefix="select"
          isClearable={true}
          defaultValue={vehicles[0]}
          isSearchable={true}
          name="vehicle"
          onChange = {(item) => loadingIdVehicle(item)}       
          options = {vehicles === undefined ? [] : vehicles.map((item) => (
            {value : item.id, 
              label : item.surname + " - " + item.plate}
          ))} 
          theme = {theme => ({
            ...theme,
            colors: {
            ...theme.colors,
              primary: "#2c73d2",
              secondary: "#bbb",
            },
          })}
          /> 
          </InputArea>

          <InputArea>
            <Label>Data inicial</Label>
            <InputDate
              type="date"
              value={locDateI}
              onChange={(e) => [setLocDate(e.target.value)]}
              style={{ width: "147px" }}
            />
          </InputArea>
          <InputArea>
            <Label>Data final</Label>
            <InputDate
              type="date"
              value={locDateF}
              onChange={(e) => [setLocDateF(e.target.value)]}
              style={{ width: "147px" }}
            />
          </InputArea>

          <ButtonArea style={{marginTop:"70%"}}>
            <Button type="submit">Visualizar</Button>
          </ButtonArea>
        </FormContainer>

        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 5 }}
          open={openL}
        >
          <Label>Carregando...</Label>
          <CircularProgress color="inherit" />
        </Backdrop>
        <ToastContainer autoClose={3000} />
      </ContentPage>
    </>
  );
};

export default ReportsReleases;