
import React, { useEffect, useState, useRef } from "react";
import ContentPage from "../../../components/Content/ContentPage";
import ModalTireMovement from "../../../components/Tires/TireMovement/ModalTireMovement";
import GridTireMovement from "../../../components/Tires/TireMovement/GridTireMovement";
import { toast } from "react-toastify";
import axios from "axios";

import {
  FormContainer,
  Loc,
  InputArea,
  Button,
  Select1
} from "../../Styles/Styles";

const TireMovementList = () => {
  const [tireMovement, setTireMovement] = useState([]);
  const [tireRegistration, setTireRegistration] = useState([]);
  const [onEdit, setOnEdit] = useState(null);
  const [open, setOpen] = React.useState(false);
  const [loc, setLoc] = React.useState("");
  const [tireStatus, setTireStatus] = React.useState("0");
  const [regist, setRegist] = React.useState(false);
  const IdCompany = localStorage.getItem("id_company");
  const vehicles = useRef([]);
  const [axisSchema, setAxisSchema] = useState([]);

  const [openL, setOpenL] = React.useState(false);

  const handleCloseL = () => {
    setOpenL(false);
  };
  const handleOpenL = () => {
    setOpenL(true);
  };


  const handleOpen = () => {
    setOpen(true);
    setRegist(true);
  };

  const getVehicles = async () => {
    handleOpenL();
    try {
      const res = await axios.get("vehicle/vehicle-list/" + IdCompany + "/?loc=%");

      vehicles.current = (res.data.sort((a, b) => (a.id > b.id ? 1 : -1)));

    } catch (error) {
      toast.error(error);
      handleCloseL();
    }
  };

  const getAxisSchema = async () => {
    setAxisSchema([]);
    try {
      const res = await axios.get("tires/axis-schema-list/" + IdCompany + "/?loc=%");

      setAxisSchema(res.data.sort((a, b) => (a.id > b.id ? 1 : -1)));

    } catch (error) {
      toast.error(error);
      handleCloseL();
    }
  };

  const getTireRegistration = async () => {
    setTireRegistration([]);
    try {
      const res = await axios.get("tires/tire-registration-list/" + IdCompany + "/?loc=%");
      setTireRegistration(res.data.sort((a, b) => (a.id > b.id ? 1 : -1)));
    } catch (error) {
      toast.error(error);
      handleCloseL();
    }
  };

  const getTireMovement = async () => {
    setTireMovement([]);
    setOnEdit(null);

    getVehicles();
    getAxisSchema();
    getTireRegistration();
    let getLoc = (loc === "" ? '%' : loc);
    try {
      await axios.post("tires/tire-mov-list/",{
        id_company: IdCompany,
        tire_status: (tireStatus === "0" ? "%" :
          tireStatus === "1" ? 1 :
          tireStatus === "2" ? 2 :
          tireStatus === "3" ? 3 :
          tireStatus === "4" ? 4 : "%" 
        ),
        fire_number: (tireStatus === "0" ? getLoc :
                      tireStatus === "1" ? getLoc :
                      tireStatus === "2" ? getLoc :
                      tireStatus === "3" ? getLoc :
                      tireStatus === "4" ? getLoc : "%" 
        ),
        surname: (tireStatus === "0" ? "%" :
                  tireStatus === "1" ? "%" :
                  tireStatus === "2" ? "%" :
                  tireStatus === "3" ? "%" :
                  tireStatus === "4" ? "%" : getLoc 
        ),
      }).then(res => {
        setTireMovement(res.data.sort((a, b) => (a.tire_18 > b.tire_1? 1 : -1)));
      }); 
      handleCloseL();
    } catch (error) {
      toast.error(error);
      handleCloseL();
    }
  };

  useEffect(() => {
    getTireMovement();

    // eslint-disable-next-line
  }, []);

  return (
    <>
      <ContentPage caminho={[{ nome: "Pneu > Movimentação de Pneus ", link: "/tire-movement/list" }]}>

        <ModalTireMovement
          regist={regist}
          tireRegistration={tireRegistration}
          setTireRegistration={setTireRegistration}
          setLoc={setLoc}
          loc={loc}
          onEdit={onEdit}
          setOnEdit={setOnEdit}
          getTireMovement={getTireMovement}
          setOpen={setOpen}
          open={open}
          openL={openL}
          handleOpenL={handleOpenL}
          handleCloseL={handleCloseL}
          vehicles={vehicles.current}
          axisSchema={axisSchema}
        />

        <FormContainer>

          <InputArea>
            <Select1 
              onChange={(e) => [setTireStatus(e.target.value)]}>
              <option value={0}>Todos</option>
              <option value={1}>Transito</option>
              <option value={2}>Manutenção</option>
              <option value={3}>Sucata</option>
              <option value={4}>Finalização do Transito</option>
              <option value={5}>Veículo</option>
            </Select1>
          </InputArea>

          <Loc
            style={{
              width: "350px",
              height: "30px",
              border: "1px solid #343f46",
              textTransform: "uppercase",
              fontSize: "12px"
            }}
            type='text'
            value={loc}
            onChange={(e) => [setLoc(e.target.value)]}
          />

          <InputArea >
            <Button
              onClick={getTireMovement}>Localizar
            </Button>
          </InputArea>
          <InputArea  >
            <Button
              onClick={handleOpen}>Novo
            </Button>
          </InputArea>
        </FormContainer>

        <GridTireMovement openL={openL} regist={regist} setRegist={setRegist} tireMovement={tireMovement} setTireMovement={setTireMovement} setOnEdit={setOnEdit} setOpen={setOpen} />
      </ContentPage>
    </>
  );
};

export default TireMovementList;