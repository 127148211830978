import React from 'react';
import { InputCustomizado } from "./styles"

const Input = ({ 
  type, 
  placeholder, 
  value, 
  onChange,
  name, 
  secrete
}) => {
  return (
    <InputCustomizado
      value={value}
      onChange={onChange}
      type={type}
      placeholder={placeholder}
      name={name}
      secrete={secrete}
    />
  )
}

export default Input;