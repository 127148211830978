import React from 'react';
import axios from 'axios';
import { toast } from "react-toastify";
import './Navbar.style.css';

const Navbar = ({tipoMenu, handleMenu }) => {
    const user = localStorage.getItem('name');
    const IdCompany = localStorage.getItem('id_company');
    const date = new Date();  // Ano, mês e dia

    function adicionaZero(numero) {
        if (numero <= 9)
          return "0" + numero;
        else
          return numero;
      }
    
    const DateStart = ((date.getFullYear()) + "-01-01");
    const DateEnd = ((date.getFullYear()) + '-' + (adicionaZero(date.getMonth() + 1)) + '-' + adicionaZero(date.getDate()));

    const getMaintenance = async () => {
        try {
          const res = await axios.get("maintenance-control/maintenance-control-list/"
            + IdCompany + "/1/" + DateStart + "/" + DateEnd+ "/?loc=%");
          if (res.data.length > 0) {
             if(res.data.length > 1){
                 toast.info("À " + res.data.length +" Manutenções agendadas pendentes!");
                 return;  // Se houver mais de uma manutenção, não exibe a
             }else{
                 toast.info("À " + res.data.length +" Manutenção agendada pendente!");
                 return;  // Se houver uma manutenção, exibe a notificação
             }
          } else {
            return;  // Se não houver manutenções, não exibe a notificação
          }
        } catch (error) {
          toast.error(error);
        }
    };

    React.useEffect(() => {
       // Executa a função de verificação de manutenções a cada 120 segundos
        setInterval(() => {
            getMaintenance();
        }, 120000)
    }, []); // Executa o useEffect apenas uma vez
    

    return (
        <div className="navbar">
            <button className="btn-menu" onClick={() => handleMenu()}>
            {tipoMenu === 'aberto' ? <i class="fa-regular fa-circle-xmark"></i> :  <i class="fa-solid fa-list"></i>}
            </button>
            <div>
               <span className="user" >{user}</span>
               <img src="/images/user.png" className="user-ciclo" alt="user" />
            </div>
        </div>
    );
};

export default Navbar;