
import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from 'react-router-dom';
import { signout } from "../../Services/UserService";
import ContentPage from "../../components/Content/ContentPage";
import ModalReleasesFuel from "../../components/ReleasesFuel/ModalReleasesFuel";
import GridReleasesFuel from "../../components/ReleasesFuel/GridReleasesFuel";
import { toast } from "react-toastify";
import axios from "axios";
import {
  FormContainer,
  Loc,
  InputArea,
  Button,
  Input
} from "../Styles/Styles"
import { Label } from "../../components/Styles/Styles";


const ReleasesFuelList = () => {
  const navigate = useNavigate();
  const [releases, setReleases] = useState([]);
  const [onEdit, setOnEdit] = useState(null);
  const [open, setOpen] = React.useState(false);
  const [loc, setLoc] = React.useState();
  const [regist, setRegist] = React.useState(false);
  const [employees, setEmployees] = useState([]);
  const [vehicles, setVehicles] = useState([]);
  const [bombs, setBombs] = useState([]);
  const fuel = useRef([]);
  const IdCompany = localStorage.getItem("id_company");

  const [openL, setOpenL] = React.useState(false);

  const handleCloseL = () => {
    setOpenL(false);
  };
  const handleOpenL = () => {
    setOpenL(true);
  };


  const handleOpen = () => {
    setOpen(true);
    setRegist(true);
  };

  function adicionaZero(numero) {
    if (numero <= 9)
      return "0" + numero;
    else
      return numero;
  }

  let dataAtual = new Date();
  let dataAtualFormatada = (dataAtual.getFullYear() + "-" + (adicionaZero(dataAtual.getMonth() + 1).toString()) + "-" + adicionaZero(dataAtual.getDate().toString()));

  const [locDate, setLocDate] = React.useState(dataAtualFormatada);
  const [locDateF, setLocDateF] = React.useState(dataAtualFormatada);

  const getReleases = async () => {
    handleOpenL();
    setReleases([]);
    setOnEdit(null);

    if (locDate > locDateF) {
      toast.error("A data inicial não pode ser maior que a data final!");
      handleCloseL();
      return;
    };

    try {
      const res = await axios.get("releases/releases-fuel-list/" +
        IdCompany + "/" + locDate + "/" + locDateF + "/?loc=" + (loc === undefined ? '%' : loc));
      if (res.data[0].status === "false") {
        toast.error(res.data[0].message);
        signout();
        navigate("/login");
      } else {
        setReleases(res.data.sort((a, b) => (a.date > b.date ? 1 : -1)));
      }
      handleCloseL();
    } catch (error) {
      toast.error(error);
      handleCloseL();
    }
  };

  const getEmployees = async () => {
    handleOpenL();
    setEmployees([]);
    setOnEdit(null);
    try {
      const res = await axios.get("employees/employees-list/" + IdCompany + "/?loc=%");
      if (res.data[0].status === "false") {
        toast.error(res.data[0].message);
        signout();
        navigate("/login");
      } else {
        setEmployees(res.data.sort((a, b) => (a.nome > b.nome ? 1 : -1)));
      }
      handleCloseL();
    } catch (error) {
      toast.error(error);
      handleCloseL();
    }
  };

  const getVehicles = async () => {
    handleOpenL();
    setVehicles([]);
    setOnEdit(null);
    try {
      const res = await axios.get("vehicle/vehicle-list/" + IdCompany + "/?loc=%");
      if (res.data[0].status === "false") {
        toast.error(res.data[0].message);
        signout();
        navigate("/login");
      } else {
        setVehicles(res.data.sort((a, b) => (a.id > b.id ? 1 : -1)));
      }
      handleCloseL();
    } catch (error) {
      toast.error(error);
      handleCloseL();
    }
  };

  const getFuel = async () => {
    handleOpenL();
    try {
      const res = await axios.get("fuel/fuel-list/" + IdCompany + "/?loc=%");
      fuel.current = res.data.sort((a, b) => (a.fuel > b.fuel ? 1 : -1));
      handleCloseL();
    } catch (error) {
      toast.error(error);
    }
  };

  const getBombs = async () => {
    handleOpenL();
    setBombs([]);
    setOnEdit(null);
    try {
      const res = await axios.get("bombs/bombs-list/" + IdCompany + "/?loc=%");
      if (res.data[0].status === "false") {
        toast.error(res.data[0].message);
        signout();
        navigate("/login");
      } else {
        setBombs(res.data.sort((a, b) => (a.id > b.id ? 1 : -1)));
      }
      handleCloseL();
    } catch (error) {
      toast.error(error);
      handleCloseL();
    }
  };

  useEffect(() => {
    getReleases();
    getEmployees();
    getVehicles();
    getFuel();
    getBombs();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <ContentPage caminho={[{ nome: "Lançamentos > Abastecimentos", link: "/Releases/list" }]}>

        <ModalReleasesFuel
          regist={regist}
          releases={releases}
          setReleases={setReleases}
          setLoc={setLoc}
          loc={loc}
          onEdit={onEdit}
          setOnEdit={setOnEdit}
          getReleases={getReleases}
          setOpen={setOpen}
          open={open}
          openL={openL}
          handleOpenL={handleOpenL}
          handleCloseL={handleCloseL}
          locDate={locDate}
          employees={employees}
          vehicles={vehicles}
          bombs={bombs}
          fuel={fuel.current}
          getEmployees={getEmployees}
          getVehicles={getVehicles}
          getFuel={getFuel}
          getBombs={getBombs}
        />

        <FormContainer>
          <Loc
            style={{
              width: "300px",
              height: "30px",
              border: "1px solid #343f46",
              textTransform: "uppercase",
              fontSize: "12px"
            }}
            type='text'
            placeholder="Digite apelido do veículo"
            value={loc}
            onChange={(e) => [setLoc(e.target.value)]}
          />
          <InputArea>
            <Input type="date"
              value={locDate}
              onChange={(e) => [setLocDate(e.target.value)]}
            />
          </InputArea>

          <InputArea>
            <Label style={{ color: "#242a38" }}>à</Label>
          </InputArea>

          <InputArea>
            <Input type="date"
              value={locDateF}
              onChange={(e) => [setLocDateF(e.target.value)]}
            />
          </InputArea>

          <InputArea >
            <Button
              onClick={getReleases}>Localizar
            </Button>
          </InputArea>
          <InputArea  >
            <Button
              onClick={handleOpen}>Novo
            </Button>
          </InputArea>
        </FormContainer>

        <GridReleasesFuel openL={openL} regist={regist} setRegist={setRegist} releases={releases} setReleases={setReleases} setOnEdit={setOnEdit} setOpen={setOpen} />
      </ContentPage>
    </>
  );
};

export default ReleasesFuelList;