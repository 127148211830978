import React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import FromBombs from "./FromBombs";
import { Container, styledModal } from "../Styles/Styles";

const ModalBombs = (
  { handleCloseL, 
    handleOpenL, 
    openL, 
    regist, 
    bombs, 
    onEdit, 
    setOnEdit, 
    getBombs, 
    setOpen, 
    open, 
    company 
  }
  ) => {
  

  return (
    <>
      <Modal
        open={open}
        aria-labelledby="modal-modal-title"
      >
        <Box sx={styledModal}>
          <Container>
            <FromBombs 
              handleCloseL={handleCloseL} 
              handleOpenL={handleOpenL}  
              openL={openL} 
              regist={regist} 
              bombs={bombs} 
              onEdit={onEdit} 
              setOnEdit={setOnEdit} 
              getBombs={getBombs} 
              setOpen={setOpen}
              company={company}
            />
          </Container>
        </Box>
        
        
      </Modal>
    </>
  );
};

export default ModalBombs;