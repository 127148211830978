import React, { useEffect, useRef } from "react";
import axios from "axios";
import {toast } from "react-toastify";
import Typography from '@mui/material/Typography';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import DeleteIcon from '@mui/icons-material/Delete';

import { 
  FormContainer, 
  InputArea, 
  Label, 
  Input, 
  Select1, 
  InputEmail, 
  InputDate, 
  ButtonArea, 
  Button 
} from "../Styles/Styles";


const FromSupplier = ({handleCloseL, openL, handleOpenL, regist, onEdit, setOnEdit, getSupplier, setOpen }) => {
  const ref = useRef();
  const handleClose = () => setOpen(false);
  const IdCompany = localStorage.getItem("id_company");
  const IdUser = localStorage.getItem("id");

  const limparCampos = () => {
    const supplier = ref.current;

    supplier.cod.value = "";
    supplier.name.value = ""; 
    supplier.fantasy.value = "";
    supplier.cnpj.value = "";
    supplier.address.value = "";
    supplier.number.value = ""; 
    supplier.neighborhood.value = "";
    supplier.complement.value = "";
    supplier.city.value = "";
    supplier.uf.value = "";
    supplier.cep.value = "";
    supplier.telephone.value = "";
    supplier.email.value = "";
    supplier.st.value = "1";
  }

  useEffect(() => {
    const supplier = ref.current;
      if(regist) {
       limparCampos();
    } else {
    if (onEdit) {
      
      supplier.cod.value = onEdit.id;
      supplier.name.value = onEdit.name; 
      supplier.fantasy.value = onEdit.fantasy;
      supplier.cnpj.value = onEdit.cnpj;
      supplier.address.value = onEdit.address;
      supplier.number.value = onEdit.number; 
      supplier.neighborhood.value = onEdit.neighborhood;
      supplier.complement.value = onEdit.complement;
      supplier.city.value = onEdit.city;
      supplier.uf.value = onEdit.uf;
      supplier.cep.value = onEdit.cep;
      supplier.telephone.value = onEdit.telephone;
      supplier.email.value = onEdit.email;
      supplier.st.value = onEdit.st;
      
    };
  };
  // eslint-disable-next-line
  }, []);

  const handleDelete = async (e) => {
    e.preventDefault();
    const supplier = ref.current;
    handleOpenL();
    
    
      if (!window.confirm("Tem certeza que deseja excluir?")) {
        handleCloseL();
        return;
      } else {
      await axios
      .delete("supplier/supplier-list/" + supplier.cod.value)
      .then(() => {
          toast.success("Cadastro excluido com sucesso!");
          getSupplier();
          setOnEdit(null);
          handleClose();
      })
      .catch(() => toast.error("Erro, não foi possível excluir!"));
      }
    
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    handleOpenL();
  
    const supplier = ref.current;
   
    if (
      !supplier.name.value 
    ) {
      handleCloseL();
      return toast.warn("Preencha o campos Nome !");
    };
    if (
      !supplier.fantasy.value 
    ) {
      handleCloseL();
      return toast.warn("Preencha o campos Fantasia !");
    };
    if (
      !supplier.cnpj.value 
    ) {
      handleCloseL();
      return toast.warn("Preencha o campos CNPJ !");
    };

    if (onEdit) {
      await axios
        .put("supplier/supplier-list/", {
          id: supplier.cod.value,
          name: supplier.name.value.trim(), 
          fantasy: supplier.fantasy.value.trim(),
          cnpj: supplier.cnpj.value.trim(),
          address: supplier.address.value.trim(),
          number: supplier.number.value.trim(), 
          neighborhood: supplier.neighborhood.value.trim(),
          complement: supplier.complement.value.trim(),
          city: supplier.city.value.trim(),
          uf: supplier.uf.value,
          cep: supplier.cep.value.trim(),
          telephone: supplier.telephone.value.trim(),
          email: supplier.email.value.trim(),
          st: supplier.st.value,
          user: IdUser,
        })
        .then(() => {
          toast.success("Cadastro atualizado com sucesso!");

          limparCampos();
          
          getSupplier();
          setOnEdit(null);
          handleClose();
        })
        .catch(() => {
          toast.error("Erro na atualizado!");
          handleCloseL();
        });
    } else {
      await axios
        .post("supplier/supplier-list/", { 
          name: supplier.name.value.trim(), 
          fantasy: supplier.fantasy.value.trim(),
          cnpj: supplier.cnpj.value.trim(),
          address: supplier.address.value.trim(),
          number: supplier.number.value.trim(), 
          neighborhood: supplier.neighborhood.value.trim(),
          complement: supplier.complement.value.trim(),
          city: supplier.city.value.trim(),
          uf: supplier.uf.value,
          cep: supplier.cep.value.trim(),
          telephone: supplier.telephone.value.trim(),
          email: supplier.email.value.trim(),
          st: supplier.st.value,
          user: IdUser,
          id_company: IdCompany,

        })
        .then(() => {
          toast.success("Cadastrado com sucesso!");
          limparCampos();
          getSupplier();
          setOnEdit(null);
          handleClose();
        })
        .catch(() => {
          toast.error("Erro no Cadastro!");
          handleCloseL();
        });
        
    }


  };

  return (
    <>
          <Typography style={{ color: "#fff" }} id="modal-modal-title" variant="h6" component="h2">
            Ficha da Fornecedor
          </Typography>
          <FormContainer  ref={ref} onSubmit={handleSubmit}>
            <InputArea>
              <Label>Código</Label>
              <Input style={{ color: "#fff" }} disabled={true} name="cod"  />
            </InputArea>
            <InputArea>
              <Label>Situação</Label>
              <Select1 name="st">
                <option value={1}>Ativo</option>
                <option value={0}>Inativo</option>
              </Select1>
            </InputArea>
            <InputArea>
              <Label>Nome*</Label>
              <Input style={{ width: "350px" }} name="name" />
            </InputArea>
            <InputArea>
              <Label>Fantasia*</Label>
              <Input style={{ width: "350px" }} name="fantasy" />
            </InputArea>
            <InputArea>
              <Label>CNPJ</Label>
              <Input name="cnpj" />
            </InputArea>
            <InputArea>
              <Label>Endereço</Label>
              <Input style={{ width: "350px" }} name="address" />
            </InputArea>
            <InputArea>
              <Label>Número</Label>
              <Input name="number" type="number" pattern="[0-9]+([,\.][0-9]+)?" min="0" step="any" placeholder="Digite só número"/>
            </InputArea>
            <InputArea>
              <Label>Bairro</Label>
              <Input name="neighborhood" />
            </InputArea>
            <InputArea>
              <Label>Complemento</Label>
              <Input style={{ width: "350px" }} name="complement" />
            </InputArea>
            <InputArea>
              <Label>Cidade</Label>
              <Input name="city" />
            </InputArea>
            <InputArea>
              <Label>UF</Label>
              <Select1  name="uf">
                <option value="">Selecione UF</option>
                <option value="AC">AC</option>
                <option value="AL">AL</option>
                <option value="AM">AM</option>
                <option value="AP">AP</option>
                <option value="BA">BA</option>
                <option value="CE">CE</option>
                <option value="DF">DF</option>
                <option value="ES">ES</option>
                <option value="GO">GO</option>
                <option value="MA">MA</option>
                <option value="MG">MG</option>
                <option value="MS">MS</option>
                <option value="MT">MT</option>
                <option value="PA">PA</option>
                <option value="PB">PB</option>
                <option value="PE">PE</option>
                <option value="PI">PI</option>
                <option value="PR">PR</option>
                <option value="RJ">RJ</option>
                <option value="RN">RN</option>
                <option value="RO">RO</option>
                <option value="RR">RR</option>
                <option value="RS">RS</option>
                <option value="SC">SC</option>
                <option value="SE">SE</option>
                <option value="SP">SP</option>
                <option value="TO">TO</option>
              </Select1>
            </InputArea>
            <InputArea>
              <Label>CEP</Label>
              <InputDate name="cep" />
            </InputArea>
            <InputArea>
              <Label>Telefone</Label>
              <Input name="telephone" />
            </InputArea>
            <InputArea>
              <Label>E-mail</Label>
              <InputEmail name="email" />
            </InputArea>
                        
            <div style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }} >
              <ButtonArea >
                <Button type="submit">SALVAR</Button>
                { regist === true? 
                 null
                 : 
                 <Button 
                    style={{backgroundColor:"red"}} 
                    onClick={handleDelete}>EXCLUIR <DeleteIcon/>
                 </Button>
                 }
                
                <Button onClick={handleClose}>SAIR</Button>
              </ButtonArea>
            </div>

          </FormContainer>
          
      <Backdrop
              sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 5}}
              open={openL}
          >
         <Label>Carregando...</Label>
              <CircularProgress color="inherit" />
          </Backdrop>
    </>
  );

};


export default FromSupplier;