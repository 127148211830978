
import React from "react";
import { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import { signout } from "../../../Services/UserService";
import ContentPage from "../../../components/Content/ContentPage";
import ModalBillsToPay from "../../../components/BillsToPay/ModalBillsToPay";
import GridBillsToPay from "../../../components/BillsToPay/GridBillsToPay";
import { toast } from "react-toastify";
import axios from "axios";
import {
  FormContainer,
  Loc,
  InputArea,
  Button,
  Label,
  Input,
  Select1
} from "../../Styles/Styles"

const BillsToPayList = () => {
  const navigate = useNavigate();
  const [billsToPay, setBillsToPay] = useState([]);
  const [onEdit, setOnEdit] = useState(null);
  const [open, setOpen] = React.useState(false);
  const [loc, setLoc] = React.useState();
  const [regist, setRegist] = React.useState(false);
  const [situation, setSituation] = useState(1);
  const [supplier, setSupplier] = useState([]);
  const IdCompany = localStorage.getItem("id_company");

  const [openL, setOpenL] = React.useState(false);

  const handleCloseL = () => {
    setOpenL(false);
  };
  const handleOpenL = () => {
    setOpenL(true);
  };


  const handleOpen = () => {
    setOpen(true);
    setRegist(true);
  };

  function adicionaZero(numero) {
    if (numero <= 9)
      return "0" + numero;
    else
      return numero;
  }

  let dataAtual = new Date();
  let dataAtualFormatada = (dataAtual.getFullYear() + "-" + (adicionaZero(dataAtual.getMonth() + 1).toString()) + "-" + adicionaZero(dataAtual.getDate().toString()));

  const [locDate, setLocDate] = React.useState(dataAtualFormatada);
  const [locDateF, setLocDateF] = React.useState(dataAtualFormatada);

  const getSupplier = async () => {
    handleOpenL();
    setSupplier([]);
    setOnEdit(null);
    try {
      const res = await axios.get("supplier/supplier-list/" + IdCompany + "/?loc=%");
      if (res.data[0].status === "false") {
        toast.error(res.data[0].message);
        signout();
        navigate("/login");
      } else {
        setSupplier(res.data.sort((a, b) => (a.id > b.id ? 1 : -1)));
      }
    } catch (error) {
      toast.error(error);
    }
  };

  const getBillsToPay = async () => {
    handleOpenL();
    setBillsToPay([]);
    setOnEdit(null);

    if (locDate > locDateF) {
        toast.error("A data inicial não pode ser maior que a data final!");
        handleCloseL();
        return;
    };

    try {
      const res = await axios.get("financial/bills-to-pay-list/" + IdCompany + "/"+ situation  + "/" + locDate + "/" + locDateF + "/?loc=" + (loc === undefined ? '%' : loc));
      if (res.data[0].status === "false") {
        toast.error(res.data[0].message);
        signout();
        navigate("/login");
      } else {
        setBillsToPay(res.data.sort((a, b) => (a.id > b.id ? 1 : -1)));
      }
      handleCloseL();
    } catch (error) {
        toast.error(error);
        handleCloseL();
    }
  };

  useEffect(() => {
    getBillsToPay();
    setSituation(1);
    getSupplier();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <ContentPage caminho={[{ nome: "Financeiro > Contas a pagar", link: "/bills-to-pay/list" }]}>

        <ModalBillsToPay
          regist={regist}
          billsToPay={billsToPay}
          setBillsToPay={setBillsToPay}
          setLoc={setLoc}
          loc={loc}
          onEdit={onEdit}
          setOnEdit={setOnEdit}
          getBillsToPay={getBillsToPay}
          setOpen={setOpen}
          open={open}
          openL={openL}
          handleOpenL={handleOpenL}
          handleCloseL={handleCloseL}
          supplier={supplier}
        />

        <FormContainer>
          <Loc
            style={{
              width: "300px",
              height: "30px",
              border: "1px solid #343f46",
              textTransform: "uppercase",
              fontSize: "12px"
            }}
            type='text'
            placeholder="Digite fornecedor..."
            value={loc}
            onChange={(e) => [setLoc(e.target.value)]}
          />
          <Label>Situação</Label>
          <Select1 
            value={situation}
            onChange={(e) => [setSituation(e.target.value)]} 
            style={{
              width: "150px",
              height: "30px",
              border: "1px solid #343f46",
              textTransform: "uppercase",
              fontSize: "12px"
            }}>
            <option value={1}>A pagar</option>
            <option value={2}>pago</option>
          </Select1>
          <InputArea>
            <Input type="date"
              value={locDate}
              onChange={(e) => [setLocDate(e.target.value)]}
            />
          </InputArea>

          <InputArea>
            <Label style={{ color: "#242a38" }}>à</Label>
          </InputArea>

          <InputArea>
            <Input type="date"
              value={locDateF}
              onChange={(e) => [setLocDateF(e.target.value)]}
            />
          </InputArea>

          <InputArea >
            <Button
              onClick={getBillsToPay}>Localizar
            </Button>
          </InputArea>
          <InputArea  >
            <Button
              onClick={handleOpen}>Novo
            </Button>
          </InputArea>
        </FormContainer>

        <GridBillsToPay
          openL={openL}
          regist={regist}
          setRegist={setRegist}
          billsToPay={billsToPay}
          setOnEdit={setOnEdit}
          setOpen={setOpen} />
      </ContentPage>
    </>
  );
};

export default BillsToPayList;