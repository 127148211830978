import * as React from 'react';
import { GridActionsCellItem } from '@mui/x-data-grid';
import EditIcon from '@mui/icons-material/Edit';

import { DataGrid, GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';
import { useDemoData } from '@mui/x-data-grid-generator';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

import { 
  Table, 
  Tbody, 
  Label
} from "../../Styles/Styles";

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarExport />
    </GridToolbarContainer>
  );
}


const GridTireRegistration = ({openL, setRegist, tireRegistration, setOnEdit, setOpen }) => {

  const alterUsers = React.useCallback(
    (id) => () => {
      const res = tireRegistration.filter((a) => (a.id === id));
       // eslint-disable-next-line
       res.map((a) => {
        handleEdit(a);
       });
    },
    [tireRegistration],
  );

  const data = {
    columns: [
      {
        field: 'actions',
        type: 'actions',
        headerName: 'Ações',
        width: 80,
        getActions: (params) => [
          <GridActionsCellItem
            icon={<EditIcon style={{
              color: "#2c73d2"}} />}
            label="Delete"
            onClick={alterUsers(params.id)}
          />,
        ],
      },
      { field: 'tire_status', headerName: 'Status Pneu', width: 150 },
      { field: 'fire_number', headerName: 'Número fogo', width: 150 },
      { field: 'identification', headerName: 'Identificação', width: 700 },
      { field: 'vehicle', headerName: 'Veículo', width: 150 },
      { field: 'axle_positioning', headerName: 'Posicionamento ( Eixo )', width: 200 },
      { field: 'purchase_value', headerName: 'Valor Compra', width: 150 },
      { field: 'purchase_date', headerName: 'Data Compra', width: 150 },
      { field: 'invoice', headerName: 'NF', width: 100 },
    ],
    rows: tireRegistration === undefined? [] : tireRegistration.map((item, i) => ({
      id: item.id,
      identification: item.identification,
      vehicle: item.vehicle,
      axle_positioning: item.axle_positioning,
      fire_number: item.fire_number,
      tire_status: (
        item.tire_status === "0"? "Estoque" :
        item.tire_status === "1"? "Transito" :
        item.tire_status === "2"? "Manutenção" :
        item.tire_status === "3"? "Sucata" :
        item.tire_status === "4"? "Finalização do Transito" : "Não Definido"
      ),
      purchase_value: "R$ "+ item.purchase_value,
      purchase_date: item.purchase_date.split("-").reverse().join("/"),
      invoice: item.invoice,
    }))
  }
  ;

  const { loading } = useDemoData({
    dataSet: 'Commodity',
    rowLength: 10,
    maxColumns: 6,
  });

  const handleOpen = () => setOpen(true);

  const handleEdit = (item) => {
    setRegist(false);
    setOnEdit(item);
    handleOpen();
  };



  return (
    <>
    <Table onlyWeb>
      <Tbody > 
      <DataGrid
        {...data}
        loading={loading}
        rowHeight = {30}
        slots={{
          toolbar: CustomToolbar,
        }}
      />
      </Tbody>
    </Table>
    
    <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 5}}
        open={openL}
    >
         <Label>Carregando...</Label>
        <CircularProgress color="inherit" />
    </Backdrop>
    </>
  );
};

export default GridTireRegistration;