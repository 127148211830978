import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import {toast } from "react-toastify";
import Typography from '@mui/material/Typography';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import DeleteIcon from '@mui/icons-material/Delete';

import { 
  FormContainer, 
  InputArea, 
  Label, 
  Input, 
  Select1, 
  Input2, 
  Input3, 
  ButtonArea, 
  Button, 
  InputEmail
} from "../Styles/Styles";

const FromUsers = ({handleCloseL, openL, handleOpenL, regist, onEdit, setOnEdit, getUsers, setOpen }) => {
  const ref = useRef();
  const handleClose = () => setOpen(false);
  const IdCompany = localStorage.getItem("id_company");
  const IdUser = localStorage.getItem("id");
  const [openPassword, setOpenPassword] = useState( "password");
  const [showPassword, setShowPassword] = useState("fa-solid fa-eye-slash");

  const handleMenu = () => {
      (openPassword === "text") ? setOpenPassword("password") : setOpenPassword("text");
      (showPassword === "fa-solid fa-eye") ? setShowPassword("fa-solid fa-eye-slash") : setShowPassword("fa-solid fa-eye");
  };


  useEffect(() => {
    const user = ref.current;
    if(regist) {
      user.cod.value = "";
      user.name.value = "";
      user.phone.value = "";
      user.email.value = "";
      user.password.value = "";
      user.confirm_password.value = "";
      user.st.value = "1";
      user.type_user.value = "";
    } else {
    if (onEdit) {
      user.cod.value = onEdit.id;
      user.name.value = onEdit.name;
      user.phone.value = onEdit.phone;
      user.email.value = onEdit.email;
      user.password.value = onEdit.password;
      user.confirm_password.value = onEdit.password;
      user.st.value = onEdit.st;
      user.type_user.value = onEdit.type_user;
    };
  };
  // eslint-disable-next-line
  }, []);

  const handleDelete = async (e) => {
    e.preventDefault();
    const user = ref.current;
    handleOpenL();

    if (!window.confirm("Tem certeza que deseja excluir?")) {
      handleCloseL();
      return;
    } else {
    await axios
     .delete("users/users-list/"+ user.cod.value)
     .then(() => {
        toast.success("Cadastro excluido com sucesso!");
        getUsers();
        setOnEdit(null);
        handleClose();
     })
     .catch(() => {
      handleCloseL();
      toast.error("Erro, não foi possível excluir!");});
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    handleOpenL();
  
    const user = ref.current;
   if( user.password.value === user.confirm_password.value){
      if (
        !user.name.value ||
        !user.email.value ||
        !user.password.value ||
        !user.confirm_password.value ||
        !user.type_user.value
      ) {
        handleCloseL();
        return toast.warn("Preencha todos os campos com ( * )!");
      }
    } else {
      handleCloseL();
      return toast.warn("Confirmação de senha inválida!");
    }
    if (onEdit) {
      await axios
        .put("users/users-list/", {
          id: user.cod.value.trim(),
          name: user.name.value.trim(),
          phone: user.phone.value.trim(),
          email: user.email.value.trim(),
          password: user.password.value.trim(),
          st: user.st.value,
          type_user: user.type_user.value,
          user :IdUser,
        })
        .then(() => {
          toast.success("Cadastro atualizado com sucesso!");

          user.cod.value = "";
          user.name.value = "";
          user.phone.value = "";
          user.email.value = "";
          user.password.value = "";
          user.st.value = "1";
          user.type_user.value = "";
          
          getUsers();
          setOnEdit(null);
          handleClose();
        })
        .catch(() => {
          handleCloseL();
          toast.error("Erro na atualizado!");
        });
    } else {
      await axios
        .post("users/users-list/", {
          name: user.name.value.trim(),
          phone: user.phone.value.trim(),
          email: user.email.value.trim(),
          password: user.password.value.trim(),
          st: user.st.value,
          type_user: user.type_user.value,
          id_company: IdCompany,
          user :IdUser,
        })
        .then(() => {
          toast.success("Cadastrado com sucesso!");
          
          user.cod.value = "";
          user.name.value = "";
          user.phone.value = "";
          user.email.value = "";
          user.password.value = "";
          user.st.value = "1";
          user.type_user.value = "";

          getUsers();
          setOnEdit(null);
          handleClose();
        })
        .catch(() => {
          handleCloseL();
          toast.error("Erro no Cadastro!");
        });
        
    }


  };

  return (
    <>
          <Typography style={{ color: "#fff" }} id="modal-modal-title" variant="h6" component="h2">
            Ficha do usuário
          </Typography>
          <FormContainer  ref={ref} onSubmit={handleSubmit}>
            <InputArea>
              <Label>Código</Label>
              <Input style={{ color: "#fff" }} disabled={true} name="cod"  />
            </InputArea>
            <InputArea>
              <Label>Situação</Label>
              <Select1  name="st">
                <option value={1}>Ativo</option>
                <option value={0}>Inativo</option>
              </Select1>
            </InputArea>
            <InputArea>
              <Label>Tipo de Usuário</Label>
              <Select1  name="type_user">
                <option value="">Selecione a categoria</option>
                <option value={1}>Admin</option>
                <option value={2}>Operador</option>
                <option value={3}>Cliente</option>
              </Select1>
            </InputArea>
            <InputArea>
              <Label>Nome*</Label>
              <Input2 style={{ width: "350px" }} name="name" />
            </InputArea>
            <InputArea>
              <Label>E-mail*</Label>
              <InputEmail style={{textTransform: "lowercase"}} name="email" />
            </InputArea>
            <InputArea>
              <Label>Telefone</Label>
              <Input3 type="number" pattern="/([0-9]{3}).([0-9]{3}),([0-9]{2})$/g, '.$1.$2,$3'" min="0" step="any" placeholder="Digite só número" name="phone" />
            </InputArea>
            <InputArea>
              <Label>Senha*</Label>
              <Input3 type={openPassword} name="password" />
            </InputArea>
            <InputArea>
              <Label>Confirmar senha*</Label>
              <Input3 type={openPassword} name="confirm_password" />
            </InputArea>
            <div style={{ 
              display: "flex",
              marginTop: "20px"
            }} >
              <i class={showPassword} onClick={handleMenu}></i>
            </div>
            <div style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }} >
              <ButtonArea >
                <Button type="submit">SALVAR</Button>
                { regist === true? 
                 null
                 : 
                 <Button 
                    style={{backgroundColor:"red"}} 
                    onClick={handleDelete}>EXCLUIR <DeleteIcon/> 
                 </Button>
                 }
                
                <Button onClick={handleClose}>SAIR</Button>
              </ButtonArea>
            </div>

          </FormContainer>
          
      <Backdrop
              sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 5}}
              open={openL}
          >
         <Label>Carregando...</Label>
              <CircularProgress color="inherit" />
          </Backdrop>
    </>
  );
};

export default FromUsers;