import React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import FromProductsServices from "./FromProductsServices";

import { Container, styledModal } from "../Styles/Styles";


const ModalProductsServices = ({ handleCloseL, handleOpenL, openL, regist, onEdit, setOnEdit, getProducts, setOpen, open}) => {
  

  return (
    <>
      <Modal
        open={open}
        aria-labelledby="modal-modal-title"
      >
        <Box sx={styledModal}>
          <Container>
            <FromProductsServices 
              handleCloseL={handleCloseL} 
              handleOpenL={handleOpenL}  
              openL={openL} 
              regist={regist} 
              onEdit={onEdit} 
              setOnEdit={setOnEdit} 
              getProducts={getProducts} 
              setOpen={setOpen}
            />
          </Container>
        </Box>
        
        
      </Modal>
    </>
  );
};

export default ModalProductsServices;