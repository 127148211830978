import * as React from 'react';
import { GridActionsCellItem } from '@mui/x-data-grid';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';

import { DataGrid, GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';
import { useDemoData } from '@mui/x-data-grid-generator';

import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { 
  Table, 
  Tbody, 
  Label
} from "../Styles/Styles";

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarExport />
    </GridToolbarContainer>
  );
}


const GridImportXml = ({openL, setOnEdit, setOpen, releases }) => {


  const alterOrderService = React.useCallback(
    (id) => () => {
      const res = releases.filter((a) => (a.id === id));
       // eslint-disable-next-line
       res.map((a) => {
        handleEdit(a);
       });
    },
    [releases],
  );

  const data = {
    columns: [
      { field: 'nNF', headerName: 'Núm. Nota', width: 100 },
      { field: 'issuer', headerName: 'Fornecedo', width: 500 },
      { field: 'date_of_issue', headerName: 'Emissão', width: 100 },
      { field: 'release_date_of', headerName: 'Lançamento', width: 100 },
      { field: 'mode', headerName: 'Modelo', width: 80 },
      { field: 'type_prod', headerName: 'Tipo NF', width: 100 },
      { field: 'total_note', headerName: 'Valor', width: 150 },
      {
        field: 'actions',
        type: 'actions',
        headerName: 'Ações',
        width: 80,
        getActions: (params) => [
          <GridActionsCellItem
            icon={<RemoveRedEyeIcon style={{
              color: "#2c73d2"}} />}
                label="edit"
            onClick={alterOrderService(params.id)}
          />,
        ],
      },
    ],
    rows: releases === undefined? [] : releases.map((item, i) => ({
      id: item.id,
      nNF: item.nNF,
      issuer: item.issuer,
      date_of_issue: item.date_of_issue.split("-").reverse().join("/"),
      release_date_of: item.release_date_of.split("-").reverse().join("/"),
      mode: item.mode,
      type_prod: item.type_prod === "P" ? "Produto" : "Serviço",
      total_note: `${Intl.NumberFormat('pt-br', { style: 'currency', currency: 'BRL' }).format(item.total_note)}`,
    }))
    
  };

  const { loading } = useDemoData({
    dataSet: 'Commodity',
    rowLength: 10,
    maxColumns: 6,
  });


  const handleOpen = () => setOpen(true);

  const handleEdit = (item) => {
    setOnEdit(item);
    handleOpen();
  };

  return (
    <>
    <Table onlyWeb>
      <Tbody > 
      <DataGrid
        {...data}
        loading={loading}
        rowHeight = {30}
        slots={{
          toolbar: CustomToolbar,
        }}
      />
      </Tbody>
    </Table>
    
    <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 5}}
        open={openL}
    >
         <Label>Carregando...</Label>
        <CircularProgress color="inherit" />
    </Backdrop>
    </>
  );
};

export default GridImportXml;