import React, { useEffect, useRef } from "react";
import axios from "axios";
import {toast } from "react-toastify";
import Typography from '@mui/material/Typography';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import DeleteIcon from '@mui/icons-material/Delete';

import { 
  FormContainer, 
  InputArea, 
  Label, 
  Input, 
  Input2, 
  ButtonArea, 
  Button,
  Select1
} from "../../Styles/Styles";

const FromReasonScrapping = ({handleCloseL, openL, handleOpenL, regist, onEdit, setOnEdit, getReasonScrapping, setOpen }) => {
  const ref = useRef();
  const handleClose = () => setOpen(false);
  const IdCompany = localStorage.getItem("id_company");
  const IdUser = localStorage.getItem("id");

   
   const cleanearFields = () => {
    const reasonScrapping = ref.current;
    reasonScrapping.cod.value = "";
    reasonScrapping.description.value = "";
    reasonScrapping.damage.value = "";
    reasonScrapping.probable_causes.value = "";
    reasonScrapping.advice.value = "";
    reasonScrapping.destiny.value = "";
    reasonScrapping.st.value = 1;
   }

  useEffect(() => {
    const reasonScrapping = ref.current;
    if(regist) {
      cleanearFields();
    } else {
    if (onEdit) {
      reasonScrapping.cod.value = onEdit.id;
      reasonScrapping.description.value = onEdit.description;
      reasonScrapping.damage.value = onEdit.damage;
      reasonScrapping.probable_causes.value = onEdit.probable_causes;
      reasonScrapping.advice.value = onEdit.advice;
      reasonScrapping.destiny.value = onEdit.destiny;
      reasonScrapping.st.value = onEdit.st;;
    };
  };
  // eslint-disable-next-line
  }, []);

  const handleDelete = async (e) => {
    e.preventDefault();
    const reasonScrapping = ref.current;
    handleOpenL();

    if (!window.confirm("Tem certeza que deseja excluir?")) {
      handleCloseL();
      return;
    } else {
    await axios
     .delete("tires/reason-scrapping-list/"+ reasonScrapping.cod.value)
     .then(() => {
        toast.success("Cadastro excluido com sucesso!");
        getReasonScrapping();
        setOnEdit(null);
        handleClose();
     })
     .catch(() => {
      handleCloseL();
      toast.error("Erro, não foi possível excluir!");});
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    handleOpenL();
  
    const reasonScrapping = ref.current;
      if (
        !reasonScrapping.description.value
      ) {
        handleCloseL();
        return toast.warn("Preencha todos os campos com ( * )!");
      }

    if (onEdit) {
      await axios
        .put("tires/reason-scrapping-list/", {
          id: reasonScrapping.cod.value,
          description: reasonScrapping.description.value.trim(),
          damage: reasonScrapping.damage.value.trim(),
          probable_causes: reasonScrapping.probable_causes.value.trim(),
          advice: reasonScrapping.advice.value.trim(),
          destiny: reasonScrapping.destiny.value.trim(),
          st: reasonScrapping.st.value,
          user :IdUser,
        })
        .then(() => {
          toast.success("Cadastro atualizado com sucesso!");

          getReasonScrapping();
          cleanearFields();
          setOnEdit(null);
          handleClose();
        })
        .catch(() => {
          handleCloseL();
          toast.error("Erro na atualizado!");
        });
    } else {
      await axios
        .post("tires/reason-scrapping-list/", {
          description: reasonScrapping.description.value.trim(),
          damage: reasonScrapping.damage.value.trim(),
          probable_causes: reasonScrapping.probable_causes.value.trim(),
          advice: reasonScrapping.advice.value.trim(),
          destiny: reasonScrapping.destiny.value.trim(),
          st: reasonScrapping.st.value,
          id_company: IdCompany,
          user :IdUser,
        })
        .then(() => {
          toast.success("Cadastrado com sucesso!");
          
          cleanearFields();
          getReasonScrapping();
          setOnEdit(null);
          handleClose();
        })
        .catch(() => {
          handleCloseL();
          toast.error("Erro no Cadastro!");
        });
        
    }


  };

  return (
    <>
          <Typography style={{ color: "#fff" }} id="modal-modal-title" variant="h6" component="h2">
            Ficha de Motivos de Sucateamentos
          </Typography>
          <FormContainer  ref={ref} onSubmit={handleSubmit}>
          <div style={{
              display: "flex",
              justifyContent: "space-between",
              gap: "10px",
              marginBottom: "10px",
              padding: "10px",
              border: "2px solid rgba(255, 255, 255, 1.155)"
            }} >
            <InputArea>
              <Label>Código</Label>
              <Input style={{ color: "#fff" }} disabled={true} name="cod"  />
            </InputArea>
            <InputArea>
              <Label>Situação</Label>
              <Select1 style={{ width: "100px" }} name="st">
                <option value={1}>Ativo</option>
                <option value={0}>Inativo</option>
              </Select1>
            </InputArea>
            <InputArea>
              <Label>Descrição*</Label>
              <Input2 style={{ width: "660px" }} name="description" />
            </InputArea>
            </div>
            <InputArea>
              <Label>Dano*</Label>
              <Input2 style={{ width: "450px" }} name="damage" />
            </InputArea>
            <InputArea>
              <Label>Causas Prováveis*</Label>
              <Input2 style={{ width: "450px" }} name="probable_causes" />
            </InputArea>
            <InputArea>
              <Label>Conselho*</Label>
              <Input2 style={{ width: "450px" }} name="advice" />
            </InputArea>
            <InputArea>
              <Label>Destino*</Label>
              <Input2 style={{ width: "450px" }} name="destiny" />
            </InputArea>
            

            <div style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }} >
              <ButtonArea >
                <Button type="submit">SALVAR</Button>
              </ButtonArea>
              <ButtonArea >
              { regist === true? 
                 null
                 : 
                 <Button 
                    style={{backgroundColor:"red"}} 
                    onClick={handleDelete}>EXCLUIR <DeleteIcon/> 
                 </Button>
                 }
              </ButtonArea>
              <ButtonArea >
                <Button onClick={handleClose}>SAIR</Button>
              </ButtonArea>
            </div>

          </FormContainer>
          
      <Backdrop
              sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 5}}
              open={openL}
          >
         <Label>Carregando...</Label>
              <CircularProgress color="inherit" />
          </Backdrop>
    </>
  );
};

export default FromReasonScrapping;