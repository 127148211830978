import styled from "styled-components";

export const ButtonCustomizado = styled.button`
  outline: none;
  border: none;
  border-radius: 10px;
  width: 100%;
  background-color: #2c73d2;
  color: white;
  font-weight: 600;
  font-size: 16px;
  max-width: 350px;
  padding: 12px 20px;
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(5.5px);
  border-radius: 5px;
  border: 1px solid rgba(255, 255, 255, 0.18);   
  transition: .3s;
  cursor: pointer;
  z-index: 15; 
  opacity: 0.8;
  &:hover {  
  opacity: 1;
  }
  
`;