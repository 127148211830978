import styled from "styled-components";


export const FormContainer = styled.div`
  display: flex;
  padding: 5px;
  gap: 10px;
  flex-wrap: wrap;
  border-radius: 5px;
  align-items: center;
`;

export const Loc = styled.input`
  padding: 0 10px;
  border-radius: 5px;
  height: 40px;
  width: 200px;
  font-size: 12px;
  
`;

export const Label = styled.label`
font-size: 14px;
`

export const Select1 = styled.select`
  width: 175px;
  padding: 0 10px;
  border: 1px solid #bbb;
  border-radius: 5px;
  height: 30px;

  font-size: 12px;
`;

export const InputArea = styled.div`
  display: flex;
  gap: 20px;
  justify-content: space-between;
`;


export const Button = styled.button`
  cursor: pointer;
  border-radius: 5px;
  border: none;
  background-color: #2c73d2;
  color: white;
  height: 30px;
  width: 80px;
  font-size: 12px; 
  &:hover {
    cursor: pointer;
    background-color: #bbb;
    color: #000;
    transition: 0.3s ease-in-out;
  };
  
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(5.5px);
  border: 1px solid rgba(255, 255, 255, 0.18);
`;


export const Input = styled.input`
  width: 115px;
  padding: 0 10px;
  border: 1px solid #bbb;
  border-radius: 5px;
  height: 30px;
  font-size: 12px;
`;
