import React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import FromEmployees from "./FromEmployees";

import { Container, styledModal } from "../Styles/Styles";

const ModalEmployees = ({
  handleCloseL, 
  handleOpenL, 
  openL, 
  regist, 
  onEdit, 
  setOnEdit, 
  getEmployees, 
  setOpen, 
  open 
}) => {
  

  return (
    <>
      <Modal
        open={open}
        aria-labelledby="modal-modal-title"
      >
        <Box sx={styledModal}>
          <Container>
            <FromEmployees 
              handleCloseL={handleCloseL} 
              handleOpenL={handleOpenL}  
              openL={openL} 
              regist={regist} 
              onEdit={onEdit} 
              setOnEdit={setOnEdit} 
              getEmployees={getEmployees} 
              setOpen={setOpen}
            />
          </Container>
        </Box>
        
        
      </Modal>
    </>
  );
};

export default ModalEmployees;