import React, { useEffect, useRef } from "react";
import axios from "axios";
import {toast } from "react-toastify";
import Typography from '@mui/material/Typography';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import DeleteIcon from '@mui/icons-material/Delete';

import { 
  FormContainer, 
  InputArea, 
  Label, 
  Input, 
  Input2, 
  Input3, 
  ButtonArea, 
  Button,
  Select1
} from "../../Styles/Styles";

const FromAxisSchema = ({handleCloseL, openL, handleOpenL, regist, onEdit, setOnEdit, getAxisSchema, setOpen }) => {
  const ref = useRef();
  const handleClose = () => setOpen(false);
  const IdCompany = localStorage.getItem("id_company");
  const IdUser = localStorage.getItem("id");
  const [axisQuantity, setAxisQuantity] = React.useState(2);
  const [quantitySteppes, setQuantitySteppes] = React.useState(0);
  const [totalQuantityTires, setTotalQuantityTires] = React.useState(0);
  const [releaseField3, setReleaseField3] = React.useState( { value: "none" });
  const [releaseField4, setReleaseField4] = React.useState( { value: "none" });
  const [releaseField5, setReleaseField5] = React.useState( { value: "none" });
  const [releaseField6, setReleaseField6] = React.useState( { value: "none" });
  const [releaseField7, setReleaseField7] = React.useState( { value: "none" });
  const [releaseField8, setReleaseField8] = React.useState( { value: "none" });
  const [releaseField9, setReleaseField9] = React.useState( { value: "none" });

    const onAxisQuantityChange = (event) =>{
      onStatusChange(event.target.value)
    };

    const onSteppesQuantityChange = (event) =>{
      setQuantitySteppes(event.target.value);
      onTiresQuantityChange();
    };

    const onTiresQuantityChange = () =>{
      const axisSchema = ref.current;
      let quantity1 = axisSchema.axle_tire_quantity_1.value === ""? 0 : parseInt(axisSchema.axle_tire_quantity_1.value);
      let quantity2 = axisSchema.axle_tire_quantity_2.value === ""? 0 : parseInt(axisSchema.axle_tire_quantity_2.value);
      let quantity3 = axisSchema.axle_tire_quantity_3.value === ""? 0 : parseInt(axisSchema.axle_tire_quantity_3.value);
      let quantity4 = axisSchema.axle_tire_quantity_4.value === ""? 0 : parseInt(axisSchema.axle_tire_quantity_4.value);
      let quantity5 = axisSchema.axle_tire_quantity_5.value === ""? 0 : parseInt(axisSchema.axle_tire_quantity_5.value);
      let quantity6 = axisSchema.axle_tire_quantity_6.value === ""? 0 : parseInt(axisSchema.axle_tire_quantity_6.value);
      let quantity7 = axisSchema.axle_tire_quantity_7.value === ""? 0 : parseInt(axisSchema.axle_tire_quantity_7.value);
      let quantity8 = axisSchema.axle_tire_quantity_8.value === ""? 0 : parseInt(axisSchema.axle_tire_quantity_8.value);
      let quantity9 = axisSchema.axle_tire_quantity_9.value === ""? 0 : parseInt(axisSchema.axle_tire_quantity_9.value);
      let totalQuantityTires = quantity1 + quantity2 + quantity3 + quantity4 + quantity5 + quantity6 + quantity7 + quantity8 + quantity9;
      let steppers = axisSchema.quantity_steppes.value === ""? 0 : parseInt(axisSchema.quantity_steppes.value);
      setTotalQuantityTires(totalQuantityTires + steppers);
    };

    const onStatusChange = (a) =>{  

     let status = (a === undefined ? onEdit.axis_quantity : a);

    if(status === "2" ){
      setReleaseField3( { value: "none" });
      setReleaseField4( { value: "none" });
      setReleaseField5( { value: "none" });
      setReleaseField6( { value: "none" });
      setReleaseField7( { value: "none" });
      setReleaseField8( { value: "none" });
      setReleaseField9( { value: "none" });
    }
    if(status === "3" ){
      setReleaseField3( { value: "table-column" });
      setReleaseField4( { value: "none" });
      setReleaseField5( { value: "none" });
      setReleaseField6( { value: "none" });
      setReleaseField7( { value: "none" });
      setReleaseField8( { value: "none" });
      setReleaseField9( { value: "none" });
    }
    if(status === "4" ){
      setReleaseField3( { value: "table-column" });
      setReleaseField4( { value: "table-column" });

      setReleaseField5( { value: "none" });
      setReleaseField6( { value: "none" });
      setReleaseField7( { value: "none" });
      setReleaseField8( { value: "none" });
      setReleaseField9( { value: "none" });
    }  
    if(status === "5" ){
      setReleaseField3( { value: "table-column" });
      setReleaseField4( { value: "table-column" });
      setReleaseField5( { value: "table-column" });

      setReleaseField6( { value: "none" });
      setReleaseField7( { value: "none" });
      setReleaseField8( { value: "none" });
      setReleaseField9( { value: "none" });
    } 
    if(status === "6" ){
      setReleaseField3( { value: "table-column" });
      setReleaseField4( { value: "table-column" });
      setReleaseField5( { value: "table-column" });
      setReleaseField6( { value: "table-column" });

      setReleaseField7( { value: "none" });
      setReleaseField8( { value: "none" });
      setReleaseField9( { value: "none" });
    } 
    if(status === "7" ){
      setReleaseField3( { value: "table-column" });
      setReleaseField4( { value: "table-column" });
      setReleaseField5( { value: "table-column" });
      setReleaseField6( { value: "table-column" });
      setReleaseField7( { value: "table-column" });

      setReleaseField8( { value: "none" });
      setReleaseField9( { value: "none" });
    } 
    if(status === "8" ){
      setReleaseField3( { value: "table-column" });
      setReleaseField4( { value: "table-column" });
      setReleaseField5( { value: "table-column" });
      setReleaseField6( { value: "table-column" });
      setReleaseField7( { value: "table-column" });
      setReleaseField8( { value: "table-column" });

      setReleaseField9( { value: "none" });
    } 
    if(status === "9" ){
      setReleaseField3( { value: "table-column" });
      setReleaseField4( { value: "table-column" });
      setReleaseField5( { value: "table-column" });
      setReleaseField6( { value: "table-column" });
      setReleaseField7( { value: "table-column" });
      setReleaseField8( { value: "table-column" });
      setReleaseField9( { value: "table-column" });
    }
    
        onTiresQuantityChange();
   
    setAxisQuantity(status);
   }

   const cleanearFields = () => {
    const axisSchema = ref.current;
    axisSchema.cod.value = "";
    axisSchema.description.value = "";
    axisSchema.axle_tire_quantity_1.value = "";
    axisSchema.minimum_pressure_axle_1.value = "";
    axisSchema.axle_tire_quantity_2.value = "";
    axisSchema.minimum_pressure_axle_2.value = "";
    axisSchema.axle_tire_quantity_3.value = "";
    axisSchema.minimum_pressure_axle_3.value = "";
    axisSchema.axle_tire_quantity_4.value = "";
    axisSchema.minimum_pressure_axle_4.value = "";
    axisSchema.axle_tire_quantity_5.value = "";
    axisSchema.minimum_pressure_axle_5.value = "";
    axisSchema.axle_tire_quantity_6.value = "";
    axisSchema.minimum_pressure_axle_6.value = "";
    axisSchema.axle_tire_quantity_7.value = "";
    axisSchema.minimum_pressure_axle_7.value = "";
    axisSchema.axle_tire_quantity_8.value = "";
    axisSchema.minimum_pressure_axle_8.value = "";
    axisSchema.axle_tire_quantity_9.value = "";
    axisSchema.minimum_pressure_axle_9.value = "";
    axisSchema.axis_quantity.value = 2;
    axisSchema.quantity_steppes.value = "";
   }

  useEffect(() => {
    const axisSchema = ref.current;
    if(regist) {
      cleanearFields();
    } else {
    if (onEdit) {
      axisSchema.cod.value = onEdit.id;
      axisSchema.description.value = onEdit.description;
      axisSchema.axle_tire_quantity_1.value = onEdit.axle_tire_quantity_1;
      axisSchema.minimum_pressure_axle_1.value = onEdit.minimum_pressure_axle_1;
      axisSchema.axle_tire_quantity_2.value = onEdit.axle_tire_quantity_2;
      axisSchema.minimum_pressure_axle_2.value = onEdit.minimum_pressure_axle_2;
      axisSchema.axle_tire_quantity_3.value = onEdit.axle_tire_quantity_3;
      axisSchema.minimum_pressure_axle_3.value = onEdit.minimum_pressure_axle_3;
      axisSchema.axle_tire_quantity_4.value = onEdit.axle_tire_quantity_4;
      axisSchema.minimum_pressure_axle_4.value = onEdit.minimum_pressure_axle_4;
      axisSchema.axle_tire_quantity_5.value = onEdit.axle_tire_quantity_5;
      axisSchema.minimum_pressure_axle_5.value = onEdit.minimum_pressure_axle_5;
      axisSchema.axle_tire_quantity_6.value = onEdit.axle_tire_quantity_6;
      axisSchema.minimum_pressure_axle_6.value = onEdit.minimum_pressure_axle_6;
      axisSchema.axle_tire_quantity_7.value = onEdit.axle_tire_quantity_7;
      axisSchema.minimum_pressure_axle_7.value = onEdit.minimum_pressure_axle_7;
      axisSchema.axle_tire_quantity_8.value = onEdit.axle_tire_quantity_8;
      axisSchema.minimum_pressure_axle_8.value = onEdit.minimum_pressure_axle_8;
      axisSchema.axle_tire_quantity_9.value = onEdit.axle_tire_quantity_9;
      axisSchema.minimum_pressure_axle_9.value = onEdit.minimum_pressure_axle_9;
      axisSchema.axis_quantity.value = onEdit.axis_quantity;
      axisSchema.quantity_steppes.value = onEdit.quantity_steppes;
      setAxisQuantity(onEdit.axis_quantity);
      setQuantitySteppes(onEdit.quantity_steppes);
      setTotalQuantityTires(onEdit.total_quantity_tires);
      onStatusChange();
    };
  };
  // eslint-disable-next-line
  }, []);

  const handleDelete = async (e) => {
    e.preventDefault();
    const axisSchema = ref.current;
    handleOpenL();

    if (!window.confirm("Tem certeza que deseja excluir?")) {
      handleCloseL();
      return;
    } else {
    await axios
     .delete("tires/axis-schema-list/"+ axisSchema.cod.value)
     .then(() => {
        toast.success("Cadastro excluido com sucesso!");
        getAxisSchema();
        setOnEdit(null);
        handleClose();
     })
     .catch(() => {
      handleCloseL();
      toast.error("Erro, não foi possível excluir!");});
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    handleOpenL();
  
    const axisSchema = ref.current;
      if (
        !axisSchema.description.value ||
        !axisSchema.axle_tire_quantity_1.value ||
        !axisSchema.axle_tire_quantity_2.value ||
        !axisSchema.quantity_steppes.value
      ) {
        handleCloseL();
        return toast.warn("Preencha todos os campos com ( * )!");
      }

    if (onEdit) {
      await axios
        .put("tires/axis-schema-list/", {
          id: axisSchema.cod.value,
          description: axisSchema.description.value.trim(),
          axle_tire_quantity_1: axisSchema.axle_tire_quantity_1.value,
          minimum_pressure_axle_1: axisSchema.minimum_pressure_axle_1.value.trim(),
          axle_tire_quantity_2: axisSchema.axle_tire_quantity_2.value,
          minimum_pressure_axle_2: axisSchema.minimum_pressure_axle_2.value.trim(),
          axle_tire_quantity_3: axisSchema.axle_tire_quantity_3.value,
          minimum_pressure_axle_3: axisSchema.minimum_pressure_axle_3.value.trim(),
          axle_tire_quantity_4: axisSchema.axle_tire_quantity_4.value,
          minimum_pressure_axle_4: axisSchema.minimum_pressure_axle_4.value.trim(),
          axle_tire_quantity_5: axisSchema.axle_tire_quantity_5.value,
          minimum_pressure_axle_5: axisSchema.minimum_pressure_axle_5.value.trim(),
          axle_tire_quantity_6: axisSchema.axle_tire_quantity_6.value,
          minimum_pressure_axle_6: axisSchema.minimum_pressure_axle_6.value.trim(),
          axle_tire_quantity_7: axisSchema.axle_tire_quantity_7.value,
          minimum_pressure_axle_7: axisSchema.minimum_pressure_axle_7.value.trim(),
          axle_tire_quantity_8: axisSchema.axle_tire_quantity_8.value,
          minimum_pressure_axle_8: axisSchema.minimum_pressure_axle_8.value.trim(),
          axle_tire_quantity_9: axisSchema.axle_tire_quantity_9.value,
          minimum_pressure_axle_9: axisSchema.minimum_pressure_axle_9.value.trim(),
          axis_quantity: axisSchema.axis_quantity.value,
          quantity_steppes: axisSchema.quantity_steppes.value,
          total_quantity_tires: totalQuantityTires,
          st: axisSchema.st.value,
          user :IdUser,
        })
        .then(() => {
          toast.success("Cadastro atualizado com sucesso!");

          getAxisSchema();
          cleanearFields();
          setOnEdit(null);
          handleClose();
        })
        .catch(() => {
          handleCloseL();
          toast.error("Erro na atualizado!");
        });
    } else {
      await axios
        .post("tires/axis-schema-list/", {
          description: axisSchema.description.value.trim(),
          axle_tire_quantity_1: axisSchema.axle_tire_quantity_1.value,
          minimum_pressure_axle_1: axisSchema.minimum_pressure_axle_1.value.trim(),
          axle_tire_quantity_2: axisSchema.axle_tire_quantity_2.value,
          minimum_pressure_axle_2: axisSchema.minimum_pressure_axle_2.value.trim(),
          axle_tire_quantity_3: axisSchema.axle_tire_quantity_3.value,
          minimum_pressure_axle_3: axisSchema.minimum_pressure_axle_3.value.trim(),
          axle_tire_quantity_4: axisSchema.axle_tire_quantity_4.value,
          minimum_pressure_axle_4: axisSchema.minimum_pressure_axle_4.value.trim(),
          axle_tire_quantity_5: axisSchema.axle_tire_quantity_5.value,
          minimum_pressure_axle_5: axisSchema.minimum_pressure_axle_5.value.trim(),
          axle_tire_quantity_6: axisSchema.axle_tire_quantity_6.value,
          minimum_pressure_axle_6: axisSchema.minimum_pressure_axle_6.value.trim(),
          axle_tire_quantity_7: axisSchema.axle_tire_quantity_7.value,
          minimum_pressure_axle_7: axisSchema.minimum_pressure_axle_7.value.trim(),
          axle_tire_quantity_8: axisSchema.axle_tire_quantity_8.value,
          minimum_pressure_axle_8: axisSchema.minimum_pressure_axle_8.value.trim(),
          axle_tire_quantity_9: axisSchema.axle_tire_quantity_9.value,
          minimum_pressure_axle_9: axisSchema.minimum_pressure_axle_9.value.trim(),
          axis_quantity: axisSchema.axis_quantity.value,
          quantity_steppes: axisSchema.quantity_steppes.value,
          total_quantity_tires: totalQuantityTires,
          st: axisSchema.st.value,
          id_company: IdCompany,
          user :IdUser,
        })
        .then(() => {
          toast.success("Cadastrado com sucesso!");
          
          cleanearFields();
          getAxisSchema();
          setOnEdit(null);
          handleClose();
        })
        .catch(() => {
          handleCloseL();
          toast.error("Erro no Cadastro!");
        });
        
    }


  };

  return (
    <>
          <Typography style={{ color: "#fff" }} id="modal-modal-title" variant="h6" component="h2">
            Ficha do Esquema de Eixo
          </Typography>
          <FormContainer  ref={ref} onSubmit={handleSubmit}>
          <div style={{
              display: "flex",
              justifyContent: "space-between",
              gap: "10px",
              marginBottom: "10px",
              padding: "10px",
              border: "2px solid rgba(255, 255, 255, 1.155)"
            }} >
            <InputArea>
              <Label>Código</Label>
              <Input style={{ color: "#fff" }} disabled={true} name="cod"  />
            </InputArea>
            <InputArea>
              <Label>Situação</Label>
              <Select1 style={{ width: "100px" }} name="st">
                <option value={1}>Ativo</option>
                <option value={0}>Inativo</option>
              </Select1>
            </InputArea>
            <InputArea>
              <Label>Descrição*</Label>
              <Input2 style={{ width: "530px" }} name="description" />
            </InputArea>
              <InputArea>
                <Label>Qtde Eixo</Label>
                <Select1 onChange={onAxisQuantityChange} style={{ width: "80px" }} name="axis_quantity">
                  <option value={2}>2</option>
                  <option value={3}>3</option>
                  <option value={4}>4</option>
                </Select1>
              </InputArea>
            </div>
            <div style={{
                display: "column",
                justifyContent: "space-between",
                gap: "10px",
                marginBottom: "10px",
                padding: "10px",
                border: "2px solid rgba(255, 255, 255, 1.155)"
              }} >
              <InputArea>
                <Label>Qtde Pneu Eixo 1</Label>
                <Select1 onChange={onTiresQuantityChange} style={{ width: "120px" }} name="axle_tire_quantity_1">
                  <option value="">Selecione</option>
                  <option value={2}>2 Pneus</option>
                </Select1>
              </InputArea>
              <InputArea>
                <Label>Pressão mínima</Label>
                <Input3 style={{ width: "120px" }} type="number" pattern="/([0-9]{3}).([0-9]{3}),([0-9]{2})$/g, '.$1.$2,$3'" min="0" step="any" placeholder="Só número" name="minimum_pressure_axle_1" />
              </InputArea>
              </div>
            <div style={{
                display: "column",
                justifyContent: "space-between",
                gap: "10px",
                marginBottom: "10px",
                padding: "10px",
                border: "2px solid rgba(255, 255, 255, 1.155)"
              }} >
              <InputArea>
                <Label>Qtde Pneu Eixo 2</Label>
                <Select1 onChange={onTiresQuantityChange} style={{ width: "120px" }} name="axle_tire_quantity_2">
                <option value="">Selecione</option>
                  <option value={2}>2 Pneus</option>
                  <option value={4}>4 Pneus</option>
                </Select1>
              </InputArea>
              <InputArea>
                <Label>Pressão mínima</Label>
                <Input3 style={{ width: "120px" }} type="number" pattern="/([0-9]{3}).([0-9]{3}),([0-9]{2})$/g, '.$1.$2,$3'" min="0" step="any" placeholder="Só número" name="minimum_pressure_axle_2" />
              </InputArea>
              </div>
            <div style={{
                display: releaseField3.value,
                justifyContent: "space-between",
                gap: "10px",
                marginBottom: "10px",
                padding: "10px",
                border: "2px solid rgba(255, 255, 255, 1.155)"
              }} >
              <InputArea>
                <Label>Qtde Pneu Eixo 3</Label>
                <Select1 onChange={onTiresQuantityChange} style={{ width: "120px" }} name="axle_tire_quantity_3">
                <option value="">Selecione</option>
                  <option value={2}>2 Pneus</option>
                  <option value={4}>4 Pneus</option>
                </Select1>
              </InputArea>
              <InputArea>
                <Label>Pressão mínima</Label>
                <Input3 style={{ width: "120px" }} type="number" pattern="/([0-9]{3}).([0-9]{3}),([0-9]{2})$/g, '.$1.$2,$3'" min="0" step="any" placeholder="Só número" name="minimum_pressure_axle_3" />
              </InputArea>
              </div>
            <div style={{
                display: releaseField4.value,
                justifyContent: "space-between",
                gap: "10px",
                marginBottom: "10px",
                padding: "10px",
                border: "2px solid rgba(255, 255, 255, 1.155)"
              }} >
              <InputArea>
                <Label>Qtde Pneu Eixo 4</Label>
                <Select1 onChange={onTiresQuantityChange} style={{ width: "120px" }} name="axle_tire_quantity_4">
                <option value="">Selecione</option>
                  <option value={2}>2 Pneus</option>
                  <option value={4}>4 Pneus</option>
                </Select1>
              </InputArea>
              <InputArea>
                <Label>Pressão mínima</Label>
                <Input3 style={{ width: "120px" }} type="number" pattern="/([0-9]{3}).([0-9]{3}),([0-9]{2})$/g, '.$1.$2,$3'" min="0" step="any" placeholder="Só número" name="minimum_pressure_axle_4" />
              </InputArea>
              </div>
            <div style={{
                display: releaseField5.value,
                justifyContent: "space-between",
                gap: "10px",
                marginBottom: "10px",
                padding: "10px",
                border: "2px solid rgba(255, 255, 255, 1.155)"
              }} >
              <InputArea>
                <Label>Qtde Pneu Eixo 5</Label>
                <Select1 onChange={onTiresQuantityChange} style={{ width: "120px" }} name="axle_tire_quantity_5">
                <option value="">Selecione</option>
                  <option value={2}>2 Pneus</option>
                  <option value={4}>4 Pneus</option>
                </Select1>
              </InputArea>
              <InputArea>
                <Label>Pressão mínima</Label>
                <Input3 style={{ width: "120px" }} type="number" pattern="/([0-9]{3}).([0-9]{3}),([0-9]{2})$/g, '.$1.$2,$3'" min="0" step="any" placeholder="Só número" name="minimum_pressure_axle_5" />
              </InputArea>
              </div>
            <div style={{
                display: releaseField6.value,
                justifyContent: "space-between",
                gap: "10px",
                marginBottom: "10px",
                padding: "10px",
                border: "2px solid rgba(255, 255, 255, 1.155)"
              }} >
              <InputArea>
                <Label>Qtde Pneu Eixo 6</Label>
                <Select1 onChange={onTiresQuantityChange} style={{ width: "120px" }} name="axle_tire_quantity_6">
                <option value="">Selecione</option>
                  <option value={2}>2 Pneus</option>
                  <option value={4}>4 Pneus</option>
                </Select1>
              </InputArea>
              <InputArea>
                <Label>Pressão mínima</Label>
                <Input3 style={{ width: "120px" }} type="number" pattern="/([0-9]{3}).([0-9]{3}),([0-9]{2})$/g, '.$1.$2,$3'" min="0" step="any" placeholder="Só número" name="minimum_pressure_axle_6" />
              </InputArea>
              </div>
            <div style={{
                display: releaseField7.value,
                justifyContent: "space-between",
                gap: "10px",
                marginBottom: "10px",
                padding: "10px",
                border: "2px solid rgba(255, 255, 255, 1.155)"
              }} >
              <InputArea>
                <Label>Qtde Pneu Eixo 7</Label>
                <Select1 onChange={onTiresQuantityChange} style={{ width: "120px" }} name="axle_tire_quantity_7">
                <option value="">Selecione</option>
                  <option value={2}>2 Pneus</option>
                  <option value={4}>4 Pneus</option>
                </Select1>
              </InputArea>
              <InputArea>
                <Label>Pressão mínima</Label>
                <Input3 style={{ width: "120px" }} type="number" pattern="/([0-9]{3}).([0-9]{3}),([0-9]{2})$/g, '.$1.$2,$3'" min="0" step="any" placeholder="Só número" name="minimum_pressure_axle_7" />
              </InputArea>
              </div>
            <div style={{
                display: releaseField8.value,
                justifyContent: "space-between",
                gap: "10px",
                marginBottom: "10px",
                padding: "10px",
                border: "2px solid rgba(255, 255, 255, 1.155)"
              }} >
              <InputArea>
                <Label>Qtde Pneu Eixo 8</Label>
                <Select1 onChange={onTiresQuantityChange} style={{ width: "120px" }} name="axle_tire_quantity_8">
                <option value="">Selecione</option>
                  <option value={2}>2 Pneus</option>
                  <option value={4}>4 Pneus</option>
                </Select1>
              </InputArea>
              <InputArea>
                <Label>Pressão mínima</Label>
                <Input3 style={{ width: "120px" }} type="number" pattern="/([0-9]{3}).([0-9]{3}),([0-9]{2})$/g, '.$1.$2,$3'" min="0" step="any" placeholder="Só número" name="minimum_pressure_axle_8" />
              </InputArea>
              </div>
            <div style={{
                display: releaseField9.value,
                justifyContent: "space-between",
                gap: "10px",
                marginBottom: "10px",
                padding: "10px",
                border: "2px solid rgba(255, 255, 255, 1.155)"
              }} >
              <InputArea>
                <Label>Qtde Pneu Eixo 9</Label>
                <Select1 onChange={onTiresQuantityChange} style={{ width: "120px" }} name="axle_tire_quantity_9">
                <option value="">Selecione</option>
                  <option value={2}>2 Pneus</option>
                  <option value={4}>4 Pneus</option>
                </Select1>
              </InputArea>
              <InputArea>
                <Label>Pressão mínima</Label>
                <Input3 style={{ width: "120px" }} type="number" pattern="/([0-9]{3}).([0-9]{3}),([0-9]{2})$/g, '.$1.$2,$3'" min="0" step="any" placeholder="Só número" name="minimum_pressure_axle_9" />
              </InputArea>
              </div>
            <div style={{
                display: "column",
                justifyContent: "space-between",
                gap: "10px",
                marginBottom: "10px",
                padding: "10px",
                border: "2px solid rgba(255, 255, 255, 1.155)"
              }} >
              <InputArea>
                <Label>Qtde Pneu Estepe</Label>
                <Select1 onChange={onSteppesQuantityChange} style={{ width: "120px" }} name="quantity_steppes">
                <option value="">Selecione</option>
                  <option value={1}>1 Pneus</option>
                  <option value={2}>2 Pneus</option>
                </Select1>
              </InputArea>
              </div>
            <div style={{
                display: "column",
                justifyContent: "space-between",
                gap: "10px",
                marginBottom: "10px",
                padding: "10px",
                border: "2px solid rgba(255, 255, 255, 1.155)"
              }} >
              <InputArea>
                <Label>Quantidade de Eixo: {axisQuantity}</Label>
                <Label>Quantidade de Estepe: {quantitySteppes}</Label>
                <Label>Quantidade Total de Pneus: {totalQuantityTires} </Label>
              </InputArea>
              </div>
            <div style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }} >
              <ButtonArea >
                <Button type="submit">SALVAR</Button>
              </ButtonArea>
              <ButtonArea >
              { regist === true? 
                 null
                 : 
                 <Button 
                    style={{backgroundColor:"red"}} 
                    onClick={handleDelete}>EXCLUIR <DeleteIcon/> 
                 </Button>
                 }
              </ButtonArea>
              <ButtonArea >
                <Button onClick={handleClose}>SAIR</Button>
              </ButtonArea>
            </div>

          </FormContainer>
          
      <Backdrop
              sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 5}}
              open={openL}
          >
         <Label>Carregando...</Label>
              <CircularProgress color="inherit" />
          </Backdrop>
    </>
  );
};

export default FromAxisSchema;