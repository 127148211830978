import React from 'react';
import {ButtonCustomizado} from "./styles"

const Button = ({ 
  Text, 
  onClick, 
  Type = "button",
  disabled, 
}) => {
  return(
    <ButtonCustomizado
      type={Type} 
      onClick={onClick}
      disabled={disabled}
    >
       {Text}
    </ButtonCustomizado>
  )
}

export default Button;