import React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import {Button, Container, styledModal } from "../Styles/Styles";
import FromAddProduct from "./FromAddProduct";


const ModalAddProtuct = ({ 
  idOs, 
  getProducts, 
  totalProducts, 
  getOrderService, 
  getOS, 
  dateCurrent,
  kmCurrent,
  osVehicle 
}) => {
  const [open, setOpen] = React.useState(false);
  const handleClose = () => setOpen(false);

  const handleOpen = () => { 
    return setOpen(true);  
  };

  

  return (
    <>
    <div style={{
      display: "flex",
      justifyContent: "space-between",
      width: "100%",
    }} >
    <h5 style={{ marginRight: "10px"}} 
    >Total {Intl.NumberFormat('pt-br', { style: 'currency', currency: 'BRL' }).format(totalProducts)}</h5>
    <Button onClick={handleOpen}>Adicionar Produtos</Button>
    </div>
      <Modal
        open={open}
        aria-labelledby="modal-modal-title"
        
      >
        <Box sx={styledModal}>
          <Container>
            <FromAddProduct 
            handleClose={handleClose} 
            idOs={idOs} 
            getProducts={getProducts} 
            getOrderService={getOrderService} 
            getOS={getOS} 
            dateCurrent={dateCurrent}
            kmCurrent={kmCurrent}
            osVehicle={osVehicle} 
            />
          </Container>
        </Box>
        
        
      </Modal>
      
    </>
  );
};

export default ModalAddProtuct;