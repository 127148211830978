import React, { useRef, useEffect } from "react";
import axios from "axios";
import {toast } from "react-toastify";
import Typography from '@mui/material/Typography';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import {geraIterAleatoria} from "../Function/Base";
import { 
  FormContainer, 
  InputArea, 
  Label, 
  Input, 
  Select1, 
  ButtonArea, 
  Button, 
  InputDate
} from "../Styles/Styles"


const FromAddProduct = ({ 
  handleClose, 
  idOs, 
  getProducts, 
  getOrderService, 
  getOS, 
  dateCurrent,
  kmCurrent,
  osVehicle
}) => {
  const ref = useRef();
  const IdCompany = localStorage.getItem("id_company");
  const IdUser = localStorage.getItem("id");
  const [loading, setLoading] = React.useState(false);
  const [products, setProducts] = React.useState([]);
  const escriptionProduct = useRef("");
  const barCodeProduct = useRef("");
  const cal = useRef(0);
  const term = useRef(0);
  const [alterColor, setAlterColor] = React.useState({value:"#fff"});
  const [releaseField, setReleaseField] = React.useState(true);

  const typeReg = "P";
  const statusReg = "1";

  function adicionaZero(numero) {
    if (numero <= 9)
      return "0" + numero;
    else
      return numero;
  };

  const onServiceChange = (event) =>{   
   const serv = ref.current;
   const res = products.filter((a) => (a.id === event.target.value));
   // eslint-disable-next-line
       res.map((a) => {
        escriptionProduct.current = a.description;
        serv.price.value = a.value_vlr;
        serv.amount.value = 1;   
        serv.total.value = a.value_vlr;
        barCodeProduct.current = a.bar_code;
       });
  };

  const onTermChange = (event) =>{   
    if(event.target.value === "1"){
      term.current = 1;
      setAlterColor({value:"#000"});
      setReleaseField(false); 
      return;
    } 
    if(event.target.value === "2"){
      term.current = 2;
      setAlterColor({value:"#000"});
      setReleaseField(false); 
      return;
    } else{
      term.current = 0;
      setAlterColor({value:"#fff"});
      setReleaseField(true);
      return;
    }
   };

   const onCalChange = () =>{
    const serv = ref.current;
    const quantity = serv.amount.value.trim();
    const initial = serv.price.value.trim();
    cal.current = (parseFloat((+initial) * (+quantity)))
    serv.total.value = cal.current;
   };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const serv = ref.current;
    const idCode = geraIterAleatoria(16);

    if (
      !serv.id_product.value 
    ) {
      setLoading(false);
      return toast.warn("Preencha todos os campos!");
    }
    if(term.current === 1){
      if (
        !serv.term_km.value 
      ) {
        setLoading(false);
        return toast.warn("Preencha o campo Prazo KM!");
      }
    }
    if(term.current === 2){
      if (
        !serv.term_day.value 
      ) {
        setLoading(false);
        return toast.warn("Preencha o campo Prazo Dias!");
      }
    }

    if(term.current !== 0){
      var time = new Date();
      var nextMaintenanceDate = ""
      var km = (kmCurrent === undefined ? 0 : kmCurrent);
      var termKm = 0;
      var termDay = 0;
      if(term.current === 1){
       termKm = (serv.term_km.value === undefined ? 0 :serv.term_km.value);
      } else{
       termDay = (serv.term_day.value === undefined? 0 : serv.term_day.value);
       let newD = new Date(time.setDate((+time.getDate()) +(+termDay)));
       let newDate = (newD.getFullYear() + "-" + (adicionaZero(newD.getMonth() + 1).toString()) + "-" + adicionaZero((newD.getDate()).toString()));
       nextMaintenanceDate = newDate;
      }
      await axios
      .post("maintenance-control/maintenance-control-list/", {
        id_vehicle: osVehicle,
        maintenance_date: dateCurrent,
        km_maintenance: (kmCurrent === undefined ? 0 : kmCurrent),
        maintenance_description: escriptionProduct.current,
        term: (term.current === 1? termKm.trim() : termDay.trim()),
        unit: term.current,
        km_next_maintenance: (term.current === 1? (parseFloat((+km) + (+termKm))) : ""),
        next_maintenance_date: nextMaintenanceDate,
        cost: serv.price.value.trim(),
        id_company: IdCompany,
        user: IdUser,
      })
      .then(() => {
        toast.success("Controle de manutenção cadastrado com sucesso!");
      })
      .catch(() => {
        toast.error("Erro no Cadastro!");
        setLoading(false);
      });
    }

    await axios
      .post("order-service/order-service-Products-list/", {
        id_order_service: idOs,
        id_product: serv.id_product.value,
        bar_code: barCodeProduct.current,
        Identification_code: idCode,
        product: escriptionProduct.current,
        price: serv.price.value.trim(),
        amount: serv.amount.value.trim(),
        total: serv.total.value,
        id_company: IdCompany,
        user: IdUser,
      })
      .then(() => {
        toast.success("Produto cadastrado com sucesso!");

        setLoading(false);
        getProducts();
        getOrderService();
        getOS();
        handleClose();
      })
      .catch(() => {
        toast.error("Erro no Cadastro!");
        setLoading(false);
      });
  };

  const getProduct = async () => {
    setLoading(true);
    setProducts([]);
    try {
      const res = await axios.get("products-services/products-services-list/" + IdCompany + "/" + typeReg + "/" + statusReg + "/?loc=%");
      
        setProducts(res.data.sort((a, b) => (a.description > b.description ? 1 : -1)));
      
      setLoading(false);
    } catch (error) {
      toast.error(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    getProduct();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <Typography style={{ color: "#fff" }} id="modal-modal-title" variant="h6" component="h2">
        Adicionar Produtos
      </Typography>
      <FormContainer ref={ref} onSubmit={handleSubmit}>
        <InputArea>
          <Label>Código O.S</Label>
          <Input style={{ color: "#fff" }} disabled={true} name="id_order_service" value={idOs} />
        </InputArea>
        <InputArea>
          <Label>Produto*</Label>
          <Select1 onChange={onServiceChange} style={{ width: "710px" }} name="id_product">
          <option value="">Selecione</option>
          {products === undefined ? [] : products.map((item, i) => (
                  <option key={i} value={item.id}>{item.bar_code} - {item.description}</option>
                ))}
          </Select1>
        </InputArea>
        <InputArea>
          <Label>Quantidade</Label>
          <Input onChange={onCalChange} type="number" pattern="[0-9]+([,\.][0-9]+)?" min="0" step="any" placeholder="Digite seu número" name="amount" />
        </InputArea>
        <InputArea>
          <Label>Valor Unidade</Label>
          <Input onChange={onCalChange} type="number" pattern="[0-9]+([,\.][0-9]+)?" min="0" step="any" placeholder="Digite seu número" name="price" />
        </InputArea>
        <InputArea>
          <Label>Total</Label>
          <Input style={{ color: "#fff" }} disabled={true} type="number" pattern="[0-9]+([,\.][0-9]+)?" min="0" step="any" placeholder="Digite seu número" name="total" />
        </InputArea>
        <InputArea>
          <Label>Controle de manutenção</Label>
          <Select1 onChange={onTermChange} name="category" >
            <option value={0}>Selecione</option>
            <option value={1}>KM</option>
            <option value={2}>Dias</option>
          </Select1>
        </InputArea>
        { term.current === 1?
        <InputArea>
          <Label>Prazo KM</Label>
          <Input style={{ color:{alterColor} }} disabled={releaseField} type="number" pattern="[0-9]+([,\.][0-9]+)?" min="0" step="any" placeholder="Digite seu número" name="term_km" />
        </InputArea>
        : 
        <InputArea>
          <Label>Prazo Dias</Label>
          <InputDate style={{ color:{alterColor} }} disabled={releaseField} type="number" pattern="[0-9]+([,\.][0-9]+)?" min="0" step="any" placeholder="Digite seu número" name="term_day" />
        </InputArea>
        }

        <div style={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
        }} >
          <ButtonArea >
            <Button type="submit">SALVAR</Button>
          </ButtonArea>
          <ButtonArea >
            <Button onClick={handleClose}>SAIR</Button>
          </ButtonArea>
        </div>

      </FormContainer>

      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 5 }}
        open={loading}
      >
         <Label>Carregando...</Label>
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};

export default FromAddProduct;