
import React, { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import { signout } from "../../Services/UserService";
import ContentPage from "../../components/Content/ContentPage";
import ModalXml from "../../components/ImportXml/ModalXml";
import { toast } from "react-toastify";
import axios from "axios";
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import {
  FormContainer,
  Loc,
  InputArea,
  Button,
  Input
} from "../Styles/Styles"
import { Label } from "../../components/Styles/Styles";
import GridImportXml from "../../components/ImportXml/GridImportXml";


const ImportProducts = () => {
  const navigate = useNavigate();
  const [releases, setReleases] = useState([]);
  const [onEdit, setOnEdit] = useState(null);
  const [open, setOpen] = React.useState(false);
  const [loc, setLoc] = React.useState();
  const [regist, setRegist] = React.useState(false);
  const IdCompany = localStorage.getItem("id_company");
  const [title, setTitle] = React.useState("");

  const [openL, setOpenL] = React.useState(false);

  const handleCloseL = () => {
    setOpenL(false);
  };
  const handleOpenL = () => {
    setOpenL(true);
  };


  const handleOpen = () => {
    setOpen(true);
    setRegist(true);
    setOnEdit(null);
  };

  function adicionaZero(numero) {
    if (numero <= 9)
      return "0" + numero;
    else
      return numero;
  }

  let dataAtual = new Date();
  const DateStart = ((dataAtual.getFullYear()) + "-" + (adicionaZero(dataAtual.getMonth() + 1).toString()) +"-01");
  const DateEnd = ((dataAtual.getFullYear()) + '-' + (adicionaZero(dataAtual.getMonth() + 1)) + '-' + adicionaZero(dataAtual.getDate()));

  const [locDate, setLocDate] = React.useState(DateStart);
  const [locDateF, setLocDateF] = React.useState(DateEnd);

  const getReleases = async () => {
    handleOpenL();
    setReleases([]);
    setOnEdit(null);
    setTitle("Aguarde... Verificando nota fiscal!");

    if (locDate > locDateF) {
      toast.error("A data inicial não pode ser maior que a data final!");
      handleCloseL();
      return;
    };

    try {
      const res = await axios.get("buy-products/buy-list/" +
        IdCompany + "/" + locDate + "/" + locDateF + "/?loc=" + (loc === undefined ? '%' : loc));
      if (res.data[0].status === "false") {
        toast.error(res.data[0].message);
        signout();
        navigate("/login");
      } else {
        setReleases(res.data.sort((a, b) => (a.id > b.id ? 1 : -1)));
      }
      handleCloseL();
    } catch (error) {
      toast.error(error);
      handleCloseL();
    }
  };


  useEffect(() => {
    getReleases();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <ContentPage caminho={[{ nome: "Estoque > Notas fiscais de entrada", link: "/import-xml-products/list" }]}>

        <ModalXml
          regist={regist}
          releases={releases}
          setReleases={setReleases}
          setLoc={setLoc}
          loc={loc}
          onEdit={onEdit}
          setOnEdit={setOnEdit}
          getReleases={getReleases}
          setOpen={setOpen}
          open={open}
          openL={openL}
          handleOpenL={handleOpenL}
          handleCloseL={handleCloseL}
          locDate={locDate}
        />

        <FormContainer>
          <Loc
            style={{
              width: "300px",
              height: "30px",
              border: "1px solid #343f46",
              textTransform: "uppercase",
              fontSize: "12px"
            }}
            type='text'
            placeholder="Digite nome fornecedor"
            value={loc}
            onChange={(e) => [setLoc(e.target.value)]}
          />
          <InputArea>
            <Input type="date"
              value={locDate}
              onChange={(e) => [setLocDate(e.target.value)]}
            />
          </InputArea>

          <InputArea>
            <Label style={{ color: "#242a38" }}>à</Label>
          </InputArea>

          <InputArea>
            <Input type="date"
              value={locDateF}
              onChange={(e) => [setLocDateF(e.target.value)]}
            />
          </InputArea>

          <InputArea >
            <Button
              onClick={getReleases}>Localizar
            </Button>
          </InputArea>
          <InputArea  >
            <Button
              onClick={handleOpen}>Novo
            </Button>
          </InputArea>
        </FormContainer>
        <GridImportXml releases={releases} setOnEdit={setOnEdit} 
          setOpen={setOpen}/>
      </ContentPage>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 15 }}
        open={openL}
      >
        <Label>{title}</Label>
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};

export default ImportProducts;