import * as React from 'react';
import { GridActionsCellItem } from '@mui/x-data-grid';
import EditIcon from '@mui/icons-material/Edit';

import { DataGrid, GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';
import { useDemoData } from '@mui/x-data-grid-generator';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

import { 
  Table, 
  Tbody, 
  Label
} from "../../Styles/Styles";

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarExport />
    </GridToolbarContainer>
  );
}


const GridTireMovement = ({openL, setRegist, tireMovement, setOnEdit, setOpen }) => {

  const alterUsers = React.useCallback(
    (id) => () => {
      const res = tireMovement.filter((a) => (a.id === id));
       // eslint-disable-next-line
       res.map((a) => {
        handleEdit(a);
       });
    },
    [tireMovement],
  );
  const data = {
    columns: [
      {
        field: 'actions',
        type: 'actions',
        headerName: 'Ações',
        width: 80,
        getActions: (params) => [
          <GridActionsCellItem
            icon={<EditIcon style={{
              color: "#2c73d2"}} />}
            label="Delete"
            onClick={alterUsers(params.id)}
          />,
        ],
      },
      { field: 'tire_status', headerName: 'Status Pneu', width: 200 },
      { field: 'movement_date', headerName: 'Data Mov.', width: 150 },
      { field: 'fire_number', headerName: 'Número fogo', width: 130 },
      { field: 'surname', headerName: 'Veículo', width: 150 },
      { field: 'axle_positioning', headerName: 'Posicionamento ( Eixo )', width: 200 },
      { field: 'identification', headerName: 'Identificação', width: 700 },
      { field: 'name', headerName: 'Usuário', width: 200 },
    ],
    rows: tireMovement === undefined? [] : tireMovement.map((item) => ({
      id: item.id,
      movement_date: item.movement_date.split("-").reverse().join("/"),
      identification: item.identification,
      surname: item.surname,
      fire_number: item.fire_number,
      tire_status: (
        item.tire_status === "0"? "Estoque" :
        item.tire_status === "1"? "Transito" :
        item.tire_status === "2"? "Manutenção" :
        item.tire_status === "3"? "Sucata" :
        item.tire_status === "4"? "Finalização do Transito" : "Não Definido"
      ),
      axle_positioning: (
        parseFloat(item.tire_1) > 0 ? "Eixo 1-E1" :
        parseFloat(item.tire_2) > 0 ? "Eixo 1-D2" :
        parseFloat(item.tire_3) > 0 ? "Eixo 2-E3" :
        parseFloat(item.tire_4) > 0 ? "Eixo 2-D4" :
        parseFloat(item.tire_5) > 0 ? "Eixo 2-EE3" :
        parseFloat(item.tire_6) > 0 ? "Eixo 2-EI4" :
        parseFloat(item.tire_7) > 0 ? "Eixo 2-DE5" :
        parseFloat(item.tire_8) > 0 ? "Eixo 2-DI6" :
        parseFloat(item.tire_9) > 0 ? "Eixo 3-EE7" :
        parseFloat(item.tire_10) > 0 ? "Eixo 3-EI8" :
        parseFloat(item.tire_11) > 0 ? "Eixo 3-DI9" :
        parseFloat(item.tire_12) > 0 ? "Eixo 3-DE10" :
        parseFloat(item.tire_13) > 0 ? "Eixo 4-EE11" :
        parseFloat(item.tire_14) > 0 ? "Eixo 4-EI12" :
        parseFloat(item.tire_15) > 0 ? "Eixo 4-DI13" :
        parseFloat(item.tire_16) > 0 ? "Eixo 4-DE14" :
        parseFloat(item.tire_17) > 0 ? "Estepe E1" :
        parseFloat(item.tire_18) > 0 ? "Estepe D2" : "Não Definido"
      ),
      name: item.name,
    }))
  }
  ;

  const { loading } = useDemoData({
    dataSet: 'Commodity',
    rowLength: 10,
    maxColumns: 6,
  });

  const handleOpen = () => setOpen(true);

  const handleEdit = (item) => {
    setRegist(false);
    setOnEdit(item);
    handleOpen();
  };



  return (
    <>
    <Table onlyWeb>
      <Tbody > 
      <DataGrid
        {...data}
        loading={loading}
        rowHeight = {30}
        slots={{
          toolbar: CustomToolbar,
        }}
      />
      </Tbody>
    </Table>
    
    <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 5}}
        open={openL}
    >
         <Label>Carregando...</Label>
        <CircularProgress color="inherit" />
    </Backdrop>
    </>
  );
};

export default GridTireMovement;