import React, { useEffect, useRef } from "react";
import axios from "axios";
import {toast } from "react-toastify";
import Typography from '@mui/material/Typography';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import DeleteIcon from '@mui/icons-material/Delete';

import { 
  FormContainer, 
  InputArea, 
  Label, 
  Input, 
  Input2, 
  Input3, 
  ButtonArea, 
  Button,
  Select1
} from "../../Styles/Styles";

const FromTireDrawing = ({handleCloseL, openL, handleOpenL, regist, onEdit, setOnEdit, getTireDrawing, setOpen }) => {
  const ref = useRef();
  const handleClose = () => setOpen(false);
  const IdCompany = localStorage.getItem("id_company");
  const IdUser = localStorage.getItem("id");

   
   const cleanearFields = () => {
    const tireDrawing = ref.current;
    tireDrawing.cod.value = "";
    tireDrawing.description.value = "";
    tireDrawing.durability_without_resurfacing.value = "";
    tireDrawing.resurfacing_durability_1.value = "";
    tireDrawing.resurfacing_durability_2.value = "";
    tireDrawing.resurfacing_durability_3.value = "";
    tireDrawing.resurfacing_durability_4.value = "";
    tireDrawing.resurfacing_durability_5.value = "";
    tireDrawing.resurfacing_durability_6.value = "";
    tireDrawing.resurfacing_durability_7.value = "";
    tireDrawing.st.value = 1;
   }

  useEffect(() => {
    const tireDrawing = ref.current;
    if(regist) {
      cleanearFields();
    } else {
    if (onEdit) {
      tireDrawing.cod.value = onEdit.id;
      tireDrawing.description.value = onEdit.description;
      tireDrawing.durability_without_resurfacing.value = onEdit.durability_without_resurfacing;
      tireDrawing.resurfacing_durability_1.value = onEdit.resurfacing_durability_1;
      tireDrawing.resurfacing_durability_2.value = onEdit.resurfacing_durability_2;
      tireDrawing.resurfacing_durability_3.value = onEdit.resurfacing_durability_3;
      tireDrawing.resurfacing_durability_4.value = onEdit.resurfacing_durability_4;
      tireDrawing.resurfacing_durability_5.value = onEdit.resurfacing_durability_5;
      tireDrawing.resurfacing_durability_6.value = onEdit.resurfacing_durability_6;
      tireDrawing.resurfacing_durability_7.value = onEdit.resurfacing_durability_7;
      tireDrawing.st.value = onEdit.st;
    };
  };
  // eslint-disable-next-line
  }, []);

  const handleDelete = async (e) => {
    e.preventDefault();
    const tireDrawing = ref.current;
    handleOpenL();

    if (!window.confirm("Tem certeza que deseja excluir?")) {
      handleCloseL();
      return;
    } else {
    await axios
     .delete("tires/tire-drawing-list/"+ tireDrawing.cod.value)
     .then(() => {
        toast.success("Cadastro excluido com sucesso!");
        getTireDrawing();
        setOnEdit(null);
        handleClose();
     })
     .catch(() => {
      handleCloseL();
      toast.error("Erro, não foi possível excluir!");});
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    handleOpenL();
  
    const tireDrawing = ref.current;
      if (
        !tireDrawing.description.value
      ) {
        handleCloseL();
        return toast.warn("Preencha todos os campos com ( * )!");
      }

    if (onEdit) {
      await axios
        .put("tires/tire-drawing-list/", {
          id: tireDrawing.cod.value,
          description: tireDrawing.description.value.trim(),
          durability_without_resurfacing: tireDrawing.durability_without_resurfacing.value.trim(),
          resurfacing_durability_1: tireDrawing.resurfacing_durability_1.value.trim(),
          resurfacing_durability_2: tireDrawing.resurfacing_durability_2.value.trim(),
          resurfacing_durability_3: tireDrawing.resurfacing_durability_3.value.trim(),
          resurfacing_durability_4: tireDrawing.resurfacing_durability_4.value.trim(),
          resurfacing_durability_5: tireDrawing.resurfacing_durability_5.value.trim(),
          resurfacing_durability_6: tireDrawing.resurfacing_durability_6.value.trim(),
          resurfacing_durability_7: tireDrawing.resurfacing_durability_7.value.trim(),
          st: tireDrawing.st.value,
          user :IdUser,
        })
        .then(() => {
          toast.success("Cadastro atualizado com sucesso!");

          getTireDrawing();
          cleanearFields();
          setOnEdit(null);
          handleClose();
        })
        .catch(() => {
          handleCloseL();
          toast.error("Erro na atualizado!");
        });
    } else {
      await axios
        .post("tires/tire-drawing-list/", {
          description: tireDrawing.description.value.trim(),
          durability_without_resurfacing: tireDrawing.durability_without_resurfacing.value.trim(),
          resurfacing_durability_1: tireDrawing.resurfacing_durability_1.value.trim(),
          resurfacing_durability_2: tireDrawing.resurfacing_durability_2.value.trim(),
          resurfacing_durability_3: tireDrawing.resurfacing_durability_3.value.trim(),
          resurfacing_durability_4: tireDrawing.resurfacing_durability_4.value.trim(),
          resurfacing_durability_5: tireDrawing.resurfacing_durability_5.value.trim(),
          resurfacing_durability_6: tireDrawing.resurfacing_durability_6.value.trim(),
          resurfacing_durability_7: tireDrawing.resurfacing_durability_7.value.trim(),
          st: tireDrawing.st.value,
          id_company: IdCompany,
          user :IdUser,
        })
        .then(() => {
          toast.success("Cadastrado com sucesso!");
          
          cleanearFields();
          getTireDrawing();
          setOnEdit(null);
          handleClose();
        })
        .catch(() => {
          handleCloseL();
          toast.error("Erro no Cadastro!");
        });
        
    }


  };

  return (
    <>
          <Typography style={{ color: "#fff" }} id="modal-modal-title" variant="h6" component="h2">
            Ficha de vida do Pneu - KM
          </Typography>
          <FormContainer  ref={ref} onSubmit={handleSubmit}>
          <div style={{
              display: "flex",
              justifyContent: "space-between",
              gap: "10px",
              marginBottom: "10px",
              padding: "10px",
              border: "2px solid rgba(255, 255, 255, 1.155)"
            }} >
            <InputArea>
              <Label>Código</Label>
              <Input style={{ color: "#fff" }} disabled={true} name="cod"  />
            </InputArea>
            <InputArea>
              <Label>Situação</Label>
              <Select1 style={{ width: "100px" }} name="st">
                <option value={1}>Ativo</option>
                <option value={0}>Inativo</option>
              </Select1>
            </InputArea>
            <InputArea>
              <Label>Descrição*</Label>
              <Input2 style={{ width: "630px" }} name="description" />
            </InputArea>
            </div>
            
              <InputArea>
                <Label>Durabilidade Sem Recape</Label>
                <Input3 style={{ width: "180px" }} type="number" pattern="/([0-9]{3}).([0-9]{3}),([0-9]{2})$/g, '.$1.$2,$3'" min="0" step="any" placeholder="Só número KM" name="durability_without_resurfacing" />
              </InputArea>
            
            <InputArea>
              <Label>Durabilidade 1° Recape</Label>
              <Input3 style={{ width: "180px" }} type="number" pattern="/([0-9]{3}).([0-9]{3}),([0-9]{2})$/g, '.$1.$2,$3'" min="0" step="any" placeholder="Só número KM" name="resurfacing_durability_1" />
            </InputArea>
            
            <InputArea>
              <Label>Durabilidade 2° Recape</Label>
              <Input3 style={{ width: "180px" }} type="number" pattern="/([0-9]{3}).([0-9]{3}),([0-9]{2})$/g, '.$1.$2,$3'" min="0" step="any" placeholder="Só número KM" name="resurfacing_durability_2" />
            </InputArea>
            
            <InputArea>
              <Label>Durabilidade 3° Recape</Label>
              <Input3 style={{ width: "180px" }} type="number" pattern="/([0-9]{3}).([0-9]{3}),([0-9]{2})$/g, '.$1.$2,$3'" min="0" step="any" placeholder="Só número KM" name="resurfacing_durability_3" />
            </InputArea>
            
            <InputArea>
              <Label>Durabilidade 4° Recape</Label>
              <Input3 style={{ width: "180px" }} type="number" pattern="/([0-9]{3}).([0-9]{3}),([0-9]{2})$/g, '.$1.$2,$3'" min="0" step="any" placeholder="Só número KM" name="resurfacing_durability_4" />
            </InputArea>
            
            <InputArea>
              <Label>Durabilidade 5° Recape</Label>
              <Input3 style={{ width: "180px" }} type="number" pattern="/([0-9]{3}).([0-9]{3}),([0-9]{2})$/g, '.$1.$2,$3'" min="0" step="any" placeholder="Só número KM" name="resurfacing_durability_5" />
            </InputArea>
            
            <InputArea>
              <Label>Durabilidade 6° Recape</Label>
              <Input3 style={{ width: "180px" }} type="number" pattern="/([0-9]{3}).([0-9]{3}),([0-9]{2})$/g, '.$1.$2,$3'" min="0" step="any" placeholder="Só número KM" name="resurfacing_durability_6" />
            </InputArea>
            
            <InputArea>
              <Label>Durabilidade 7° Recape</Label>
              <Input3 style={{ width: "180px" }} type="number" pattern="/([0-9]{3}).([0-9]{3}),([0-9]{2})$/g, '.$1.$2,$3'" min="0" step="any" placeholder="Só número KM" name="resurfacing_durability_7" />
            </InputArea>

            <div style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }} >
              <ButtonArea >
                <Button type="submit">SALVAR</Button>
              </ButtonArea>
              <ButtonArea >
              { regist === true? 
                 null
                 : 
                 <Button 
                    style={{backgroundColor:"red"}} 
                    onClick={handleDelete}>EXCLUIR <DeleteIcon/> 
                 </Button>
                 }
              </ButtonArea>
              <ButtonArea >
                <Button onClick={handleClose}>SAIR</Button>
              </ButtonArea>
            </div>

          </FormContainer>
          
      <Backdrop
              sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 5}}
              open={openL}
          >
         <Label>Carregando...</Label>
              <CircularProgress color="inherit" />
          </Backdrop>
    </>
  );
};

export default FromTireDrawing;