import styled from "styled-components";

export const Label = styled.label`
    color: #2c73d2;
    font-size: 20px;
    font-weight: bold;
`;

export const Div = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: rgba(255, 255, 255, 0.3);
    box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
    backdrop-filter: blur(5.5px);
    border-radius: 10px;
    border: 1px solid rgba(255, 255, 255, 0.18); @media (max-width: 650px) {
        ${(props) => props.onlyWeb && "display: none"}
      }
`;