import React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import From from "./Form";

import { Container, styledModal } from "../Styles/Styles";


const ModalXml= ({ setOpen, open, getReleases, onEdit, regist }) => {
  

  return (
    <>
      <Modal
        open={open}
        aria-labelledby="modal-modal-title"
      >
        <Box sx={styledModal}>
          <Container>
            <From
              setOpen={setOpen}
              getReleases={getReleases}
              onEdit={onEdit}  // passando a função para atualizar a lista quando um item for editado
              regist={regist}  // passando a lista de releases para o componente Form
            />
          </Container>
        </Box>
        
        
      </Modal>
    </>
  );
};

export default ModalXml;