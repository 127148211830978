import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.css';
import './styles/custom.css'

import { createBrowserRouter, RouterProvider} from "react-router-dom"
import Home from "./pages/home/Home"
import BombsList from "./pages/Bombs/BombsList"
import EmployeesList from "./pages/Employees/EmployeesList"
import UsersList from "./pages/Users/UsersList";
import VehiclesList from "./pages/Vehicles/VehiclesList";
import ReleasesFuelList from "./pages/Releases/ReleasesFuelList";
import ReportsReleases from "./pages/Reports/ReportsReleases";
import CompanyList from "./pages/Company/CompanyList";
import ProductsServicesList from "./pages/ProductsServices/ProductsServicesList";
import OrderOfServiceList from "./pages/OrderOfService/OrderOfServiceList";
import ImportProducts from "./pages/ImportProdXml/ImportProducts";
import FuelList from "./pages/Fuel/FuelList";
import MaintenanceControlList from "./pages/MaintenanceControl/MaintenanceControlList";
import AxisSchemaRegistrationList from "./pages/Tires/AxisSchemaRegistration/AxisSchemaRegistrationList";
import TireDrawingList from "./pages/Tires/TireDrawing/TireDrawingList";
import ReasonScrappingList from "./pages/Tires/ReasonScrapping/ReasonScrappingList";
import SupplierList from "./pages/Supplier/SupplierList";
import BillsToPayList from "./pages/Financial/BillsToPay/BillsToPayList";
import LaunchSalesTicketList from './pages/LaunchSalesTicket/LaunchSalesTicketList';
import ReportsLaunchSalesTicket from './pages/Reports/ReportsLaunchSalesTicket';
import RegisterLinesList from './pages/RegisterLines/RegisterLinesList';
import TireRegistrationList from './pages/Tires/TireRegistration/TireRegistrationList';
import TireMovementList from './pages/Tires/TireMovement/TireMovementList';
import ReportsMovTires from './pages/Reports/ReportsMovTires';
import MapsTelemetry from './pages/MapsTelemetry/MapsTelemetry';
import NotFound from './pages/Page404';
import Login from "./pages/Login/Login"

import UserServices from './Services/UserService';
import Menu from "./components/Menu/Menu";

const userService = new UserServices();

const ProtectedRoute = ({ Item }) => {
  const userAuthenticated = userService.userAuthenticated();
  return userAuthenticated ? Item : <Login />;
}

const PublicRoute = () => {
  const userAuthenticated = userService.userAuthenticated();
  return !userAuthenticated ? <Login /> : <App />;
}

const router = createBrowserRouter([
  {
    path: "/",
    element:
    <ProtectedRoute 
    Item={
      <Menu>
        <App/>
      </Menu> 
    }/>,
    children: [
      
        {
          path: "/",
          element: <ProtectedRoute Item={ <Home />}/>,
        },
        {
          path: "/Home",
          element: <ProtectedRoute Item={ <Home />}/>,
        },
        {
          path: "/Bombs/list",
          element: <ProtectedRoute Item={ <BombsList />}/>,
        },
        {
          path: "/Employees/list",
          element: <ProtectedRoute Item={ <EmployeesList />}/>,
        },
        {
          path: "/Vehicles/list",
          element: <ProtectedRoute Item={ <VehiclesList />}/>,
        },
        {
          path: "/ReleasesFuel/list",
          element: <ProtectedRoute Item={ <ReleasesFuelList />}/>,
        },
        {
          path: "/Reports/Releases",
          element: <ProtectedRoute Item={ <ReportsReleases />}/>,
        },
        {
          path: "/Users/list",
          element: <ProtectedRoute Item={ <UsersList />}/>,
        },
        {
          path: "/Company/list",
          element: <ProtectedRoute Item={ <CompanyList />}/>,
        },
        {
          path: "/Products-Services/list",
          element: <ProtectedRoute Item={ <ProductsServicesList />}/>,
        },
        {
          path: "/Order-Of-Service/list",
          element: <ProtectedRoute Item={ <OrderOfServiceList />}/>,
        },
        {
          path: "/import-xml-products/list",
          element: <ImportProducts Item={ <ImportProducts />}/>,
        },
        {
          path: "/fuel/list",
          element: <FuelList Item={ <FuelList />}/>,
        },
        {
          path: "/maintenance-control/list",
          element: <MaintenanceControlList Item={ <MaintenanceControlList />}/>,
        },
        {
          path: "/axisSchema-registration/list",
          element: <AxisSchemaRegistrationList Item={ <AxisSchemaRegistrationList />}/>,
        },
        {
          path: "/tire-drawing/list",
          element: <TireDrawingList Item={ <TireDrawingList />}/>,
        },
        {
          path: "/reason-scrapping/list",
          element: <ReasonScrappingList Item={ <ReasonScrappingList />}/>,
        },
        {
          path: "/supplier/list",
          element: <SupplierList Item={ <SupplierList />}/>,
        },
        {
          path: "/bills-to-pay/list",
          element: <BillsToPayList Item={ <BillsToPayList />}/>,
        },
        {
          path: "/launch-sales-ticket/list",
          element: <LaunchSalesTicketList Item={ <LaunchSalesTicketList />}/>,
        },
        {
          path: "/Reports/Launch-Sales-Ticket",
          element: <ReportsLaunchSalesTicket Item={ <ReportsLaunchSalesTicket />}/>,
        },
        {
          path: "/lines/list",
          element: <RegisterLinesList Item={ <RegisterLinesList />}/>,
        },
        {
          path: "/tire-registration/list",
          element: <TireRegistrationList Item={ <TireRegistrationList />}/>,
        },
        {
          path: "/tire-movement/list",
          element: <TireMovementList Item={ <TireMovementList />}/>,
        },
        {
          path: "/Reports/MovTires",
          element: <ReportsMovTires Item={ <ReportsMovTires />}/>,
        },
        {
          path: "/Maps/Telemetry",
          element: <MapsTelemetry Item={ <MapsTelemetry />}/>,
        },
        { path: '*', 
          element: <NotFound Item={ <NotFound />}/>
        },
        { path: '404', 
          element: <NotFound Item={ <NotFound />}/>
        },

      ]
  },
  {
    path: "/",
    element: <PublicRoute />,
    children: [
      {
        path: "/login",
        element: <Login />,
      },
    ]
  },
  { path: '*', 
    element: <NotFound Item={ <NotFound />}/>
  },
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RouterProvider router={router}/>
  </React.StrictMode>
);

reportWebVitals();