import React, { useEffect, useRef } from "react";
import axios from "axios";
import {toast } from "react-toastify";
import Typography from '@mui/material/Typography';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import DeleteIcon from '@mui/icons-material/Delete';

import { 
  FormContainer, 
  InputArea, 
  Label, 
  Input, 
  Input2, 
  Input3, 
  ButtonArea, 
  Button
} from "../Styles/Styles";

const FromLines = ({handleCloseL, openL, handleOpenL, regist, onEdit, setOnEdit, getLines, setOpen }) => {
  const ref = useRef();
  const handleClose = () => setOpen(false);
  const IdCompany = localStorage.getItem("id_company");
  const IdUser = localStorage.getItem("id");

  const cleanearFields = () => {
    const lines = ref.current;
    lines.cod.value = "";
    lines.name_line.value = "";
    lines.vt.value = "";
    lines.school.value = "";
    lines.payers.value = "";
    lines.integration.value = "";
    lines.citizen.value = "";
  }

  useEffect(() => {
    const lines = ref.current;
    if(regist) {
      cleanearFields();
    } else {
    if (onEdit) {
      lines.cod.value = onEdit.id;
      lines.name_line.value = onEdit.name_line;
      lines.vt.value = onEdit.vt;
      lines.school.value = onEdit.school;
      lines.payers.value = onEdit.payers;
      lines.integration.value = onEdit.integration;
      lines.citizen.value = onEdit.citizen;
    };
  };
  // eslint-disable-next-line
  }, []);

  const handleDelete = async (e) => {
    e.preventDefault();
    const lines = ref.current;
    handleOpenL();

    if (!window.confirm("Tem certeza que deseja excluir?")) {
      handleCloseL();
      return;
    } else {
    await axios
     .delete("lines/lines-list/"+ lines.cod.value)
     .then(() => {
        toast.success("Cadastro excluido com sucesso!");
        getLines();
        setOnEdit(null);
        handleClose();
     })
     .catch(() => {
      handleCloseL();
      toast.error("Erro, não foi possível excluir!");});
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    handleOpenL();
  
    const lines = ref.current;
      if (
        !lines.name_line.value 
      ) {
        handleCloseL();
        return toast.warn("Preencha o campo linha!");
      }

    if (onEdit) {
      await axios
        .put("lines/lines-list/", {
          id: lines.cod.value,
          name_line: lines.name_line.value.trim(),
          vt: lines.vt.value.trim(),
          school: lines.school.value.trim(),
          payers: lines.payers.value.trim(),
          integration: lines.integration.value.trim(),
          citizen: lines.citizen.value.trim(),
          user :IdUser,
        })
        .then(() => {
          toast.success("Cadastro atualizado com sucesso!");
          cleanearFields();
          
          getLines();
          setOnEdit(null);
          handleClose();
        })
        .catch(() => {
          handleCloseL();
          toast.error("Erro na atualizado!");
        });
    } else {
      await axios
        .post("lines/lines-list/", {
          name_line: lines.name_line.value.trim(),
          vt: lines.vt.value.trim(),
          school: lines.school.value.trim(),
          payers: lines.payers.value.trim(),
          integration: lines.integration.value.trim(),
          citizen: lines.citizen.value.trim(),
          id_company: IdCompany,
          user :IdUser,
        })
        .then(() => {
          toast.success("Cadastrado com sucesso!");
          cleanearFields();

          getLines();
          setOnEdit(null);
          handleClose();
        })
        .catch(() => {
          handleCloseL();
          toast.error("Erro no Cadastro!");
        });
        
    }


  };

  return (
    <>
          <Typography style={{ color: "#fff" }} id="modal-modal-title" variant="h6" component="h2">
            Ficha da Linhas
          </Typography>
          <FormContainer  ref={ref} onSubmit={handleSubmit}>
            <InputArea>
              <Label>Código</Label>
              <Input style={{ color: "#fff" }} disabled={true} name="cod"  />
            </InputArea>
            <InputArea>
              <Label>Linha*</Label>
              <Input2 style={{ width: "350px" }} name="name_line" />
            </InputArea>
            <InputArea>
              <Label>VT R$</Label>
              <Input3 name="vt" type="number" pattern="/([0-9]{3}).([0-9]{3}),([0-9]{2})$/g, '.$1.$2,$3'" min="0" step="any" placeholder="Digite só número" />
            </InputArea>
            <InputArea>
              <Label>Cidadão R$</Label>
              <Input3 name="citizen" type="number" pattern="/([0-9]{3}).([0-9]{3}),([0-9]{2})$/g, '.$1.$2,$3'" min="0" step="any" placeholder="Digite só número" />
            </InputArea>
            <InputArea>
              <Label>Integração R$</Label>
              <Input3 name="integration" type="number" pattern="/([0-9]{3}).([0-9]{3}),([0-9]{2})$/g, '.$1.$2,$3'" min="0" step="any" placeholder="Digite só número" />
            </InputArea>
            <InputArea>
              <Label>Escolar R$</Label>
              <Input3 name="school" type="number" pattern="/([0-9]{3}).([0-9]{3}),([0-9]{2})$/g, '.$1.$2,$3'" min="0" step="any" placeholder="Digite só número" />
            </InputArea>
            <InputArea>
              <Label>Pagantes R$</Label>
              <Input3 name="payers" type="number" pattern="/([0-9]{3}).([0-9]{3}),([0-9]{2})$/g, '.$1.$2,$3'" min="0" step="any" placeholder="Digite só número" />
            </InputArea>
            <div style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }} >
              <ButtonArea >
                <Button type="submit">SALVAR</Button>
              </ButtonArea>
              <ButtonArea >
              { regist === true? 
                 null
                 : 
                 <Button 
                    style={{backgroundColor:"red"}} 
                    onClick={handleDelete}>EXCLUIR <DeleteIcon/> 
                 </Button>
                 }
              </ButtonArea>
              <ButtonArea >
                <Button onClick={handleClose}>SAIR</Button>
              </ButtonArea>
            </div>

          </FormContainer>
          
      <Backdrop
              sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 5}}
              open={openL}
          >
         <Label>Carregando...</Label>
              <CircularProgress color="inherit" />
          </Backdrop>
    </>
  );
};

export default FromLines;