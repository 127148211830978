import React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { toast } from "react-toastify";
import axios from "axios";
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { 
    FormContainer, 
    InputArea, 
    Label, 
    Button, 
    Loc,
    styledModal,
  } from "../Styles/Styles";
import GridLoc from "./GridLoc";

const ModalEdit = ({openModal, setOpenModal, dataEdit, xmlProd, notRef}) => {
  const handleClose = () => setOpenModal(false);
  const [loc, setLoc] = React.useState();
  const IdCompany = localStorage.getItem("id_company");
  const [products, setProducts] = React.useState([]);
  const [openL, setOpenL] = React.useState(false);
  const [title, setTitle] = React.useState("");
  

  const getProducts = async (e) => {
    e.preventDefault();
    setTitle("Aguarde... Localizando produto!");
    setOpenL(true);
    setProducts([]);
    try {
      const res = await axios.get("products-services/products-services-list/" + IdCompany+ "/P" + "/1" + "/?loc=" + (loc === undefined ? '%' : loc));
      
        setProducts(res.data.sort((a, b) => (a.description > b.description ? 1 : -1)));
        setOpenL(false);
    } catch (error) {
      toast.error(error);
      setOpenL(false);
    }
  };


  return (
    <>
      <Modal
        open={openModal}
        aria-labelledby="modal-modal-title"
      >
        <Box sx={{ styledModal }}>
          <div style={{
            justifyContent: "space-between",
            alignItems: "center",
            padding: "100px",
          }} >
          <FormContainer>
          <Loc
            style={{
              width: "300px",
              height: "30px",
              border: "1px solid #343f46",
              textTransform: "uppercase",
              fontSize: "12px"
            }}
            type='text'
            placeholder="Digite nome produto"
            value={loc}
            onChange={(e) => [setLoc(e.target.value)]}
          />

          <InputArea >
            <Button
              onClick={getProducts}>Localizar
            </Button>
          </InputArea>
          <InputArea  >
            <Button
              onClick={handleClose}>Voltar
            </Button>
          </InputArea>
        </FormContainer>
        <GridLoc products={products} handleClose={handleClose} dataEdit={dataEdit} xmlProd={xmlProd} notRef={notRef}/>
          
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 15 }}
        open={openL}
      >
        <Label>{title}</Label>
        <CircularProgress color="inherit" />
      </Backdrop>
      </div>
        </Box>
  
      </Modal>
      
    </>
  );
};

export default ModalEdit;