import pdfMake from 'pdfmake/build/pdfmake';
import pdfFontes from 'pdfmake/build/vfs_fonts';



const ReleasesMovTiresDetailedPdf = (releasesRef, dataAtualFormatada) => {
    pdfMake.vfs = pdfFontes.pdfMake.vfs;
    const qtde = releasesRef.length;
    const dados = releasesRef.map((item) => {
        return [
            { text: item.movement_date.split("-").reverse().join("/"), fontSize: 7, margin: [0, 2, 0, 2] },
            { text: item.surname, fontSize: 7, margin: [0, 2, 0, 2] },
            { text: item.fire_number, fontSize: 7, margin: [0, 2, 0, 2] },
            { text: (
                item.tire_status === "1"?"Transito": 
                item.tire_status === "2"?"Manutenção":
                item.tire_status === "3"?"Sucata": 
                item.tire_status === "4"?"Finalização do transito": "Não Definido"
            ), fontSize: 7, margin: [0, 2, 0, 2] },
            { text: (
                parseFloat(item.tire_1) > 0 ? "Eixo 1-E1" :
                parseFloat(item.tire_2) > 0 ? "Eixo 1-D2" :
                parseFloat(item.tire_3) > 0 ? "Eixo 2-E3" :
                parseFloat(item.tire_4) > 0 ? "Eixo 2-D4" :
                parseFloat(item.tire_5) > 0 ? "Eixo 2-EE3" :
                parseFloat(item.tire_6) > 0 ? "Eixo 2-EI4" :
                parseFloat(item.tire_7) > 0 ? "Eixo 2-DE5" :
                parseFloat(item.tire_8) > 0 ? "Eixo 2-DI6" :
                parseFloat(item.tire_9) > 0 ? "Eixo 3-EE7" :
                parseFloat(item.tire_10) > 0 ? "Eixo 3-EI8" :
                parseFloat(item.tire_11) > 0 ? "Eixo 3-DI9" :
                parseFloat(item.tire_12) > 0 ? "Eixo 3-DE10" :
                parseFloat(item.tire_13) > 0 ? "Eixo 4-EE11" :
                parseFloat(item.tire_14) > 0 ? "Eixo 4-EI12" :
                parseFloat(item.tire_15) > 0 ? "Eixo 4-DI13" :
                parseFloat(item.tire_16) > 0 ? "Eixo 4-DE14" :
                parseFloat(item.tire_17) > 0 ? "Estepe E1" :
                parseFloat(item.tire_18) > 0 ? "Estepe D2" : "Não Definido"
              ), fontSize: 7, margin: [0, 2, 0, 2] },
              { text: item.identification, fontSize: 7, margin: [0, 2, 0, 2] },
              { text: item.km_v_moment_of_inst, fontSize: 7, margin: [0, 2, 0, 2] },
              { text: item.qty_km_tire_will_be_rotated, fontSize: 7, margin: [0, 2, 0, 2] },
              { text: item.exchange_with_km, fontSize: 7, margin: [0, 2, 0, 2] },
              { text: item.already_driven_km, fontSize: 7, margin: [0, 2, 0, 2] },
              { text: item.recaps, fontSize: 7, margin: [0, 2, 0, 2] },
        ]
    })

    const details = [
        {
            text: 'Relatório Mov. Pneus Detalhado',
            alignment: 'center',
            fontSize: 12,
            margin: [0, 5, 0, 5] // left, top, right, bottom
        },
        {
            text:
                "Data.: " + dataAtualFormatada.split("-").reverse().join("/"),
            style: "textBody",
            fontSize: 8,
            alignment: "right",
            margin: [5, 0, 5, 0] // left, top, right, bottom
        },
        {
            text:
                "Registros.: " + qtde,
            style: "textBody",
            fontSize: 8,
            alignment: "right",
            margin: [5, 0, 5, 0] // left, top, right, bottom
        },
        {
            text: "Movimentações",
            style: "textBody",
            fontSize: 8,
            alignment: "justify",
            margin: [5, 5, 0, 1], // left, top, right, bottom
            bold: true
        },
        {
            style: 'tableExample',
            alignment: 'left',
            table: {
                headerRows: 1,
                widths: [40, 50, 50, 80, 90, 50, 90, 80, 50, 50, 50, 50, 50],
                body: [
                    [
                        { text: 'Data', style: 'tableHeader', fontSize: 8, bold: true },
                        { text: 'Veículo', style: 'tableHeader', fontSize: 8, bold: true },
                        { text: 'Número Fogo', style: 'tableHeader', fontSize: 8, bold: true },
                        { text: 'Status Pneu', style: 'tableHeader', fontSize: 8, bold: true },
                        { text: 'Posicionamento( Eixo )', style: 'tableHeader', fontSize: 8, bold: true },
                        { text: 'Ind. Pneu.', style: 'tableHeader', fontSize: 8, bold: true },
                        { text: 'Km V. Momento da inst.', style: 'tableHeader', fontSize: 8, bold: true },
                        { text: 'QTde Km Pneu irá rodar', style: 'tableHeader', fontSize: 8, bold: true },
                        { text: 'Trocar com (KM)', style: 'tableHeader', fontSize: 8, bold: true },
                        { text: 'Já rodou (KM)', style: 'tableHeader', fontSize: 8, bold: true },
                        { text: 'Recapes', style: 'tableHeader', fontSize: 8, bold: true },
                    ],
                    ...dados
                ]
            },
            layout: {
                hLineWidth: function (i, node) {
                    return (i === 0 || i === node.table.body.length) ? 2 : 1;
                },
                vLineWidth: function (i, node) {
                    return (i === 0 || i === node.table.widths.length) ? 2 : 1;
                },
                hLineColor: function (i, node) {
                    return (i === 0 || i === node.table.body.length) ? 'black' : 'gray';
                },
                vLineColor: function (i, node) {
                    return (i === 0 || i === node.table.widths.length) ? 'black' : 'gray';
                },
            }
        },


    ];

    const baseboard = (currentPage, pageCount) => {
        return [
            {
                text: currentPage + ' / ' + pageCount,
                alignment: 'right',
                fontSize: 9,
                margin: [0, 10, 20, 0] // left, top, right, bottom
            }
        ]
    }

    const docDefinitios = {
        pageSize: 'A4',
        pageOrientation: 'landscape',
        headerMargin: 10,
        footerMargin: 10,
        
        //header: [reportTitle],
        content: [details],
        footer: baseboard
    }

    pdfMake.createPdf(docDefinitios).print();
}

export default ReleasesMovTiresDetailedPdf;