import pdfMake from 'pdfmake/build/pdfmake';
import pdfFontes from 'pdfmake/build/vfs_fonts';
//import { Logo } from './Logo4bus';



const ReleasesDetailedPdf = (locDateI, locDateF, releases, dataAtualFormatada, releasesSumRef, NameLinhe) => {
    pdfMake.vfs = pdfFontes.pdfMake.vfs;
    const qtde = releases.length;
    const { 
        amount: amount, 
        citizen: citizen, 
        integration: integration,
        payers: payers,
        school: school,
        total_users: total_users,
        vt: vt
    }= releasesSumRef
    const dados = releases.map((item) => {
        return [
            { text: item.id, fontSize: 7, margin: [0, 2, 0, 2] },
            { text: item.date, fontSize: 7, margin: [0, 2, 0, 2] },
            { text: item.surname, fontSize: 7, margin: [0, 2, 0, 2] },
            { text: item.plate, fontSize: 7, margin: [0, 2, 0, 2] },
            { text: item.nome, fontSize: 7, margin: [0, 2, 0, 2] },
            {
                style: 'tableExample',
                table: {
                    fontSize: 8,
                    body: [
                        [
                            { text: item.vt_value, fontSize: 7, alignment: "left", bold: true },
                            { text: 'x', style: 'tableHeader', fontSize: 8, alignment: "left", bold: true },
                            { text: item.vt, fontSize: 7, alignment: "left", bold: true },
                            { text: '=', style: 'tableHeader', fontSize: 8, alignment: "left", bold: true },
                            { text: item.vt_total, fontSize: 7, alignment: "left", bold: true }
                        ],
                    ]
                },
                layout: 'noBorders'
            },
            {
                style: 'tableExample',
                table: {
                    fontSize: 8,
                    body: [
                        [
                            { text: item.citizen_value, fontSize: 7, alignment: "left", bold: true },
                            { text: 'x', style: 'tableHeader', fontSize: 8, alignment: "left", bold: true },
                            { text: item.citizen, fontSize: 7, alignment: "left", bold: true },
                            { text: '=', style: 'tableHeader', fontSize: 8, alignment: "left", bold: true },
                            { text: item.citizen_total, fontSize: 7, alignment: "left", bold: true }
                        ],
                    ]
                },
                layout: 'noBorders'
            },
            {
                style: 'tableExample',
                table: {
                    fontSize: 8,
                    body: [
                        [
                            { text: item.integration_value, fontSize: 7, alignment: "left", bold: true },
                            { text: 'x', style: 'tableHeader', fontSize: 8, alignment: "left", bold: true },
                            { text: item.integration, fontSize: 7, alignment: "left", bold: true },
                            { text: '=', style: 'tableHeader', fontSize: 8, alignment: "left", bold: true },
                            { text: item.integration_total, fontSize: 7, alignment: "left", bold: true }
                        ],
                    ]
                },
                layout: 'noBorders'
            },
            {
                style: 'tableExample',
                table: {
                    fontSize: 8,
                    body: [
                        [
                            { text: item.school_value, fontSize: 7, alignment: "left", bold: true },
                            { text: 'x', style: 'tableHeader', fontSize: 8, alignment: "left", bold: true },
                            { text: item.school, fontSize: 7, alignment: "left", bold: true },
                            { text: '=', style: 'tableHeader', fontSize: 8, alignment: "left", bold: true },
                            { text: item.school_total, fontSize: 7, alignment: "left", bold: true }
                        ],
                    ]
                },
                layout: 'noBorders'
            },
            {
                style: 'tableExample',
                table: {
                    fontSize: 8,
                    body: [
                        [
                            { text: item.payers_value, fontSize: 7, alignment: "left", bold: true },
                            { text: 'x', style: 'tableHeader', fontSize: 8, alignment: "left", bold: true },
                            { text: item.payers, fontSize: 7, alignment: "left", bold: true },
                            { text: '=', style: 'tableHeader', fontSize: 8, alignment: "left", bold: true },
                            { text: item.payers_total, fontSize: 7, alignment: "left", bold: true }
                        ],
                    ]
                },
                layout: 'noBorders'
            },
            { text: item.total_users, fontSize: 7, margin: [0, 2, 0, 2] },
            { text: item.amount, fontSize: 7, margin: [0, 2, 0, 2] },
            { text: item.difference, fontSize: 7, margin: [0, 2, 0, 2] },
        ]
    })

    const details = [
        {
            text: 'Relatório de Fechamento Detalhado',
            alignment: 'center',
            fontSize: 12,
            margin: [0, 5, 0, 5] // left, top, right, bottom
        },
        {
            text:"Linha.: " + (NameLinhe === "" ? "Todos" : NameLinhe),
            style: "textBody",
            fontSize: 10,
            alignment: "left",
            margin: [5, 0, 5, 0] // left, top, right, bottom
        },
        {
            text:
                "Data.: " + dataAtualFormatada.split("-").reverse().join("/"),
            style: "textBody",
            fontSize: 8,
            alignment: "right",
            margin: [5, 0, 5, 0] // left, top, right, bottom
        },
        {
            text:
                "Registros.: " + qtde,
            style: "textBody",
            fontSize: 8,
            alignment: "right",
            margin: [5, 0, 5, 0] // left, top, right, bottom
        },
        {
            style: 'tableExample',
            table: {
                alignment: "justify",
                margin: [5, 5, 0, 1], // left, top, right, bottom
                headerRows: 1,
                widths: [100,  '*', '*', '*', '*', '*', '*', '*', 100],
                body: [
                    [{ text: 'Período', style: 'tableHeader', fontSize: 8, color: '#071251', bold: true },
                    { text: 'VT R$.:', style: 'tableHeader', fontSize: 8, color: '#071251', bold: true },
                    { text: 'Cidadão R$.:', style: 'tableHeader', fontSize: 8, color: '#071251', bold: true },
                    { text: 'Integração R$.:', style: 'tableHeader', fontSize: 8, color: '#071251', bold: true },
                    { text: 'Escolar R$.:', style: 'tableHeader', fontSize: 8, color: '#071251', bold: true },
                    { text: 'Pagantes R$.:', style: 'tableHeader', fontSize: 8, color: '#071251', bold: true },
                    { text: 'Total Usuários', style: 'tableHeader', fontSize: 8, color: '#071251', bold: true },
                    { text: 'Total Linha', style: 'tableHeader', fontSize: 8, color: '#071251', bold: true }
                    ],
                    [
                        { text: locDateI.split("-").reverse().join("/") + " à " + locDateF.split("-").reverse().join("/"), style: 'tableHeader', fontSize: 8 },
                        { text: vt, style: 'tableHeader', fontSize: 8, alignment: "right", bold: true },
                        { text: citizen, style: 'tableHeader', fontSize: 8, alignment: "right", bold: true },
                        { text: integration, style: 'tableHeader', fontSize: 8, alignment: "right", bold: true },
                        { text: school, style: 'tableHeader', fontSize: 8, alignment: "right", bold: true },
                        { text: payers, style: 'tableHeader', fontSize: 8, alignment: "right", bold: true },
                        { text: total_users, style: 'tableHeader', fontSize: 8, alignment: "right", bold: true },
                        {
                            style: 'tableExample',
                            table: {
                                fontSize: 8,
                                widths: ['*', '*'],
                                body: [
                                    [{ text: 'R$.:', style: 'tableHeader', fontSize: 8, alignment: "left", bold: true },
                                    { text: amount, style: 'tableHeader', fontSize: 8, alignment: "right", bold: true }
                                    ],
                                ]
                            },
                            layout: 'noBorders'
                        }
                    ]
                ]
            }
        },
        {
            text: "Movimentações",
            style: "textBody",
            fontSize: 8,
            alignment: "justify",
            margin: [5, 5, 0, 1], // left, top, right, bottom
            bold: true
        },
        {
            style: 'tableExample',
            alignment: 'left',
            table: {
                headerRows: 1,
                body: [
                    [
                        { text: 'Cód.', style: 'tableHeader', fontSize: 8, bold: true },
                        { text: 'Data', style: 'tableHeader', fontSize: 8, bold: true },
                        { text: 'Veículo', style: 'tableHeader', fontSize: 8, bold: true },
                        { text: 'Placa', style: 'tableHeader', fontSize: 8, bold: true },
                        { text: 'Motorista', style: 'tableHeader', fontSize: 8, bold: true },
                        { text: 'VT', style: 'tableHeader', fontSize: 8, bold: true },
                        { text: 'Cidadão.', style: 'tableHeader', fontSize: 8, bold: true },
                        { text: 'Integração', style: 'tableHeader', fontSize: 8, bold: true },
                        { text: 'Escolar', style: 'tableHeader', fontSize: 8, bold: true },
                        { text: 'Pagantes', style: 'tableHeader', fontSize: 8, bold: true },
                        { text: 'Total usuários', style: 'tableHeader', fontSize: 8, bold: true },
                        { text: 'Total R$', style: 'tableHeader', fontSize: 8, bold: true },
                        { text: 'Diferença', style: 'tableHeader', fontSize: 8, bold: true }
                    ],
                    ...dados
                ]
            },
            layout: {
                hLineWidth: function (i, node) {
                    return (i === 0 || i === node.table.body.length) ? 2 : 1;
                },
                vLineWidth: function (i, node) {
                    return (i === 0 || i === node.table.widths.length) ? 2 : 1;
                },
                hLineColor: function (i, node) {
                    return (i === 0 || i === node.table.body.length) ? 'black' : 'gray';
                },
                vLineColor: function (i, node) {
                    return (i === 0 || i === node.table.widths.length) ? 'black' : 'gray';
                },
            }
        },


    ];

    const baseboard = (currentPage, pageCount) => {
        return [
            {
                text: currentPage + ' / ' + pageCount,
                alignment: 'right',
                fontSize: 9,
                margin: [0, 10, 20, 0] // left, top, right, bottom
            }
        ]
    }

    const docDefinitios = {
        pageSize: 'A4',
        pageOrientation: 'landscape',
        headerMargin: 2,
        footerMargin: 2,
        
        //header: [reportTitle],
        content: [details],
        footer: baseboard
    }

    pdfMake.createPdf(docDefinitios).print();
}

export default ReleasesDetailedPdf;