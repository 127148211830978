import React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import FromVehicles from "./FromVehicles";

import { Container, styledModal } from "../Styles/Styles";


const ModalVehicles = ({company, handleCloseL, handleOpenL, openL, regist, onEdit, setOnEdit, getVehicles, setOpen, open, fuel, axisSchema }) => {
  

  return (
    <>
      <Modal
        open={open}
        aria-labelledby="modal-modal-title"
      >
        <Box sx={styledModal}>
          <Container>
            <FromVehicles 
              handleCloseL={handleCloseL} 
              handleOpenL={handleOpenL}  
              openL={openL} 
              regist={regist} 
              onEdit={onEdit} 
              setOnEdit={setOnEdit} 
              getVehicles={getVehicles} 
              setOpen={setOpen}
              company={company}
              fuel={fuel}
              axisSchema={axisSchema}
            />
          </Container>
        </Box>
        
        
      </Modal>
    </>
  );
};

export default ModalVehicles;