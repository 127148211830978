import React from 'react';
import axios from 'axios';
import { XMLParser } from "fast-xml-parser";
import { toast } from "react-toastify";
import GridXML from './GridXml';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { geraStringAleatoria } from "../Function/Base";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import DeleteIcon from '@mui/icons-material/Delete';

import {
  FormContainer,
  InputArea,
  Label,
  ButtonArea,
  Button,
  Select1
} from "../Styles/Styles";

const styleArea = {
  display: "flex",
  height: "50px",
  width: "100%",
  border: "1px solid #fff",
  color: "#fff",
  padding: "5px",
  margin: "5px",
  overflow: "hidden",
};
const style = {
  display: "flex",
  height: "50px",
  width: "150px",
  border: "1px solid #fff",
  color: "#fff",
  padding: "5px",
  margin: "5px",
};
function Form({ setOpen, getReleases, onEdit }) {
  const [xmlProd, setXmlProd] = React.useState([]);
  const [dhEmi, setDhEmi] = React.useState([]);
  const [issuer, setIssuer] = React.useState([]);
  const [issuerF, setIssuerF] = React.useState([]);
  const [issuerCnpj, setIssuerCnpj] = React.useState([]);
  const [issuerIE, setIssuerIE] = React.useState([]);
  const [total, setTotal] = React.useState([]);
  const [dadosProd, setDadosProd] = React.useState();
  const [nNf, setNnf] = React.useState([]);
  const reader = new FileReader();
  const [openL, setOpenL] = React.useState(false);
  const [title, setTitle] = React.useState("");
  const IdCompany = localStorage.getItem("id_company");
  const IdUser = localStorage.getItem("id");
  const [checkNote, setCheckNote] = React.useState(false);
  const cnpjRef = React.useRef();
  const nNfRef = React.useRef();
  const noteCodeRef = React.useRef();
  const CEP = React.useRef();
  const UF = React.useRef();
  const fone = React.useRef();
  const nro = React.useRef();
  const xBairro = React.useRef();
  const xLgr = React.useRef();
  const xMun = React.useRef();
  const pag = React.useRef();
  const cobr = React.useRef();
  const typeEnter = React.useRef();
  const dadosCobr = React.useRef([]);
  const [status, setStatus] = React.useState(false);

  const handleClose = () => setOpen(false);

  const onTypeEnterChange = (event) =>{
    typeEnter.current = event.target.value;
   };

  function adicionaZero(numero) {
    if (numero <= 9)
      return "0" + numero;
    else
      return numero;
  };

  const clean = async () => {
    setXmlProd([]);
    setDhEmi([]);
    setIssuer([]);
    setIssuerF([]);
    setIssuerCnpj([]);
    setIssuerIE([]);
    setTotal([]);
    setDadosProd();
    setNnf([]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    cobr.current = null;
    dadosCobr.current = null;
    const geraS = geraStringAleatoria(64);
    const getFile = document.querySelector("#file");

    if(typeEnter.current === undefined || typeEnter.current === "0"){
      return toast.warn("Selecione um tipo de entrada!");
    };

    if (getFile.files.length) {
      reader.readAsText(await getFile.files[0]);
      reader.onload = function (e) {
        let resultado = e.target.result;

        let parser = new XMLParser();
        const xml = parser.parse(resultado);

        if (xml.nfeProc === undefined) {
          let dateE = new Date(xml.NFe.infNFe.ide.dhEmi);
          let DateEmi = (adicionaZero(dateE.getDate()) + '/' + (adicionaZero(dateE.getMonth() + 1)) + '/' + (dateE.getFullYear()));

          setIssuer(xml.NFe.infNFe.emit.xNome);
          setIssuerF(xml.NFe.infNFe.emit.xFant);
          setIssuerCnpj(xml.NFe.infNFe.emit.CNPJ);
          setIssuerIE(xml.NFe.infNFe.emit.IE);
          setDhEmi(DateEmi);
          setTotal(xml.NFe.infNFe.pag.detPag.vPag);
          noteCodeRef.current = geraS;
          setXmlProd(xml.NFe.infNFe.det);
          setNnf(xml.NFe.infNFe.ide.nNF);
          cnpjRef.current = xml.NFe.infNFe.emit.CNPJ;
          nNfRef.current = xml.NFe.infNFe.ide.nNF;

          CEP.current = xml.NFe.infNFe.emit.enderEmit.CEP;
          UF.current = xml.NFe.infNFe.emit.enderEmit.UF;
          fone.current = xml.NFe.infNFe.emit.enderEmit.fone;
          nro.current = xml.NFe.infNFe.emit.enderEmit.nro;
          xBairro.current = xml.NFe.infNFe.emit.enderEmit.xBairro;
          xLgr.current = xml.NFe.infNFe.emit.enderEmit.xLgr;
          xMun.current = xml.NFe.infNFe.emit.enderEmit.xMun;

          //pega fatura para gerar financeiro
          if (xml.NFe.infNFe.cobr !== undefined) {
            if (xml.NFe.infNFe.cobr.dup.length === undefined) {
              cobr.current = xml.NFe.infNFe.cobr.dup;
              dadosCobr.current = cobr.current === undefined? [] : [{
                id: 1,
                issuance_date: DateEmi.split("/").reverse().join("-"),
                due_date: cobr.current.dVenc,
                account_code: 2,
                cnpj_cpf_supplier: cnpjRef.current,
                supplier_name: xml.NFe.infNFe.emit.xNome,
                amount: cobr.current.vDup,
                document: nNfRef.current+"-"+cobr.current.nDup,
                invoice: nNfRef.current,
                historic: "Lançamento por Nota Fiscal Eletrônica",
                species: xml.NFe.infNFe.pag.detPag.tPag,
                situation: 1,
                user: IdUser,
                id_company: IdCompany,
              }];

            } else {
              cobr.current = xml.NFe.infNFe.cobr.dup;
              dadosCobr.current = cobr.current === undefined? [] : cobr.current.map((item, i) => ({
                id: i + 1,
                issuance_date: DateEmi.split("/").reverse().join("-"),
                due_date: item.dVenc,
                account_code: 2,
                cnpj_cpf_supplier: cnpjRef.current,
                supplier_name: xml.NFe.infNFe.emit.xNome,
                amount: item.vDup,
                document: nNfRef.current+"-"+item.nDup,
                invoice: nNfRef.current,
                historic: "Lançamento por Nota Fiscal Eletrônica",
                species: xml.NFe.infNFe.pag.detPag.tPag,
                situation: 1,
                user: IdUser,
                id_company: IdCompany,
              }));

            }
          } else {
            cobr.current = xml.NFe.infNFe.pag.detPag;
            dadosCobr.current = cobr.current === undefined? [] : [{
              id: 1,
              issuance_date: DateEmi.split("/").reverse().join("-"),
              due_date: DateEmi.split("/").reverse().join("-"),
              account_code: 2,
              cnpj_cpf_supplier: cnpjRef.current,
              supplier_name: xml.NFe.infNFe.emit.xNome,
              amount: cobr.current.vPag,
              document: nNfRef.current+"-"+1,
              invoice: nNfRef.current,
              historic: "Lançamento por Nota Fiscal Eletrônica",
              species: xml.NFe.infNFe.pag.detPag.tPag,
              situation: 1,
              user: IdUser,
              id_company: IdCompany,
            }];
          }
          
        } else {

          let dateE = new Date(xml.nfeProc.NFe.infNFe.ide.dhEmi);
          let DateEmi = (adicionaZero(dateE.getDate()) + '/' + (adicionaZero(dateE.getMonth() + 1)) + '/' + (dateE.getFullYear()));

          setIssuer(xml.nfeProc.NFe.infNFe.emit.xNome);
          setIssuerF(xml.nfeProc.NFe.infNFe.emit.xFant);
          setIssuerCnpj(xml.nfeProc.NFe.infNFe.emit.CNPJ);
          setIssuerIE(xml.nfeProc.NFe.infNFe.emit.IE);

          setTotal(xml.nfeProc.NFe.infNFe.pag.detPag.vPag);
          noteCodeRef.current = geraS;
          setDhEmi(DateEmi);
          setXmlProd(xml.nfeProc.NFe.infNFe.det);
          setNnf(xml.nfeProc.NFe.infNFe.ide.nNF);

          nNfRef.current = xml.nfeProc.NFe.infNFe.ide.nNF;
          cnpjRef.current = xml.nfeProc.NFe.infNFe.emit.CNPJ;

          CEP.current = xml.nfeProc.NFe.infNFe.emit.enderEmit.CEP;
          UF.current = xml.nfeProc.NFe.infNFe.emit.enderEmit.UF;
          fone.current = xml.nfeProc.NFe.infNFe.emit.enderEmit.fone;
          nro.current = xml.nfeProc.NFe.infNFe.emit.enderEmit.nro;
          xBairro.current = xml.nfeProc.NFe.infNFe.emit.enderEmit.xBairro;
          xLgr.current = xml.nfeProc.NFe.infNFe.emit.enderEmit.xLgr;
          xMun.current = xml.nfeProc.NFe.infNFe.emit.enderEmit.xMun;
          //Pega forma de pagamento
          if (xml.nfeProc.NFe.infNFe.pag.detPag.tPag === 1) {
            pag.current = "Dinheiro";
          } else if (xml.nfeProc.NFe.infNFe.pag.detPag.tPag === 2) {
            pag.current = "Cheque";
          } else if (xml.nfeProc.NFe.infNFe.pag.detPag.tPag === 3) {
            pag.current = "Cartão de Crédito";
          } else if (xml.nfeProc.NFe.infNFe.pag.detPag.tPag === 4) {
            pag.current = "Cartão de Débito";
          } else if (xml.nfeProc.NFe.infNFe.pag.detPag.tPag === 5) {
            pag.current = "Crédito Loja";
          } else if (xml.nfeProc.NFe.infNFe.pag.detPag.tPag === 10) {
            pag.current = "Vale Alimentação";
          } else if (xml.nfeProc.NFe.infNFe.pag.detPag.tPag === 11) {
            pag.current = "Vale Refeição";
          } else if (xml.nfeProc.NFe.infNFe.pag.detPag.tPag === 12) {
            pag.current = "Vale Presente";
          } else if (xml.nfeProc.NFe.infNFe.pag.detPag.tPag === 13) {
            pag.current = "Vale Combustível";
          } else if (xml.nfeProc.NFe.infNFe.pag.detPag.tPag === 14) {
            pag.current = "Duplicata Mercantil";
          } else if (xml.nfeProc.NFe.infNFe.pag.detPag.tPag === 15) {
            pag.current = "Boleto Bancário";
          } else if (xml.nfeProc.NFe.infNFe.pag.detPag.tPag === 17) {
            pag.current = "PIX";
          } else if (xml.nfeProc.NFe.infNFe.pag.detPag.tPag === 90) {
            pag.current = "Sem Pagamento";
          } else if (xml.nfeProc.NFe.infNFe.pag.detPag.tPag === 99) {
            pag.current = "Outros";
          }

          if (xml.nfeProc.NFe.infNFe.cobr !== undefined) {
            if (xml.nfeProc.NFe.infNFe.cobr.dup.length === undefined) {
              cobr.current = xml.nfeProc.NFe.infNFe.cobr.dup;
              dadosCobr.current = cobr.current === undefined? [] : [{
                id: 1,
                issuance_date: DateEmi.split("/").reverse().join("-"),
                due_date: cobr.current.dVenc,
                account_code: 2,
                cnpj_cpf_supplier: cnpjRef.current,
                supplier_name: xml.nfeProc.NFe.infNFe.emit.xNome,
                amount: cobr.current.vDup,
                document: nNfRef.current+"-"+cobr.current.nDup,
                invoice: nNfRef.current,
                historic: "Lançamento por Nota Fiscal Eletrônica",
                species: xml.nfeProc.NFe.infNFe.pag.detPag.tPag,
                situation: 1,
                user: IdUser,
                id_company: IdCompany,
              }];

            } else {
              cobr.current = xml.nfeProc.NFe.infNFe.cobr.dup;
              dadosCobr.current = cobr.current === undefined? [] : cobr.current.map((item, i) => ({
                id: i + 1,
                issuance_date: DateEmi.split("/").reverse().join("-"),
                due_date: item.dVenc,
                account_code: 2,
                cnpj_cpf_supplier: cnpjRef.current,
                supplier_name: xml.nfeProc.NFe.infNFe.emit.xNome,
                amount: item.vDup,
                document: nNfRef.current+"-"+item.nDup,
                invoice: nNfRef.current,
                historic: "Lançamento por Nota Fiscal Eletrônica",
                species: xml.nfeProc.NFe.infNFe.pag.detPag.tPag,
                situation: 1,
                user: IdUser,
                id_company: IdCompany,
              }));

            }
          } else {
            cobr.current = xml.nfeProc.NFe.infNFe.pag.detPag;
            dadosCobr.current = cobr.current === undefined? [] : [{
              id: 1,
              issuance_date: DateEmi.split("/").reverse().join("-"),
              due_date: DateEmi.split("/").reverse().join("-"),
              account_code: 2,
              cnpj_cpf_supplier: cnpjRef.current,
              supplier_name: xml.nfeProc.NFe.infNFe.emit.xNome,
              amount: cobr.current.vPag,
              document: nNfRef.current+"-"+1,
              invoice: nNfRef.current,
              historic: "Lançamento por Nota Fiscal Eletrônica",
              species: xml.nfeProc.NFe.infNFe.pag.detPag.tPag,
              situation: 1,
              user: IdUser,
              id_company: IdCompany,
            }];
          }

        }

        handleCheckNote();

      }
      reader.onerror = function (e) {
        toast.error("Error", e);
      };
    } else {
      toast.error(" Nenhum arquivo XML selecionado! ");
    }
  };

  const handleCheckNote = async () => {

    setTitle("Aguarde... Verificando nota fiscal!");

    try {
      await axios.put("buy-products/buy-list/", {
        issuer_cnpj: cnpjRef.current,
        nNF: nNfRef.current,
        id_company: IdCompany,
        user: IdUser,
      })
        .then(async (res) => {
          if (res.data.status === 1) {
            toast.error(res.data.message);
            return setCheckNote(false);
          } else {
            toast.success("Verificado com sucesso!");
            return setCheckNote(true);
          }
        })

    } catch (error) {
      toast.error(error);
    }

  };

  const handleItems = async () => {
    setOpenL(true);
    setXmlProd([]);
    setTitle("Aguarde... Verificando itens da nota fiscal!");

    try {
      const res = await axios.get("buy-products/buy-items-list/" +
        IdCompany + "/" + noteCodeRef.current);

      setXmlProd(res.data);

      setOpenL(false);
    } catch (error) {
      toast.error(error);
      setOpenL(false);
    }

  };

  const handleRegiterProd = async (e) => {
    e.preventDefault();
    setOpenL(true);

    const getFile = document.querySelector("#file");
    if (dadosProd === 0) {
      toast.error("Você precisa enviar arquivo!");
      setOpenL(false);
      return;
    } else {
      if (checkNote) {
        if (getFile.files.length && dadosProd.length > 0) {
          let dataAtual = new Date();
          let dataAtualFormatada = (dataAtual.getFullYear() + "-" + (adicionaZero(dataAtual.getMonth() + 1).toString()) + "-" + adicionaZero(dataAtual.getDate().toString()));

          try {
            //Cadastro fornecedor
            await axios
              .post("buy-products/buy-list/", {
                note_code: noteCodeRef.current,
                issuer: issuer,
                issuer_f: issuerF,
                issuer_cnpj: issuerCnpj,
                issuer_ie: issuerIE,
                type_prod: "P",
                nNF: nNf,
                date_of_issue: dhEmi.split("/").reverse().join("-"),
                release_date_of: dataAtualFormatada,
                mode: "55",
                total_note: total,
                CEP: CEP.current,
                UF: UF.current,
                fone: fone.current,
                nro: nro.current,
                xBairro: xBairro.current,
                xLgr: xLgr.current,
                xMun: xMun.current,
                id_company: IdCompany,
                user: IdUser,
              })
              .then(() => {
                setTitle("Aguarde... Cadastrado fornecedor.: " + issuer);
              })
              .catch(() => {
                toast.error("Erro no Cadastro!");
                return;
              });

              // Lançamento financeiro
              for (let ind = 0; ind <= dadosCobr.current.length - 1; ind++) {
                await axios
                  .post("financial/bills-to-pay-list/", dadosCobr.current[ind])
                  .then(() => {
                    setTitle("Aguarde... Lançando financeiro... ");
                  })
                  .catch(() => {
                    toast.error("Erro no Cadastro!");
                    return;
                  });
  
              }
              if(typeEnter.current === "1") {
                //Cadastra produto
                for (let ind = 0; ind <= dadosProd.length - 1; ind++) {
                  await axios
                    .post("buy-products/buy-items-list/", dadosProd[ind])
                    .then(() => {
                      setTitle("Aguarde... Cadastrado produto.: " + dadosProd[ind].xProd);
                    })
                    .catch(() => {
                      toast.error("Erro no Cadastro!");
                      return;
                    });
                }
              } 
              if(typeEnter.current === "2") {
                //Cadastra pneu
                for (let ind = 0; ind <= dadosProd.length - 1; ind++) {
                  await axios
                    .post("buy-products/buy-items-tire-list/",{
                      nNF: nNf,
                      date_of_issue: dhEmi.split("/").reverse().join("-"),
                     ...dadosProd[ind]
                    })
                    .then(() => {
                      setTitle("Aguarde... Cadastrado pneu.: " + dadosProd[ind].xProd);
                    })
                    .catch(() => {
                      toast.error("Erro no Cadastro!");
                      return;
                    });
                }
              }        
            setOpenL(false);
            getReleases();
            handleClose();
            toast.success("Lançamento realizado com sucesso!");
            setDadosProd(0);
          } catch (error) {
            toast.error("Erro ao cadastrar fornecedor: " + error);
            setOpenL(false);
            return;
          }
        } else {
          toast.error("Nota fiscal já cadastrada!");
          setOpenL(false);
        }
      } else {
        toast.error("Você precisa enviar arquivo!");
        setOpenL(false);
      }
    }
  };

  React.useEffect(() => {


    if (onEdit) {
      setDhEmi(onEdit.date_of_issue.split("-").reverse().join("/"));
      setNnf(onEdit.nNF);
      noteCodeRef.current = onEdit.note_code;
      setTotal(onEdit.total_note);
      setIssuer(onEdit.issuer);

      setIssuerF(onEdit.issuer_f);
      setIssuerCnpj(onEdit.issuer_cnpj);
      setIssuerIE(onEdit.issuer_ie);
      handleItems();
      setStatus(true);
    } else {
      clean();
      setStatus(false);
    }

    return;
    // eslint-disable-next-line
  }, []);

  const handleDelete = async (e) => {
    e.preventDefault();
    setOpenL(true);

    if (!window.confirm("Tem certeza que deseja excluir?")) {
      setOpenL(false);
      return;
    } else {
      await axios
        .delete("buy-products/buy-list/" + noteCodeRef.current)
        .then(() => {
          getReleases();
          handleClose();
          setDadosProd(0);
          setOpenL(false);
          toast.success("Cadastro excluido com sucesso!");

        })
        .catch(() => {
          setOpenL(false);
          toast.error("Erro, não foi possível excluir!");
        });
    }
  }

  return (
    <>
      <Typography style={{ color: "#fff" }} id="modal-modal-title" variant="h6" component="h2">
        Importação de notas - XML - Núm. Nota.: {nNf}
      </Typography>
      <Box sx={{ width: '100%', height: "calc(90vh - 10px)", backgroundColor: "#fff", borderRadius: "5px", border: "5px solid #343f46" }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <FormContainer onSubmit={handleSubmit}>

            <InputArea >
              <Label>Tipo de entrada</Label>
              <Select1 style={{ width: "150px" }} onChange={onTypeEnterChange} >
                <option value={0}>Selecione</option>
                <option value={1}>Produto</option>
                <option value={2}>Pneu</option>
              </Select1>
            </InputArea>

            <InputArea style={style}>
              <small style={{ fontSize: 12 }} >Emissão</small>
              <Label >{dhEmi}</Label>
            </InputArea>

            <InputArea style={style}>
              <small style={{ fontSize: 12 }} >Valor Produtos </small>
              <Label>{Intl.NumberFormat('pt-br', { style: 'currency', currency: 'BRL' }).format(total)}</Label>
            </InputArea>

            <InputArea style={style}>
              <small style={{ fontSize: 12 }} >Forma Pagamento</small>
              <Label>{pag.current}</Label>
            </InputArea>

            <InputArea style={style}>
              <small style={{ fontSize: 12 }} >CNPJ</small>
              <Label>{issuerCnpj}</Label>
            </InputArea>

            <InputArea style={style}>
              <small style={{ fontSize: 12 }} >IE</small>
              <Label>{issuerIE}</Label>
            </InputArea>

            <InputArea style={styleArea}>
              <small>Fornecedor</small>
              <Label>{issuer}</Label>
            </InputArea>

            <div style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }} >
              {status === true ?
                <ButtonArea >
                  <Button style={{
                    backgroundColor: "red",
                    width: "100%",
                  }} onClick={handleDelete} >Excluir nota fiscal <DeleteIcon /></Button>
                </ButtonArea>
                :
                <ButtonArea >
                  <input
                    name="file" type="file" id="file" />
                  <Button type="submit">Enviar arquivo</Button>
                </ButtonArea>
              }
              <ButtonArea >
                {status === true ? null :
                  <Button onClick={handleRegiterProd} >SALVAR</Button>
                }
                <Button onClick={handleClose} >SAIR</Button>
              </ButtonArea>
            </div>
          </FormContainer>
          <GridXML xmlProd={xmlProd} setDadosProd={setDadosProd} noteCode={noteCodeRef.current} issuer={issuer} onEdit={onEdit} />
        </Box>
      </Box>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 15 }}
        open={openL}
      >
        <Label>{title}</Label>
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
}

export default Form;