import * as React from 'react';
import { GridActionsCellItem } from '@mui/x-data-grid';
import EditIcon from '@mui/icons-material/Edit';

import { DataGrid, GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';
import { useDemoData } from '@mui/x-data-grid-generator';
import { FaFileSignature } from "react-icons/fa"
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

import { 
  TableMobile,
  Table, 
  Tbody, 
  Tr, 
  Td, 
  Label
} from "../Styles/Styles";

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarExport />
    </GridToolbarContainer>
  );
}


const GridLines = ({openL, setRegist, lines, setOnEdit, setOpen }) => {

  const alterUsers = React.useCallback(
    (id) => () => {
      const res = lines.filter((a) => (a.id === id));
       // eslint-disable-next-line
       res.map((a) => {
        handleEdit(a);
       });
    },
    [lines],
  );

  const data = {
    columns: [
      { field: 'name_line', headerName: 'Linha', width: 350 },
      { field: 'vt', headerName: 'VT', width: 100 },
      { field: 'citizen', headerName: 'Cidadão', width: 100 },
      { field: 'integration', headerName: 'Integração', width: 100 },
      { field: 'school', headerName: 'Escola', width: 100 },
      { field: 'payers', headerName: 'Pagantes', width: 100 },
      {
        field: 'actions',
        type: 'actions',
        headerName: 'Ações',
        width: 80,
        getActions: (params) => [
          <GridActionsCellItem
            icon={<EditIcon style={{
              color: "#2c73d2"}} />}
            label="Delete"
            onClick={alterUsers(params.id)}
          />,
        ],
      },
    ],
    rows: lines === undefined? [] : lines.map((item) => ({
      id: item.id,
      name_line: item.name_line,
      vt: item.vt,
      citizen: item.citizen,
      integration: item.integration,
      school: item.school,
      payers: item.payers,
    }))
  }
  ;

  const { loading } = useDemoData({
    dataSet: 'Commodity',
    rowLength: 10,
    maxColumns: 6,
  });

  const handleOpen = () => setOpen(true);

  const handleEdit = (item) => {
    setRegist(false);
    setOnEdit(item);
    handleOpen();
  };



  return (
    <>
    <TableMobile onlyWeb>
      <Tbody >
        {lines === undefined ? [] : lines.map((item, i) => (
          <Tr key={i}>
            <Td >
              <h1
                style={{fontSize: "12px", color:"#022025" }} >
                    Linha.: {item.name_line} <br />
                    VT R$.: {item.vt} <br />
                    Cidadão R$.: {item.citizen} <br />
                    Integração R$.: {item.integration} <br />
                    Escola R$.: {item.school} <br />
                    Pagantes R$.: {item.payers}   
              </h1>


            </Td>
            <Td >
                <FaFileSignature onClick={() => handleEdit(item)}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "start",
                    cursor: "pointer",
                    width: "20px",
                    height: "70px",
                    textAlign: "start",
                    color: "#2c73d2",
                  }}
                />
            </Td>
          </Tr>
        ))}
      </Tbody>
    </TableMobile>
    
    <Table onlyWeb>
      <Tbody > 
      <DataGrid
        {...data}
        loading={loading}
        rowHeight = {30}
        slots={{
          toolbar: CustomToolbar,
        }}
      />
      </Tbody>
    </Table>
    
    <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 5}}
        open={openL}
    >
         <Label>Carregando...</Label>
        <CircularProgress color="inherit" />
    </Backdrop>
    </>
  );
};

export default GridLines;