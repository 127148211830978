import React, { useEffect, useRef } from "react";
import axios from "axios";
import {toast } from "react-toastify";
import Typography from '@mui/material/Typography';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import DeleteIcon from '@mui/icons-material/Delete';

import { 
  FormContainer, 
  InputArea, 
  Label, 
  Input, 
  Select1, 
  Input2, 
  InputDate, 
  ButtonArea, 
  Button 
} from "../Styles/Styles";


const FromVehicles = ({
  handleCloseL, 
  openL, 
  handleOpenL, 
  regist, 
  onEdit, 
  setOnEdit, 
  getVehicles, 
  setOpen,
  company,
  fuel,
  axisSchema 
}) => {
  const ref = useRef();
  const handleClose = () => setOpen(false);
  const IdCompany = localStorage.getItem("id_company");
  const IdUser = localStorage.getItem("id");

  const limparCampos = () => {
    const vehicle = ref.current;

    vehicle.cod.value = "";
    vehicle.plate.value = "";
    vehicle.surname.value = ""; 
    vehicle.equipment.value = "";
    vehicle.owner.value = "";
    vehicle.contact.value = "";
    vehicle.brand.value = ""; 
    vehicle.model.value = "";
    vehicle.year.value = "";
    vehicle.fuel.value = "";
    vehicle.color.value = "";
    vehicle.chassis.value = "";
    vehicle.reindeer.value = "";
    vehicle.fuel_consumption.value = "";
    vehicle.document_expiration.value = "";
    vehicle.km_current.value  = "";
    vehicle.st.value = "1";
    vehicle.category.value = 0;
    vehicle.axis_schema.value = "";
  }

  useEffect(() => {
    const vehicle = ref.current;
      if(regist) {
       limparCampos();
    } else {
    if (onEdit) {
      
      vehicle.cod.value = onEdit.id;
      vehicle.plate.value = onEdit.plate;
      vehicle.surname.value = onEdit.surname; 
      vehicle.equipment.value = onEdit.equipment;
      vehicle.owner.value = onEdit.owner; 
      vehicle.contact.value = onEdit.contact; 
      vehicle.brand.value = onEdit.brand; 
      vehicle.model.value = onEdit.model;
      vehicle.year.value = onEdit.year;
      vehicle.fuel.value = onEdit.fuel;
      vehicle.color.value = onEdit.color;
      vehicle.chassis.value = onEdit.chassis;
      vehicle.reindeer.value = onEdit.reindeer;
      vehicle.fuel_consumption.value = onEdit.fuel_consumption;
      vehicle.document_expiration.value = onEdit.document_expiration;
      vehicle.st.value = onEdit.st;
      vehicle.km_current.value = onEdit.km_current;
      vehicle.category.value = onEdit.category;
      vehicle.id_subcompany.value = onEdit.id_subcompany;
      vehicle.axis_schema.value = onEdit.axis_schema;
      
    };
  };
  // eslint-disable-next-line
  }, [fuel]);

  const handleDelete = async (e) => {
    e.preventDefault();
    const vehicle = ref.current;
    handleOpenL();

    try {
          const res = await axios.get("releases/releases-fuel-list/" +
            IdCompany + "/2000-01-01/5000-01-30/?loc=");
          if (res.data.length > 0 ) {
            handleClose();
            handleCloseL();
            return toast.error("Veículo possui movimentações em aberto, excluir movimentações primeiro!");
          } else {

            if (!window.confirm("Tem certeza que deseja excluir?")) {
              handleCloseL();
              return;
            } else {
            await axios
            .delete("vehicle/vehicle-list/"+ vehicle.cod.value)
            .then(() => {
                toast.success("Cadastro excluido com sucesso!");
                getVehicles();
                setOnEdit(null);
                handleClose();
            })
            .catch(() => toast.error("Erro, não foi possível excluir!"));
            }

          }
          handleCloseL();
        } catch (error) {
          toast.error(error);
          handleCloseL();
        }
    
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    handleOpenL();
  
    const vehicle = ref.current;
   
    if (
      !vehicle.plate.value 
    ) {
      handleCloseL();
      return toast.warn("Preencha o campos Placa !");
    };
    if (
      !vehicle.fuel.value 
    ) {
      handleCloseL();
      return toast.warn("Preencha o campos Combustível !");
    };
    if (
      !vehicle.surname.value 
    ) {
      handleCloseL();
      return toast.warn("Preencha o campos Apelido !");
    };
    if (
      !vehicle.km_current.value 
    ) {
      handleCloseL();
      return toast.warn("Preencha o campos Km Atual !");
    };
    if (
      !vehicle.id_subcompany.value 
    ) {
      handleCloseL();
      return toast.warn("Preencha o campos Empresa !");
    };
    if (
      !vehicle.fuel_consumption.value 
    ) {
      handleCloseL();
      return toast.warn("Preencha o campos Consumo de Km por LT !");
    };
    if (
      !vehicle.category.value 
    ) {
      handleCloseL();
      return toast.warn("Preencha o campos Categoria !");
    };
    if (
      !vehicle.axis_schema.value 
    ) {
      handleCloseL();
      return toast.warn("Preencha o campos Esquema Eixo !");
    };

    if (onEdit) {
      await axios
        .put("vehicle/vehicle-list/", {
          id: vehicle.cod.value,
          plate: vehicle.plate.value.trim(),
          surname: vehicle.surname.value.trim(), 
          equipment: vehicle.equipment.value.trim(),
          owner: vehicle.owner.value.trim(), 
          contact: vehicle.contact.value.trim(), 
          brand: vehicle.brand.value.trim(), 
          model: vehicle.model.value.trim(),
          year: vehicle.year.value.trim(),
          fuel: vehicle.fuel.value.trim(),
          color: vehicle.color.value.trim(),
          chassis: vehicle.chassis.value.trim(),
          reindeer: vehicle.reindeer.value.trim(),
          fuel_consumption: vehicle.fuel_consumption.value.trim(),
          document_expiration: vehicle.document_expiration.value,
          st: vehicle.st.value,
          km_current: vehicle.km_current.value,
          category: vehicle.category.value,
          id_company: IdCompany,
          id_subcompany: vehicle.id_subcompany.value,
          user :IdUser,
          axis_schema: vehicle.axis_schema.value,
        })
        .then(() => {
          toast.success("Cadastro atualizado com sucesso!");

          limparCampos();
          
          getVehicles();
          setOnEdit(null);
          handleClose();
        })
        .catch(() => {
          toast.error("Erro na atualizado!");
          handleCloseL();
        });
    } else {
      await axios
        .post("vehicle/vehicle-list/", { 
          plate: vehicle.plate.value.trim(),
          surname: vehicle.surname.value.trim(), 
          equipment: vehicle.equipment.value.trim(),
          owner: vehicle.owner.value.trim(), 
          contact: vehicle.contact.value.trim(), 
          brand: vehicle.brand.value.trim(), 
          model: vehicle.model.value.trim(),
          year: vehicle.year.value.trim(),
          fuel: vehicle.fuel.value.trim(),
          color: vehicle.color.value.trim(),
          chassis: vehicle.chassis.value.trim(),
          reindeer: vehicle.reindeer.value.trim(),
          fuel_consumption: vehicle.fuel_consumption.value.trim(),
          document_expiration: vehicle.document_expiration.value,
          st: vehicle.st.value,
          km_current: vehicle.km_current.value,
          category: vehicle.category.value,
          id_company: IdCompany,
          id_subcompany: vehicle.id_subcompany.value,
          user :IdUser,
          axis_schema: vehicle.axis_schema.value,
        })
        .then(() => {
          toast.success("Cadastrado com sucesso!");
          limparCampos();
          getVehicles();
          setOnEdit(null);
          handleClose();
        })
        .catch(() => {
          toast.error("Erro no Cadastro!");
          handleCloseL();
        });
        
    }


  };

  return (
    <>
          <Typography style={{ color: "#fff" }} id="modal-modal-title" variant="h6" component="h2">
            Ficha do Veículo
          </Typography>
          <FormContainer  ref={ref} onSubmit={handleSubmit}>
            <InputArea>
              <Label>Código</Label>
              <Input style={{ color: "#fff" }} disabled={true} name="cod"  />
            </InputArea>
            <InputArea>
              <Label>Situação</Label>
              <Select1 name="st">
                <option value={1}>Ativo</option>
                <option value={0}>Inativo</option>
              </Select1>
            </InputArea>
              <InputArea>
                <Label>Empresa*</Label>
                <Select1 style={{ width: "355px" }} name="id_subcompany">
                  <option value="">Selecione</option>
                  {company === undefined ? [] : company.map((item, i) => (
                    <option key={i} value={item.id}>{item.name}</option>
                  ))}
                </Select1>
              </InputArea>
            <InputArea>
              <Label>Categoria</Label>
              <Select1 name="category" >
                <option value={0}>Selecione</option>
                <option value={1}>Urbano</option>
                <option value={2}>Rodoviário</option>
                <option value={3}>Escolar</option>
              </Select1>
            </InputArea>
            <InputArea>
              <Label>Placa*</Label>
              <Input name="plate" />
            </InputArea>
            <InputArea>
              <Label>Apelido*</Label>
              <Input name="surname" />
            </InputArea>
            <InputArea>
              <Label>Equipamento</Label>
              <Input name="equipment" />
            </InputArea>
            <InputArea>
              <Label>Marca</Label>
              <Input name="brand" />
            </InputArea>
            <InputArea>
              <Label>Modelo</Label>
              <Input name="model" />
            </InputArea>
            <InputArea>
              <Label>Ano</Label>
              <Input type="number" pattern="[0-9]+([,\.][0-9]+)?" min="0" step="any" placeholder="Digite só número" name="year" />
            </InputArea>
            <InputArea>
              <Label>Combustível*</Label>
              <Select1 name="fuel">
                <option value="">Selecione a categoria</option>
                  {fuel === undefined ? [] : fuel.map((item, i) => (
                    <option key={i} value={item.fuel}>{item.fuel}</option>
                  ))}
              </Select1>
            </InputArea>
            <InputArea>
              <Label>Cor</Label>
              <InputDate name="color" />
            </InputArea>
            <InputArea>
              <Label>Chassi</Label>
              <Input name="chassis" />
            </InputArea>
            <InputArea>
              <Label>Renavam</Label>
              <InputDate name="reindeer" />
            </InputArea>
            <InputArea>
              <Label>KM Atual*</Label>
              <Input type="number" pattern="[0-9]+([,\.][0-9]+)?" min="0" step="any" placeholder="Digite só número" name="km_current" />
            </InputArea>
            <InputArea>
              <Label>Consumo de Km por LT*</Label>
              <Input type="number" pattern="[0-9]+([,\.][0-9]+)?" min="0" step="any" placeholder="Digite só número" name="fuel_consumption" />
            </InputArea>
            <InputArea>
              <Label>Vencimento CRV</Label>
              <InputDate type="date" name="document_expiration" />
            </InputArea>
              <InputArea>
                <Label>Esquema Eixo</Label>
                <Select1 style={{ width: "355px" }} name="axis_schema">
                  <option value="">Selecione</option>
                  {axisSchema === undefined ? [] : axisSchema.map((item, i) => (
                    <option key={i} value={item.id}>{item.description}</option>
                  ))}
                </Select1>
              </InputArea>
            <InputArea>
              <Label>Contato</Label>
              <Input type="number" pattern="[0-9]+([,\.][0-9]+)?" min="0" step="any" placeholder="Digite só número" name="contact"/>
            </InputArea>
            <InputArea>
              <Label>Proprietário</Label>
              <Input2 style={{ width: "355px" }} name="owner" />
            </InputArea>
                        
            <div style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }} >
              <ButtonArea >
                <Button type="submit">SALVAR</Button>
                { regist === true? 
                 null
                 : 
                 <Button 
                    style={{backgroundColor:"red"}} 
                    onClick={handleDelete}>EXCLUIR <DeleteIcon/>
                 </Button>
                 }
                
                <Button onClick={handleClose}>SAIR</Button>
              </ButtonArea>
            </div>

          </FormContainer>
          
      <Backdrop
              sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 5}}
              open={openL}
          >
         <Label>Carregando...</Label>
              <CircularProgress color="inherit" />
          </Backdrop>
    </>
  );
};

export default FromVehicles;