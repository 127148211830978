import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import Typography from '@mui/material/Typography';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Select from 'react-select';
//import DeleteIcon from '@mui/icons-material/Delete';

import {
  FormContainer,
  InputArea,
  Label,
  Input,
  Input2,
  ButtonArea,
  Button,
  Select1
} from "../../Styles/Styles";

const FromTireMovement = ({
  handleCloseL,
  openL,
  handleOpenL,
  regist,
  onEdit,
  setOnEdit,
  getTireMovement,
  setOpen,
  tireRegistration,
  vehicles,
  axisSchema
}) => {
  const ref = useRef();
  const handleClose = () => setOpen(false);
  const IdCompany = localStorage.getItem("id_company");
  const IdUser = localStorage.getItem("id");
  const [vehicleList, setVehicleList] = useState(true);
  const [axisPositioning, setAxisPositioning] = useState(true);
  const [kmVMomentOfInst, setKmVMomentOfInst] = useState(true);
  const [qtyKmTireWillBeRotated, setQtyKmTireWillBeRotated] = useState(true);
  const [transitCompleted, setTransitCompleted] = useState(true);
  const [finalKmVehicle, setFinalKmVehicle] = useState(true);
  const [transitEndDate, setTransitEndDate] = useState(true);
  const [tireStatus, settireStatus] = useState(true);
  const [releasePanel, setReleasePanel] = React.useState({ value: "none" });
  const [maintenancePanel, setMaintenancePanel] = React.useState({ value: "none" });
  const [scrapPanel, setScrapPanel] = useState({ value: "none" });
  const [axis1, setAxis1] = React.useState({ value: "none" });
  const [axis2, setAxis2] = React.useState({ value: "none" });
  const [axis3, setAxis3] = React.useState({ value: "none" });
  const [axis4, setAxis4] = React.useState({ value: "none" });
  const [tCompleted, setTCompleted] = React.useState({ value: "none" });
  const [axisSteppes, setAxisSteppes] = React.useState({ value: "none" });
  const [axisQuantity, setAxisQuantity] = React.useState(2);
  const [quantitySteppes, setQuantitySteppes] = React.useState(0);
  const [totalQuantityTires, setTotalQuantityTires] = React.useState(0);
  const [tireSteppes, setTireSteppes] = useState({ value: "none" });
  const [tireFire, setTireFire] = useState(true);
  const nFireTire = useRef();
  const vehicleName = useRef();
  const stTire = useRef();
  const vehicle = useRef(null);
  const [st, setSt] = useState(true);
  const [tireP, setTireP] = useState(0);
  const loadingTires = useRef([]);
  const dadosTires = useRef([]);
  const dadosVehicle = useRef([]);

  function adicionaZero(numero) {
    if (numero <= 9)
      return "0" + numero;
    else
      return numero;
  }
  let dataAtual = new Date();
  let dataAtualFormatada = (dataAtual.getFullYear() + "-" + (adicionaZero(dataAtual.getMonth() + 1).toString()) + "-" + adicionaZero(dataAtual.getDate().toString()));

  const cleanearFields = () => {
    const tire = ref.current;

    tire.cod.value = "";
    tire.movement_date.value = dataAtualFormatada;
    tire.tire.value = "";
    tire.tire_status.value = "";
    tire.vehicle.value = "";
    tire.tire_1.value = "";
    tire.tire_2.value = "";
    tire.tire_3.value = "";
    tire.tire_4.value = "";
    tire.tire_5.value = "";
    tire.tire_6.value = "";
    tire.tire_7.value = "";
    tire.tire_8.value = "";
    tire.tire_9.value = "";
    tire.tire_10.value = "";
    tire.tire_11.value = "";
    tire.tire_12.value = "";
    tire.tire_13.value = "";
    tire.tire_14.value = "";
    tire.tire_15.value = "";
    tire.tire_16.value = "";
    tire.tire_17.value = "";
    tire.tire_18.value = "";
    tire.km_v_moment_of_inst.value = "";
    tire.qty_km_tire_will_be_rotated.value = "";
    tire.exchange_with_km.value = "";
    tire.already_driven_km.value = "";
    tire.maintenance_obs.value = "";
    tire.maintenance_km_vehicle.value = "";
    tire.scrap_obs.value = "";
    tire.scrap_km_vehicle.value = "";
    tire.transit_completed.value = "1";
    tire.final_km_vehicle.value = "";
    tire.transit_end_date.value = "";

  }

  const onTireList = () => {
    loadingTires.current = [];
    if(regist){
      const res = tireRegistration.filter((a) => (a.st === "1"));
      // eslint-disable-next-line
        loadingTires.current = res;
    } 
  };

  const onStatusTireChange = () => {
    const tire = ref.current;

    if (tire.tire_status.value === "0") {
      setVehicleList(true);
      setAxisPositioning(true);
      setKmVMomentOfInst(true);
      setQtyKmTireWillBeRotated(true);
      setTransitCompleted(true);

      setReleasePanel({ value: "none" });
      setMaintenancePanel({ value: "none" });
      setScrapPanel({ value: "none" });
    }
    if (tire.tire_status.value === "1") {
      setVehicleList(false);
      setAxisPositioning(false);
      setKmVMomentOfInst(false);
      setQtyKmTireWillBeRotated(false);
      setTransitCompleted(false);
      setMaintenancePanel({ value: "none" });
      setScrapPanel({ value: "none" });

      if (parseFloat(tire.vehicle.value) > 0) {
        setReleasePanel({ value: "flex" });
        onGetAxisPositioning();
      } else {
        setReleasePanel({ value: "none" });
      }
    }
    if (tire.tire_status.value === "2") {
      setVehicleList(true);
      setAxisPositioning(true);
      setKmVMomentOfInst(true);
      setQtyKmTireWillBeRotated(true);
      setTransitCompleted(true);
      setReleasePanel({ value: "none" });
      setMaintenancePanel({ value: "flex" });
      setScrapPanel({ value: "none" });
    }
    if (tire.tire_status.value === "3") {
      setVehicleList(true);
      setAxisPositioning(true);
      setKmVMomentOfInst(true);
      setQtyKmTireWillBeRotated(true);
      setTransitCompleted(true);
      setReleasePanel({ value: "none" });
      setMaintenancePanel({ value: "none" });
      setScrapPanel({ value: "flex" });
    }
    if (tire.tire_status.value === "4") {
      setVehicleList(true);
      setAxisPositioning(true);
      setKmVMomentOfInst(true);
      setQtyKmTireWillBeRotated(true);
      setTransitCompleted(false);

      setReleasePanel({ value: "none" });
      setMaintenancePanel({ value: "none" });
      setScrapPanel({ value: "none" });
      setTCompleted({ value: "flex" });
    }

    if (regist) {
      if (stTire.current === "1") {
        setVehicleList(true);
        setAxisPositioning(true);
        setKmVMomentOfInst(true);
        setQtyKmTireWillBeRotated(true);
        setSt(false);
        return toast.warn("Pneu já utilizado em outro veículo!");
      } else {
        setSt(true);
      }
    }

  };

  const onTransitCompletedChange = () => {
    const tire = ref.current;
    if (tire.transit_completed.value === "1") {
      setFinalKmVehicle(true);
      setTransitEndDate(true);
    } else {
      setFinalKmVehicle(false);
      setTransitEndDate(false);
    }
  };

  const onGetAxisPositioning = (item) => {
    const tire = ref.current;
    if(vehicleList === false){
      tire.vehicle.value = item.value;
    };
    if(vehicleList === true){
      tire.vehicle.value = onEdit.vehicle;
    };
    const res = vehicles.filter((a) => (a.id === tire.vehicle.value === undefined ? "" : a.id === tire.vehicle.value));
    // eslint-disable-next-line
    let AxisSchema = "";
    if (res.length > 0) {
      res.map((a) => {
        AxisSchema = a.axis_schema;
        vehicleName.current = a.surname;
        tire.tire_1.value = a.tire_1;
        tire.tire_2.value = a.tire_2;
        tire.tire_3.value = a.tire_3;
        tire.tire_4.value = a.tire_4;
        tire.tire_5.value = a.tire_5;
        tire.tire_6.value = a.tire_6;
        tire.tire_7.value = a.tire_7;
        tire.tire_8.value = a.tire_8;
        tire.tire_9.value = a.tire_9;
        tire.tire_10.value = a.tire_10;
        tire.tire_11.value = a.tire_11;
        tire.tire_12.value = a.tire_12;
        tire.tire_13.value = a.tire_13;
        tire.tire_14.value = a.tire_14;
        tire.tire_15.value = a.tire_15;
        tire.tire_16.value = a.tire_16;
        tire.tire_17.value = a.tire_17;
        tire.tire_18.value = a.tire_18;
      });
      const rest = axisSchema.filter((a) => (a.id === AxisSchema === undefined ? 0 : a.id === AxisSchema));
      // eslint-disable-next-line
      if (rest.length > 0) {
        setReleasePanel({ value: "flex" });
        setAxisQuantity(rest[0].axis_quantity);
        setQuantitySteppes(rest[0].quantity_steppes);
        setTotalQuantityTires(rest[0].total_quantity_tires);

        if (rest[0].axis_quantity === "2") {
          if (rest[0].axle_tire_quantity_2 === "2") {
            setAxis1({ value: "table-column" });
            setAxis2({ value: "none" });
          } else {
            setAxis2({ value: "table-column" });
            setAxis1({ value: "none" });
          }

          setAxis3({ value: "none" });
          setAxis4({ value: "none" });
        };

        if (rest[0].axis_quantity === "3") {
          if (rest[0].axle_tire_quantity_2 === "2") {
            setAxis1({ value: "table-column" });
            setAxis2({ value: "none" });
          } else {
            setAxis2({ value: "table-column" });
            setAxis1({ value: "none" });
          }

          if (rest[0].axle_tire_quantity_3 === "4") {
            setAxis3({ value: "table-column" });
          } else {
            setAxis3({ value: "table-column" });
          }

          setAxis4({ value: "none" });
        };

        if (rest[0].axis_quantity === "4") {
          if (rest[0].axle_tire_quantity_2 === "2") {
            setAxis1({ value: "table-column" });
            setAxis2({ value: "none" });
          } else {
            setAxis2({ value: "table-column" });
            setAxis1({ value: "none" });
          }

          if (rest[0].axle_tire_quantity_3 === "4") {
            setAxis3({ value: "table-column" });
          } else {
            setAxis3({ value: "table-column" });
          }

          if (rest[0].axle_tire_quantity_4 === "4") {
            setAxis4({ value: "table-column" });
          } else {
            setAxis4({ value: "table-column" });
          }
        };

        if (parseFloat(rest[0].quantity_steppes) > 0) {
          setAxisSteppes({ value: "table-column" });
          if (rest[0].quantity_steppes === "1") {
            setTireSteppes({ value: "none" });
          } else {
            setTireSteppes({ value: "flex" });
          }
        };
      }
    }

  };

  const onCalKmChange = () => {
    const tire = ref.current;
    const initial = tire.km_v_moment_of_inst.value === undefined ? 0 : tire.km_v_moment_of_inst.value;
    const quantity = tire.qty_km_tire_will_be_rotated.value === undefined ? 0 : tire.qty_km_tire_will_be_rotated.value;
    tire.exchange_with_km.value = parseFloat((+initial) + (+quantity));
  };

  const onSavePositioning = (event) => {
    const tire = ref.current;
    verifyPositioning();

    if (event.target.value === "1") {
      if (tire.tire_1.value === "") {
        tire.tire_1.value = nFireTire.current;
        setSt(true);
      } else {
        setSt(false);
        return toast.error("Este carro já possui um pneu no eixo!");
      }
    };
    if (event.target.value === "2") {
      if (tire.tire_2.value === "") {
        tire.tire_2.value = nFireTire.current;
        setSt(true);
      } else {
        setSt(false);
        return toast.error("Este carro já possui um pneu no eixo!");
      }
    };
    if (event.target.value === "3") {
      if (tire.tire_3.value === "") {
        tire.tire_3.value = nFireTire.current;
        setSt(true);
      } else {
        setSt(false);
        return toast.error("Este carro já possui um pneu no eixo!");
      }
    };
    if (event.target.value === "4") {
      if (tire.tire_4.value === "") {
        tire.tire_4.value = nFireTire.current;
      } else {
        return toast.error("Este carro já possui um pneu no eixo!");
      }
    };
    if (event.target.value === "5") {
      if (tire.tire_5.value === "") {
        tire.tire_5.value = nFireTire.current;
        setSt(true);
      } else {
        setSt(false);
        return toast.error("Este carro já possui um pneu no eixo!");
      }
    };
    if (event.target.value === "6") {
      if (tire.tire_6.value === "") {
        tire.tire_6.value = nFireTire.current;
        setSt(true);
      } else {
        setSt(false);
        return toast.error("Este carro já possui um pneu no eixo!");
      }
    };
    if (event.target.value === "7") {
      if (tire.tire_7.value === "") {
        tire.tire_7.value = nFireTire.current;
        setSt(true);
      } else {
        setSt(false);
        return toast.error("Este carro já possui um pneu no eixo!");
      }
    };
    if (event.target.value === "8") {
      if (tire.tire_8.value === "") {
        tire.tire_8.value = nFireTire.current;
        setSt(true);
      } else {
        setSt(false);
        return toast.error("Este carro já possui um pneu no eixo!");
      }
    };
    if (event.target.value === "9") {
      if (tire.tire_9.value === "") {
        tire.tire_9.value = nFireTire.current;
        setSt(true);
      } else {
        setSt(false);
        return toast.error("Este carro já possui um pneu no eixo!");
      }
    };
    if (event.target.value === "10") {
      if (tire.tire_10.value === "") {
        tire.tire_10.value = nFireTire.current;
        setSt(true);
      } else {
        setSt(false);
        return toast.error("Este carro já possui um pneu no eixo!");
      }
    };
    if (event.target.value === "11") {
      if (tire.tire_11.value === "") {
        tire.tire_11.value = nFireTire.current;
        setSt(true);
      } else {
        setSt(false);
        return toast.error("Este carro já possui um pneu no eixo!");
      }
    };
    if (event.target.value === "12") {
      if (tire.tire_12.value === "") {
        tire.tire_12.value = nFireTire.current;
        setSt(true);
      } else {
        setSt(false);
        return toast.error("Este carro já possui um pneu no eixo!");
      }
    };
    if (event.target.value === "13") {
      if (tire.tire_13.value === "") {
        tire.tire_13.value = nFireTire.current;
        setSt(true);
      } else {
        setSt(false);
        return toast.error("Este carro já possui um pneu no eixo!");
      }
    };
    if (event.target.value === "14") {
      if (tire.tire_14.value === "") {
        tire.tire_14.value = nFireTire.current;
        setSt(true);
      } else {
        setSt(false);
        return toast.error("Este carro já possui um pneu no eixo!");
      }
    };
    if (event.target.value === "15") {
      if (tire.tire_15.value === "") {
        tire.tire_15.value = nFireTire.current;
        setSt(true);
      } else {
        setSt(false);
        return toast.error("Este carro já possui um pneu no eixo!");
      }
    };
    if (event.target.value === "16") {
      if (tire.tire_16.value === "") {
        tire.tire_16.value = nFireTire.current;
        setSt(true);
      } else {
        setSt(false);
        return toast.error("Este carro já possui um pneu no eixo!");
      }
    };
    if (event.target.value === "17") {
      if (tire.tire_17.value === "") {
        tire.tire_17.value = nFireTire.current;
        setSt(true);
      } else {
        setSt(false);
        return toast.error("Este carro já possui um pneu no eixo!");
      }
    };
    if (event.target.value === "18") {
      if (tire.tire_18.value === "") {
        tire.tire_18.value = nFireTire.current;
        setSt(true);
      } else {
        setSt(false);
        return toast.error("Este carro já possui um pneu no eixo!");
      }
    };

    
    setTireP(parseFloat(tire.axis_positioning.value));
    
  }

  const verifyPositioning = () => {
    const tire = ref.current;
    if (nFireTire.current === tire.tire_1.value) {
      tire.tire_1.value = "";
    }
    if (nFireTire.current === tire.tire_2.value) {
      tire.tire_2.value = "";
    }
    if (nFireTire.current === tire.tire_3.value) {
      tire.tire_3.value = "";
    }
    if (nFireTire.current === tire.tire_4.value) {
      tire.tire_4.value = "";
    }
    if (nFireTire.current === tire.tire_5.value) {
      tire.tire_5.value = "";
    }
    if (nFireTire.current === tire.tire_6.value) {
      tire.tire_6.value = "";
    }
    if (nFireTire.current === tire.tire_7.value) {
      tire.tire_7.value = "";
    }
    if (nFireTire.current === tire.tire_8.value) {
      tire.tire_8.value = "";
    }
    if (nFireTire.current === tire.tire_9.value) {
      tire.tire_9.value = "";
    }
    if (nFireTire.current === tire.tire_10.value) {
      tire.tire_10.value = "";
    }
    if (nFireTire.current === tire.tire_11.value) {
      tire.tire_11.value = "";
    }
    if (nFireTire.current === tire.tire_12.value) {
      tire.tire_12.value = "";
    }
    if (nFireTire.current === tire.tire_13.value) {
      tire.tire_13.value = "";
    }
    if (nFireTire.current === tire.tire_14.value) {
      tire.tire_14.value = "";
    }
    if (nFireTire.current === tire.tire_15.value) {
      tire.tire_15.value = "";
    }
    if (nFireTire.current === tire.tire_16.value) {
      tire.tire_16.value = "";
    }
    if (nFireTire.current === tire.tire_17.value) {
      tire.tire_17.value = "";
    }
    if (nFireTire.current === tire.tire_18.value) {
      tire.tire_18.value = "";
    }
  }

  useEffect(() => {
    const tire = ref.current;
    vehicleName.current = "";
    onTireList();
    if (regist) {
      cleanearFields();
      setTireFire(false);
    } else {
      if (onEdit) {
        setTireP(
          parseFloat(onEdit.tire_1) > 0 ? 1 :
            parseFloat(onEdit.tire_2) > 0 ? 2 :
              parseFloat(onEdit.tire_3) > 0 ? 3 :
                parseFloat(onEdit.tire_4) > 0 ? 4 :
                  parseFloat(onEdit.tire_5) > 0 ? 5 :
                    parseFloat(onEdit.tire_6) > 0 ? 5 :
                      parseFloat(onEdit.tire_7) > 0 ? 7 :
                        parseFloat(onEdit.tire_8) > 0 ? 8 :
                          parseFloat(onEdit.tire_9) > 0 ? 9 :
                            parseFloat(onEdit.tire_10) > 0 ? 10 :
                              parseFloat(onEdit.tire_11) > 0 ? 11 :
                                parseFloat(onEdit.tire_12) > 0 ? 12 :
                                  parseFloat(onEdit.tire_13) > 0 ? 13 :
                                    parseFloat(onEdit.tire_14) > 0 ? 14 :
                                      parseFloat(onEdit.tire_15) > 0 ? 15 :
                                        parseFloat(onEdit.tire_16) > 0 ? 16 :
                                          parseFloat(onEdit.tire_17) > 0 ? 17 :
                                            parseFloat(onEdit.tire_18) > 0 ? 18 : " "
        );
        tire.axis_positioning.value = tireP;
        tire.cod.value = onEdit.id;
        tire.movement_date.value = dataAtualFormatada;
        tire.tire.value = onEdit.tire;
        tire.tire_status.value = onEdit.tire_status;
        tire.vehicle.value = onEdit.vehicle;
        tire.km_v_moment_of_inst.value = onEdit.km_v_moment_of_inst;
        tire.qty_km_tire_will_be_rotated.value = onEdit.qty_km_tire_will_be_rotated;
        tire.exchange_with_km.value = onEdit.exchange_with_km;
        tire.already_driven_km.value = onEdit.already_driven_km;
        tire.maintenance_obs.value = onEdit.maintenance_obs;
        tire.maintenance_km_vehicle.value = onEdit.maintenance_km_vehicle;
        tire.scrap_obs.value = onEdit.scrap_obs;
        tire.scrap_km_vehicle.value = onEdit.scrap_km_vehicle;
        tire.transit_completed.value = onEdit.transit_completed;
        tire.final_km_vehicle.value = onEdit.final_km_vehicle;
        tire.transit_end_date.value = onEdit.transit_end_date;

        onGetAxisPositioning();
        onStatusTireChange();
        onTireChange();

        setTireFire(true);
        setVehicleList(true);
        setAxisPositioning(true);
        if (onEdit.tire_status === "4") {
          setSt(false);
          settireStatus(true);
          setTransitCompleted(true);
        };
      };
    };
    
    loadingTire();
    loadingVehicle();
    // eslint-disable-next-line
  }, []);

  const onTireChange = (item) => {
    const tire = ref.current;

    if(regist){
    tire.tire.value = item.value === undefined ? tire.tire.value : item.value;
    const res = tireRegistration.filter((a) => (a.id === tire.tire.value === undefined ? 0 : a.id === tire.tire.value));
    // eslint-disable-next-line
    res.map((a) => {
      stTire.current = a.tire_status;
      nFireTire.current = a.fire_number;
      tire.recaps.value = a.recaps;
      if (regist) {
        if (a.tire_status === "1") {
          tire.tire_status.value = "";
          setSt(true);
          return toast.warn("Pneu já utilizado em outro veículo!");
        } else {
          setSt(false);
        }
      }
    });
  } else {
    const res = tireRegistration.filter((a) => (a.id === tire.tire.value === undefined ? 0 : a.id === tire.tire.value));
    // eslint-disable-next-line
    res.map((a) => {
      stTire.current = a.tire_status;
      nFireTire.current = a.fire_number;
      tire.recaps.value = a.recaps;
      if (regist) {
        if (a.tire_status === "1") {
          tire.tire_status.value = "";
          setSt(true);
          return toast.warn("Pneu já utilizado em outro veículo!");
        } else {
          setSt(false);
        }
      }
    });
  }

    verifyMovementTire();
  };

  /*
    const handleDelete = async (e) => {
      e.preventDefault();
      const tire = ref.current;
      handleOpenL();
  
      if (!window.confirm("Tem certeza que deseja excluir?")) {
        handleCloseL();
        return;
      } else {
        verifyPositioning();
  
        updateTireVehicle();
        if (st) {
          await axios
            .delete("tires/tire-movement-list/" + tire.cod.value + "/" + tire.tire.value)
            .then(() => {
              toast.success("Cadastro excluido com sucesso!");
              getTireMovement();
              setOnEdit(null);
              handleClose();
            })
            .catch(() => {
              handleCloseL();
              toast.error("Erro, não foi possível excluir!");
            });
        }
        else {
          handleCloseL();
          toast.error("Erro, não foi possível excluir!");
        }
      }
    }
  */
  const updateTireVehicle = async () => {
    const tire = ref.current;
    const res = vehicles.filter((a) => (a.id === tire.vehicle.value));
    // eslint-disable-next-line
    res.map((a) => {
      vehicle.current = a;
    });
    const v = vehicle.current;
    if (v) {
      await axios
        .put("tires/tire-movement-vehicle/", {
          id: v.id,
          tire_1: tire.tire_1.value,
          tire_2: tire.tire_2.value,
          tire_3: tire.tire_3.value,
          tire_4: tire.tire_4.value,
          tire_5: tire.tire_5.value,
          tire_6: tire.tire_6.value,
          tire_7: tire.tire_7.value,
          tire_8: tire.tire_8.value,
          tire_9: tire.tire_9.value,
          tire_10: tire.tire_10.value,
          tire_11: tire.tire_11.value,
          tire_12: tire.tire_12.value,
          tire_13: tire.tire_13.value,
          tire_14: tire.tire_14.value,
          tire_15: tire.tire_15.value,
          tire_16: tire.tire_16.value,
          tire_17: tire.tire_17.value,
          tire_18: tire.tire_18.value,
        })
        .then(() => {
          toast.success("Cadastro do veículo atualizado com sucesso!");
        })
        .catch(() => {
          toast.error("Erro na atualizado!");
        });
    }
  }

  const verifyMovementTire = async () => {
    const tire = ref.current;
    handleOpenL();
    if (regist) {
      if (stTire.current === "4") {
        if (tire.tire.value) {
          settireStatus(false);
        } else {
          settireStatus(true);
        }
        onStatusTireChange();

        handleCloseL();

      } else {
        try {
          const res = await axios.get("tires/tire-movement-list/" + IdCompany + "/?loc=" + nFireTire.current);
          if (res.data.length > 0) {
            if(res.data[0].tire_status === "4"){
              setSt(true);

              if (tire.tire.value) {
                settireStatus(false);
              } else {
                settireStatus(true);
              }
              onStatusTireChange();

            } else {
            setSt(false);
            toast.error("Já tem movimentação com esse pneu!");
            }
          } else {
            setSt(true);

            if (tire.tire.value) {
              settireStatus(false);
            } else {
              settireStatus(true);
            }
            onStatusTireChange();

          }
          handleCloseL();
        } catch (error) {
          toast.error(error);
          handleCloseL();
        }
      }
    } else {
      if (tire.tire.value) {
        settireStatus(false);
      } else {
        settireStatus(true);
      }
      onStatusTireChange();

      handleCloseL();
    }
  }

  const handleSubmitHistoryTires = async () => {
    const tire = ref.current;

    try {
      await axios.post("tires/tire-movement/", {
        id_movement: "%",
        tire: tire.tire.value,
        tire_status: tire.tire_status.value,
        vehicle: (tire.vehicle.value === undefined ? "%" : tire.vehicle.value),
        id_company: IdCompany,
      })
        .then(async (res) => {
          if (res.data.length > 0) {
            await axios
              .put("tires/tire-history-movement-list/", {
                id_movement: tire.cod.value,
                movement_date: tire.movement_date.value,
                tire: tire.tire.value,
                fire_number: nFireTire.current,
                tire_status: tire.tire_status.value,
                vehicle: (tire.vehicle.value === undefined ? "" : tire.vehicle.value),
                surname: (vehicleName.current === undefined ? "" : vehicleName.current),
                tire_1: (tire.tire_1.value === nFireTire.current ? tire.tire_1.value : ""),
                tire_2: (tire.tire_2.value === nFireTire.current ? tire.tire_2.value : ""),
                tire_3: (tire.tire_3.value === nFireTire.current ? tire.tire_3.value : ""),
                tire_4: (tire.tire_4.value === nFireTire.current ? tire.tire_4.value : ""),
                tire_5: (tire.tire_5.value === nFireTire.current ? tire.tire_5.value : ""),
                tire_6: (tire.tire_6.value === nFireTire.current ? tire.tire_6.value : ""),
                tire_7: (tire.tire_7.value === nFireTire.current ? tire.tire_7.value : ""),
                tire_8: (tire.tire_8.value === nFireTire.current ? tire.tire_8.value : ""),
                tire_9: (tire.tire_9.value === nFireTire.current ? tire.tire_9.value : ""),
                tire_10: (tire.tire_10.value === nFireTire.current ? tire.tire_10.value : ""),
                tire_11: (tire.tire_11.value === nFireTire.current ? tire.tire_11.value : ""),
                tire_12: (tire.tire_12.value === nFireTire.current ? tire.tire_12.value : ""),
                tire_13: (tire.tire_13.value === nFireTire.current ? tire.tire_13.value : ""),
                tire_14: (tire.tire_14.value === nFireTire.current ? tire.tire_14.value : ""),
                tire_15: (tire.tire_15.value === nFireTire.current ? tire.tire_15.value : ""),
                tire_16: (tire.tire_16.value === nFireTire.current ? tire.tire_16.value : ""),
                tire_17: (tire.tire_17.value === nFireTire.current ? tire.tire_17.value : ""),
                tire_18: (tire.tire_18.value === nFireTire.current ? tire.tire_18.value : ""),
                recaps: tire.recaps.value,
                km_v_moment_of_inst: tire.km_v_moment_of_inst.value,
                qty_km_tire_will_be_rotated: tire.qty_km_tire_will_be_rotated.value,
                exchange_with_km: tire.exchange_with_km.value,
                already_driven_km: tire.already_driven_km.value,
                maintenance_obs: tire.maintenance_obs.value,
                maintenance_km_vehicle: tire.maintenance_km_vehicle.value,
                scrap_obs: tire.scrap_obs.value,
                scrap_km_vehicle: tire.scrap_km_vehicle.value,
                transit_completed: tire.transit_completed.value,
                final_km_vehicle: tire.final_km_vehicle.value,
                transit_end_date: tire.transit_end_date.value,
                user: IdUser,
              })
              .then(() => {
                toast.success("Histórico atualizado com sucesso!");
              })
              .catch(() => {
                toast.error("Erro na atualizado!");
              });
          } else {
            await axios
              .post("tires/tire-history-movement-list/", {
                movement_date: tire.movement_date.value,
                id_movement: tire.cod.value,
                tire: tire.tire.value,
                fire_number: nFireTire.current,
                tire_status: tire.tire_status.value,
                vehicle: (tire.vehicle.value === undefined ? "" : tire.vehicle.value),
                surname: (vehicleName.current === undefined? "" : vehicleName.current),
                tire_1: (tire.tire_1.value === nFireTire.current ? tire.tire_1.value : ""),
                tire_2: (tire.tire_2.value === nFireTire.current ? tire.tire_2.value : ""),
                tire_3: (tire.tire_3.value === nFireTire.current ? tire.tire_3.value : ""),
                tire_4: (tire.tire_4.value === nFireTire.current ? tire.tire_4.value : ""),
                tire_5: (tire.tire_5.value === nFireTire.current ? tire.tire_5.value : ""),
                tire_6: (tire.tire_6.value === nFireTire.current ? tire.tire_6.value : ""),
                tire_7: (tire.tire_7.value === nFireTire.current ? tire.tire_7.value : ""),
                tire_8: (tire.tire_8.value === nFireTire.current ? tire.tire_8.value : ""),
                tire_9: (tire.tire_9.value === nFireTire.current ? tire.tire_9.value : ""),
                tire_10: (tire.tire_10.value === nFireTire.current ? tire.tire_10.value : ""),
                tire_11: (tire.tire_11.value === nFireTire.current ? tire.tire_11.value : ""),
                tire_12: (tire.tire_12.value === nFireTire.current ? tire.tire_12.value : ""),
                tire_13: (tire.tire_13.value === nFireTire.current ? tire.tire_13.value : ""),
                tire_14: (tire.tire_14.value === nFireTire.current ? tire.tire_14.value : ""),
                tire_15: (tire.tire_15.value === nFireTire.current ? tire.tire_15.value : ""),
                tire_16: (tire.tire_16.value === nFireTire.current ? tire.tire_16.value : ""),
                tire_17: (tire.tire_17.value === nFireTire.current ? tire.tire_17.value : ""),
                tire_18: (tire.tire_18.value === nFireTire.current ? tire.tire_18.value : ""),
                recaps: tire.recaps.value,
                km_v_moment_of_inst: tire.km_v_moment_of_inst.value,
                qty_km_tire_will_be_rotated: tire.qty_km_tire_will_be_rotated.value,
                exchange_with_km: tire.exchange_with_km.value,
                already_driven_km: tire.already_driven_km.value,
                maintenance_obs: tire.maintenance_obs.value,
                maintenance_km_vehicle: tire.maintenance_km_vehicle.value,
                scrap_obs: tire.scrap_obs.value,
                scrap_km_vehicle: tire.scrap_km_vehicle.value,
                transit_completed: tire.transit_completed.value,
                final_km_vehicle: tire.final_km_vehicle.value,
                transit_end_date: tire.transit_end_date.value,
                user: IdUser,
                id_company: IdCompany,
              })
              .then(() => {
                toast.success("Histórico registrado com sucesso!");
              })
              .catch(() => {
                toast.error("Erro no histórico!");
              });
    
          }
        })

    } catch (error) {
      toast.error(error);
    }

    
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    handleOpenL();
    const tire = ref.current;

    if (
      !tire.tire_status.value
    ) {
      toast.error("Precisa selecionar Status Pneu!");
      handleCloseL();
      return;
    };

    if (tire.tire_status.value === "1") {
      if (
        !tire.vehicle.value ||
        !tire.km_v_moment_of_inst.value ||
        !tire.qty_km_tire_will_be_rotated.value
      ) {
        handleCloseL();
        return toast.warn("Preencha todos os campos com ( * )!");
      };

    };

    if (tire.tire_status.value === "2") {
      if (
        !tire.maintenance_obs.value ||
        !tire.maintenance_km_vehicle.value
      ) {
        handleCloseL();
        return toast.warn("Preencha todos os campos com ( * )!");
      };

    };

    if (tire.tire_status.value === "3") {
      if (
        !tire.scrap_obs.value ||
        !tire.scrap_km_vehicle.value
      ) {
        handleCloseL();
        return toast.warn("Preencha todos os campos com ( * )!");
      };

    };

    if (tire.tire_status.value === "4") {
      if (
        !tire.final_km_vehicle.value ||
        !tire.transit_end_date.value
      ) {
        toast.error("Preencha todos os campos Finalização do Transito com ( * ) !");
        handleCloseL();
        return;
      }
    }

    if (tire.tire_status.value === "0" || tire.tire_status.value === "2" || tire.tire_status.value === "3" || tire.tire_status.value === "4") {
      verifyPositioning();
    };

    if (nFireTire.current === undefined || nFireTire.current === null || nFireTire.current === 0 || nFireTire.current === "") {
      handleCloseL();
      return toast.warn("Pneu não pode ser utilizado no lançamento!");
    };

    if (st) {
      await handleSubmitHistoryTires();
      await updateTireVehicle();

      if (onEdit) {
        await axios
          .put("tires/tire-movement-list/", {
            id: tire.cod.value,
            movement_date: tire.movement_date.value,
            tire: tire.tire.value,
            fire_number: nFireTire.current,
            tire_status: tire.tire_status.value,
            vehicle: (
              tire.tire_status.value === "0" ? "" :
                tire.tire_status.value === "2" ? "" :
                tire.tire_status.value === "3" ? "" :
                tire.tire_status.value === "4" ? "" : tire.vehicle.value),
            surname: (
              tire.tire_status.value === "0" ? "" :
                tire.tire_status.value === "2" ? "" :
                tire.tire_status.value === "3" ? "" :
                tire.tire_status.value === "4" ? "" : vehicleName.current),
            tire_1: (tire.tire_1.value === nFireTire.current ? tire.tire_1.value : ""),
            tire_2: (tire.tire_2.value === nFireTire.current ? tire.tire_2.value : ""),
            tire_3: (tire.tire_3.value === nFireTire.current ? tire.tire_3.value : ""),
            tire_4: (tire.tire_4.value === nFireTire.current ? tire.tire_4.value : ""),
            tire_5: (tire.tire_5.value === nFireTire.current ? tire.tire_5.value : ""),
            tire_6: (tire.tire_6.value === nFireTire.current ? tire.tire_6.value : ""),
            tire_7: (tire.tire_7.value === nFireTire.current ? tire.tire_7.value : ""),
            tire_8: (tire.tire_8.value === nFireTire.current ? tire.tire_8.value : ""),
            tire_9: (tire.tire_9.value === nFireTire.current ? tire.tire_9.value : ""),
            tire_10: (tire.tire_10.value === nFireTire.current ? tire.tire_10.value : ""),
            tire_11: (tire.tire_11.value === nFireTire.current ? tire.tire_11.value : ""),
            tire_12: (tire.tire_12.value === nFireTire.current ? tire.tire_12.value : ""),
            tire_13: (tire.tire_13.value === nFireTire.current ? tire.tire_13.value : ""),
            tire_14: (tire.tire_14.value === nFireTire.current ? tire.tire_14.value : ""),
            tire_15: (tire.tire_15.value === nFireTire.current ? tire.tire_15.value : ""),
            tire_16: (tire.tire_16.value === nFireTire.current ? tire.tire_16.value : ""),
            tire_17: (tire.tire_17.value === nFireTire.current ? tire.tire_17.value : ""),
            tire_18: (tire.tire_18.value === nFireTire.current ? tire.tire_18.value : ""),
            recaps: tire.recaps.value,
            km_v_moment_of_inst: tire.km_v_moment_of_inst.value,
            qty_km_tire_will_be_rotated: tire.qty_km_tire_will_be_rotated.value,
            exchange_with_km: tire.exchange_with_km.value,
            already_driven_km: tire.already_driven_km.value,
            maintenance_obs: tire.maintenance_obs.value,
            maintenance_km_vehicle: tire.maintenance_km_vehicle.value,
            scrap_obs: tire.scrap_obs.value,
            scrap_km_vehicle: tire.scrap_km_vehicle.value,
            transit_completed: tire.transit_completed.value,
            final_km_vehicle: tire.final_km_vehicle.value,
            transit_end_date: tire.transit_end_date.value,
            user: IdUser,
          })
          .then(() => {
            toast.success("Cadastro atualizado com sucesso!");

            getTireMovement();
            cleanearFields();
            setOnEdit(null);
            handleClose();
          })
          .catch(() => {
            handleCloseL();
            toast.error("Erro na atualizado!");
          });
      } else {
        await axios
          .post("tires/tire-movement-list/", {
            movement_date: tire.movement_date.value,
            tire: tire.tire.value,
            fire_number: nFireTire.current,
            tire_status: tire.tire_status.value,
            vehicle: (
              tire.tire_status.value === "0" ? "" :
                tire.tire_status.value === "2" ? "" :
                tire.tire_status.value === "3" ? "" :
                tire.tire_status.value === "4" ? "" : tire.vehicle.value),
            surname: (
              tire.tire_status.value === "0" ? "" :
                tire.tire_status.value === "2" ? "" :
                tire.tire_status.value === "3" ? "" :
                tire.tire_status.value === "4" ? "" : vehicleName.current),
            tire_1: (tire.tire_1.value === nFireTire.current ? tire.tire_1.value : ""),
            tire_2: (tire.tire_2.value === nFireTire.current ? tire.tire_2.value : ""),
            tire_3: (tire.tire_3.value === nFireTire.current ? tire.tire_3.value : ""),
            tire_4: (tire.tire_4.value === nFireTire.current ? tire.tire_4.value : ""),
            tire_5: (tire.tire_5.value === nFireTire.current ? tire.tire_5.value : ""),
            tire_6: (tire.tire_6.value === nFireTire.current ? tire.tire_6.value : ""),
            tire_7: (tire.tire_7.value === nFireTire.current ? tire.tire_7.value : ""),
            tire_8: (tire.tire_8.value === nFireTire.current ? tire.tire_8.value : ""),
            tire_9: (tire.tire_9.value === nFireTire.current ? tire.tire_9.value : ""),
            tire_10: (tire.tire_10.value === nFireTire.current ? tire.tire_10.value : ""),
            tire_11: (tire.tire_11.value === nFireTire.current ? tire.tire_11.value : ""),
            tire_12: (tire.tire_12.value === nFireTire.current ? tire.tire_12.value : ""),
            tire_13: (tire.tire_13.value === nFireTire.current ? tire.tire_13.value : ""),
            tire_14: (tire.tire_14.value === nFireTire.current ? tire.tire_14.value : ""),
            tire_15: (tire.tire_15.value === nFireTire.current ? tire.tire_15.value : ""),
            tire_16: (tire.tire_16.value === nFireTire.current ? tire.tire_16.value : ""),
            tire_17: (tire.tire_17.value === nFireTire.current ? tire.tire_17.value : ""),
            tire_18: (tire.tire_18.value === nFireTire.current ? tire.tire_18.value : ""),
            recaps: tire.recaps.value,
            km_v_moment_of_inst: tire.km_v_moment_of_inst.value,
            qty_km_tire_will_be_rotated: tire.qty_km_tire_will_be_rotated.value,
            exchange_with_km: tire.exchange_with_km.value,
            already_driven_km: tire.already_driven_km.value,
            maintenance_obs: tire.maintenance_obs.value,
            maintenance_km_vehicle: tire.maintenance_km_vehicle.value,
            scrap_obs: tire.scrap_obs.value,
            scrap_km_vehicle: tire.scrap_km_vehicle.value,
            transit_completed: tire.transit_completed.value,
            final_km_vehicle: tire.final_km_vehicle.value,
            transit_end_date: tire.transit_end_date.value,
            user: IdUser,
            id_company: IdCompany,
          })
          .then(() => {
            toast.success("Cadastrado com sucesso!");

            cleanearFields();
            getTireMovement();
            setOnEdit(null);
            handleClose();
          })
          .catch(() => {
            handleCloseL();
            toast.error("Erro no Cadastro!");
          });

      }
    } else {
      handleCloseL();
      return toast.error("Todos os campos são obrigatórios!");
    }

  };

  

  //debugger
  const loadingTire = () => {
    dadosTires.current = [];
    if(regist){ 
     dadosTires.current = loadingTires.current === undefined ? [] : loadingTires.current.map((item) => (
      {value : item.id, 
        label : item.fire_number + " - " + (item.tire_status === "0" ? " Estoque" :
        item.tire_status === "1" ? " Transito" :
          item.tire_status === "2" ? " Manutenção" :
            item.tire_status === "3" ? " Sucata" :
              item.tire_status === "4" ? " Finalização do Transito" : " Outros"
      )}
    )) 
    } else {
    dadosTires.current = tireRegistration === undefined ? [] : tireRegistration.map((item) => (
      {value : item.id, 
        label : item.fire_number + " - " + (item.tire_status === "0" ? " Estoque" :
        item.tire_status === "1" ? " Transito" :
          item.tire_status === "2" ? " Manutenção" :
            item.tire_status === "3" ? " Sucata" :
              item.tire_status === "4" ? " Finalização do Transito" : " Outros"
      )}
    ))
    }
  };

  const loadingVehicle = () => {
    dadosVehicle.current = [];
    
     dadosVehicle.current = vehicles === undefined ? [] : vehicles.map((item) => (
      {value : item.id, 
        label : item.surname + " - " + item.plate}
    )) 
  };

  return (
    <>
      <Typography style={{ color: "#fff" }} id="modal-modal-title" variant="h6" component="h2">
        Movimentações do Pneu
      </Typography>
      <FormContainer ref={ref} onSubmit={handleSubmit}>
        <InputArea>
          <Label>Código</Label>
          <Input style={{ color: "#fff" }} disabled={true} name="cod" />
        </InputArea>
        <InputArea>
          <Label>Data Mov.*</Label>
          <Input2 type="date" name="movement_date" />
        </InputArea>
        
        <InputArea style={{width: "175px", color: "#000", fontSize:"12px" }}>
          <Label style={{color: "#fff" }}>Número Fogo Pneu*</Label>
          {tireFire === false?
         <Select 
          name={"tire"}
          onChange = {(item) => onTireChange(item)}       
          options = {dadosTires.current} 
          theme = {theme => ({
            ...theme,
            colors: {
             ...theme.colors,
             primary: "#2c73d2",
             secondary: "#bbb",
            },
          })}
          /> : 
          <InputArea>
              <Select1 disabled={tireFire} onChange={onTireChange}   name="tire">
              <option value="">Selecione</option>
              {tireRegistration === undefined ? [] : tireRegistration.map((item, i) => (
                <option key={i} value={item.id}>{item.fire_number}</option>
              ))}
            </Select1>
          </InputArea>}
        </InputArea>

        <InputArea>
          <Label>Status Pneu</Label>
          <Select1 disabled={tireStatus} onChange={onStatusTireChange} name="tire_status">
            <option value="">Selecione</option>
            <option value={1}>Transito</option>
            <option value={2}>Manutenção</option>
            <option value={3}>Sucata</option>
            <option value={4}>Finalização do Transito</option>
          </Select1>
        </InputArea>
        <InputArea style={{width: "175px", color: "#000", fontSize:"12px" }}>
          <Label style={{color: "#fff" }}>Veículo*</Label>
          {vehicleList === false?
         <Select 
          name={"vehicle"}
          onChange = {(item) => onGetAxisPositioning(item)}       
          options = {dadosVehicle.current} 
          theme = {theme => ({
            ...theme,
            colors: {
             ...theme.colors,
             primary: "#2c73d2",
             secondary: "#bbb",
            },
          })}
          /> : <Select1 onChange={onGetAxisPositioning} disabled={vehicleList} name="vehicle">
          <option value="">Selecione</option>
          {vehicles === undefined ? [] : vehicles.map((item, i) => (
            <option key={i} value={item.id}>{item.surname} - {item.plate}</option>
          ))}
        </Select1>}
        </InputArea>
        <InputArea>
          <Label>Posicionamento (Eixo)</Label>
          <Select1 onChange={onSavePositioning} disabled={axisPositioning} name="axis_positioning">
            <option value="">Selecione</option>
            <option value={0}>----------------------</option>
            <option value={0}>EIXO 1 - 2 PNEUS</option>
            <option value={1}> 1 ( Esquerdo )</option>
            <option value={2}> 2 ( Direito )</option>

            {axis1.value === "none" ? "" : <option value={0}>----------------------</option>}
            {axis1.value === "none" ? "" : <option value={0}> EIXO 2 - 2 PNEUS</option>}
            {axis1.value === "none" ? "" : <option value={3}> 3 ( Esquerdo )</option>}
            {axis1.value === "none" ? "" : <option value={4}> 4 ( Direito )</option>}

            {axis2.value === "none" ? "" : <option value={0}>----------------------</option>}
            {axis2.value === "none" ? "" : <option value={0}> EIXO 2 - 4 PNEUS</option>}
            {axis2.value === "none" ? "" : <option value={5}> 3 ( Esquerdo - Externo )</option>}
            {axis2.value === "none" ? "" : <option value={6}> 4 ( Esquerdo - Interno )</option>}
            {axis2.value === "none" ? "" : <option value={7}> 5 ( Direito - Interno )</option>}
            {axis2.value === "none" ? "" : <option value={8}> 6 ( Direito - Externo )</option>}

            {axis3.value === "none" ? "" : <option value={0}>----------------------</option>}
            {axis3.value === "none" ? "" : <option value={0}> EIXO 3 - 4 PNEUS</option>}
            {axis3.value === "none" ? "" : <option value={9}> 7 ( Esquerdo - Externo )</option>}
            {axis3.value === "none" ? "" : <option value={10}> 8 ( Esquerdo - Interno )</option>}
            {axis3.value === "none" ? "" : <option value={11}> 9 ( Direito - Interno )</option>}
            {axis3.value === "none" ? "" : <option value={12}> 10 ( Direito - Externo)</option>}

            {axis4.value === "none" ? "" : <option value={0}>----------------------</option>}
            {axis4.value === "none" ? "" : <option value={0}> EIXO 4 - 4 PNEUS</option>}
            {axis4.value === "none" ? "" : <option value={13}> 11 ( Esquerdo - Externo )</option>}
            {axis4.value === "none" ? "" : <option value={14}> 12 ( Esquerdo - Interno )</option>}
            {axis4.value === "none" ? "" : <option value={15}> 13 ( Direito - Interno )</option>}
            {axis4.value === "none" ? "" : <option value={16}> 14 ( Direito - Externo)</option>}

            <option value={0}>----------------------</option>
            <option value={0}> Estepe </option>
            <option value={17}> 1 ( Esquerdo )</option>
            {tireSteppes.value === "none" ? "" : <option value={18}> 2 ( Direito )</option>}
            <option value={0}></option>

          </Select1>
        </InputArea>
        <InputArea>
          <Label>Km V. momento da inst.*</Label>
          <Input2 style={{ color: (kmVMomentOfInst === true ? "#fff" : "#000") }} disabled={kmVMomentOfInst} name="km_v_moment_of_inst" type="number" pattern="/([0-9]{3}).([0-9]{3}),([0-9]{2})$/g, '.$1.$2,$3'" min="0" step="any" placeholder="Digite só número" />
        </InputArea>
        <InputArea>
          <Label>QTd Km Pneu irá Rodar*</Label>
          <Input2 style={{ color: (qtyKmTireWillBeRotated === true ? "#fff" : "#000") }} onChange={onCalKmChange} disabled={qtyKmTireWillBeRotated} name="qty_km_tire_will_be_rotated" type="number" pattern="/([0-9]{3}).([0-9]{3}),([0-9]{2})$/g, '.$1.$2,$3'" min="0" step="any" placeholder="Digite só número" />
        </InputArea>
        <InputArea>
          <Label>Trocar com ( km )*</Label>
          <Input2 style={{width: "123px", color: "#fff" }} onChange={onCalKmChange} disabled={true} name="exchange_with_km" type="number" pattern="/([0-9]{3}).([0-9]{3}),([0-9]{2})$/g, '.$1.$2,$3'" min="0" step="any" placeholder="Digite só número" />
        </InputArea>
        <InputArea>
          <Label>Já Rodou ( km )*</Label>
          <Input2 style={{width: "123px", color: "#fff" }} disabled={true} name="already_driven_km" type="number" pattern="/([0-9]{3}).([0-9]{3}),([0-9]{2})$/g, '.$1.$2,$3'" min="0" step="any" placeholder="Digite só número" />
        </InputArea>
        <InputArea>
          <Label>Recapes</Label>
          <Select1 style={{ width: "100px" }} name="recaps" disabled={kmVMomentOfInst}>
            <option value="">Selecione</option>
            <option value={0}>0</option>
            <option value={1}>1</option>
            <option value={2}>2</option>
            <option value={3}>3</option>
            <option value={4}>4</option>
            <option value={5}>5</option>
            <option value={6}>6</option>
            <option value={7}>7</option>
          </Select1>
        </InputArea>

        <div style={{
          display: releasePanel.value,
          width: "100%",
          marginBottom: "10px",
          marginTop: "1%",
          border: "1px solid #fff",
          borderRadius: "5px",
          backgroundColor: "#333",
          color: "#fff",
          padding: "10px",
          gap: "10px"
        }} >
          <Typography style={{ color: "#fff" }} >
            <InputArea>
              <Label>Posicionamento (Pneus)</Label>
              <Label>Capacidade de Eixo: {axisQuantity}</Label>
              <Label>Capacidade de Estepe: {quantitySteppes}</Label>
              <Label>Capacidade Total de Pneus: {totalQuantityTires} </Label>
            </InputArea>
          </Typography>
          {/*EIXO 1 */}
          <div style={{
            display: "column",
            justifyContent: "space-between",
            gap: "10px",
            marginBottom: "10px",
            padding: "10px",
            borderEndStartRadius: "25px",
            borderStartStartRadius: "25px",
            borderLeft: "2px solid rgba(255, 255, 255, 1.155)",
            borderTop: "2px solid rgba(255, 255, 255, 1.155)",
            borderBottom: "2px solid rgba(255, 255, 255, 1.155)"
          }} >
            <Label>EIXO 1 - 2 PNEUS</Label>
            <InputArea style={{ marginBottom: "0", marginTop: "43%" }}>
              <Label>D-2</Label>
              <Input2 style={{ color: "#fff", width: "120px", border:(tireP === 2 ? "2px solid rgba(158, 235, 7, 1.155)" : "1px solid rgba(255, 255, 255, 1.155)") }} disabled={true} name="tire_2" />
            </InputArea>
            <InputArea>
              <Label>|----------|||----------</Label>
            </InputArea>
            <InputArea >
              <Input2 style={{ color: "#fff", width: "120px", border:(tireP === 1 ? "2px solid rgba(158, 235, 7, 1.155)" : "1px solid rgba(255, 255, 255, 1.155)") }} disabled={true} name="tire_1" />
              <Label>E-1</Label>
            </InputArea>
          </div>
          {/*EIXO 2 */}
          <div style={{
            display: axis1.value,
            justifyContent: "space-between",
            gap: "10px",
            marginBottom: "10px",
            marginLeft: "-10px",
            padding: "10px",
            borderTop: "2px solid rgba(255, 255, 255, 1.155)",
            borderBottom: "2px solid rgba(255, 255, 255, 1.155)"
          }} >
            <Label>EIXO 2 - 2 PNEUS</Label>
            <InputArea style={{ marginBottom: "0", marginTop: "43%" }}>
              <Label>D-4</Label>
              <Input2 style={{ color: "#fff", width: "120px", border:(tireP === 4 ? "2px solid rgba(158, 235, 7, 1.155)" : "1px solid rgba(255, 255, 255, 1.155)") }} disabled={true} name="tire_4" />
            </InputArea>
            <InputArea>
              <Label>-----------|||----------</Label>
            </InputArea>
            <InputArea >
              <Input2 style={{ color: "#fff", width: "120px", border:(tireP === 3 ? "2px solid rgba(158, 235, 7, 1.155)" : "1px solid rgba(255, 255, 255, 1.155)") }} disabled={true} name="tire_3" />
              <Label>E-3</Label>
            </InputArea>
          </div>
          {/*EIXO 3 */}
          <div style={{
            display: axis2.value,
            justifyContent: "space-between",
            gap: "10px",
            marginBottom: "10px",
            marginLeft: "-10px",
            padding: "10px",
            borderTop: "2px solid rgba(255, 255, 255, 1.155)",
            borderBottom: "2px solid rgba(255, 255, 255, 1.155)"
          }} >
            <Label>EIXO 2 - 4 PNEUS</Label>
            <InputArea>
              <Label>DE-6</Label>
              <Input2 style={{ color: "#fff", width: "120px", border:(tireP === 8 ? "2px solid rgba(158, 235, 7, 1.155)" : "1px solid rgba(255, 255, 255, 1.155)") }} disabled={true} name="tire_8" />
            </InputArea>
            <InputArea>
              <Label>DI-5 ___|||___</Label>
              <Input2 style={{ color: "#fff", width: "120px", border:(tireP === 7 ? "2px solid rgba(158, 235, 7, 1.155)" : "1px solid rgba(255, 255, 255, 1.155)") }} disabled={true} name="tire_7" />
            </InputArea>
            ----------|||----------
            <InputArea>
              <Input2 style={{ color: "#fff", width: "120px", border:(tireP === 6 ? "2px solid rgba(158, 235, 7, 1.155)" : "1px solid rgba(255, 255, 255, 1.155)") }} disabled={true} name="tire_6" />
              <Label>EI-4 ___|||___</Label>
            </InputArea>
            <InputArea>
              <Input2 style={{ color: "#fff", width: "120px", border:(tireP === 5 ? "2px solid rgba(158, 235, 7, 1.155)" : "1px solid rgba(255, 255, 255, 1.155)") }} disabled={true} name="tire_5" />
              <Label>EE-3</Label>
            </InputArea>
          </div>
          {/*EIXO 4 */}
          <div style={{
            display: axis3.value,
            justifyContent: "space-between",
            gap: "10px",
            marginBottom: "10px",
            marginLeft: "-10px",
            padding: "10px",
            borderTop: "2px solid rgba(255, 255, 255, 1.155)",
            borderBottom: "2px solid rgba(255, 255, 255, 1.155)"
          }} >
            <Label>EIXO 3 - 4 PNEUS</Label>
            <InputArea>
              <Label>DE-10</Label>
              <Input2 style={{ color: "#fff", width: "120px", border:(tireP === 12 ? "2px solid rgba(158, 235, 7, 1.155)" : "1px solid rgba(255, 255, 255, 1.155)") }} disabled={true} name="tire_12" />
            </InputArea>
            <InputArea>
              <Label>DI-9 ___|||___</Label>
              <Input2 style={{ color: "#fff", width: "120px", border:(tireP === 11 ? "2px solid rgba(158, 235, 7, 1.155)" : "1px solid rgba(255, 255, 255, 1.155)") }} disabled={true} name="tire_11" />
            </InputArea>
            ----------|||----------
            <InputArea>
              <Input2 style={{ color: "#fff", width: "120px", border:(tireP === 10 ? "2px solid rgba(158, 235, 7, 1.155)" : "1px solid rgba(255, 255, 255, 1.155)") }} disabled={true} name="tire_10" />
              <Label>EI-8 ___|||___</Label>
            </InputArea>
            <InputArea>
              <Input2 style={{ color: "#fff", width: "120px", border:(tireP === 9 ? "2px solid rgba(158, 235, 7, 1.155)" : "1px solid rgba(255, 255, 255, 1.155)") }} disabled={true} name="tire_9" />
              <Label>EE-7</Label>
            </InputArea>
          </div>
          {/*EIXO 5 */}
          <div style={{
            display: axis4.value,
            justifyContent: "space-between",
            gap: "10px",
            marginBottom: "10px",
            marginLeft: "-10px",
            padding: "10px",
            borderTop: "2px solid rgba(255, 255, 255, 1.155)",
            borderBottom: "2px solid rgba(255, 255, 255, 1.155)"
          }} >
            <Label>EIXO 4 - 4 PNEUS</Label>
            <InputArea>
              <Label>DE-14</Label>
              <Input2 style={{ color: "#fff", width: "120px", border:(tireP === 16 ? "2px solid rgba(158, 235, 7, 1.155)" : "1px solid rgba(255, 255, 255, 1.155)") }} disabled={true} name="tire_16" />
            </InputArea>
            <InputArea>
              <Label>DI-13 __|||___</Label>
              <Input2 style={{ color: "#fff", width: "120px", border:(tireP === 15 ? "2px solid rgba(158, 235, 7, 1.155)" : "1px solid rgba(255, 255, 255, 1.155)") }} disabled={true} name="tire_15" />
            </InputArea>
            ----------|||----------
            <InputArea>
              <Input2 style={{ color: "#fff", width: "120px", border:(tireP === 14 ? "2px solid rgba(158, 235, 7, 1.155)" : "1px solid rgba(255, 255, 255, 1.155)") }} disabled={true} name="tire_14" />
              <Label>EI-12 __|||___</Label>
            </InputArea>
            <InputArea>
              <Input2 style={{ color: "#fff", width: "120px", border:(tireP === 13 ? "2px solid rgba(158, 235, 7, 1.155)" : "1px solid rgba(255, 255, 255, 1.155)") }} disabled={true} name="tire_13" />
              <Label>EE-11</Label>
            </InputArea>
          </div>
          {/*Estepe */}
          <div style={{
            display: axisSteppes.value,
            justifyContent: "space-between",
            gap: "10px",
            marginBottom: "10px",
            marginLeft: "-10px",
            padding: "10px",
            borderTop: "2px solid rgba(255, 255, 255, 1.155)",
            borderBottom: "2px solid rgba(255, 255, 255, 1.155)",
            borderRight: "2px solid rgba(255, 255, 255, 1.155)"
          }} >
            <Label>Estepe</Label>
            <InputArea style={{ display: tireSteppes.value, marginBottom: "0", marginTop: "43%" }} >
              <Label>D-2</Label>
              <Input2 style={{ color: "#fff", width: "120px", border:(tireP === 18 ? "2px solid rgba(158, 235, 7, 1.155)" : "1px solid rgba(255, 255, 255, 1.155)") }} disabled={true} name="tire_18" />
            </InputArea>
            <InputArea style={{ display: tireSteppes.value }} >
              ----------|||---------|
            </InputArea>
            <InputArea>
              <Input2 style={{ color: "#fff", width: "120px", border:(tireP === 17 ? "2px solid rgba(158, 235, 7, 1.155)" : "1px solid rgba(255, 255, 255, 1.155)") }} disabled={true} name="tire_17" />
              <Label>E-1</Label>
            </InputArea>
          </div>
        </div>

        <div style={{
          display: maintenancePanel.value,
          width: "100%",
          marginBottom: "10px",
          marginTop: "1%",
          border: "1px solid #fff",
          borderRadius: "5px",
          backgroundColor: "#333",
          color: "#fff",
          padding: "10px",
          gap: "10px"
        }} >
          <InputArea>
            <Label>Manutenção / Observação*</Label>
            <textarea style={{
              width: "280px",
              height: "calc(100vh - 650px)",
              border: "1px solid #343f46",
              textTransform: "uppercase",
              fontSize: "12px"
            }} name="maintenance_obs"
            />
          </InputArea>
          <InputArea>
            <Label>Km Atual Veículo*</Label>
            <Input2 name="maintenance_km_vehicle" type="number" pattern="/([0-9]{3}).([0-9]{3}),([0-9]{2})$/g, '.$1.$2,$3'" min="0" step="any" placeholder="Digite só número" />
          </InputArea>
        </div>

        <div style={{
          display: scrapPanel.value,
          width: "100%",
          marginBottom: "10px",
          marginTop: "1%",
          border: "1px solid #fff",
          borderRadius: "5px",
          backgroundColor: "#333",
          color: "#fff",
          padding: "10px",
          gap: "10px"
        }} >
          <InputArea>
            <Label>Sucata / Observação*</Label>
            <textarea style={{
              width: "280px",
              height: "calc(100vh - 650px)",
              border: "1px solid #343f46",
              textTransform: "uppercase",
              fontSize: "12px"
            }} name="scrap_obs"
            />
          </InputArea>
          <InputArea>
            <Label>Km Atual Veículo*</Label>
            <Input2 name="scrap_km_vehicle" type="number" pattern="/([0-9]{3}).([0-9]{3}),([0-9]{2})$/g, '.$1.$2,$3'" min="0" step="any" placeholder="Digite só número" />
          </InputArea>
        </div>

        <div style={{
          display: tCompleted.value,
          justifyContent: "space-between",
          width: "100%",
          marginBottom: "10px",
          border: "1px solid #fff",
          borderRadius: "5px",
          backgroundColor: "#333",
          color: "#fff",
          padding: "10px",
        }} >

          <Typography style={{ color: "#fff" }} >
            Finalização do Transito
          </Typography>
          <InputArea>
            <Label>Transito Finalizado</Label>
            <Select1 onChange={onTransitCompletedChange} disabled={transitCompleted} name="transit_completed">
              <option value={1}>Não</option>
              <option value={2}>Sim</option>
            </Select1>
          </InputArea>
          <InputArea>
            <Label>Km Final Veículo</Label>
            <Input2 disabled={finalKmVehicle} style={{ color: (finalKmVehicle ? "#fff" : "#000") }} name="final_km_vehicle" type="number" pattern="/([0-9]{3}).([0-9]{3}),([0-9]{2})$/g, '.$1.$2,$3'" min="0" step="any" placeholder="Digite só número" />
          </InputArea>
          <InputArea>
            <Label>Data Final Transito</Label>
            <Input2 disabled={transitEndDate} style={{ color: (transitEndDate ? "#fff" : "#000") }} type="date" name="transit_end_date" />
          </InputArea>
        </div>

        <div style={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
        }} >

          <ButtonArea >
            {st === false ? null : <Button type="submit">SALVAR</Button>}
          </ButtonArea>

          {/*  <ButtonArea >
            {regist === true ?
              null
              :
              <Button
                style={{ backgroundColor: "red" }}
                onClick={handleDelete}>EXCLUIR <DeleteIcon />
              </Button>
            }
          </ButtonArea> */}
          <ButtonArea >
            <Button onClick={handleClose}>SAIR</Button>
          </ButtonArea>
        </div>

      </FormContainer>

      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 5 }}
        open={openL}
      >
         <Label>Carregando...</Label>
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};

export default FromTireMovement;