import React, { useEffect, useRef } from "react";
import axios from "axios";
import {toast } from "react-toastify";
import Typography from '@mui/material/Typography';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import DeleteIcon from '@mui/icons-material/Delete';

import { 
  FormContainer, 
  InputArea, 
  Label, 
  Input, 
  Select1, 
  ButtonArea, 
  Button 
} from "../Styles/Styles";


const FromBillsToPay = ({handleCloseL, openL, handleOpenL, regist, onEdit, setOnEdit, getBillsToPay, setOpen, supplier }) => {
  const ref = useRef();
  const handleClose = () => setOpen(false);
  const IdCompany = localStorage.getItem("id_company");
  const IdUser = localStorage.getItem("id");
  const [supplierCNPJ, setSupplierCNPJ] = React.useState("");
  const [supplierName, setSupplierName] = React.useState(false);

  const onKmSupplierChange = (event) =>{
   const res = supplier.filter((a) => (a.id === event.target.value ));
   // eslint-disable-next-line
       res.map((a) => {
        setSupplierCNPJ(a.cnpj);
        setSupplierName(a.name);
       });
  }

  const onKmSupplierDataChange = () =>{
    const billsToPay = ref.current;
      const res = supplier.filter((a) => (a.id === billsToPay.id_supplier.value ));
       // eslint-disable-next-line
       res.map((a) => {
        setSupplierCNPJ(a.cnpj);
        setSupplierName(a.name);
       });
   }

  function adicionaZero(numero) {
    if (numero <= 9)
        return "0" + numero;
    else
        return numero;
  }

  let dataAtual = new Date();
  let dataAtualFormatada = (dataAtual.getFullYear() + "-" + (adicionaZero(dataAtual.getMonth() + 1).toString()) + "-" + adicionaZero(dataAtual.getDate().toString()));


  const limparCampos = () => {
    const billsToPay = ref.current;

    billsToPay.cod.value = "";
    billsToPay.issuance_date.value = dataAtualFormatada; 
    billsToPay.invoice.value = "";
    billsToPay.due_date.value = "";
    billsToPay.species.value = ""; 
    billsToPay.document.value = "";
    billsToPay.account_code.value = 2; 
    billsToPay.historic.value = ""; 
    billsToPay.amount.value = "";
  }

  useEffect(() => {
    const billsToPay = ref.current;
      if(regist) {
       limparCampos();
    } else {
    if (onEdit) {
      const res = supplier.filter((a) => (a.cnpj === onEdit.cnpj_cpf_supplier ));
       // eslint-disable-next-line
       res.map((a) => {
        billsToPay.id_supplier.value = a.id;
        setSupplierCNPJ(a.cnpj);
        setSupplierName(a.name);
       });
      billsToPay.cod.value = onEdit.id;
      billsToPay.issuance_date.value = onEdit.issuance_date; 
      billsToPay.invoice.value = onEdit.invoice; 
      billsToPay.due_date.value = onEdit.due_date; 
      billsToPay.species.value = onEdit.species; 
      billsToPay.document.value = onEdit.document; 
      billsToPay.account_code.value = onEdit.account_code; 
      billsToPay.historic.value = onEdit.historic; 
      billsToPay.amount.value = onEdit.amount; 
    };
  };
  // eslint-disable-next-line
  }, []);

  const handleDelete = async (e) => {
    e.preventDefault();
    const billsToPay = ref.current;
    handleOpenL();
    
      if (!window.confirm("Tem certeza que deseja excluir?")) {
        handleCloseL();
        return;
      } else {
      await axios
      .delete("financial/bills-to-pay-list/" + billsToPay.cod.value)
      .then(() => {
          toast.success("Cadastro excluido com sucesso!");
          getBillsToPay();
          setOnEdit(null);
          handleClose();
      })
      .catch(() => toast.error("Erro, não foi possível excluir!"));
      }
    
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    handleOpenL();
  
    const billsToPay = ref.current;
   
    if (
      !billsToPay.issuance_date.value 
    ) {
      handleCloseL();
      return toast.warn("Preencha o campos Nome !");
    };
    if (
      !billsToPay.id_supplier.value 
    ) {
      handleCloseL();
      return toast.warn("Preencha o campos Fornecedor!");
    };
    if (
      !billsToPay.due_date.value 
    ) {
      handleCloseL();
      return toast.warn("Preencha o campos Data de Vencimento!");
    };
    if (
      !billsToPay.amount.value 
    ) {
      handleCloseL();
      return toast.warn("Preencha o campos Valor!");
    };
    if (
      !billsToPay.document.value 
    ) {
      handleCloseL();
      return toast.warn("Preencha o campos Número do Documento!");
    };
    if (
      !billsToPay.account_code.value 
    ) {
      handleCloseL();
      return toast.warn("Preencha o campos Código da Conta!");
    };
    if (
      !billsToPay.species.value 
    ) {
      handleCloseL();
      return toast.warn("Preencha o campos Espécie!");
    };
    if (
      !billsToPay.historic.value 
    ) {
      handleCloseL();
      return toast.warn("Preencha o campos Histórico!");
    };

    onKmSupplierDataChange();

    if (onEdit) {
      await axios
        .put("financial/bills-to-pay-list/", {
          id: billsToPay.cod.value,
          issuance_date: billsToPay.issuance_date.value,
          due_date: billsToPay.due_date.value,
          payment_date: onEdit.payment_date,
          account_code: billsToPay.account_code.value,
          cnpj_cpf_supplier: supplierCNPJ,
          supplier_name: supplierName,
          amount: billsToPay.amount.value,
          amount_paid: onEdit.amount_paid,
          liquid: onEdit.liquid,
          discount: onEdit.discount,
          addition: onEdit.addition,
          document: billsToPay.document.value,
          invoice: billsToPay.invoice.value,
          historic: billsToPay.historic.value,
          species: billsToPay.species.value,
          situation: onEdit.situation,
          user: IdUser,
        })
        .then(() => {
          toast.success("Cadastro atualizado com sucesso!");

          limparCampos();
          
          getBillsToPay();
          setOnEdit(null);
          handleClose();
        })
        .catch(() => {
          toast.error("Erro na atualizado!");
          handleCloseL();
        });
    } else {
      await axios
        .post("financial/bills-to-pay-list/", { 
          issuance_date: billsToPay.issuance_date.value,
          due_date: billsToPay.due_date.value,
          account_code: billsToPay.account_code.value,
          cnpj_cpf_supplier: supplierCNPJ,
          supplier_name: supplierName,
          amount: billsToPay.amount.value,
          document: billsToPay.document.value,
          invoice: billsToPay.invoice.value,
          historic: billsToPay.historic.value,
          species: billsToPay.species.value,
          situation: 1,
          user: IdUser,
          id_company: IdCompany,

        })
        .then(() => {
          toast.success("Cadastrado com sucesso!");
          limparCampos();
          getBillsToPay();
          setOnEdit(null);
          handleClose();
        })
        .catch(() => {
          toast.error("Erro no Cadastro!");
          handleCloseL();
        });
        
    }


  };

  return (
    <>
          <Typography style={{ color: "#fff" }} id="modal-modal-title" variant="h6" component="h2">
            Ficha Contas a pagar
          </Typography>
          <FormContainer  ref={ref} onSubmit={handleSubmit}>
            <InputArea>
              <Label>Código</Label>
              <Input style={{ color: "#fff" }} disabled={true} name="cod"  />
            </InputArea>
            <InputArea>
              <Label>Emissão*</Label>
              <Input type="date" name="issuance_date" />
            </InputArea>
            <InputArea>
              <Label>Fornecedor</Label>
              <Select1 onChange={onKmSupplierChange} style={{ width: "350px" }} name="id_supplier">
                <option value="">Selecione</option>
                {supplier === undefined ? [] : supplier.map((item, i) => (
                  <option key={i} value={item.id}>{item.name}</option>
                ))}
              </Select1>
            </InputArea>
            <InputArea>
              <Label>Nota Fiscal*</Label>
              <Input  name="invoice" />
            </InputArea>
            <InputArea>
              <Label>Vencimento*</Label>
              <Input type="date" name="due_date" />
            </InputArea>
            <InputArea>
              <Label>Documento*</Label>
              <Input name="document" />
            </InputArea>
            <InputArea>
              <Label>Espécie*</Label>
              <Select1 name="species">
                  <option value="">Selecione</option>
                  <option value={1}>Dinheiro</option>
                  <option value={2}>Cheque</option>
                  <option value={3}>Cartão de Crédito</option>
                  <option value={4}>Cartão de Débito</option>
                  <option value={5}>Crédito Loja</option>
                  <option value={10}>Vale Alimentação</option>
                  <option value={11}>Vale Refeição</option>
                  <option value={12}>Vale Presente</option>
                  <option value={13}>Vale Combustível</option>
                  <option value={14}>Duplicata Mercantil</option>
                  <option value={15}>Boleto Bancário</option>
                  <option value={17}>PIX</option>
                  <option value={90}>Sem Pagamento</option>
                  <option value={99}>Outros</option>
              </Select1>
            </InputArea>
            <InputArea>
              <Label>Conta</Label>
              <Select1 style={{ width: "350px" }} name="account_code">
                <option value={2}>DESPESAS OPERACIONAIS</option>
              </Select1>
            </InputArea>
            <InputArea>
              <Label>Histórico*</Label>
              <Input style={{ width: "710px" }} name="historic" />
            </InputArea>
            <InputArea>
              <Label>Valor R$*</Label>
              <Input name="amount" type="number" pattern="[0-9]+([,\.][0-9]+)?" min="0" step="any" placeholder="Digite só número"/>
            </InputArea>
                        
            <div style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }} >
              <ButtonArea >
                <Button type="submit">SALVAR</Button>
                { regist === true? 
                 null
                 : 
                 <Button 
                    style={{backgroundColor:"red"}} 
                    onClick={handleDelete}>EXCLUIR <DeleteIcon/>
                 </Button>
                 }
                
                <Button onClick={handleClose}>SAIR</Button>
              </ButtonArea>
            </div>

          </FormContainer>
          
      <Backdrop
              sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 5}}
              open={openL}
          >
         <Label>Carregando...</Label>
              <CircularProgress color="inherit" />
          </Backdrop>
    </>
  );

};


export default FromBillsToPay;