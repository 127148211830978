
import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from 'react-router-dom';
import { signout } from "../../../Services/UserService";
import ContentPage from "../../../components/Content/ContentPage";
import ModalTireRegistration from "../../../components/Tires/TireRegistration/ModalTireRegistration";
import GridTireRegistration from "../../../components/Tires/TireRegistration/GridTireRegistration";
import { toast } from "react-toastify";
import axios from "axios";

import {
  FormContainer,
  Loc,
  InputArea,
  Button
} from "../../Styles/Styles";

const TireRegistrationList = () => {
  const navigate = useNavigate();
  const [tireRegistration, setTireRegistration] = useState([]);
  const [onEdit, setOnEdit] = useState(null);
  const [open, setOpen] = React.useState(false);
  const [supplier, setSupplier] = useState([]);
  const [loc, setLoc] = React.useState();
  const [regist, setRegist] = React.useState(false);
  const IdCompany = localStorage.getItem("id_company");
  const identification = useRef([]);
  const category = useRef(null);

  const [openL, setOpenL] = React.useState(false);

  const handleCloseL = () => {
    setOpenL(false);
  };
  const handleOpenL = () => {
    setOpenL(true);
  };


  const handleOpen = () => {
    setOpen(true);
    setRegist(true);
  };


  const getsetIdentification = async () => {
    handleOpenL();
    identification.current = [];
    try {

      const res = await axios.get("tires/tire-identification-list/" + IdCompany + "/" + (category.current === null ? "1" : category.current) + "/" + "1" + "/?loc=%");
      identification.current = (res.data.sort((a, b) => (a.id > b.id ? 1 : -1)));
      handleCloseL();

    } catch (error) {
      toast.error(error);
      handleCloseL();
    }
  };

  const getSupplier = async () => {
    setSupplier([]);
    setOnEdit(null);
    try {
      const res = await axios.get("supplier/supplier-list/" + IdCompany + "/?loc=%");

      setSupplier(res.data.sort((a, b) => (a.id > b.id ? 1 : -1)));

    } catch (error) {
      toast.error(error);
    }
  };

  const getTireRegistration = async () => {
    setTireRegistration([]);
    setOnEdit(null);
    try {
      const res = await axios.get("tires/tire-registration-list/" + IdCompany + "/?loc=" + (loc === undefined ? '%' : loc));
      if (res.data[0].status === "false") {
        toast.error(res.data[0].message);
        signout();
        navigate("/login");
      } else {
        setTireRegistration(res.data.sort((a, b) => (a.id > b.id ? 1 : -1)));
      }
      handleCloseL();
    } catch (error) {
      toast.error(error);
      handleCloseL();
    }
  };

  useEffect(() => {
    getSupplier();
    getsetIdentification();
    getTireRegistration();

    // eslint-disable-next-line
  }, []);

  return (
    <>
      <ContentPage caminho={[{ nome: "Pneu > Cadastro Pneus ", link: "/tire-registration/list" }]}>

        <ModalTireRegistration
          regist={regist}
          tireRegistration={tireRegistration}
          setTireRegistration={setTireRegistration}
          setLoc={setLoc}
          loc={loc}
          onEdit={onEdit}
          setOnEdit={setOnEdit}
          getTireRegistration={getTireRegistration}
          setOpen={setOpen}
          open={open}
          openL={openL}
          handleOpenL={handleOpenL}
          handleCloseL={handleCloseL}
          supplier={supplier}
          getsetIdentification={getsetIdentification}
          identification={identification}
          category={category}
        />

        <FormContainer>
          <Loc
            style={{
              width: "350px",
              height: "30px",
              border: "1px solid #343f46",
              textTransform: "uppercase",
              fontSize: "12px"
            }}
            type='text'
            placeholder="Digite Número Fogo"
            value={loc}
            onChange={(e) => [setLoc(e.target.value)]}
          />

          <InputArea >
            <Button
              onClick={getTireRegistration}>Localizar
            </Button>
          </InputArea>
          <InputArea  >
            <Button
              onClick={handleOpen}>Novo
            </Button>
          </InputArea>
        </FormContainer>

        <GridTireRegistration openL={openL} regist={regist} setRegist={setRegist} tireRegistration={tireRegistration} setTireRegistration={setTireRegistration} setOnEdit={setOnEdit} setOpen={setOpen} />
      </ContentPage>
    </>
  );
};

export default TireRegistrationList;